import React from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { AppContext } from "../context/AppContext";
import PrivacyEN from "./PrivacyEN";
export default class TermsEN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isButtonEnabled: false,
    };
    this.scrollDiv = React.createRef();
  }

  static contextType = AppContext;

  componentDidMount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.addEventListener("scroll", this.handleScroll);
    }
  }

  /*
  componentWillUnmount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.removeEventListener("scroll", this.handleScroll);
    }
  }
 */

  show = async () => {
    this.setState({
      showModal: true,
    });
  };
  close = async () => {
    this.setState({
      showModal: false,
    });
  };

  accept = async () => {
    if (
      this.context.checkedTerms &&
      this.context.checkedPrivacy &&
      this.state.showModal &&
      this.context.approvedTP
    ) {
      setTimeout(() => {
        this.setState({ showModal: false });
      }, 1000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if both conditions are met and the modal is open
    if (
      this.context.checkedTerms &&
      this.context.checkedPrivacy &&
      this.state.showModal &&
      this.context.approvedTP == true
    ) {
      this.setState({ showModal: false });
    }
  }
  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleNext = () => {
    if (!this.context.checkedPrivacy) {
      this.privacy.show();
    } else {
      this.setState({ showModal: false });
    }
  };

  handleCheck = () => {
    this.setState({ isButtonEnabled: !this.state.isButtonEnabled });
    this.context.setCheckedTerms(!this.state.isButtonEnabled);
  };

  handleScroll = (event) => {

    const div = event.target;
    if (div.scrollHeight - div.scrollTop <= div.clientHeight + 500) {
      this.setState({ isButtonEnabled: true });
      this.context.setCheckedTerms(true);
    }
  };

  render() {
    const PrivacyComponent = PrivacyEN;
    return (
      <Modal
        show={this.state.showModal}
        onHide={this.handleClose}
        size="lg"
        centered
        id="termos-de-uso-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100 ml-3 text-center">
            <h4 className="legal-title">USE TERMS BC COINS - EXCHANGE</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="w-100 text2">
          <div
            ref={(div) => {
              this.scrollDiv = div;
            }}
            id="div-scroll"
            className="ml-5 mr-1 pr-5"
            style={{
              maxHeight: 300,
              overflowY: "scroll",
            }}
            onScroll={this.handleScroll}
          >
            <div className="legal-div">
              <p className="legal-text">
                <strong>BC COINS INTERMEDIADORA LTDA.</strong>, a private legal entity,
                registered under CNPJ number 21.830.817/0001-67, located at Avenida Osvaldo Reis,
                No. 3385, Room 1407, Praia Brava de Itajaí, in the city of Itajaí/SC, ZIP code
                88.306-003, is the holder of all rights and responsible for the online platform
                for the intermediary of buying and selling crypto assets "SERVICES," made
                available through the website hosted under the domain www.bcxcorretora.com.br,
                referred to in these TERMS as the "PLATFORM."
              </p>
              <div>
                <h5 className="legal-subtitle">1. Acceptance</h5>
                <p className="legal-text">
                  1.1. Before using the SERVICES offered by BC COINS, the USER agrees to
                  carefully read these TERMS, ensuring perfect understanding.
                </p>
                <p className="legal-text">
                  1.2. By accepting these TERMS, the USER agrees to the provisions therein,
                  accepting and binding themselves to them, and declares that they are aware
                  that the TERMS may be changed over time, with their responsibility to
                  periodically check, especially before using the PLATFORM SERVICES.
                </p>
                <p className="legal-text">
                  1.2.2 In case of changes, BC COINS will inform the USER on the PLATFORM
                  and/or by email. For the USER's convenience, the date of the last change
                  is listed at the end for easy reference.
                </p>
                <p className="legal-text">
                  1.3. If there is any objection to any provision of these TERMS, even after
                  acceptance, the USER must immediately cease using the PLATFORM, applying
                  the rules provided here during the period of use, if any.
                </p>
                <p className="legal-text">
                  1.4. To use the SERVICES of the PLATFORM, the USER must have knowledge
                  about what crypto assets are, the crypto asset market, and have considered
                  their risk. The value of crypto assets can vary over time, and the USER is
                  aware of this circumstance and accepts it at their own risk, consciously and
                  informed.
                </p>
                <p className="legal-text">
                  1.5. The PLATFORM provides SERVICES for the intermediary of buying and
                  selling crypto assets; therefore, the responsibility for the operations
                  carried out through it lies solely with the USER. The PLATFORM does not
                  provide any investment advice.
                </p>
                <p className="legal-text">
                  1.6 The use of the PLATFORM by individuals under 18 (eighteen) years of age
                  is not allowed.
                </p>

                <h5 className="legal-subtitle">2. The PLATFORM</h5>
                <p className="legal-text">
                  2.1. BC COINS may update the PLATFORM whenever it wishes or deems
                  necessary, as well as cease to make it available, update it, or
                  discontinue it at any time.
                </p>
                <p className="legal-text">
                  2.2. In case of discontinuity of the PLATFORM, BC COINS will inform USERS
                  with a notice of 60 (sixty) days in advance.
                </p>

                <h5 className="legal-subtitle">3. Registration and Personal Identification</h5>
                <p className="legal-text">
                  3.1. To use the PLATFORM, it is necessary to register and submit the
                  documentation mentioned in the <a href="src/panel/kyc">KYC Policy</a>,
                  which we recommend reading to access BC COINS SERVICES.
                </p>
                <p className="legal-text">
                  3.2. After completing the registration, an account will be created for the
                  USER, which will be accessed solely through login and password, which are
                  for personal and non-transferable use, being the responsibility of the USER
                  for their secure, responsible, and good-faith use.
                </p>
                <p className="legal-text">
                  3.2.1 BC COINS is not responsible for any misuse by the USER or third
                  parties, for their security failure, as well as for any attacks the USER
                  may suffer.
                </p>
                <p className="legal-text">
                  3.2.2 To provide more security to their account, the USER may activate the
                  mechanism provided by BC COINS through two-factor authentication (2FA).
                </p>
                <p className="legal-text">
                  3.3. Each USER may maintain only one account on the PLATFORM. If BC COINS
                  detects the existence of more than one account in the name of the same USER,
                  it may suspend one or all accounts, temporarily or permanently, or even
                  cancel the accounts, with prior notice to the USER.
                </p>
                <p className="legal-text">
                  3.4. The USER is entirely responsible for the information they provide,
                  which will be treated and protected according to the PRIVACY POLICY. If
                  irregularities are found in the registration, omission of information,
                  incorrect data, or erroneous filling, BC COINS may suspend the USER's
                  access to the registration area until the situation is regularized or even
                  cancel the account.
                </p>
                <p className="legal-text">
                  3.5. Registration is free, and it must be validated by BC COINS for the use
                  of the SERVICES provided on the PLATFORM. BC COINS may perform verification
                  procedures for data and request the USER to send documents and additional
                  information according to its KYC Policy.
                </p>
                <p className="legal-text">
                  3.5.1 The validation of the registration is at the discretion of BC COINS,
                  which may refuse a registration without the need to provide justification.
                </p>
                <p className="legal-text">
                  3.6. The USER accepts and agrees that any transaction must be carried out
                  solely by the account holder.
                </p>

                <h5 className="legal-subtitle">4. PLATFORM Operation</h5>
                <p className="legal-text">
                  4.1. The PLATFORM is intended for the intermediary of buying and selling
                  crypto assets between USERS, which can be done in two ways, through private
                  BUY and SELL ORDERS, through the tool called "OTC," or public orders, called
                  "book."
                </p>
                <p className="legal-text">
                  4.1.1. The crypto assets supported by the PLATFORM are listed on the BCX
                  website and can be accessed by clicking <a href="src/panel/tarifas">here</a>.
                  Attempting to send/receive unsupported crypto assets may result in
                  irreversible losses, which will be the responsibility of the USER.
                </p>
                <p className="legal-text">
                  4.1.2. The USER acknowledges and accepts that, unless there is explicit
                  notice from BC COINS to the contrary, the PLATFORM does not support crypto
                  assets derived from processes known as "hard forks."
                </p>
                <p className="legal-text">
                  4.2. USERS can make deposits in reais or crypto assets into their accounts
                  on the PLATFORM to carry out their transactions.
                </p>
                <p className="legal-text">
                  4.3. USER deposits in reais must be made by bank transfer to BC COINS,
                  so cash deposits made in any way, especially those made directly at the
                  counter, will not be accepted. Transfers from bank accounts of persons,
                  physical or legal, other than the USER, will also not be accepted.
                </p>
                <p className="legal-text">
                  4.3.1. If an attempt to transfer to BC COINS is confirmed through a cash
                  deposit or through third-party bank accounts other than the USER, BC COINS
                  will refund the deposited amount, retaining, however, the administrative fee.
                  The fee amount can be consulted by clicking <a href="src/panel/tarifas">here</a>.
                </p>
                <p className="legal-text">
                  4.3.2. In case of an unidentified cash deposit, BC COINS will refund the
                  amount only upon a court decision proving ownership of the funds.
                </p>
                <p className="legal-text">
                  4.4. Transactions related to the purchase and sale of crypto assets can be
                  carried out, respectively, through private BUY and SELL ORDERS via OTC,
                  directly via the book, directly on the platform at the market price, or via
                  our peer-to-peer tool (P2P Guaranteed).
                </p>
                <p className="legal-text">
                  4.4.1. Private BUY and SELL ORDERS must always be made for USERS already
                  registered and with KYC approved on the platform and will have a quotation
                  and validity period defined between the parties, who can use the chat tool
                  for negotiation.
                </p>
                <p className="legal-text">
                  4.4.1.1. Private BUY and SELL ORDERS, outside the book, can only be executed
                  in their entirety.
                </p>
                <p className="legal-text">
                  4.4.2. If the recipient USER of the private BUY or SELL ORDER does not
                  finalize the order, it will be transferred to the book, becoming a public
                  order.
                </p>
                <p className="legal-text">
                  4.5. It is clarified here that the negotiation is carried out directly
                  between USERS, without the interference of BC COINS, which only provides
                  the PLATFORM for negotiation and settlement of transactions.
                </p>
                <p className="legal-text">
                  4.6. To carry out buy/sell transactions on the PLATFORM, USERS must have
                  sufficient balance, in reais or crypto assets, previously deposited in their
                  accounts on the PLATFORM. Limits for deposits, withdrawals, and daily
                  transactions may be established according to the USER's profile. Check the
                  minimum and maximum limits of the PLATFORM <a href="src/panel/tarifas">here</a>.
                </p>
                <p className="legal-text">
                  4.6.1 The balance in reais/crypto assets corresponding to BUY/SELL ORDERS will be blocked in the USER's account until:
                </p>
                <p className="legal-text">
                  a) the buy/sell operation is consummated, and the mentioned balance is transferred to the seller/buyer USER's account; or
                </p>
                <p className="legal-text">
                  b) in case the BUY/SELL ORDER has not been accepted, and the buyer/seller USER cancels it.
                </p>
                <p className="legal-text">
                  4.6.2 Once the SELL ORDER is accepted by a buyer USER, the corresponding crypto assets will be transferred from the seller USER's account to the buyer USER's account. After completion, the sale operation cannot be undone or modified. Therefore, the cancellation of a BUY/SELL ORDER can only be done before its acceptance by another USER.
                </p>
                <p className="legal-text">
                  4.6.3 Public orders registered by USERS can be accepted by other USERS in total or in part. If an ORDER is partially accepted, the seller USER can:
                </p>
                <p className="legal-text">a) cancel the ORDER; or</p>
                <p className="legal-text">
                  b) keep the ORDER valid and wait for other USERS to accept the remaining crypto assets at the same fixed price in the ORDER.
                </p>
                <p className="legal-text">
                  4.6.4 It is prohibited to issue more than one SELL ORDER simultaneously for the same amount of crypto assets.
                </p>
                <p className="legal-text">
                  4.7. BC COINS may, at its sole discretion, set limits on user deposits and transactions, as well as change them at any time, according to parameters defined by BC COINS and with prior notice to USERS.
                </p>
                <p className="legal-text">
                  4.8. To withdraw reais or crypto assets from the PLATFORM, the USER must have the corresponding balance in their account and select their own checking account or wallet address, being entirely responsible for this information. The withdrawal amount will be blocked until the withdrawal is consummated, within a maximum period of 7 business days for reais and crypto assets, except for the specific deadline of the underlying Blockchain network of each crypto asset.
                </p>
                <p className="legal-text">
                  4.8.1. To perform transactions, the USER must have a sufficient balance to pay the transaction fees, which are available at the link xxxxx, and will be debited from the USER's balance, in Reais or in crypto assets, at the discretion of the PLATFORM.
                </p>
                <p className="legal-text">
                  4.9. The USER agrees not to use the PLATFORM to violate the law or engage in money laundering, financing of activities and/or organizations involving terrorism, drug trafficking, people and/or human organs.
                </p>
                <p className="legal-text">
                  4.10. BC COINS provides services for the intermediation of buying and selling crypto assets. In this risk segment, BC COINS cannot be considered responsible for any losses, damages, losses, and/or lost profits that USERS may suffer due to transactions carried out with crypto assets through the PLATFORM.
                </p>
                <p className="legal-text">
                  4.11. BC COINS is not an investment consultancy, nor do the SERVICES it provides imply any type of consultancy on investments in crypto assets. USERS do so at their own risk. BC COINS suggests that its USERS inform themselves about the operation of this segment, its appreciation and fluctuation before using the SERVICES of the PLATFORM.
                </p>
                <p className="legal-text">
                  4.12. The time for processing requested bank transactions is between 9:00 am and 5:00 pm (banking hours), from Monday to Friday, except holidays. Crypto asset transactions can be carried out by USERS, with a balance, at any time through the PLATFORM.
                </p>
                <p className="legal-text">
                  4.13. Fees and commission will be charged on the SERVICES according to the table available on the page: As per our table available on the site, which can be accessed by clicking here.
                  <a href="src/panel/tarifas">here</a>.
                </p>

                <h5 className="legal-subtitle">5. SUPPORT</h5>
                <p className="legal-text">
                  5.1. Support is limited to clarifications about the operation of the PLATFORM and the SERVICES offered, assuming the USER's knowledge of computer use and its functions, as well as the operating system under which the system will be used. It is also assumed the proper configuration of the computer and mobile device regarding the use of the PLATFORM and the good working condition of said computer.
                </p>
                <p className="legal-text">
                  5.2. Support will assist the USER on business days, from 8 am to 7 pm through the contact form or the online chat available on the PLATFORM, and BC COINS undertakes to provide a response within 7 business days, counted from the call made by the USER.
                </p>
                <p className="legal-text">
                  5.3. Support provided by BC COINS does not cover issues such as internet connection, internal network, and USER's computers/mobile devices.
                </p>

                <h5 className="legal-subtitle">6. INTELLECTUAL PROPERTY</h5>
                <p className="legal-text">
                  6.1. All intellectual property rights related to the PLATFORM belong to BC COINS, and the USER is granted only the limited right to use the interface to enjoy the SERVICES made available by it.
                </p>
                <p className="legal-text">
                  6.2. The PLATFORM may be improved, and temporary service suspension may occur. The USER hereby agrees to modifications, implementation of new features, tools, improvements, or corrections in the PLATFORM, and these will continue to be the sole and exclusive property of BC COINS.
                </p>
                <p className="legal-text">
                  6.3. Misuse of any content or trademarks of BC COINS is prohibited.
                </p>

                <h5 className="legal-subtitle">7. LIMITATION OF LIABILITY</h5>
                <p className="legal-text">
                  7.1. BC COINS will make every effort to provide the SERVICES; however, it cannot be held responsible for:
                </p>
                <p className="legal-text">
                  <ul>
                    <li>
                      (i) any defect resulting from the exclusive fault of the USER;
                    </li>
                    <li>
                      (ii) integration of the PLATFORM with any other third-party software or USER's software;
                    </li>
                    <li>
                      (iii) damages or losses resulting from administrative, managerial, or commercial decisions made by the USER based on the information provided by the PLATFORM;
                    </li>
                    <li>
                      (iv) problems in the USER's physical and logical network;
                    </li>
                    <li>
                      (v) any responsibility for eventual losses and damages, lost profits or emerging, whose causes may be directly or indirectly attributed to the supply, use, or performance of the PLATFORM, bug fixes, or update stops;
                    </li>
                    <li>
                      (vi) events defined in civil legislation as fortuitous event or force majeure;
                    </li>
                    <li>
                      (vii) dangerous and/or inadequate use of the PLATFORM;
                    </li>
                    <li>
                      (viii) technical factors that prevent data transfer and/or downloading of information from the PLATFORM, including internet problems; and
                    </li>
                    <li>
                      (ix) delays due to the validation period of transactions on the underlying Blockchain networks.
                    </li>
                  </ul>
                </p>
                <p className="legal-text">
                  7.2. In case of suspicion of fraud or any other illicit or malicious activity (e.g., suspicious activities manipulating market prices in an attempt to force the rise or fall of prices, beyond market values, due to capital injected into the platform, malicious bots, or individual or collective actions by user groups), BC COINS may, in addition to resorting to legal measures, at the effective moment of termination, withhold any USER's funds until the respective investigations are completed or even close the USER's account on the platform.
                </p>
                <p className="legal-text">
                  7.3. BC COINS reserves the right to deny the purchase request and refund the amount transferred by USERS, as well as cancel their accounts, in case of indications of, but not limited to:
                </p>
                <p className="legal-text">
                  <ul>
                    <li>
                      a) providing false, inaccurate, incomplete, or misleading information;
                    </li>
                    <li>b) signs of fraud;</li>
                    <li>
                      c) failure to provide additional information and/or documents after 30 days of the request, without the USER's response;
                    </li>
                    <li>d) bad faith;</li>
                    <li>
                      e) violation of any of the provisions of these TERMS;
                    </li>
                    <li>f) using the SERVICES in violation of the law;</li>
                    <li>
                      g) involvement in fraudulent or illegal conduct;
                    </li>
                  </ul>
                </p>

                <h5 className="legal-subtitle">8. FINAL CONSIDERATIONS</h5>
                <p className="legal-text">
                  8.1. The USER may, at any time, request the cancellation of their account. For this purpose, any balance, whether in reais or crypto assets, must be transferred to another account to be provided by the USER. In this case, only mandatory record-keeping information will be retained by BC COINS, for the time required by law.
                </p>
                <p className="legal-text">
                  8.2. In case of account suspension due to suspicion of fraud or illegality, balances may be blocked, without prejudice to the adoption of appropriate legal measures.
                </p>
                <p className="legal-text">
                  8.3. The incapacity or mere discretion in not enforcing any provisions of these TERMS does not constitute a waiver of rights, and enforcement can be demanded at any time.
                </p>
                <p className="legal-text">
                  8.4. If any provision of these TERMS is deemed unenforceable or invalid, that provision will be individually invalidated and will not affect the remaining provisions.
                </p>
                <p className="legal-text">
                  8.5. These TERMS and the relationship between the Parties will be governed by the laws of the Federative Republic of Brazil, especially the Civil Code and the Consumer Protection Code.
                </p>
                <p className="legal-text">
                  8.6. The Parties elect the Forum of the District of São Paulo as the only competent forum to settle any disputes and/or claims that may involve the Parties in relation to the SERVICE.
                </p>
                <p className="legal-subtitle">LAST UPDATE DATE: 02/08/2023</p>
                <div className="input-legal-div">
                  <input
                    type="checkbox"
                    className="input-legal"
                    onChange={this.handleCheck}
                    checked={this.state.isButtonEnabled}
                  ></input>
                  <span className="legal-text">
                    By selecting the checkbox and clicking "ACCEPT," the USER agrees to the provisions in these TERMS, accepting and binding themselves to them.
                  </span>
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={this.handleNext}
            disabled={!this.state.isButtonEnabled}
          >
            Accept
          </Button>
        </Modal.Footer>
        <PrivacyComponent ref={ref => this.privacy = ref} />
      </Modal>
    );
  }
}
