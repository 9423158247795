import React, { forwardRef, useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import dayjs from "dayjs";
import { formatDate, formatDateDay } from "../utils/helper";
import { numberFormatBRL } from "./InvestmentId";
import "../utils/trade.css";
import { formatBTCString } from "../utils/Helpers/btcFormat";
// import { findNewRanges, formatDateToBrazilian } from "../utils/dateComparation";
import { useTranslation, Trans } from 'react-i18next';
import '../i18n'


const InvestmentPDF = forwardRef((props, ref) => {

  const {
    btcPrice,
  } = useContext(AppContext);

  const { ranges } = props;
  const { investment } = props;

  const findRentability = (range) => {
    const filtered = range.dailyReports.filter((dr, i) =>
      // dayjs(dr.date).isSameOrBefore(dayjs(range.base))
      dr.appliedProfit == true
    )
    if (filtered.length !== 0) {
      const lastDr = filtered[filtered.length - 1];
      return (lastDr)
    } else {
      console.log('there is no rentability')
      return null
    }
  }


  const getLastMonthValue = (ranges, i, initialAmount) => {
    if (i !== 0) {
      const lastRange = ranges[i - 1];
      const dr = lastRange?.dailyReports[lastRange?.dailyReports?.length - 1];
      let finalAmount;
      if (dr == undefined) {
        finalAmount = initialAmount;
      } else {
        finalAmount = dr?.finalAmount;
      }
      return (finalAmount);
    }
    else return null;
  }

  const { t, i18n } = useTranslation();

  return (
    <div id="pdf" ref={ref} style={{ display: "none" }}>
      <div className="reports-trade-master ">
        {/* // Informações Gerais */}
        <main className="main-investmentList">
          <div className="balances pdf-top" style={{ marginBottom: '-200px' }}>
            <h2 className="margins">
              {t("generalinformation")}
            </h2>
            <div className="balance">
              <div className="balance-data" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>
                  {formatDate(investment.createdAt)} - {t("initialinvestment")}
                </span>
                <span>
                  {formatBTCString((investment.initialAmount) / btcPrice)}
                </span>
              </div>
              <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className="refferences" style={{ color: "#50504E", fontWeight: 400 }}>
                  {t("refference")} (R$):
                </span>
                <span className="refferences" style={{ color: "#50504E", fontWeight: 400 }}>
                  {numberFormatBRL(investment.initialAmount)}
                </span>
              </div>
              <div className="balance-data">
                <span>{t("creationdate")}</span>
                <span>
                  {formatDate(investment.createdAt)}
                </span>
              </div>
              <div className="balance-data">
                <span>{t("period")}</span>
                <span>
                  {investment.period} {investment.period > 1 ? t("months") : t("month")}
                </span>
              </div>
              <div className="balance-data">
                <span>{t("fundbday")}</span>
                <span>
                  {t("day")} {formatDateDay(investment.createdAt)}
                </span>
              </div>
              <div className="balance-data">
                <span>{t("bitcoinquotation")}-{dayjs().format("DD/MM/YYYY")}</span>
                <span>
                  {numberFormatBRL(btcPrice)}
                </span>
              </div>
            </div>
          </div>

          {/* // Informações Mensais */}
          {ranges && (
            <div className="balances">
              {ranges?.map((range, i) => (<>
                {dayjs(range.start).isSameOrBefore(dayjs()) && (<>

                  {/* // Título do Mês */}
                  <div className="monthly-balance">
                    <h2 className="inv-id-months" key={i + 10} style={{ fontWeight: 'bold' }}>
                      {t("month")} {i + 1} - <span id="inv-id-month-range" style={{ fontStyle: 'italic' }}>
                        {t("from")} {formatDate(range.start)} {t("to")} {formatDate(range.end)}
                      </span>
                    </h2>
                  </div>

                  {/* // Saldo Inicial do Mês */}
                  <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', }}>
                    <span className="refference">{formatDate(range.start)} - {t("initialbalancemonth")}:</span>
                    {(i === 0) &&
                      (<span className="refferences">
                        {formatBTCString((range.dailyReports.length === 0 ? investment.initialAmount : range.dailyReports[0]?.initialAmount) / btcPrice)}
                      </span>
                      )}
                    {((i !== 0) && range.dailyReports.length > 0) && (<span className="refferences">
                      {formatBTCString((getLastMonthValue(ranges, i, investment.initialAmount)) / btcPrice)}
                    </span>
                    )}
                    {((i !== 0) && range.dailyReports.length === 0) && (<span className="refferences">
                      {formatBTCString((getLastMonthValue(ranges, i, investment.initialAmount)) / btcPrice)}
                    </span>
                    )}
                  </div>

                  <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {(i === 0) &&
                      (<>
                        <span className="refferences">
                          {t("refference")} (R$):
                        </span>
                        <span className="refferences">
                          {numberFormatBRL(range.dailyReports.length === 0 ? investment.initialAmount : range.dailyReports[0]?.initialAmount)}
                        </span>
                      </>
                      )
                    }
                    {((i !== 0) && range.dailyReports.length > 0) && (<>
                      <span className="refferences">
                        {t("refference")} (R$):
                      </span>
                      <span className="refferences">
                        {numberFormatBRL(getLastMonthValue(ranges, i, investment.initialAmount))}
                      </span>
                    </>)
                    }
                    {((i !== 0) && range.dailyReports.length === 0) && (<>
                      <span className="refferences">
                        {t("refference")} (R$):
                      </span>
                      <span className="refferences">
                        {numberFormatBRL(getLastMonthValue(ranges, i, investment.initialAmount))}
                      </span>
                    </>)
                    }
                  </div>

                  {/* // Acontecimentos do Mês */}
                  {range.dailyReports?.map((dr, i) => {

                    // {/* // Saques */}
                    if (dr.withdraws.length !== 0) {
                      return (
                        dr.withdraws.map((w, i) => (
                          w.status === 'approved' && (<>
                            {w.type === 'liquidation' && (<>
                              <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                                <span className="inv-id-statement-text">{formatDate(w.updatedAt)} - {t("totalAmount")} {/* Saldo Total */}:</span>
                                <span className="inv-id-statement-text">
                                  {/* BTC { prepareValue((w.operatingFeeAmount) / btcPrice) } */}
                                  {formatBTCString((dr.initialAmount) / btcPrice)}
                                </span>
                              </div>
                              <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span className="inv-id-refBRL">
                                  {t("refference")} (R$):
                                </span>
                                <span className="inv-id-refBRL">
                                  {numberFormatBRL(dr.initialAmount)}
                                </span>
                              </div>
                            </>
                            )}
                            <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', }} key={i + 20}>
                              <span className="inv-id-statement-text">{formatDate(w.updatedAt)} - {t("withdrawt")} {w.type === 'anticipated' ? t("anticipated") : w.type === 'liquidation' ? t("liquidate") /* ('Taxa operacional de Renovação') */ : t("profit")}:</span>
                              <span className="inv-id-statement-text">
                                {formatBTCString((w.totalAmount) / btcPrice)}
                              </span>
                            </div>
                            <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <span className="inv-id-refBRL">
                                {t("refference")} (R$):
                              </span>
                              <span className="inv-id-refBRL">
                                {numberFormatBRL(w.totalAmount)}
                              </span>
                            </div>
                            <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '600', marginLeft: '15px', marginRight: '15px' }}>
                              <span className="inv-id-statement-details">
                                {t("operationalfee")}:
                              </span>
                              <span className="inv-id-statement-details">
                                {formatBTCString((w.operatingFeeAmount) / btcPrice)}
                              </span>
                            </div>
                            <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                              <span className="inv-id-refBRL">
                                {t("refference")} (R$):
                              </span>
                              <span className="inv-id-refBRL">
                                {numberFormatBRL(w.operatingFeeAmount)}
                              </span>
                            </div>
                            {w.type === 'anticipated' && (<>
                              <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '600', marginLeft: '15px', marginRight: '15px' }}>
                                <span className="inv-id-statement-details">
                                  {t("prorata")}:
                                </span>
                                <span className="inv-id-statement-details">
                                  {formatBTCString((w.proRataAmount) / btcPrice)}
                                </span>
                              </div>
                              <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                                <span className="inv-id-refBRL">
                                  {t("refference")} (R$):
                                </span>
                                <span className="inv-id-refBRL">
                                  {numberFormatBRL(w.proRataAmount)}
                                </span>
                              </div>
                              <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '600', marginLeft: '15px', marginRight: '15px' }}>
                                <span className="inv-id-statement-details">
                                  {t("penalty")}:
                                </span>
                                <span className="inv-id-statement-details">
                                  {formatBTCString((w.penaltyAmount) / btcPrice)}
                                </span>
                              </div>
                              <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                                <span className="inv-id-refBRL">
                                  {t("refference")} (R$):
                                </span>
                                <span className="inv-id-refBRL">
                                  {numberFormatBRL(w.penaltyAmount)}
                                </span>
                              </div>
                            </>)}
                            <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '600', marginLeft: '15px', marginRight: '15px' }}>
                              <span className="inv-id-statement-details">
                                {t("solicitedvalue")}:
                              </span>
                              <span className="inv-id-statement-details">
                                {formatBTCString((w.requestedAmount) / btcPrice)}
                              </span>
                            </div>
                            <div className="inv-id-statement internal-statement" style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '15px', marginRight: '15px' }}>
                              <span className="inv-id-refBRL">
                                {t("refference")} (R$):
                              </span>
                              <span className="inv-id-refBRL">
                                {numberFormatBRL(w.requestedAmount)}
                              </span>
                            </div>
                          </>
                          )
                        ))
                      )
                    }
                    // {/* // Depósitos */}
                    if (dr.deposits.length !== 0) {
                      return (dr.deposits.map((d, i) => (
                        <>
                          <div className="inv-id-statement" key={i + 30} style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                            <span className="inv-id-statement-text">{formatDate(d.createdAt)} - {t("deposit")} :</span>
                            <span className="inv-id-statement-text">
                              {formatBTCString((d.amount) / btcPrice)}
                            </span>
                          </div>
                          <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span className="inv-id-refBRL">
                              {t("refference")} (R$):
                            </span>
                            <span className="inv-id-refBRL">
                              {numberFormatBRL(d.amount)}
                            </span>
                          </div>
                        </>
                      )))
                    }
                    // {/* // Rentabilidades */}
                    if (dr.appliedProfit && dayjs(range.base).isSameOrBefore(dayjs()) && findRentability(range)) {
                      return (
                        <>
                          <div className="inv-id-statement" key={i} style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                            <span className="inv-id-statement-text">
                              {formatDate(range.base)} - {t("rentability")} ({(findRentability(range).profitPercentage) * 100
                              }% ):
                            </span>
                            <span className="inv-id-statement-text">
                              {formatBTCString((findRentability(range).dailyProfit) / btcPrice)}
                            </span>
                          </div>
                          <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span className="inv-id-refBRL">
                              {t("refference")} (R$):
                            </span>
                            <span className="inv-id-refBRL">
                              {numberFormatBRL(findRentability(range).dailyProfit)}
                            </span>
                          </div>
                        </>
                      )
                    }
                  })}

                  {/* // Saldo Final do Mês */}
                  <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                    {(dayjs(range.end).isSameOrBefore(dayjs())) && (
                      <span className="inv-id-statement-text">
                        {formatDate(range.end)} - {t("monthfinalbalance")}:
                      </span>
                    )}
                    {(!dayjs(range.end).isSameOrBefore(dayjs())) && (
                      <span className="inv-id-statement-text">
                        {formatDate(dayjs())} - {t("currentbalance")}:
                      </span>
                    )}

                    {/* // SF Valores BTC */}
                    {(i === 0) && (range.dailyReports.length === 0) && (<>
                      <span className="inv-id-statement-text">
                        {formatBTCString((investment.initialAmount) / btcPrice)}
                      </span>
                    </>)}

                    {(i === 0) && (range.dailyReports.length !== 0) && (<>
                      <span className="inv-id-statement-text">
                        {formatBTCString((range.dailyReports[range.dailyReports.length - 1]?.finalAmount) / btcPrice)}
                      </span>
                    </>)}

                    {(i !== 0) && (range.dailyReports.length !== 0) && (<>
                      <span className="inv-id-statement-text">
                        {formatBTCString((range.dailyReports[range.dailyReports.length - 1]?.finalAmount) / btcPrice)}
                      </span>
                    </>)}

                    {(i !== 0) && (range.dailyReports?.length === 0) && (<>
                      <span className="inv-id-statement-text">
                        {formatBTCString((getLastMonthValue(ranges, i)) / btcPrice)}
                      </span>
                    </>)}
                  </div>

                  {/* // SF Referências BRL */}
                  <div className="inv-id-statement" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {(i === 0) && (range.dailyReports.length === 0) && (<>
                      <span className="inv-id-refBRL">
                        {t("refference")} (R$):
                      </span>
                      <span className="inv-id-refBRL">
                        {numberFormatBRL(investment.initialAmount)}
                      </span>
                    </>)}

                    {(i === 0) && (range.dailyReports.length !== 0) && (<>
                      <span className="inv-id-refBRL">
                        {t("refference")} (R$):
                      </span>
                      <span className="inv-id-refBRL">
                        {numberFormatBRL(range.dailyReports[range.dailyReports.length - 1]?.finalAmount)}
                      </span>
                    </>)}

                    {(i !== 0) && (range.dailyReports.length !== 0) && (<>
                      <span className="inv-id-refBRL">
                        {t("refference")} (R$):
                      </span>
                      <span className="inv-id-refBRL">
                        {numberFormatBRL(range.dailyReports[range.dailyReports.length - 1]?.finalAmount)}
                      </span>
                    </>)}

                    {(i !== 0) && (range.dailyReports?.length === 0) && (<>
                      <span className="inv-id-refBRL">
                        {t("refference")} (R$):
                      </span>
                      <span className="inv-id-refBRL">
                        {numberFormatBRL(getLastMonthValue(ranges, i))}
                      </span>
                    </>)}
                  </div>
                </>
                )}
              </>
              ))}
            </div>

          )}
        </main>

        <footer className='pdf-footer' style={{ marginTop: '1500px' }}>
          <div className="title">
            <span>{t("fundinitiated")} {/* Fundo iniciado em */} {dayjs(investment.createdAt).format("DD/MM/YYYY")}</span>
            <span>{t("nextrenew")} {/* Próxima renovação em */} {dayjs(investment.createdAt).add(investment.period, 'month').format("DD/MM/YYYY")}</span>
          </div>
          <p>
            {t("pdfwarning")} {/*  Atenção: Valores de referência em Reais, na data/horário de
            geração do relatório. Não devem ser considerados como valores
            líquidos para saque. */}
          </p>
          <p>
            {t("refference")}: Coingecko <a>https://www.coingecko.com</a>
          </p>
        </footer>
      </div >
    </div >
  );
});

export default InvestmentPDF;
