import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import Privacy from "./Privacy"
import Terms from "./Terms"

export default class BottomBarHome extends React.Component {

    render() {
        return (
        <div>
        <Container className="footer-bg pl-0 pr-0 pt-0 pb-2 text-mid_footer" fluid>
            <div style={{ width: '100%', height: '5px' }}></div>
            <div style={{ width: '100%', height: '3px', backgroundColor: '#CEBA85' }}></div>
            <Row className="justify-content-md-center m-0 pt-2 pb-4">
                <Col className="align-items-center row flex-column" md="2">
                    <a><img
                        src="/logo-w.svg"
                        width="60"
                        height="60"
                        className="d-inline-block align-top"
                        alt="BC Coins Logo"
                    /></a>
                    <span className="text1">Todos os direitos reservados</span>
                </Col>
                <Col md="2">
                    <div className="mt-3 row flex-column ml-3">
                        <a href="/panel/about">Quem Somos</a>
                        <a href="/panel/info">Informações</a>
                        <a href="/panel/fees">Tarifas</a>
                    </div>
                </Col>
                <Col md="2">
                    <div className="mt-3 row flex-column ml-3">
                        <span className="clickable" onClick={() => this.privacy.show()}>Política de Privacidade</span>
                        <span className="clickable" onClick={() => this.terms.show()}>Termo de Uso</span>
                        <a href="/panel/open_support">Suporte</a>
                        <a href="/panel/faq">FAQ</a>
                    </div>
                </Col>
                <Col md="2">
                    <div className="mt-3 row flex-column ml-3">
                        <span className="text1 pb-2">Siga-nos</span>
                        <div>
                            <a href="/panel/" className="pr-2"><i className="fab fa-twitter"></i></a>
                            <a href="/panel/" className="p-2"><i className="fab fa-facebook-f"></i></a>
                            <a href="/panel/" className="p-2"><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                </Col>
            </Row>
            <Terms ref={ref => this.terms = ref} />
            <Privacy ref={ref => this.privacy = ref} />
        </Container>
            </div>
        )
    }
}