import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { AppContext } from '../context/AppContext';
import TermsEN from "./TermsEN";

export default class Privacy extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isButtonEnabled: false,
    }
    this.scrollDiv = React.createRef();
  }

  static contextType = AppContext;

  componentDidMount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.addEventListener("scroll", this.handleScroll);
    }
  }

  /*
  componentWillUnmount() {
    if (this.scrollDiv.current) {
      this.scrollDiv.current.removeEventListener("scroll", this.handleScroll);
    }
  }
*/

  show = async () => {
    this.setState({
      showModal: true,
    });
  };

  close = async () => {
    this.setState({
      showModal: false,
    });
  };
  
  accept = async () => {
    this.setState({ accepted: true });
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if both conditions are met and the modal is open
    if (
      this.context.checkedTerms &&
      this.context.checkedPrivacy &&
      this.state.showModal &&
      this.context.approvedTP == true
    ) {
     this.setState({ showModal: false });
    }
  } 
  handleClose = () => {
    this.setState({ showModal: false });
  }

  handleCheck = () => {
    this.setState({ isButtonEnabled: !this.state.isButtonEnabled });
    this.context.setCheckedPrivacy(!this.state.isButtonEnabled);
  };
  handleNext = () => {
    this.context.setApprovedTP(true);
    setTimeout(() => {
      this.setState({ showModal: false });
    }, 2000);
  };

  handleScroll = (event) => {

    const div = event.target;
    if (div.scrollHeight - div.scrollTop <= div.clientHeight + 500) {
      this.setState({ isButtonEnabled: true });
      this.context.setCheckedPrivacy(true);
    }
  };


  render() {
    const TermsComponent = TermsEN;

    return (<Modal show={this.state.showModal} onHide={this.handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 ml-3 text-center">
          <h4 className="legal-title">PRIVACY POLICY BCX</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="w-100 text2">
        <div ref={(div) => {
          this.scrollDiv = div;
        }}
          id="div-scroll"
          className="ml-5 mr-1 pr-5"
          style={{
            maxHeight: 300,
            overflowY: "scroll",
          }}
          onScroll={this.handleScroll}
        >
          <div className="legal-div">
            <div>
              <h5 className="legal-subtitle">
                1. WHAT IS THE PURPOSE AND APPLICABILITY OF THIS PRIVACY POLICY
              </h5>
              <p className="legal-text">
                1.1 This Privacy Policy ("Privacy Policy") applies to users ("Users") and visitors ("Visitors") of the platform of BC COINS INTERMEDIADORA LTDA., named "BCX," registered under CNPJ/ME number 21,830,817/0001-67. The purpose is to clarify its practices regarding privacy and the processing of personal data of its users and visitors to the platform and website accessible at the domain "https://www.bcxcorretora.com.br". It also demonstrates its commitment to the best information security measures and transparency to safeguard privacy and personal data protection.
              </p>
              <p className="legal-text">
                1.2 The conditions established in this Privacy Policy apply to the use of the services governed by the Terms of Use and cover all the processing carried out by BCX for the personal data necessary to achieve the purposes determined in this document and that are capable of identifying or making identifiable the user who visits, contacts, and navigates the site, registers on the platform, and hires the services offered by BCX.
              </p>

              <h5 className="legal-subtitle">2. ACCEPTANCE</h5>
              <p className="legal-text">
                2.1 By expressing "Acceptance" of this Privacy Policy, the user expressly declares that they have read and understood all the rights and obligations determined herein.
              </p>
              <p className="legal-text">
                2.2 If the user does not agree with the terms of this Policy, even partially, they may choose not to accept it. However, they should be aware that some services may not be provided without the collection of certain information and/or personal data.
              </p>

              <h5 className="legal-subtitle">
                3. HOW BCX HANDLES AND PROTECTS PERSONAL DATA
              </h5>
              <p className="legal-text">
                3.1 This section describes the rules that BCX will observe in the processing of personal data to meet the data protection standards required by current legislation and regulations.
              </p>
              <p className="legal-text">
                3.2 BCX only collects, processes, and maintains personal data and sensitive personal data that are strictly necessary and when the purpose/finality of the processing falls within one of the legal hypotheses allowed. The user has the right to be informed about the reason and the manner in which their personal data is collected, processed, and maintained throughout the entire process.
              </p>


              <h5 className="legal-subtitle">
                4. WHAT DATA WE COLLECT AND FOR WHAT PROCESSING PURPOSES:
              </h5>
              <p className="legal-text">
                4.1 BCX collects and processes personal and/or sensitive personal data to achieve the following purposes:
              </p>
              <p className="legal-text">
                a) When registering on the SITE platform and requesting the account opening, as well as validating the provided personal data and complying with the provisions of RFB Normative Instruction No. 1888, the following personal data is collected and processed: email, CPF, date of birth, phone, complete address;
              </p>
              <p className="legal-text">
                b) When the client intends to carry out transactions up to R$ 200,000.00, BCX will request the submission of copies of documents and process the personal and sensitive data contained in them: Copy of photo identification document (RG, CNH, or RNE), copy of proof of address, and, if the proof is in the name of a third party, a copy of the linkage proof (birth certificate, marriage, or duly registered lease contract), and a selfie;
              </p>
              <p className="legal-text">
                c) For clients intending to carry out transactions above R$ 200,000.00, BCX will request, in addition to the documents described in item b), the submission of the following documents: Income Tax Declaration and Receipt of Delivery. Additional documents may be requested, such as: payslips, purchase and sale contracts, bank statements, among others capable of proving the financial capacity of the investor. All personal and sensitive data entered in the documents mentioned in this item will be processed in accordance with this Privacy Policy;
              </p>
              <p className="legal-text">
                BCX DOES NOT INTENTIONALLY COLLECT OR REQUEST PERSONAL DATA FROM INDIVIDUALS UNDER 18 (EIGHTEEN) YEARS OF AGE. HOWEVER, THE DOCUMENTS PROVIDED IN THIS ITEM ARE INDISPENSABLE TO VERIFY THE ORIGIN OF THE FUNDS TRANSACTED ON THE PLATFORM AND MAY CONTAIN DATA OF CHILDREN AND/OR ADOLESCENTS. IN THIS CASE, YOU, AS ONE OF THE PARENTS OR LEGAL GUARDIANS OF THE MINOR, BY ACCEPTING THIS POLICY, CONSENT TO THE PROCESSING OF THE RESPECTIVE PERSONAL DATA FOR THE PROVISION OF THE SERVICE CONTRACTED BY YOU.
              </p>
              <p className="legal-text">
                d) For Legal Entity clients, personal and sensitive data of each partner will be processed, such as: photo identification document (RG, CNH, or RNE), proof of address, and selfie, in addition to the duly signed social contract and contractual amendments if any;
              </p>
              <p className="legal-text">
                e) For the opening of an account for Foreign Natural Person clients, data validation, and compliance with RFB Normative Instruction No. 1888, the following are requested: photo identification document from the country of origin and selfie;
              </p>
              <p className="legal-text">
                f) To allow BCX to contact you and to enable the handling of support requests and bug resolution, clarification of doubts, or complaints from users and visitors voluntarily sent via chat or contact form available on the website, email, social networks, or through complaint websites such as "Reclame Aqui," and to allow the resolution of the demand, identification of the registration, and the return of contact, the following are collected: Name, email, and phone; eventually, if the user's registration cannot be located by email, phone, or name, the CPF may be requested. To meet refund requests, the following will be collected and processed additionally: bank details and PIX key;
              </p>
              <p className="legal-text">
                g) To enable the sending of email marketing, newsletters, download of e-books, or other materials made available for free on the SITE, disclosures of new services and products indicated for your profile, the following are collected and processed: Name, phone, email, and CPF;
              </p>
              <p className="legal-text">
                h) When navigating the Platform, without any direct interaction with us, through the use of various technologies, BCX may collect information such as information about the browser and device you are using, your IP address, your location, the website address from which you arrived, and your navigation within our Platform ("Navigation Data"). Some of this information is collected using technological tools that include cookies, web beacons, and embedded web links.
              </p>
              <p className="legal-text">
                h.1) The general public may browse the Platform without the need for any registration and submission of Personal Data, except for the Navigation Data indicated above. However, some of the Platform's features may depend on prior registration and submission of Personal Data.
              </p>
              <p className="legal-text">
                i) To enable the issuance of invoices for the services provided by BCX and collect the taxes due due to our activity, the following are processed: Name, CPF, and address and email address if applicable.
              </p>
              <p className="legal-text">
                4.1.1 The data indicated in the items above will be collected directly from the user or visitor when they access and use the BCX platform and services, when they fill out forms and/or when they voluntarily send via chat, email, or social networks.
              </p>
              <p className="legal-text">
                4.2 In addition to the purposes described in the items above, BCX may process your Personal Data to:
              </p>
              <p className="legal-text">
                a) Form, manipulate, backup, and ensure the security of our databases;
              </p>
              <p className="legal-text">
                b) Ensure the security of our operation and our users;
              </p>
              <p className="legal-text">
                c) Understand your experience on the Platform and improve the services provided, optimizing and personalizing your experience and relationship with BCX;
              </p>
              <p className="legal-text">
                d) Confirm and correct User information to validate account access and the services provided, including by means of authentication methods (2FA), such as via text message (SMS) or email;
              </p>
              <p className="legal-text">
                e) Verify the authenticity of the information and documents provided by the user, as well as confirm their identity and the lawful origin of the resources bet on the Platform;
              </p>
              <p className="legal-text">
                f) Detect and prevent fraud or the commission of any other illegal acts;
              </p>
              <p className="legal-text">
                h) Allow defense in administrative or judicial proceedings filed against BCX;
              </p>
              <p className="legal-text">
                4.3 The user acknowledges being solely responsible for the truth, accuracy, authenticity, and legality of the Personal Data and information provided directly by you, committing, furthermore, to the custody, confidentiality, and good use of any login and password registered, when applicable, releasing BCX from any liability in this regard.
              </p>

              <h5 className="legal-subtitle">
                5. WHAT LEGAL GROUNDS AUTHORIZE THE PROCESSING OF DATA
              </h5>
              <p className="legal-text">
                5.1 The processing of personal and sensitive data is carried out in a manner compatible with the purposes for which they were collected, as defined in this Privacy Policy, and cannot be processed for different purposes.
              </p>
              <p className="legal-text">
                5.2 Users agree that the data will be processed according to the legal grounds below:
              </p>
              <p className="legal-text">
                a) To allow the execution of the contract or preliminary procedures related to the contract and the fulfillment of all obligations arising from the provision of services offered by BCX (article 7, item II of LGPD);
              </p>
              <p className="legal-text">
                b) To guarantee fraud prevention and the security of the data subject in the identification and authentication processes of registration in electronic systems (article 11, item II, letter g of LGPD);
              </p>
              <p className="legal-text">
                c) To meet the legitimate interests of the controller or third parties, to support and promote their activities or to protect, in relation to the data subject, the regular exercise of their rights or the provision of services that benefit them (article 7, item IX of LGPD);
              </p>
              <p className="legal-text">
                d) To allow compliance with legal or regulatory obligations by BCX (article 7, item II, and article 11, item II, letter a, both of LGPD);
              </p>
              <p className="legal-text">
                e) Through the consent of the User, which may be revoked at any time (article 7, item I, and article 11, item I, both of LGPD);
              </p>
              <p className="legal-text">
                f) To allow the regular exercise of rights, including in contract and in judicial, administrative, and arbitration proceedings (article 7, item VI, and article 11, item II, letter d, both of LGPD).
              </p>
              <p className="legal-text">
                5.3 BCX clarifies that it only processes personal and sensitive data to the extent necessary to achieve the specific purposes defined in this Policy and if they comply with the legal grounds mentioned above.
              </p>
              <p className="legal-text">
                5.4 BCX adopts measures to ensure that any data in its possession is kept accurate, updated in relation to the purposes for which it was collected, and it is ensured that the user has the possibility to request the deletion or correction of inaccurate or outdated data.
              </p>
              <p className="legal-text">
                5.5 BCX adopts recommended technical measures, according to its size and activities developed, to protect the data against unauthorized, illegal processing, or in violation of the provisions of this Policy, as well as against accidental loss, destruction, or damage.
              </p>
              <p className="legal-text">
                5.6 When the processing of the data does not fit into the legal hypotheses above, BCX will inform the user of the legal basis that authorizes the processing, or when there is no basis, BCX must obtain consent, ensuring that it is given by the user in a specific, free, unequivocal, and informed manner.
              </p>


              <h5 className="legal-subtitle">
                6. DURATION OF DATA PROCESSING
              </h5>
              <p className="legal-text">
                6.1 The collected data will be processed for a sufficient time to achieve the purposes defined in clause 4 and will be kept by BCX throughout the period of service provision or until consent is revoked, when applicable as the legal basis.
              </p>
              <p className="legal-text">
                6.1.1 After the termination of the service provision, the data may be retained for the necessary period to comply with legal and regulatory obligations applicable to BCX's activity or for the prescriptive period for the regular exercise of its rights in judicial, administrative, or arbitration proceedings.
              </p>

              <h5 className="legal-subtitle">
                7. HOW WE SHARE YOUR PERSONAL DATA
              </h5>
              <p className="legal-text">
                7.1 We do not disclose your Personal Data to third parties in a manner that would be considered a sale or commercialization.
              </p>
              <p className="legal-text">
                7.2 BCX only transmits user data to third parties with a legitimate need to access them and provide essential services to optimize service provision and achieve the purposes defined in clause 4, such as:
              </p>
              <p className="legal-text">
                a) With companies managing the technological systems, email servers, databases, and management programs used by BCX in the development of its activities;
              </p>
              <p className="legal-text">
                b) With the Federal Revenue Service or other government agencies, as the case may be;
              </p>
              <p className="legal-text">
                c) With third-party companies providing SaaS and BaaS services;
              </p>
              <p className="legal-text">d) Banks and Cryptoasset Exchanges;</p>
              <p className="legal-text">
                e) Providers of due diligence services in the KYC process.
              </p>
              <p className="legal-text">
                7.3 Third-party service providers processing user data are subject to the obligations and responsibilities imposed on data processing agents acting as Operators, according to the applicable Personal Data Protection legislation and regulation.
              </p>
              <p className="legal-text">
                7.4 BCX undertakes to verify the adequacy level of its service providers and ensures that contracts with them contain privacy clauses requiring the data operator to implement security measures, as well as appropriate technical and administrative controls to ensure the confidentiality and security of the data. These contracts specify that the operator is authorized to process the data only when strictly related to the predefined collection purposes in this Privacy Policy.
              </p>

              <h5 className="legal-subtitle">
                8. INTERNATIONAL TRANSFER OF DATA
              </h5>
              <p className="legal-text">
                8.1 In general, your Personal Data will be processed and remain stored in the Brazilian territory. However, if necessary, we may transfer your personal data to other countries such as the United States and countries of the European Economic Area, where partner companies or service providers are based or maintain their data servers, email servers, and systems servers. Any necessary transfer in this regard will occur when necessary to fulfill the purposes described in this Policy and the Terms of Use.
              </p>
              <p className="legal-text">
                8.2. BCX ensures that these international transfers will only be made to countries that have an equivalent or higher level of data protection compared to Brazil, or it will apply other legal mechanisms to justify this shared use.
              </p>

              <h5 className="legal-subtitle">9. WHAT ARE YOUR RIGHTS</h5>
              <p className="legal-text">
                9.1 BCX guarantees the user the exercise of the rights conferred on them under the General Data Protection Law (“LGPD – Law 13,853/2019), which are:
              </p>
              <p className="legal-text">
                a) Confirmation of the existence of processing – which is assured through this Privacy Policy;
              </p>
              <p className="legal-text">
                b) Right of access – that is, the right to request access to your personal data and/or sensitive data that we process;
              </p>
              <p className="legal-text">
                c) Right to rectification – the right to request that BCX change or update the user's personal data whenever they are incorrect or incomplete;
              </p>
              <p className="legal-text">
                d) Right to anonymization, blocking, or elimination of unnecessary or excessive data – the right to request that your data be anonymized or demand its deletion when it is unnecessary or excessive for the purpose;
              </p>
              <p className="legal-text">
                e) Right to data portability – the right to request a copy of your personal data in electronic format for transmission for use in third-party services;
              </p>
              <p className="legal-text">
                f) Right to information – the right to know with which entities – public or private - BCX has shared your data;
              </p>
              <p className="legal-text">
                g) Right to revocation – the right to revoke, at any time, consent for the use of data;
              </p>
              <p className="legal-text">
                h) Right to opposition – the right of the user to object to the processing of personal data that is not in line with the determinations of the LGPD;
              </p>
              <p className="legal-text">
                i) Right to petition – against BCX or the applicable Data Protection Authority, if the user has reason to believe that any of their personal data protection rights have been violated.
              </p>
              <p className="legal-text">
                9.2 These rights can be exercised through an explicit request from the user sent to the Data Protection Officer, through the email address provided in clause 13 of this Policy, and will be attended to, whenever possible, immediately.
              </p>
              <p className="legal-text">
                9.3 In the event of the impossibility of an immediate response to the request, BCX will notify the user that it is not a data processing agent, and in this case, it must indicate the agent or inform them of the factual and legal reasons that prevent it from immediately responding to the request.
              </p>

              <h5 className="legal-subtitle">10. SECURITY STANDARDS</h5>
              <p className="legal-text">
                10.1 BCX is committed to implementing Information Security standards and protecting Personal and Sensitive Data to ensure the individual's fundamental right to self-determination of information, as well as confidentiality, integrity, and availability, authenticity, responsibility, and non-repudiation.
              </p>
              <p className="legal-text">
                10.2 Obligation of Personal Data Confidentiality
              </p>
              <p className="legal-text">
                10.2.1 BCX employees who have access to personal data are bound by the duties of confidentiality and data protection outlined in this policy from their entry into the company.
              </p>
              <p className="legal-text">
                10.2.2 Operators or authorized individuals who misuse information, violating this Privacy Policy, will be subject to accountability and legal measures.
              </p>
              <p className="legal-text">
                10.3 In addition to the hypotheses provided for in clause 7, the collected data and recorded activities can only be shared:
              </p>
              <p className="legal-text">
                a) With competent judicial, administrative, or governmental authorities, whenever there is a legal determination, request, requisition, or judicial order, and also to instruct international cooperation investigative processes, as authorized by current legislation;
              </p>
              <p className="legal-text">
                b) Automatically, in the event of corporate movements, such as merger, spin-off, acquisition, and incorporation.
              </p>
              <p className="legal-text">
                10.4 Personal Data Privacy by Design and by Default
              </p>
              <p className="legal-text">
                10.4.1 When implementing new processes, procedures, or systems involving data processing, BCX must adopt measures to ensure that privacy and data protection rules are adopted from the conception phase to the launch/implementation of these projects.
              </p>
              <p className="legal-text">
                10.4.2 The user is aware that there is always a risk in the traffic of information over the internet, but BCX undertakes to use the best market standards to protect the data of its users, customers, service providers, and partners.
              </p>
              <p className="legal-text">
                10.4.3 Hypertext links to other sites and social networks: BCX's website may, from time to time, contain hypertext links that will redirect the user to partner and advertiser sites. If the user clicks on one of these links to any of these sites, please note that each site has its own privacy practices, and we are not responsible for these policies. Please refer to those policies before sending any personal data to those sites.
              </p>


              <h5 className="legal-subtitle">
                11. DATA PROTECTION AUDITS
              </h5>
              <p className="legal-text">
                11.1 BCX ensures that it will conduct periodic reviews to confirm that privacy initiatives, its systems, measures, processes, precautions, and other activities, including data protection management, have been implemented and maintained and comply with applicable laws and regulations.
              </p>

              <h5 className="legal-subtitle">
                12. USER DATA DISPOSITION
              </h5>
              <p className="legal-text">
                12.1 Users will always be informed about the data to be collected, and it is at their discretion to provide them or not, as well as their subsequent deletion, and, in each case, they will be notified about the consequences of their decision.
              </p>
              <p className="legal-text">
                12.2 Users guarantee the truthfulness and accuracy of the personal data provided to BCX, assuming corresponding responsibility if they are not accurate. It is also the responsibility of users to keep their registration data updated, especially the email, taking the necessary precautions to ensure that site emails are not sent to the spam folder.
              </p>
              <p className="legal-text">
                12.3 Personal information provided by users will not be disclosed to the public.
              </p>

              <h5 className="legal-subtitle">13. CONTACT</h5>
              <p className="legal-text">
                13.1 If you wish to exercise any of the rights provided in this Privacy Policy and/or Data Protection Laws, or resolve any doubts related to the processing of your Personal Data, please contact us via email: protecaodedados@bcxcorretora.com.br
              </p>

              <h5 className="legal-subtitle">14. FINAL PROVISIONS</h5>
              <p className="legal-text">
                14.1 BCX reserves the right to modify this Privacy Policy at any time, observing applicable legislation, with the USER being notified of such changes that will be considered immediately applicable, valid, and binding between the parties.
              </p>
              <p className="legal-text">
                14.2 This Policy shall be interpreted according to Brazilian law. The forum of the São Paulo District is chosen for the resolution of any dispute or controversy involving this document.
              </p>

              <p className="legal-subtitle">
                LAST UPDATE DATE: 16/06/2023
              </p>
              <div className="input-legal-div">
              <input type="checkbox" className="input-legal" checked={this.state.isButtonEnabled}></input>
                <span className="legal-text">
                  By selecting next to it and clicking "ACCEPT," the USER agrees with the provisions of the BC COINS Privacy Policy, accepting and binding themselves to it.
                </span>
              </div>

            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.handleNext} disabled={!this.state.isButtonEnabled}>Accept</Button>
      </Modal.Footer>
      <TermsComponent ref={ref => this.terms = ref} />
    </Modal>)
  }
}
