import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Table } from "react-bootstrap";
import Panel from "../../components/Panel";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import api from "../../utils/api";
import { useTranslation, Trans } from 'react-i18next';

export default function Fares() {

  const { t } = useTranslation();

  const {
    loggedInUser,
    fees,
    hasInvestmentPermission,
  } = useContext(AppContext);
  const [stateFees, setStateFees] = useState(true);

  useEffect(() => {
    const getFees = async () => {
      try {
        const response = await api.get("fees/serviceFees");
        const data = response.data;
        console.log("FEES DATA > ", data);
        setStateFees(data);
      } catch (error) {
        console.log(error)
      }
    };
    getFees();
  }, []);

  return (
    <>
      {(!loggedInUser && stateFees) ? (<>
        <Panel page="tarifas" title={t('taxes')} hide={true} hideBar={true}>
          <div className="legal-back-btn" style={{ marginLeft: "0" }}>
            { }
            <Link to="/" className="legal-link">
              <Button>{t('back')}</Button>
            </Link>
          </div>
          <div className="row">
            <Col>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#50504E",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: 'center'
                }}
              >
                <span className="bold text1 ml-3 text-mid2">{t('taxes')}</span>
              </div>

              <Table className="hist-table m-0 p-0" responsive="md" striped hover>
                <thead>
                  <tr>
                    <th>{t('fee')}</th>
                    <th>{t('value')}</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                <td>Depósito</td>
                <td>{stateFees.buyFee?.value} %</td>
              </tr> */}
                  <tr>
                    <td>{t('fee_withdrawfiat')}</td>
                    <td>{stateFees[1]?.value} {stateFees[1]?.currency}</td>
                  </tr>
                  <tr>
                    <td>{t('fee_bcrypto')}</td>
                    <td>{stateFees[2]?.value} {stateFees[2]?.currency}</td>
                  </tr>
                  <tr>
                    <td>{t('fee_scrypto')}</td>
                    <td>{stateFees[3]?.value} {stateFees[3]?.currency}</td>
                  </tr>
                  <tr>
                    <td>{t('fee_ob_buy')}</td>
                    <td>{stateFees[5]?.value} {stateFees[5]?.currency}</td>
                  </tr>
                  <tr>
                    <td>{t('fee_ob_sell')}</td>
                    <td>{stateFees[6]?.value} {stateFees[6]?.currency}</td>
                  </tr>
                  <tr>
                    <td>{t('fee_p2p')}</td>
                    <td>{stateFees[7]?.value} {stateFees[7]?.currency}</td>
                  </tr>
                  {/* <tr>
                <td>Depósito Cripto</td>
                <td>R$ XX,XX</td>
              </tr>
              <tr>
                <td>Saque Cripto</td>
                <td>R$ XX,XX</td>
              </tr>
              <tr>
                <td>Saque Fiat</td>
                <td>R$ XX,XX</td>
              </tr>
              <tr>
                <td>Compra</td>
                <td>R$ XX,XX</td>
              </tr>
              <tr>
                <td>Venda</td>
                <td>R$ XX,XX</td>
              </tr> */}
                </tbody>
              </Table>
            </Col>
          </div>
        </Panel>
      </>)
        : (<>
          <Panel page="tarifas" title="Tarifas">
            <div className="legal-back-btn" style={{ marginLeft: "0" }}>
              <Link to="/panel/bem-vindo" className="legal-link">
                <Button>{t('back')}</Button>
              </Link>
            </div>
            <div className="row">
              <Col>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#50504E",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: 'center'
                  }}
                >
                  <span className="bold text1 ml-3 text-mid2">{t('taxes')}</span>
                </div>

                <Table className="hist-table m-0 p-0" responsive="md" striped hover>
                  <thead>
                    <tr>
                      <th>{t('fee')}</th>
                      <th>{t('value')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t('fee_withdrawfiat')}</td>
                      <td>{stateFees[1]?.value} {stateFees[1]?.currency}</td>
                    </tr>
                    <tr>
                      <td>{t('fee_bcrypto')}</td>
                      <td>{stateFees[2]?.value} {stateFees[2]?.currency}</td>
                    </tr>
                    <tr>
                      <td>{t('fee_scrypto')}</td>
                      <td>{stateFees[3]?.value} {stateFees[3]?.currency}</td>
                    </tr>
                    <tr>
                      <td>{t('fee_ob_buy')}</td>
                      <td>{stateFees[5]?.value} {stateFees[5]?.currency}</td>
                    </tr>
                    <tr>
                      <td>{t('fee_ob_sell')}</td>
                      <td>{stateFees[6]?.value} {stateFees[6]?.currency}</td>
                    </tr>
                    <tr>
                      <td>{t('fee_p2p')}</td>
                      <td>{stateFees[7]?.value} {stateFees[7]?.currency}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </div>
          </Panel>
        </>)}

    </>
  );
}
