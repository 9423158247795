import React, { useState } from "react";
import FAQItem from "./FAQItem";
import { useTranslation, Trans } from 'react-i18next';

const FAQ = () => {

const { t } = useTranslation();

const [faqs, setFaqs] = useState([
    {
        id: 1,
        question: `${t('question1')}`,
        faq: `${t('answer1')}`,
        active: true
    },
    {
        id: 2,
        question: `${t('question2')}`,
        faq: `${t('answer2')}`,
        active: false
    },
    {
        id: 3,
        question: `${t('question3')}`,
        faq: `${t('answer3')}`,
        active: false
    },
    {
        id: 4,
        question: `${t('question4')}`,
        faq: `${t('answer4')}`,
        active: false
    },
    {
        id: 5,
        question: `${t('question5')}`,
        faq: `${t('answer5')}`,
        active: false
    },
]);

const toggleActive = (faqId) => {
  const removeClicked = faqs.map(faq => {
    if (faq.active === true) {
      return { ...faq, active: !faq.active };
    }
    return faq;
    })
  const newItens = removeClicked.map(faq => {
    if (faq.id === faqId) {
      return { ...faq, active: !faq.active };
    }
    return faq;
   })
  setFaqs(newItens);
  };

    return (
      <div className="s7-div">
        <div className="s7-left-div">
          {faqs.map((faq) => 
              <FAQItem key={faq.id} faq={faq} toggleActive={toggleActive}/>
              )} 
        </div>
        <div className="s7-right-div">
          {faqs.map((faq) => {
            if(faq.active === true)
            return(
              <p className="s7-text">
                {faq.faq}
              </p>
            )
          }
          )}
        </div>
      </div>
    );
  }

export default FAQ;
