import React from "react";
import InvestmentList from "../components/InvestmentList";
import Panel from "../components/Panel";
import { AppContext } from "../context/AppContext";
import InvestmentId from "../components/InvestmentId";

export default class TradeId extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      disable: false,
      rows: [],
      hasInvestmentActive: false,
    };
  }

  static contextType = AppContext;

  componentDidMount = async () => {
    this.setState({
      load: false,
      disable: this.context.allInvestments?.length === 0,
    });
  };

  render() {
    return (
      <Panel
        history={this.props.history}
        page="trade"
        title={
          this.context.allInvestments
            ? `Investimento`
            : "Aporte Inicial - Investimentos"
        }
      > 
      <InvestmentId />
      </Panel>
    );
  }
}
