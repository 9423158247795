import React from "react";
import Panel from "../../components/Panel";
import { Button, Row, Col } from "react-bootstrap";
import { adminPages } from "../../utils/menu";
import { useNavigate } from "react-router-dom";

class Admin extends React.Component {
  goTo = (path) => {
    this.props.navigate(path);
  };

  render() {
    return (
      <Panel
        history={this.props.history}
        page="admin"
        title="Painel De Administração"
        admin={true}
      >
        <Row
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridGap: "10px",
          }}
        >
          {adminPages.map((page) => (
            <Col key={`painel-btn-${page.name}`}>
              <Button
                style={{ width: "100%" }}
                onClick={() =>
                  page.pages
                    ? this.goTo("/panel/" + page.pages.map((el) => el.page)[0])
                    : this.goTo("/panel/" + page.page)
                }
              >
                {page.name}
              </Button>
            </Col>
          ))}
        </Row>
      </Panel>
    );
  }
}

export default (props) => <Admin {...props} navigate={useNavigate()} />;
