import React, { useState } from "react";
import Panel from "../../components/Panel";
import { Button } from "react-bootstrap";
import api from "../../utils/api";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";

export function formatPercentage(percentageValue) {
  var value = percentageValue.replace(",", ".");
  value = percentageValue.replace(/[^0-9.,]/g, "");
  if (value.includes("%")) {
    value = value.replace("%", "");
  }
  if (value.includes('.')) {
    value = value.slice(0, value.indexOf('.') + 3);
  } else {
    value = value.slice(0, 3);
    if (value > 100) {
      value = 100;
    }
  }
  return value;
}

function AdminProfitHistory(props) {

  const [divCriada, setDivCriada] = useState(false);
  const [hp, setHp] = useState([]);
  const [stndrd, setStndrd] = useState([]);
  const [modrtd, setModrtd] = useState([]);

  const removeDiv = () => {
    const removed = document.getElementById('profit-history-view');
    if (removed) {
      removed.remove();
    }
  }

  const mountDiv = async (param, title) => {

    const headers = [
      { label: "Modalidade", key: "modalidade" },
      { label: "Data", key: "data" },
      { label: "Rentabilidade", key: "rentabilidade" }
    ];

    const data = [];

    if(data.length === 0) {
      param?.forEach(item => {
        data.push({
          modalidade: `${item.modality}`,
          data: `${dayjs(item.date).format('DD/MM/YYYY')}`,
          rentabilidade: `${item.profitPercentage * 100} %`,
        })
      })
    };

    const div = (
      <div className="profit-history-view orderbook-fees order-container">
        {(!param || param?.length === 0) && <div style={{ display: "flex", flexDirection: "column", width: "100%", textAlign: "center"}}>
          <div style={{textAlign:"center", width:"100%", fontWeight: "bold", marginBottom: "20px"}}>
            { title }
          </div>
          <div style={{ display: "flex", alignContent: "center", width: "100%", textAlign: "center"}}>
            Não há histórico de rentabilidade para exibir nesta modalidade.
          </div>
        </div>}

        {(param?.length > 0) && <div style={{ display: "flex", flexDirection: "column", width: "100%"}}>
        <div style={{textAlign:"center", width:"100%", fontWeight: "bold", marginBottom: "20px"}}>
          { title }
        </div>
        <table>
          <thead>
            <tr>
              <th>
                Data
              </th>
              <th>
                Rentabilidade
              </th>
            </tr>
          </thead>
          <tbody>
            {param?.map((item, index) => (
              <tr>
                <td>
                  { dayjs(item.date).format('DD/MM/YYYY') }
                </td>
                <td>
                  { item.profitPercentage * 100 } %
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="div-export-csv" >
          <CSVLink
            headers={ headers }
            data={ data }
            filename={ `profitHistory-${title}.csv` }
            className="export-csv"
            target="_blank"
            style={{ backgroundColor:'#011535', padding:5, borderRadius: 10 }}
            separator={";"} 
          >
            Exportar CSV
          </CSVLink>
        </div>
        </div>
        }
      </div>
    );
    setDivCriada(div);
  }

  const createDiv = async (param, title) => {
    switch (param) {
      case 'standard':
        if (stndrd.length === 0) {
          const dataBase = await api.get(`profits/?modality=${param}`);
          mountDiv(dataBase.data, title)
          setStndrd(dataBase.data);
        } else {
          mountDiv(stndrd, title);
        }
        break;
      case 'alto_valor':
        if (modrtd.length === 0) {
          const dataBase = await api.get(`profits/?modality=${param}`);
          mountDiv(dataBase.data, title)
          setModrtd(dataBase.data);
        } else {
          mountDiv(modrtd, title);
        }
        break;
      case 'high_performance':
        if (hp.length === 0) {
          const dataBase = await api.get(`profits/?modality=${param}`);
          mountDiv(dataBase.data, title);
          setHp(dataBase.data);
        } else {
          mountDiv(hp, title);
        }
        break;
      default: break;
    }
  };

  const handleClick = (e) => {
    removeDiv();
    createDiv(e.target.name, e.target.innerHTML);
  };

    return (
      <Panel
        load={false}
        navigate={props.navigate}
        page="admin/cadastro-rentabilidade"
        title="Histórico de Rentabilidades"
        admin={true}
      >

      <div className="new-inv-div">
        <div className="new-inv-user">
          Histórico de Rentabilidade
        </div>
        <div style={{ marginTop: "20px", color: "black", textAlign: "center", maxWidth: "800px"}}>
          <p>Selecione o tipo de investimento que você deseja visualizar o histórico de rentabilidade</p>
          <div style={{ display: "flex", justifyContent: "space-evenly"}}>
          <Button
            variant="primary"
            name="standard"
            type="button"
            onClick={ handleClick }
          >
            Standard
          </Button>
          <Button
            variant="primary"
            name="alto_valor"
            type="button"
            onClick={ handleClick }
          >
            Alto Valor
          </Button>
          <Button
            variant="primary"
            name="high_performance"
            type="button"
            onClick={ handleClick }
          >
            High Performance
          </Button>
          </div>
        </div>
      </div>
      <br></br>
      <div>
        { divCriada }
      </div>
      </Panel>
    );
}

export default AdminProfitHistory;
