export const isLogged = () => {
    return localStorage.getItem('token') != null
}

export const saveToken = (token) => {
    localStorage.setItem('token', JSON.stringify(token))
}

export const getToken = () => {
    try {
        var token = localStorage.getItem('token')
        if (token) {
            return JSON.parse(token)
        }
    } catch (err) {
    }
    return '';
}

export const logout = () => {
    localStorage.removeItem('token')
}

export function parseJwt(token){
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
}

export function getUserDataFromJWT() {
    const token = getToken();
    if(!token) return null;
    const payload = parseJwt(token);
    return payload;
}