import React from 'react';
import Panel from '../components/Panel';

export default class FAQ extends React.Component {

    render() {
        return (<Panel history={this.props.history} page="faq" title="FAQ" hide>
            <div className="text2 mt-3 mb-5">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras id odio turpis. Phasellus scelerisque nisi nec dolor faucibus, sit amet vehicula ex feugiat. Ut quis pellentesque magna, ac efficitur lacus. Donec sollicitudin magna sed posuere placerat. Nulla vel lobortis mauris. Fusce sit amet orci odio. Vivamus pellentesque aliquet mi quis feugiat. Nam posuere, leo nec tempor convallis, ex lorem condimentum nulla, nec commodo lacus arcu in augue. Ut tincidunt ultrices lobortis. Aenean viverra placerat nunc vitae euismod. Proin et euismod magna.</p>
                <p>Mauris a ullamcorper mauris, non ornare sapien. Maecenas hendrerit tellus massa, at pretium nulla sollicitudin ac. Sed feugiat mattis augue a pulvinar. Nullam at quam iaculis, ultrices purus tincidunt, scelerisque dui. Nulla cursus lectus et iaculis cursus. Phasellus mollis efficitur purus, sed bibendum lacus mollis sit amet. Integer tincidunt enim sit amet molestie facilisis. In vitae tellus et sapien gravida blandit. Nam egestas volutpat mattis.</p>
                <p>Fusce dui mauris, cursus eget tellus eget, egestas accumsan turpis. Fusce convallis dignissim nisl, eget hendrerit tortor. Quisque fringilla lorem vitae elit fermentum posuere. Cras eget lacus enim. Suspendisse sed volutpat leo. Sed at massa nulla. In quis felis nec neque ornare suscipit. Etiam hendrerit turpis id commodo vehicula. Sed maximus eu ex eget pretium. Donec eu feugiat leo. Donec in justo non purus fermentum consectetur at scelerisque ex. Nunc convallis pulvinar dolor id porttitor. Nam pellentesque lobortis sapien sit amet malesuada. Morbi nec risus turpis. Fusce at lectus libero. Vivamus viverra porttitor ullamcorper.</p>
                <p>Donec in nunc suscipit, pretium ligula in, consequat massa. Fusce dignissim sodales nunc non interdum. Etiam elit erat, elementum at posuere ac, vulputate ultrices orci. Sed condimentum leo urna, eget vehicula metus pharetra et. Pellentesque auctor gravida velit, sed placerat nisl tempus eu. Duis malesuada velit sed turpis pellentesque eleifend. Nam elementum velit eu convallis convallis. Proin hendrerit, tortor pulvinar congue ornare, arcu felis laoreet augue, ut faucibus ligula eros a ipsum. Praesent bibendum quam id faucibus tincidunt. Suspendisse potenti.</p>
            </div>
        </Panel >)
    }
}
