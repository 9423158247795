import React, { useContext, useEffect, useState, useCallback } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import Panel from "../../components/Panel";
import { AppContext } from "../../context/AppContext";
import api from "../../utils/api";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import _isEqual from "lodash/isEqual";
import _debounce from "lodash/debounce";
import { validateMultipleStrings, validateSize } from "../Profile";

const validateContainEmployee = (value) => {
  const stringVal = String(value);
  if (stringVal && stringVal.trim() !== "" && stringVal != 'null') {
    return "";
  } else {
    return "Campo inválido";
  }
};

export default function EmployeeCreate(props) {
  const [load, setLoad] = useState(false);
  const formInitialState = {
    name: "",
    cpf: "",
    rg: "",
    birthDate: "",
    phone: "",
    email: "",
    postalCode: "",
    streetAddress: "",
    streetAddressNumber: "",
    streetAddress2: "",
    district: "",
    city: "",
    stateOrProvince: "",
    country: "",
    levelPermission: null,
    password: "",
  }
  const [form, setForm] = useState(formInitialState);

  const [validations, setValidations] = useState({
    name: validateContainEmployee,
    email: validateContainEmployee,
    cpf: (value) => validateSize(value, 14, 14),
    rg: validateContainEmployee,
    birthDate: (value) => validateSize(value, 10, 10),
    phone: (value) => validateSize(value, 15, 16),
    postalCode: (value) => validateSize(value, 9, 9),
    streetAddress: validateMultipleStrings,
    streetAddress2: validateMultipleStrings,
    streetAddressNumber: (value) => validateSize(value, 1, 4),
    district: validateContainEmployee,
    city: validateContainEmployee,
    stateOrProvince: (value) => validateSize(value, 2, 2),
    country: validateContainEmployee,
    levelPermission: validateContainEmployee,
    password: validateContainEmployee,
  });

  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const context = useContext(AppContext);

  const submitData = async (e) => {
    e.preventDefault();

    let formParams = Object.keys(form);
    let foundError = false;
    let formErrors = errors;

    formParams.forEach((key) => {
      // console.log(key, 'error')
      let error = validations[key](form[key]);
      formErrors[key] = error;
      if (error !== "") {
        foundError = true;
      }
    });
    
    if (!foundError) {
      setLoad(true);
      setErrors({});
      try {
        let req = await api.post("employees", form);
        setLoad(false);
        toast("Administrador do nível " + form.levelPermission + " criado com sucesso!");
        navigate("/panel/admin/employees");
      } catch(e){
        console.log(e)
      }
      setLoad(false);

    } else {
      setErrors(formErrors);
    }
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const debounceFn = useCallback(_debounce(handleDebounceFn, 1500), []);

  function handleDebounceFn(postalCode, formState) {
    if (postalCode !== "_____-___" && postalCode !== "") {
      fetch(`https://viacep.com.br/ws/${postalCode}/json/`)
        .then((response) => response.json())
        .then((data) => {
          setForm({
            ...formState,
            postalCode,
            streetAddress: data.logradouro,
            district: data.bairro,
            city: data.localidade,
            stateOrProvince: data.uf,
            country: "Brasil",
          });
        });
    }
  }

  const handleChangeCEP = (event) => {
    let value = event.target.value;

    setErrors({
      ...errors,
      [event.target.name]: validations[event.target.name](value),
    });

    setForm({ ...form, [event.target.name]: value });

    debounceFn(value, form);
  };

  useEffect(() => { }, []);

  return (
    <Panel
      load={load}
      history={props.history}
      page="employees"
      title="Cadastrar administrador"
      admin={true}
    >
      <Form className="w-100 card p-3 mb-5" onSubmit={submitData}>
        <Row className="">
          <Col md>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>Nome Completo</Form.Label>
                <Form.Control
                  className="input"
                  name="name"
                  type="text"
                  isInvalid={errors.name}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>CPF</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="999.999.999-99"
                  className="input"
                  name="cpf"
                  type="text"
                  isInvalid={errors.cpf}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.cpf}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>RG</Form.Label>
                <Form.Control
                  className="input"
                  name="rg"
                  type="text"
                  isInvalid={errors.rg}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.rg}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Nível de permissão</Form.Label>
                <Form.Control className="input" as="select" name="levelPermission" onChange={handleChange} isInvalid={errors.levelPermission} disabled={load} aria-label="Default select example">
                  <option value="">Escolha um nível</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.levelPermission}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>Data de Nasc.</Form.Label>
                <Form.Control
                  className="input"
                  name="birthDate"
                  type="date"
                  isInvalid={errors.birthDate}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.birthDate}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Telefone</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="(99) 99999-9999"
                  className="input"
                  name="phone"
                  type="text"
                  isInvalid={errors.phone}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className="input"
                  name="email"
                  type="email"
                  isInvalid={errors.email}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  className="input"
                  name="password"
                  type="password"
                  isInvalid={errors.password}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Col>
          <Col md>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>CEP</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="99999-999"
                  className="input"
                  name="postalCode"
                  type="text"
                  value={form.postalCode}
                  isInvalid={errors.postalCode}
                  onChange={handleChangeCEP}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.postalCode}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Endereço</Form.Label>
                <Form.Control
                  className="input"
                  name="streetAddress"
                  type="text"
                  value={form.streetAddress}
                  isInvalid={errors.streetAddress}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.streetAddress}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>n°</Form.Label>
                <Form.Control
                  className="input"
                  name="streetAddressNumber"
                  type="number"
                  value={form.streetAddressNumber}
                  isInvalid={errors.streetAddressNumber}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.streetAddressNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Complemento</Form.Label>
                <Form.Control
                  className="input"
                  name="streetAddress2"
                  type="text"
                  value={form.streetAddress2}
                  isInvalid={errors.streetAddress2}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.streetAddress2}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  className="input"
                  type="text"
                  name="district"
                  value={form.district}
                  isInvalid={errors.district}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.district}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Cidade</Form.Label>
                <Form.Control
                  className="input"
                  name="city"
                  type="text"
                  value={form.city}
                  isInvalid={errors.city}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="aa"
                  className="input"
                  type="text"
                  name="stateOrProvince"
                  value={form.stateOrProvince}
                  isInvalid={errors.stateOrProvince}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.stateOrProvince}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>País</Form.Label>
                <Form.Control
                  className="input"
                  name="country"
                  type="text"
                  value={form.country}
                  isInvalid={errors.country}
                  onChange={handleChange}
                  disabled={load}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.country}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Button type="submit" disabled={load}>
            Salvar
          </Button>

          <Button type="button" className="ml-2" onClick={() => navigate(-1)}>
            Cancelar
          </Button>
        </Row>
      </Form>
    </Panel>
  );
}
