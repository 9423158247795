import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";

import { Button, Table } from "react-bootstrap";
// import api from "../../../../utils/api";

// import { useNavigate, useParams } from "react-router-dom";
// import dayjs from "dayjs";
// import { disableAction } from "../../../../utils/helper";
// import { AppContext } from "../../../../context/AppContext";
// import { useLocation } from "react-router-dom";
import { numberFormatBRL } from "../../../../utils/Helpers/btcFormat";

export default function Deposits({ deposits }) {
  // const initialDeposits = [
  //   {
  //     id: 0,
  //     createdAt: 0,
  //     amount: 0,
  //   },
  // ];
  // const params = useParams();

  // const context = useContext(AppContext);

  // async function getDeposits() {
  //   try {
  //     const res = await api.get(`investments/${params.id}/deposits`);
  //     setDeposits(res.data);
  //   } catch (err) {}
  // }

  // useEffect(() => {
  //   if (params.id != "new") {
  //     getDeposits();
  //   }
  //   return () => {};
  // }, [params]);

  return (
    <>
    {console.log(deposits)}
      <div
        className="mt-5"
        style={{
          width: "100%",
          backgroundColor: "#50504E",
        }}
      >
        <span className="bold text1 ml-3 text-mid2">
          HISTÓRICO DE DEPÓSITOS
        </span>
      </div>
      <Table className="hist-table m-0 p-0 text-mid mb-5" responsive striped hover>
        <thead>
          <tr>
            <th>Data</th>
            <th>Valor</th>
            {/* <th>Editar</th>
            <th>Excluir</th> */}
          </tr>
        </thead>
        { (deposits?.length > 0) ? (<tbody>
          { deposits.map((dr) => {
              return (dr.deposits.map((row) => (
                <tr key={row.id}>
                  <td>{dayjs(dr.date).format("DD/MM/YYYY")}</td>
                  <td>{numberFormatBRL(row.amount)}</td>
                </tr>
              )))
          })}
        </tbody>)
        : (<tbody>
          <tr>
            <td className="text-center" colSpan={6}>
              Sem depósitos para mostrar
            </td>
          </tr>
        </tbody>) 
      }
      </Table>
    </>
  );
}
