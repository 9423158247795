import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Panel from "../../components/Panel";
import api from "../../utils/api";

import { Button } from "react-bootstrap";

import ConfirmModal from "../../components/ConfirmModal";
import DialogModal from "../../components/DialogModal";

import dayjs from "dayjs";
import { AppContext } from "../../context/AppContext";
import Investment from "../../components/Admin/Investment";
import CreateProfitabilities from "../../components/Admin/Investment/profitabilities/create";
import EditProfitability from "../../components/Admin/Investment/profitabilities/edit";
import InvestmentList from "../../components/InvestmentList";
import InvestmentForm from "../../components/Admin/Investment/Form";
import InvestmentIdAdmim from "../../components/InvestmentIdAdmim";

export default function AdminInvestment() {
  // const params = useParams();
  const { id } = useParams()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [baseDay, setBaseDay] = useState();
  const [investment, setInvestment] = useState({
    usersId: "",
    contract: 0,
    id: "",
    acceptTerms: true,
    currency: "BTC",
    type: "trade",
    status: "active",
    initialAmount: 0,
    currentAmount: 0,
    operatingFee: 0,
    period: 0,
    createdAt: null,
    updatedAt: null,
    end_date: null,
  });

  const dialog = useRef(null);
  const confirm = useRef(null);
  const endTable = useRef(null);

  const { setAvailablesMonthsOfContract, showModalEditProfitabily, trade } =
    useContext(AppContext);

  useEffect(() => {
    const period = investment.period;
    var months = [];

    for (var i = 1; i <= period; i++) {
      months.push(i);
    }

    setAvailablesMonthsOfContract(months);
  }, [investment]);
  
  // useEffect(() => {
  //   setLoading(true);
  //   if (params.id != "new") {
  //     api
  //       .get(`investments/${params.id}`)
  //       .then((res) => {
  //         setTrade(res.data);
  //         setInvestment({
  //           ...res.data,
  //           createdAt: dayjs(res.data.createdAt).format("DD/MM/YYYY"),
  //           updatedAt: dayjs(res.data.updatedAt).format("DD/MM/YYYY"),
  //           end_date: dayjs(res.data.end_date).format("DD/MM/YYYY"),
  //         });
  //         setBaseDay(dayjs(res.data.createdAt).date());
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //       });
  //   }
  // }, []);

  const formProps = {
    investment,
    setInvestment,
    baseDay,
    setBaseDay,
    isNew,
    setIsNew,
    dialog,
    confirm,
  };

  return (
    <Panel load={loading} page="admin/investment" title="Contrato" admin={true}>
      <div className="mt-3 mb-3">
        <Button className="mr-3" onClick={() => navigate(-1)}>
          Voltar
        </Button>
        {trade && (
        <Button
          onClick={() => navigate("/panel/admin/user/" + trade.userId)}
        >
          Ver cliente
        </Button>
        )}
      </div>


      {!isNew && <InvestmentIdAdmim title="Investimento" />}

      <ConfirmModal ref={confirm} />

      <CreateProfitabilities investmentId={investment._id} />

      {/* {showModalEditProfitabily.show && <EditProfitability />} */}

{/* Editar Status e Remover Investimento! Precisa de um endpoint delete Investment no Microservice */}
      {/* <InvestmentForm {...formProps} /> */}

  {/* Renderiza Histórico de Rentabilidade, Saques e Depósitos */}
      {!isNew && <Investment investment={investment} />}


      <DialogModal
        ref={dialog}
        handleClose={() =>
          endTable.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
      />
    </Panel>
  );
}
