import React from "react";
import { Table, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom"; // Importa useParams para capturar o id da URL
import { AppContext } from "../context/AppContext";
import dayjs from "dayjs";
import api from "../utils/api";
import { numberFormatBRL } from "../components/InvestmentWithdrawModal";
import Pagination from "../components/Pagination";

class InvestmentList extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      investments: [],
      load: true,
      page: 0,
      pages: 0,
      offset: 0,
      investmentCount: 0,
    };
  }

  componentDidMount() {
    this.fetchInvestments();
  }

  componentDidUpdate(_, prevState) {
    if (this.context.pagination.offset !== prevState.offset) {
      this.fetchInvestments();
    }
  }

  fetchInvestments = () => {
    const { id } = this.props.params; // Obtém o id diretamente da URL
    this.setState({
      offset: this.context.pagination.offset,
      load: true,
    });

    api
      .get(`users/investments/${id}`)
      .then((response) => {
        if (!response.error) {
          let investments = response.data;
          this.setState({
            investments: investments,
            load: false,
            investmentCount: investments.length,
          });

          this.context.setPagination((pagination) => ({
            ...pagination,
            lastPage: Math.ceil(investments.length / pagination.limit),
          }));
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar investimentos:", error);
        this.setState({ load: false });
      });
  };

  goToInvestment = (investmentId) => {
    this.props.navigate(`/panel/admin/investment/${investmentId}`);
  };

  render() {
    const { investments, load, investmentCount } = this.state;
    const { btcPrice } = this.context;

    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", padding: "10px", backgroundColor: "#50504E" }}>
          <span className="bold text1 text-mid2">Lista de Investimentos</span>
          <div>
            <span style={{ color: "white" }}>Qtd Total: {investmentCount}</span>
            <Button
              style={{ borderRadius: "5px", margin: "3px", border: "none", color: "#ceba85" }}
              onClick={this.fetchInvestments}
            >
              Recarregar
            </Button>
          </div>
        </div>
        <Table className="hist-table m-0 p-0 text-mid" responsive="md" striped hover>
          <thead>
            <tr>
              <th>N</th>
              <th>Tipo</th>
              <th>Valor Inicial</th>
              <th>Referência Inicial</th>
              <th>Valor Atual</th>
              <th>Referência Atual</th>
              <th>Início</th>
              <th>Fim</th>
              <th>Status</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {!load && investments.length > 0 ? (
              investments.map((investment, index) => (
                <tr key={investment._id}>
                  <td>{index + 1}</td>
                  <td>{investment.type === "trade" ? "Investimentos" : investment.type}</td>
                  <td>{(investment.initialAmount / btcPrice).toFixed(8)} BTC</td>
                  <td>{numberFormatBRL(investment.initialAmount)}</td>
                  <td>{(investment.currentAmount / btcPrice).toFixed(8)} BTC</td>
                  <td>{numberFormatBRL(investment.currentAmount)}</td>
                  <td>{dayjs(investment.createdAt).format("DD/MM/YYYY")}</td>
                  <td>{dayjs(investment.endDate).format("DD/MM/YYYY")}</td>
                  <td>{investment.status}</td>
                  <td>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => this.goToInvestment(investment._id)}
                    >
                      Ver
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="text-center">
                  {load ? "Carregando..." : "Nenhum investimento encontrado"}
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {investments.length > 0 && (
          <Pagination
            pagination={this.context.pagination}
            setPagination={this.context.setPagination}
          />
        )}
      </div>
    );
  }
}

// Exporta o componente com o `useParams` para capturar o id da URL
export default (props) => {
  const params = useParams();
  const navigate = useNavigate();
  return <InvestmentList {...props} params={params} navigate={navigate} />;
};
