import React from "react";
import Panel from "../../components/Panel";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation, Trans } from 'react-i18next';
import '../../i18n'

export default function TermsPage() {

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  return (

    <Panel page="termos" title={t('termstitletop')} hideBar={true}>

      <div className="legal-back-btn">
        <Link to="/" className="legal-link">
          <Button>{t('back')}</Button>
        </Link>
      </div>

      {currentLanguage === 'en' ? (
        <div className="legal-div">
          <h4 className="legal-title">{t('termstitle')}</h4>
          <br></br>
          <p className="legal-text">
            <strong>BC COINS INTERMEDIARY LTD.</strong>, a private legal entity, registered with CNPJ under number 21,830,817/0001-67, located at Avenida Osvaldo Reis, nº 3385, Room 1407, Praia Brava de Itajaí, in the city of Itajaí/SC, ZIP code 88.306-003, is the holder of all rights and responsible for the online platform for the intermediary of buying and selling crypto assets "SERVICES," made available through the website hosted under the domain www.bcxcorretora.com.br, referred to in these TERMS as the "PLATFORM."
          </p><br></br><div>
            <h5 className="legal-subtitle">1. Acceptance</h5>
            <p className="legal-text">
              1.1. Before using the SERVICES offered by BC COINS, the USER agrees to carefully read these TERMS, ensuring their perfect understanding.
            </p>
            <p className="legal-text">
              1.2. The USER, by accepting these TERMS, agrees to the provisions contained therein, accepting and binding themselves to them, and declares that they are aware that the TERMS may be changed over time, and it is their responsibility to check periodically, especially before using the SERVICES of the PLATFORM.
            </p>
            <p className="legal-text">
              1.2.2 In case of changes, BC COINS will inform the USER on the PLATFORM and/or by email. For the USER's convenience, the date of the last change is indicated at the end for easy reference.
            </p>
            <p className="legal-text">
              1.3. If there is any objection to any provision of these TERMS, even after acceptance, the USER must immediately cease using the PLATFORM, applying, during the period of use - if any, the rules provided herein.
            </p>
            <p className="legal-text">
              1.4. To use the SERVICES of the PLATFORM, the USER must have knowledge of what crypto assets are, the crypto asset market, and have considered their risk. The value of crypto assets can vary over time, and the USER is aware of this circumstance and accepts it at their own risk, consciously and informed.
            </p>
            <p className="legal-text">
              1.5. The PLATFORM provides the SERVICES of intermediation of buying and selling crypto assets; therefore, the responsibility for the operations carried out through it is solely the USER's, and the PLATFORM does not provide any investment advice.
            </p>
            <p className="legal-text">
              1.6 The use of the PLATFORM by individuals under 18 (eighteen) years old is not allowed.
            </p>

            <h5 className="legal-subtitle">2. The PLATFORM</h5>
            <p className="legal-text">
              2.1. BC COINS may update the PLATFORM whenever it wishes or deems necessary, as well as cease to make it available, update it, or even discontinue it at any time.
            </p>
            <p className="legal-text">
              2.2. In case of discontinuation of the PLATFORM, BC COINS will inform USERS in advance of 60 (sixty) days.
            </p>

            <h5 className="legal-subtitle">3. Registration and Personal Identification</h5>
            <p className="legal-text">
              3.1. To use the PLATFORM, it is necessary to register and submit the documentation mentioned in the <a href="src/panel/kyc">KYC POLICY – AML/CFT</a>, which we recommend reading to access BC COINS SERVICES.
            </p>
            <p className="legal-text">
              3.2. After completing the registration, an account will be created for the USER, which will be accessed only by login and password, which are for personal and non-transferable use, and it is the USER's responsibility to use and keep them securely, responsibly, and in good faith.
            </p>
            <p className="legal-text">
              3.2.1 BC COINS will not be responsible for any misuse by the USER or third parties, for their security failure, as well as for any attacks the USER may suffer;
            </p>
            <p className="legal-text">
              3.2.2 To provide more security to the account, the USER can activate the mechanism provided by BC COINS through the second Authentication factor (2FA).
            </p>
            <p className="legal-text">
              3.3. Each USER can maintain only one account on the PLATFORM. If BC COINS detects the existence of more than one account in the same USER's name, it may suspend one or all accounts, temporarily or permanently, or even cancel the accounts, with prior notice to the USER.
            </p>
            <p className="legal-text">
              3.4. The USER is entirely responsible for the information provided, which will be treated and protected in accordance with the PRIVACY POLICY. If irregularities are found in the registration, omission of information, incorrect data, or erroneous filling, BC COINS may suspend the USER's access to the registration area until the situation is regularized, or even cancel the account.
            </p>
            <p className="legal-text">
              3.5. Registration is free, and it is necessary for BC COINS to validate it for the use of the SERVICES provided on the PLATFORM. BC COINS may perform data verification procedures and request additional documents and information from the USER, according to its KYC POLICY – AML/CFT.
            </p>
            <p className="legal-text">
              3.5.1 The validation of the registration is at BC COINS' discretion, which may refuse a registration without the need to provide justification.
            </p>
            <p className="legal-text">
              3.6. The USER accepts and agrees that any transaction must be carried out only by the account holder.
            </p>

            <h5 className="legal-subtitle">4. PLATFORM Operation</h5>
            <p className="legal-text">
              4.1. The PLATFORM is intended for the intermediation of buying and selling crypto assets between USERS, which can be done in two ways, through private BUY and SELL ORDERS, through the tool called "OTC," or public, called "book."
            </p>
            <p className="legal-text">
              4.1.1. The crypto assets available for trading on the PLATFORM will be displayed on the website.
            </p>
            <p className="legal-text">
              4.2. BC COINS does not operate as a financial institution, custodian, or portfolio manager, being the USERS' sole responsibility to manage their crypto assets.
            </p>
            <p className="legal-text">
              4.3. BC COINS does not guarantee the availability, continuity, and infallibility of the functioning of the PLATFORM, and may temporarily suspend its provision for technical reasons or force majeure, without the USER being entitled to any claim or compensation.
            </p>
            <p className="legal-text">
              4.4. BC COINS will make efforts to maintain the PLATFORM operational 24 (twenty-four) hours a day, 7 (seven) days a week, with the exception of periods of unavailability due to scheduled maintenance.
            </p>
            <p className="legal-text">
              4.5. BC COINS may suspend the provision of SERVICES, including for maintenance purposes, at any time and without prior notice, with the USER being warned, when possible, about the suspension.
            </p>
            <p className="legal-text">
              4.6. BC COINS may perform maintenance on its PLATFORM, including updates, without prior notice, and the USER agrees that such actions may cause temporary unavailability of the SERVICES.
            </p>
            <p className="legal-text">
              4.7. BC COINS is not responsible for losses that the USER may suffer due to the suspension, interruption, or unavailability of the PLATFORM's SERVICES, except for the cases provided for in this TERM.
            </p>
            <p className="legal-text">
              4.8. The USER is solely responsible for acquiring the necessary hardware and software to access the PLATFORM, including the internet connection.
            </p>
            <p className="legal-text">
              4.9. The USER is responsible for all equipment and software necessary to access the internet, and BC COINS is not responsible for any problems, configurations, or malfunctions of the USER's equipment or software.
            </p>

            <h5 className="legal-subtitle">5. SUPPORT</h5>
            <p className="legal-text">
              5.1. BC COINS provides support services for its USERS. The support channels and hours of operation are specified on the PLATFORM.
            </p>
            <p className="legal-text">
              5.2. BC COINS' support services aim to assist USERS with questions, problems, or information related to the PLATFORM. It does not include investment advice or recommendations.
            </p>
            <p className="legal-text">
              5.3. BC COINS is not responsible for any losses or damages that may arise from the USER's failure to follow the instructions provided by the support service or for the consequences of decisions made by the USER based on information obtained through the support service.
            </p>

            <h5 className="legal-subtitle">6. INTELLECTUAL PROPERTY</h5>
            <p className="legal-text">
              6.1. The intellectual property rights of the PLATFORM, its software, and all its content, including text, images, graphics, logos, icons, and sound, are the property of BC COINS or its licensors.
            </p>
            <p className="legal-text">
              6.2. The use of the PLATFORM by the USER does not grant them any intellectual property rights over the PLATFORM or its content.
            </p>
            <p className="legal-text">
              6.3. The reproduction, modification, distribution, or public communication of the content of the PLATFORM without the express authorization of BC COINS is prohibited.
            </p>

            <h5 className="legal-subtitle">7. LIMITATION OF LIABILITY</h5>
            <p className="legal-text">
              7.1. BC COINS is not responsible for any damages or losses arising from the use of the PLATFORM, including but not limited to:
            </p>
            <p className="legal-text">
              7.1.1. Errors, mistakes, or inaccuracies in the content;
            </p>
            <p className="legal-text">
              7.1.2. Personal injury or property damage, of any nature, resulting from access or use of the PLATFORM;
            </p>
            <p className="legal-text">
              7.1.3. Unauthorized access to BC COINS' secure servers and/or any personal information stored therein;
            </p>
            <p className="legal-text">
              7.1.4. Interruption or cessation of transmission to or from the PLATFORM;
            </p>
            <p className="legal-text">
              7.1.5. Bugs, viruses, Trojan horses, or the like that may be transmitted to or through the PLATFORM by any third party;
            </p>
            <p className="legal-text">
              7.1.6. Errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the PLATFORM.
            </p>
            <p className="legal-text">
              7.2. BC COINS is not responsible for any action taken by the USER based on information obtained through the PLATFORM. It is the USER's responsibility to evaluate the accuracy, completeness, or usefulness of any information, opinion, advice, or other content available through the PLATFORM.
            </p>
            <p className="legal-text">
              7.3. BC COINS is not responsible for the actions, omissions, or negligence of other USERS or third parties connected to the use of the PLATFORM.
            </p>
            <p className="legal-text">
              7.4. BC COINS is not responsible for the proper functioning or quality of third-party services that are integrated or linked to the PLATFORM.
            </p>
            <p className="legal-text">
              7.5. BC COINS is not responsible for any losses or damages resulting from the use of the PLATFORM for illegal purposes, contrary to these TERMS, or harmful to the rights of third parties.
            </p>
            <p className="legal-text">
              7.6. In any case, BC COINS' liability is limited to the amount effectively paid by the USER for the use of the SERVICES in the last 12 (twelve) months, counted from the date of the event that gave rise to the claim.
            </p>

            <h5 className="legal-subtitle">8. FINAL CONSIDERATIONS</h5>
            <p className="legal-text">
              8.1. The USER may, at any time, request the cancellation of their account. To do so, any and all balances, whether in Brazilian reais or in crypto assets, must be transferred to another account provided by the USER. In this case, only mandatory retention information will be kept by BC COINS, for the time required by law.
            </p>
            <p className="legal-text">
              8.2. In case of account suspension due to suspicion of fraud or illegality, balances may be blocked, without prejudice to the adoption of appropriate legal measures.
            </p>
            <p className="legal-text">
              8.3. The incapacity or mere leniency in not enforcing any provisions of these TERMS does not constitute a waiver of rights and can be demanded to be fulfilled at any time.
            </p>
            <p className="legal-text">
              8.4. If any provision of these TERMS is deemed unenforceable or invalid, that provision will be invalidated individually and will not affect the remaining provisions.
            </p>
            <p className="legal-text">
              8.5. These TERMS and the relationship between the Parties will be governed by the laws of the Federative Republic of Brazil, especially the Civil Code and the Consumer Protection Code.
            </p>
            <p className="legal-text">
              8.6. The Parties choose the Forum of the District of São Paulo as the only competent authority to settle any disputes and/or claims that may arise between the Parties regarding the SERVICE.
            </p>
            <p className="legal-text">
              By clicking "I ACCEPT" at the end, the USER agrees to the provisions in these TERMS, accepting and binding to them, and to BC COINS' Privacy Policy.
            </p>

            <h5 className="legal-subtitle">
              DATE OF LAST UPDATE: 02/08/2023
            </h5>
          </div>
        </div>
      ) : (
        <div className="legal-div">
          <h4 className="legal-title">{t('termstitle')}</h4>
          <br></br>
          <p className="legal-text">
            <strong>BC COINS INTERMEDIADORA LTDA.</strong>, pessoa jurídica de
            direito privado, inscrita no CNPJ sob o nº 21.830.817/0001-67, situada
            na Avenida Osvaldo Reis, nº 3385, Sala 1407, Praia Brava de Itajaí, na
            cidade de Itajaí/SC, CEP nº 88.306-003, é a detentora de todos os
            direitos sobre e responsável pela plataforma online de intermediação
            de compra e venda de criptoativos “SERVIÇOS”, disponibilizada através
            do site hospedado sob o domínio www.bcxcorretora.com.br, denominada
            nestes TERMOS como “PLATAFORMA”.
          </p>
          <br></br>
          <div>
            <h5 className="legal-subtitle">1. Aceite</h5>
            <p className="legal-text">
              1.1. Antes de fazer uso dos SERVIÇOS oferecidos pela BC COINS, o
              USUÁRIO concorda em ler atentamente esses TERMOS, garantindo sua
              perfeita compreensão.
            </p>
            <p className="legal-text">
              1.2. O USUÁRIO, ao aceitar estes TERMOS, concorda com as disposições
              neles previstas, aceitando e vinculando-se a elas, bem como declara
              que está ciente de que os TERMOS poderão ser alterados ao longo do
              tempo, sendo sua responsabilidade a verificação periódica,
              principalmente antes de utilizar os SERVIÇOS da PLATAFORMA.
            </p>
            <p className="legal-text">
              1.2.2 Em caso de alterações, a BC COINS informará ao USUÁRIO na
              PLATAFORMA e/ou por e-mail. Para melhor comodidade do USUÁRIO, a
              data da última alteração consta ao final para fácil referência.
            </p>
            <p className="legal-text">
              1.3. Caso tenha alguma objeção a qualquer disposição destes TERMOS,
              inclusive posterior ao seu aceite, o USUÁRIO deverá cessar
              imediatamente o uso da PLATAFORMA, aplicando-se, durante o período
              que houver utilização – se houver, as regras aqui previstas.
            </p>
            <p className="legal-text">
              1.4. Para utilizar os SERVIÇOS da PLATAFORMA, o USUÁRIO deve ter
              conhecimento sobre o que são criptoativos, o mercado de criptoativos
              e ter considerado seu risco. O valor dos criptoativos pode variar no
              tempo e o USUÁRIO tem conhecimento dessa circunstância e a aceita
              por sua conta e risco, de forma consciente e informada.
            </p>
            <p className="legal-text">
              1.5. A PLATAFORMA presta os SERVIÇOS de intermediação de compra e
              venda de criptoativos, por isso, a responsabilidade pelas operações
              realizadas por seu intermédio é exclusivamente do USUÁRIO, não
              prestando a PLATAFORMA qualquer consultoria em investimentos.
            </p>
            <p className="legal-text">
              1.6 Não é permitida a utilização da PLATAFORMA por menores de 18
              (dezoito) anos.
            </p>

            <h5 className="legal-subtitle">2. A PLATAFORMA</h5>
            <p className="legal-text">
              2.1. A BC COINS poderá atualizar a PLATAFORMA sempre que desejar, ou
              for necessário, bem como deixar de disponibilizá-la, atualizá-la ou
              mesmo descontinuá-la a qualquer tempo.
            </p>
            <p className="legal-text">
              2.2. Em caso de descontinuidade da PLATAFORMA, a BC COINS informará
              os USUÁRIOS com antecedência de 60 (sessenta) dias.
            </p>

            <h5 className="legal-subtitle">
              3. CADASTRO E IDENTIFICAÇÃO PESSOAL
            </h5>
            <p className="legal-text">
              3.1. Para utilização da PLATAFORMA é necessário realizar um cadastro
              e enviar a documentação mencionada na{" "}
              <a href="src/panel/kyc">POLÍTICA DE KYC – PLD/F</a>, a qual
              recomendamos a leitura, a fim de ter acesso aos SERVIÇOS da BC
              COINS.
            </p>
            <p className="legal-text">
              3.2. Após a conclusão do cadastro será criada uma conta para o
              USUÁRIO, que será acessada unicamente mediante login e senha, que
              são de uso pessoal e intransferível, sendo de responsabilidade do
              USUÁRIO sua utilização e guarda de forma segura, responsável e de
              boa fé.
            </p>
            <p className="legal-text">
              3.2.1 A BC COINS não terá responsabilidade por eventual mau uso por
              parte do USUÁRIO ou de terceiros, por sua falha de segurança, bem
              como por eventuais ataques que o USUÁRIO venha a sofrer;
            </p>
            <p className="legal-text">
              3.2.2 Para dar mais segurança a sua conta, o USUÁRIO poderá ativar o
              mecanismo fornecido pela BC COINS por meio do segundo fator de
              Autenticação (2FA).
            </p>
            <p className="legal-text">
              3.3. Cada USUÁRIO poderá manter apenas uma conta na PLATAFORMA. Caso
              a BC COINS detecte a existência de mais de uma conta em nome do
              mesmo USUÁRIO, poderá suspender uma ou todas as contas, temporária
              ou definitivamente, ou até mesmo cancelar as contas, mediante prévio
              aviso ao USUÁRIO.
            </p>
            <p className="legal-text">
              3.4. O USUÁRIO é totalmente responsável pelas informações que
              fornece, as quais serão tratadas e protegidas de acordo com a
              POLÍTICA DE PRIVACIDADE. Se constatada irregularidade no
              preenchimento do cadastro, omissão de informações, dados equivocados
              ou preenchimento errôneo, a BC COINS poderá suspender o acesso do
              USUÁRIO à área do cadastro até que a situação se regularize, ou
              mesmo cancelar a conta.
            </p>
            <p className="legal-text">
              3.5. O cadastro é gratuito e é necessário que este seja validado
              pela BC COINS para que seja possível a utilização dos SERVIÇOS
              disponibilizados na PLATAFORMA. A BC COINS poderá realizar
              procedimentos de verificação dos dados e solicitar ao USUÁRIO o
              envio de documentos e informações adicionais, de acordo com sua
              POLÍTICA DE KYC – PLD/FT
            </p>
            <p className="legal-text">
              3.5.1 A validação do cadastro é liberalidade da BC COINS, que poderá
              recusar um cadastro sem precisar apresentar justificativa.
            </p>
            <p className="legal-text">
              3.6. O USUÁRIO aceita e concorda que toda e qualquer transação deve
              ser efetuada tão e somente pelo titular do cadastro.
            </p>

            <h5 className="legal-subtitle">4. FUNCIONAMENTO DA PLATAFORMA</h5>
            <p className="legal-text">
              4.1. A PLATAFORMA é destinada à intermediação de compra e venda de
              criptoativos entre USUÁRIOS, o que poderá ser realizado de duas
              formas, por meio de ORDENS DE COMPRA e VENDA privadas, por meio da
              ferramenta denominada “OTC”, ou públicas, chamada de “book”.
            </p>
            <p className="legal-text">
              4.1.1. Os criptoativos suportados pela PLATAFORMA estão listados no
              site da BCX , podendo ser acessados clicando{" "}
              <a href="src/panel/tarifas">aqui</a>. A tentativa de
              envio/recebimento de criptoativos não suportados pode acarretar
              perdas irreversíveis que serão de responsabilidade do USUÁRIO.
            </p>
            <p className="legal-text">
              4.1.2. O USUÁRIO reconhece e aceita que, caso não haja aviso
              expresso da BC COINS em sentido distinto, a PLATAFORMA não suporta
              criptoativos derivados de processos conhecidos como “hard forks”.
            </p>
            <p className="legal-text">
              4.2. Os USUÁRIOS poderão fazer aportes em reais ou em criptoativos
              em suas contas na PLATAFORMA para realizarem suas transações.
            </p>
            <p className="legal-text">
              4.3. Os aportes dos USUÁRIOS em reais deverão ser feitos por meio de
              transferência bancária à BC COINS, de modo que não serão aceitos
              depósitos em dinheiro realizados de qualquer forma, especialmente
              aqueles feitos diretamente no caixa. Também não serão aceitas
              transferências provenientes de contas bancárias de titularidade de
              outras pessoas, físicas ou jurídicas, que não o USUÁRIO.
            </p>
            <p className="legal-text">
              4.3.1. Caso seja confirmada a tentativa de transferência à BC COINS
              por meio de depósito em dinheiro ou por meio de contas bancárias de
              terceiros que não o USUÁRIO, a BC COINS estornará o valor
              depositado, retendo, entretanto, a taxa de administração. O valor da
              taxa poderá ser consultado clicando{" "}
              <a href="src/panel/tarifas">aqui</a>.
            </p>
            <p className="legal-text">
              4.3.2. Em caso de depósito em dinheiro, não identificado, a BC COINS
              fará a devolução do valor apenas mediante decisão judicial
              comprobatória da titularidade dos fundos.
            </p>
            <p className="legal-text">
              4.4. As transações relativas à compra e venda de criptoativos podem
              ser realizadas, respectivamente, por meio de ORDENS DE COMPRA e
              ORDENS DE VENDA privadas, via OTC, diretamente via book, diretamente
              na plataforma pelo preço de mercado e ou via nossa ferramenta de
              peer-to-peer(P2P Garantido).
            </p>
            <p className="legal-text">
              4.4.1. As ORDENS DE COMPRA e ORDENS DE VENDA privadas, devem ser
              realizadas sempre para USUÁRIOS já cadastrados e com KYC aprovado na
              plataforma e terão cotação e prazo de validade definido entre as
              partes, que poderão usar a ferramenta de chat para a negociação.
            </p>
            <p className="legal-text">
              4.4.1.1. As ORDENS DE COMPRA e ORDENS DE VENDA privadas, fora do
              book, somente poderão ser executadas em sua integralidade.
            </p>
            <p className="legal-text">
              4.4.2. Caso o USUÁRIO destinatário da ORDEM DE COMPRA ou ORDEM DE
              VENDA privada não concretize a ordem, a mesma será transferida para
              o book, passando a ser uma ordem pública.
            </p>
            <p className="legal-text">
              4.5. Fica aqui esclarecido que a negociação é realizada diretamente
              entre os USUÁRIOS, sem a interferência da BC COINS, que apenas
              oferece a PLATAFORMA para a negociação e liquidação das transações.
            </p>
            <p className="legal-text">
              4.6. Para realizar as transações de compra/venda na PLATAFORMA, os
              USUÁRIOS deverão ter saldo suficiente, em reais ou criptoativos,
              previamente depositados em suas contas na PLATAFORMA. Poderão ser
              estabelecidos limites para depósitos, saques e para transações
              diárias, de acordo com o perfil do USUÁRIO, consulte os limites
              mínimos e máximos da PLATAFORMA <a href="src/panel/tarifas">aqui</a>
              .
            </p>
            <p className="legal-text">
              4.6.1 O saldo em reais/criptoativos correspondente às ORDENS DE
              COMPRA/VENDA ficará bloqueado na conta do USUÁRIO até que:
            </p>
            <p className="legal-text">
              a) a operação de compra/venda seja consumada e o referido saldo seja
              transferido para a conta do USUÁRIO vendedor/comprador; ou
            </p>
            <p className="legal-text">
              b) caso a ORDEM DE COMPRA/VENDA não tenha sido aceita e o USUÁRIO
              comprador/vendedor a cancele.
            </p>
            <p className="legal-text">
              4.6.2. Uma vez aceita a ORDEM DE VENDA por um USUÁRIO comprador, os
              criptoativos correspondentes serão transferidos da conta do USUÁRIO
              vendedor para a conta do USUÁRIO comprador. Após concluída, a
              operação de venda não poderá ser desfeita ou modificada. Assim, o
              cancelamento de uma ORDEM DE COMPRA/VENDA só pode realizado antes de
              seu aceite por outro USUÁRIO.
            </p>
            <p className="legal-text">
              4.6.3. As ordens públicas registradas por USUÁRIOS poderão ser
              aceitas por outros USUÁRIOS de forma total ou parcial. Caso uma
              ORDEM seja parcialmente aceita, o USUÁRIO vendedor poderá:
            </p>
            <p className="legal-text">a) cancelar a ORDEM; ou</p>
            <p className="legal-text">
              b) manter a ORDEM vigente e esperar que outros USUÁRIOS aceitem os
              criptoativos remanescentes pelo mesmo preço fixado na ORDEM.
            </p>
            <p className="legal-text">
              4.6.4. É vedado emitir mais de uma ORDEM DE VENDA simultaneamente
              para a mesma quantidade de criptoativos.
            </p>
            <p className="legal-text">
              4.7. A BC COINS poderá, a seu exclusivo critério, fixar limites de
              aportes e transações de USUÁRIOS, bem como alterá-los a qualquer
              momento, conforme parâmetros definidos pela BC COINS e mediante
              aviso prévio aos USUÁRIOS.
            </p>
            <p className="legal-text">
              4.8. Para realizar saques de reais ou criptoativos da PLATAFORMA, o
              USUÁRIO deverá ter o saldo correspondente em sua conta e selecionar
              a conta corrente de sua titularidade ou endereço de wallet de sua
              titularidade, sendo totalmente responsável por essas informações. O
              valor do saque ficará bloqueado até que o saque seja consumado, no
              prazo máximo de 7 dias úteis dias para reais e criptoativos,
              ressalvado o prazo específico da rede Blockchain subjacente de cada
              criptoativo.
            </p>
            <p className="legal-text">
              4.8.1. Para realizar transações o USUÁRIO deverá ter saldo
              suficiente para pagar as taxas relativas às transações, que estão no
              link xxxxx, e que serão debitadas do saldo do USUÁRIO, em Reais ou
              em criptoativos, a critério da PLATAFORMA.
            </p>
            <p className="legal-text">
              4.9. O USUÁRIO se compromete a não utilizar a PLATAFORMA para
              infringir a legislação, nem praticar lavagem de dinheiro,
              financiamento de atividades e/ou organizações que envolvam
              terrorismo, tráfico de drogas, pessoas e/ou órgãos humanos.
            </p>
            <p className="legal-text">
              4.10. A BC COINS presta serviços de intermediação de compra e venda
              de criptoativos. Em se tratando de um segmento de risco, em nenhum
              momento a BC COINS poderá ser considerada como responsável por
              quaisquer perdas, danos, prejuízos e/ou lucros cessantes que possam
              eventualmente ser sofridos pelos USUÁRIOS devido às transações
              realizadas com criptoativos por meio da PLATAFORMA.
            </p>
            <p className="legal-text">
              4.11. A BC COINS não é uma consultoria de investimentos, tampouco os
              SERVIÇOS que presta implicam em qualquer tipo de consultoria sobre
              investimentos em criptoativos, sendo que o USUÁRIO o faz por sua
              conta e risco. A BC COINS sugere que seus USUÁRIOS se informem sobre
              o funcionamento desse segmento, sua valorização e oscilação, antes
              de utilizar os SERVIÇOS da PLATAFORMA.
            </p>
            <p className="legal-text">
              4.12. O horário para o processamento das transações bancárias
              solicitadas é entre 9:00 e 17:00 horas (horário bancário), de
              segunda à sexta, exceto feriados. Já as transações de criptoativos
              podem ser realizadas pelos USUÁRIOS, mediante posse de saldo, a
              qualquer momento através da PLATAFORMA.
            </p>
            <p className="legal-text">
              4.13. Sobre os SERVIÇOS serão cobradas tarifas e comissão de acordo
              com a tabela disponibilizada na página: Conforme nossa tabela
              disponível no site, que poderá ser acessada clicando aqui.
              <a href="src/panel/tarifas">aqui</a>.
            </p>

            <h5 className="legal-subtitle">5. SUPORTE</h5>
            <p className="legal-text">
              5.1. O suporte se limita a esclarecimentos acerca do funcionamento
              da PLATAFORMA e sobre os SERVIÇOS oferecidos, pressupondo o
              conhecimento por parte do USUÁRIO do uso do computador e suas
              funções, bem como do sistema operacional sob o qual o sistema será
              utilizado. Pressupõe-se ainda a configuração adequada do computador
              e dispositivo móvel no que se refere à utilização da PLATAFORMA e o
              bom estado de funcionamento do referido computador.
            </p>
            <p className="legal-text">
              5.2. O suporte fará atendimento ao USUÁRIO, nos dias úteis, entre
              das 8h às 19hrs por meio do formulário de contato ou do chat online
              disponibilizados na PLATAFORMA, sendo que a BC COINS se compromete a
              fornecer uma resposta em em até 7 dias úteis, contados do chamado
              realizado pelo USUÁRIO.
            </p>
            <p className="legal-text">
              5.3. O Suporte fornecido pela BC COINS não abrange questões como
              conexão com a internet, rede interna e computadores/dispositivos
              móveis do USUÁRIO.
            </p>

            <h5 className="legal-subtitle">6. PROPRIEDADE INTELECTUAL</h5>
            <p className="legal-text">
              6.1. Todos os direitos de propriedade intelectual referente à
              PLATAFORMA pertencem à BC COINS, sendo concedido ao USUÁRIO apenas o
              direito limitado de uso da interface para usufruto dos SERVIÇOS por
              ela disponibilizados.
            </p>
            <p className="legal-text">
              6.2. A PLATAFORMA poderá ser aperfeiçoado, podendo inclusive ocorrer
              suspensão temporária do serviço em virtude disso. O USUÁRIO desde
              já, concorda com modificações, implementação de novos recursos,
              ferramentas, melhorias ou correções na PLATAFORMA e que estas
              continuarão sendo também de propriedade única e exclusiva da BC
              COINS.
            </p>
            <p className="legal-text">
              6.3. É proibida a utilização indevida de quaisquer conteúdo ou
              marcas da BC COINS.
            </p>

            <h5 className="legal-subtitle">7. LIMITAÇÃO DE RESPONSABILIDADE</h5>
            <p className="legal-text">
              7.1. A BC COINS envidará todos os esforços na prestação dos
              SERVIÇOS, no entanto, não poderá ser responsabilizada por:
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  (i) qualquer defeito decorrente de culpa exclusiva do USUÁRIO;
                </li>
                <li>
                  (ii) integração da PLATAFORMA com qualquer outro software de
                  terceiros ou do USUÁRIO;
                </li>
                <li>
                  (iii) danos ou prejuízos decorrentes de decisões
                  administrativas, gerenciais ou comerciais tomadas pelo USUÁRIO
                  com base nas informações fornecidas pela PLATAFORMA;
                </li>
                <li>(iv) problemas na rede física e lógica do USUÁRIO;</li>
                <li>
                  (v) toda e qualquer responsabilidade por eventuais perdas e
                  danos, lucros cessantes ou emergentes, cujas causas possam ser
                  atribuídas, de forma direta ou indireta, ao fornecimento,
                  utilização ou desempenho da PLATAFORMA, correção de bugs ou
                  paradas de atualização;
                </li>
                <li>
                  (vi) eventos definidos na legislação civil como caso fortuito ou
                  força maior;
                </li>
                <li>(vii) uso perigoso e/ou inadequado da PLATAFORMA;</li>
                <li>
                  (viii) fatores de ordem técnica que impossibilitem a
                  transferência de dados e/ou o download de informações da
                  PLATAFORMA, nestes incluídos problemas de internet e
                </li>
                <li>
                  (ix) atrasos decorrentes do prazo de validação de transações nas
                  redes Blockchain subjacentes.
                </li>
              </ul>
            </p>
            <p className="legal-text">
              7.2. Em caso de suspeita de fraude ou qualquer outra atividade
              ilícita ou maliciosa (p.ex.: atividades suspeitas de manipulação de
              preços de mercado, na tentativa de forçar à subida ou queda dos
              preços, além dos valores de mercado, por foça de capital aportado na
              plataforma, bots maliciosos ou ação individual ou coletiva de grupos
              de usuários), a BC COINS poderá, além de recorrer às medidas legais
              cabíveis, no momento efetivo da rescisão, reter eventuais fundos do
              USUÁRIO até a conclusão das respectivas investigações ou mesmo
              encerrar a conta do USUÁRIO na plataforma.
            </p>
            <p className="legal-text">
              7.3. A BC COINS reserva para si o direito de negar a solicitação de
              compra e proceder a devolução do valor transferido por USUÁRIOS, bem
              como cancelar suas contas, em caso de indícios de, mas sem se
              limitar a:
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  a) fornecimento de informações falsas, imprecisas, incompletas
                  ou enganosas;
                </li>
                <li>b) indícios de fraude;</li>
                <li>
                  c) não fornecimento de informações e/ou documentos adicionais
                  após 30 dias da solicitação, sem resposta do USUÁRIO;
                </li>
                <li>d) má-fé;</li>
                <li>e) violação de alguma das disposições destes TERMOS;</li>
                <li>f) uso dos SERVIÇO em desacordo com a legislação;</li>
                <li>g) envolvimento em condutas fraudulentas ou ilegais;</li>
              </ul>
            </p>

            <h5 className="legal-subtitle">8. CONSIDERAÇÕES FINAIS</h5>
            <p className="legal-text">
              8.1. O USUÁRIO poderá, a qualquer momento, solicitar o cancelamento
              da sua conta. Para tanto, todo e qualquer saldo, seja em reais ou em
              criptoativos, deverá ser transferido para outra conta a ser
              informada pelo USUÁRIO. Nesse caso, apenas as informações de guarda
              obrigatória serão mantidas pela BC COINS, pelo tempo exigido por
              lei.
            </p>
            <p className="legal-text">
              8.2. Em caso de suspensão da conta por suspeita de fraude ou
              ilegalidade, os saldos poderão ser bloqueados, sem prejuízo da
              adoção de medidas legais cabíveis.
            </p>
            <p className="legal-text">
              8.3. A incapacidade ou a mera liberalidade em não fazer cumprir
              quaisquer disposições destes TERMOS não constitui renúncia de
              direitos, podendo ser exigido o seu cumprimento em qualquer momento.
            </p>
            <p className="legal-text">
              8.4. Caso qualquer disposição destes TERMOS seja considerada
              inexequível ou inválida, essa disposição será isoladamente
              invalidada e não atingirá o restante das disposições.
            </p>
            <p className="legal-text">
              8.5. Estes TERMOS e a relação entre as Partes serão regidos pelas
              leis da República Federativa do Brasil, principalmente o Código
              Civil e o Código de Defesa do Consumidor.
            </p>
            <p className="legal-text">
              8.6. As Partes elegem o Foro da Comarca de São Paulo como sendo o
              único competente para dirimir quaisquer litígios e/ou demandas que
              venham a envolver as Partes em relação ao SERVIÇO.
            </p>
            <p className="legal-text">
              "ACEITO" ao final, o USUÁRIO concorda com o previsto nesses TERMOS,
              aceitando e vinculando-se a eles, e com a Política de Privacidade da
              BC COINS.
            </p>

            <h5 className="legal-subtitle">
              DATA DA ÚLTIMA ATUALIZAÇÃO: 02/08/2023
            </h5>
          </div>
        </div>
      )}
    </Panel >
  );
}
