import React from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Row,
  Form,
  Collapse,
} from "react-bootstrap";
import api from "../../utils/api";
import ConfirmModal from "../ConfirmModal";
import { AppContext } from "../../context/AppContext";


export default class AdminUserPpe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ppe: true};
  }
  
  componentDidMount = () => {
    this.toggleShow();
  };
  
  static contextType = AppContext;

  toggleShow = () => {
    if (this.state.show) {
      this.setState({ show: false });
    } else {
      let id = this.props.id;
      api.get(`users/${id}`).then((req) => {
        if (!req.error) {
          console.log(req.data);
        }
      });
  };
};

  handleChange = (event) => {
    const { value } = event.target;
    if (value === "yes") {
      this.setState({
        showInvestments: true,
      });
    } else {
      this.setState({
        showInvestments: false,
      });
    }
  };

  submit = () => {
    this.confirm.show(
      "Atenção",
      <h3 className="text-center">Deseja salvar as modificações?</h3>,
      () => {
        this.saveProfile();
      }
    );
  };

  saveProfile = async () => {
    let id = this.props.id;
    console.log(id);
    if (!this.state.showInvestments) {
      let req = await api.del(`seeInvestment/${id}`);
      if (!req.error) {
        toast.success(`Usuário editado`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`Erro ao editar usuário: ${req.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (this.state.showInvestments) {
      let req = await api.post(`seeInvestment`, {
        "usersId": id,
      });
      if (!req.error) {
        toast.success(`Usuário editado`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`Erro ao editar usuário: ${req.message}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
};

  render() {
    return (
      <>
        {(this.context.loggedInUser?.permission?.level >= 4 || this.context.hasInvestmentPermission) && (
        <Form className="w-100 card p-3 mb-5">
          <div
            className="text-large bold mb-2 w-100 d-flex btn"
            onClick={this.toggleShow}
          >
            {this.context.selectedUser?.roles?.name === "admin" ? (<span className="">Permissão Para Editar Quem Vê Investimentos</span>) : (<span className="">Permissão Para Ver Investimentos</span>)}
            <i className="fas fa-caret-down text-large mr-3 ml-auto"></i>
          </div>
          <div
            style={{ width: "100%", height: 1, backgroundColor: "#50504E" }}
          ></div>
          <Collapse in={this.state.show}>
            <div>
              <Row className="mb-1 mt-3">
                <Form.Group as={Col}>
                  { this.context.selectedUser?.roles?.name === "admin" ? (<Form.Label>Este administrador tem permissão para editar a visibilidade da aba "Meus Investimentos"?</Form.Label>) : (<Form.Label>Este usuário tem permissão para ver a aba "Meus Investimentos"?</Form.Label>) }
                  <Form.Control
                      className="input"
                      as="select"
                      onChange={this.handleChange}
                      value={this.state.showInvestments ? "yes" : "no"}
                    >
                      <option value="yes">Sim</option>
                      <option value="no">Não</option>
                    </Form.Control>
                </Form.Group>
              </Row>
              <Row>
                <Button
                  className="ml-auto mr-auto"
                  onClick={this.submit}
                >
                  Salvar
                </Button>
              </Row>
            </div>
          </Collapse>
        </Form>
        )}
        <ConfirmModal ref={(ref) => (this.confirm = ref)} />
      </>
    );
  }
}
