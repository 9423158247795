import React, { useContext, useState } from "react";
import { Button, Col, Row, Card, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../context/AppContext";
import api from "../../utils/api";
import { useTranslation, Trans } from 'react-i18next';
import '../../i18n'

const File = ({ desc, fileName, removeFile }) => (
  <div
    style={{
      borderStyle: "solid",
      borderColor: "#888",
      borderWidth: 1,
      borderRadius: 5,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 20,
      width: 140,
      minHeight: 30,
      padding: "5px 10px",
      textAlign: "center",
    }}
  >
    <div className="d-flex btn p-0" onClick={() => removeFile(desc)}>
      <span
        style={{
          width: 100,
          fontSize: 12,
          overflow: "hidden",
          whiteSpace: "nowrap",
          paddingRight: 10,
        }}
      >
        {fileName}
      </span>
      <i className="fas fa-trash text-danger ml-auto"></i>
    </div>
  </div>
);

const Status = (props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  if (props.status === "rejected") {
    return (
      <div className="mb-5 mt-2 text-mid">
        <span className="m-0 p-0">{t("rejecteddoc")} {/* Documento rejeitado. */}</span>

        <div className="kyc-icon-miss m-0 p-0 text-large">
          <i className="fas fa-times-circle"></i>
        </div>

        <span className="m-0 p-0">{t("rejectmotif")} {/* Motivo: */} {props.reason}</span>
      </div>
    );
  } else if (props.status === "approval") {
    return (
      <div className="mb-5 mt-2 text-mid">
        <span className="m-0 p-0">{t("accepteddoc")} {/* Documento aceito */}</span>
        <br />
        <div className="kyc-icon-ok m-0 p-0 text-large">
          <i className="fas fa-check-circle"></i>
        </div>
      </div>
    );
  } else if (props.status === "waiting") {
    return (
      <div className="mb-5 mt-2">
        <span className="m-0 p-0">{t("inanalysis")} {/* Em análise */}</span>
        <br />
        <div className="kyc-icon-waiting2 m-0 p-0 text-large">
          <i className="fas fa-info-circle"></i>
        </div>
      </div>
    );
  }
  // status === "none"
  return null;
};

export function formatFileDesc(desc, t) {


  if (desc == "socialContract") {
    return t("docsocialcontract") /* Contrato Social */
  }

  if (desc == "cnpjCard") {
    return t("doccnpj") /* Cartão CNPJ */
  }

  if (desc == "id") {
    return t("docidcnh") /* Identidade ou CNH */
  }

  if (desc == "selfie") {
    return t("docselfieid") /* "Selfie segurando identidade ou CNH" */
  }

  if (desc == "address") {
    return t("residenceproof") /* Comprovante de residência" */
  }

  if (desc == "balanceSheet") {
    return t("balancesheet") /* "Balanço Patrimonial" */
  }

  if (desc == "incomeTax") {
    return t("incometax") /* "Imposto de Renda (IR)" */
  }

  if (desc == "deliveryReceipt") {
    return t("deliveryreceipt") /* "Recibo de entrega do IR";*/
  }
}

export function kycStatusCurrentToRequestFile(user) {
  if (user.legalPerson) {
    return [
      "socialContract",
      "cnpjCard",
      "id",
      "selfie",
      "address",
      "balanceSheet",
    ];
  }

  return ["id", "selfie", "address", "incomeTax", "deliveryReceipt"];
}

const verifyStatus = (kycFiles, desc) => {
  if (!kycFiles) {
    kycFiles = [];
  }

  const files = kycFiles?.filter(
    (file) => file.desc == desc && file.status == "received"
  );

  if (files.length === 0) return "none";

  const file = files[0];

  if (file.approval == 1) {
    return "approval";
  }

  if (file.approval == 0 && file.rejectReason) {
    return "rejected";
  }

  if (file.approval == 0 && !file.rejectReason) {
    return "waiting";
  }

  return "none";
};

const verifyReason = (kycFiles, desc) => {
  if (!kycFiles) {
    kycFiles = [];
  }

  const files = kycFiles.filter(
    (file) => file.desc == desc && file.status !== "old"
  );

  if (files.length == 0) return null;

  const file = files[0];

  return file.rejectReason;
};

export default function UploadKYC(props) {
  const itemsRef = {};
  const { loggedInUser, setLoggedInUser } = useContext(AppContext);
  const [fileInputName, setFileInputName] = useState({});
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const { t, i18n } = useTranslation();
  const removeFile = (name) => {
    setFileInputName((prevState) => ({
      ...prevState,
      [name]: null,
    }));
  };

  const onFileChange = async (event) => {
    const input = event.target;
    let file = input.files.length > 0 && input.files[0];

    if (!file) return null;

    var fileName = file.name;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

    if (!input.accept.includes(extFile)) {
      toast.error(t("error.invalidformatfile"));  // Formato de arquivo inválido
      return null;
    }

    // Add a size check (e.g., 64MB = 64 * 1024 * 1024 bytes)
    const maxSize = 64 * 1024 * 1024; // 64MB
    if (file.size > maxSize) {
      toast.error(t("error.filetoolarge")); // Arquivo muito grande
      return null;
    }

    try {
      setFileInputName((prevState) => ({
        ...prevState,
        [input.name]: file,
      }));
    } catch (err) {
      toast.error(err.message);
      setFileInputName((prevState) => ({
        ...prevState,
        [input.name]: null,
      }));
    }
  };

  const uploadFiles = async () => {
    let req;
    let promises = [];

    Object.keys(fileInputName).map((desc) => {
      req = api.postFiles(`profile/kyc-files/${desc}`, [fileInputName[desc]]);
      promises.push(req);
      return desc;
    });

    setLoadingChangeStatus(true);
    Promise.all(promises).then(async (values) => {
      // Check if there are any errors in the responses
      const hasError = values.some((value) => value.error);

      if (!hasError) {

        const res = await api.get("profile");

        if (!res.error) {
          setLoadingChangeStatus(false);
          setLoggedInUser(res.data);

          toast.success(t("docssent"), { // Documentos enviados
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          await props.setTab(0);
        }
      } else {
        toast.error(t("error.docssent"), { // Documentos falhou
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoadingChangeStatus(false);
      }
    });
  };

  return (
    <>
      <Card className="kyc-card2">
        <h4 className="mb-5">{t("senddoccopy")} {/* Insira uma cópia dos documentos abaixo:*/}</h4>

        <Row>
          {kycStatusCurrentToRequestFile(loggedInUser).map((desc) => (
            <Col key={desc} md>
              <img
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "5px",
                  marginBottom: "10px",
                }}
                src="/images/doc.png"
                height="40px"
                alt="BC Coins Logo"
              />

              <p>{formatFileDesc(desc, t)}</p>

              {verifyStatus(loggedInUser?.kycFiles, desc) !== "approval" &&
                verifyStatus(loggedInUser?.kycFiles, desc) !== "waiting" && (
                  <>
                    <File
                      desc={desc}
                      fileName={fileInputName[desc]?.name}
                      removeFile={removeFile}
                    />
                    <input
                      ref={(el) => (itemsRef[desc] = el)}
                      className="d-none"
                      type="file"
                      name={desc}
                      onChange={onFileChange}
                      multiple
                      accept="image/png,image/jpeg,image/jpg,application/pdf"
                    />
                    <Button
                      variant="secondary"
                      onClick={() => itemsRef[desc].click()}
                    >
                      {t("indexed")} {/* Anexar*/}
                    </Button>
                  </>
                )}
              <Status
                status={verifyStatus(loggedInUser?.kycFiles, desc)}
                reason={verifyReason(loggedInUser?.kycFiles, desc)}
              />
            </Col>
          ))}
        </Row>

        {loadingChangeStatus && (
          <div className="text-center">
            <Spinner
              className="mt-3"
              variant="secondary"
              animation="border"
              role="status"
            ></Spinner>
          </div>
        )}

        {loggedInUser.kycStatus !== "3" && (
          <>
            <div className="text-center mt-4 text-danger">
              {t("maxsizefile")}
            </div>
            <div className="text-center mt-2">
              <Button onClick={uploadFiles}>  {t("confirm")} </Button>
            </div></>
        )}
      </Card>
      <div className="btn-voltar mb-5">
        <span onClick={() => props.setTab(0)}>{`<` + t("back") + `>`}</span>
      </div>
    </>
  );
}
