import React from "react";
import { Table } from "react-bootstrap";
import Panel from "../../components/Panel";

export default class AdminEmployees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="employees"
        title="Wallets Empresa"
        admin={true}
      >
        <div className="panel_wallets">
          <div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#50504E",
              }}
            >
              <span className="bold text1 mx-3 text-mid2">Carteiras</span>
            </div>
            <Table
              className="hist-table m-0 p-0 text-mid"
              responsive="md"
              striped
              hover
            >
              <thead>
                <tr>
                  <th>Moeda</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>bitcoin</td>
                  <td>2,9797979</td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#50504E",
              }}
            >
              <span className="bold text1 mx-3 text-mid2">Em ordem</span>
            </div>
            <Table
              className="hist-table m-0 p-0 text-mid"
              responsive="md"
              striped
              hover
            >
              <thead>
                <tr>
                  <th>Moeda</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>bitcoin</td>
                  <td>2,9797979</td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#50504E",
              }}
            >
              <span className="bold text1 mx-3 text-mid2">Em investimento</span>
            </div>
            <Table
              className="hist-table m-0 p-0 text-mid"
              responsive="md"
              striped
              hover
            >
              <thead>
                <tr>
                  <th>Moeda</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>bitcoin</td>
                  <td>2,9797979</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Panel>
    );
  }
}
