import React from "react";
import Panel from "../../components/Panel";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation, Trans } from 'react-i18next';
import '../../i18n'

export default function InvestmentPolicy() {

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  return (
    <Panel page="investmentPolicy" title={t("investmentpolicytitle")}>
      <div className="legal-back-btn">
        <Link to="/panel/trade" className="legal-link">
          <Button>{t('back')}</Button>
        </Link>
      </div>
      {currentLanguage === 'en' ? (
        <div className="legal-div">
          <h4 className="legal-title">
            EXCLUSIVE TERMS AND CONDITIONS OF THE "ASSET MANAGEMENT SERVICE"
          </h4>
          <br></br>
          <p className="legal-text">
            This service is available for contracting only for selected users who are already clients of BCX BROKER.
          </p>
          <p className="legal-text">
            BY SUBSCRIBING TO THE SERVICE, IT IS UNDERSTOOD THAT THE USER AGREES TO THE TERMS AND CONDITIONS SET FORTH IN THIS DOCUMENT. THEREFORE, PLEASE READ ALL THE PROVISIONS OF THESE TERMS CAREFULLY BEFORE ACCEPTING THEM.
          </p>
          <p className="legal-text">
            (i) BCX, already qualified in its Exchange Terms of Use, exclusively offers its registered USERS on the Platform, accessible by login and password, the possibility of joining the Asset Management Service ("Service").
          </p>
          <p className="legal-text">
            (ii) BY CLICKING ON THE BOX "I HAVE READ, AGREE, AND EXPRESSLY ACCEPT THESE TERMS AND CONDITIONS," YOU, THE USER, DECLARE THAT YOU AGREE TO THE CONDITIONS STATED HERE.
          </p>
          <p className="legal-text">
            (iii) IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS BELOW, YOU SHOULD NOT ACCEPT THEM OR JOIN THE SERVICE.
          </p>
          <p className="legal-text">
            (iv) BCX MAY, AT ITS DISCRETION AND AT ANY TIME, CHANGE THESE TERMS AND CONDITIONS BY PUBLISHING THE UPDATED VERSION ON THE PLATFORM. Updates to these Terms and Conditions will take effect on the date of publication unless otherwise informed. USERS will be informed in advance, by email or notice on the Platform, and new acceptance of these Terms will be required.
          </p>
          <p className="legal-text">
            (v) These Terms and Conditions are complementary, insofar as applicable, to the same provisions of the Exchange Terms of Use.
          </p>
          <br></br>
          <div>
            <h5 className="legal-subtitle">
              1. WHAT IS THE ASSET MANAGEMENT SERVICE
            </h5>
            <p className="legal-text">
              1.1 Through the Asset Management Service, USERS have the possibility to transfer part of their crypto assets to be managed by BCX for a predetermined period ("cycle"). BCX will carry out strategic operations with the aim of increasing the original amount of crypto assets transferred by the USER.
            </p>
            <p className="legal-text">
              1.2 BCX offers three modalities of the service, to meet the different profiles of USERS, according to the level of risk. The strategies are developed by a team of analysts who are experts in the crypto asset market and always indicate the best time for operations, as described below:
            </p>
            <table>
              <thead>
                <tr>
                  <th>Modality</th>
                  <th>Asset</th>
                  <th>Stop Loss</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Standard or Classic</td>
                  <td>Bitcoin</td>
                  <td>1%</td>
                </tr>
                <tr>
                  <td>Moderate, Security, or High Value</td>
                  <td>Bitcoin</td>
                  <td>0.5%</td>
                </tr>
                <tr>
                  <td>High Performance or Bold</td>
                  <td>Bitcoin</td>
                  <td>1.5%</td>
                </tr>
              </tbody>
            </table>
            <p className="legal-text">
              1.3 The USER must carefully read the characteristics of each modality and consider their risk level and the volatility involved in crypto asset operations. BCX will make every effort to achieve the best results with the developed strategies; however, BCX does not guarantee the effective increase in the quantity of USER's crypto assets. By accepting these Terms and Conditions, the USER is aware and releases BCX from ANY liability in case of losses, and in this case, no compensation will be applicable.
            </p>
            <p className="legal-text">
              1.3.1 BCX adopts cybersecurity measures to ensure the stability of the PLATFORM. However, the USER is aware that virtual environments are, even minimally, exposed to risks, instability, invasions (hacking) that could result in partial or total loss/theft of their resources. BCX is exempt from responsibility for these events when it demonstrates that it has taken all expected measures of a company of its size and activity to avoid this type of action.
            </p>

            <h5 className="legal-subtitle">2. HOW TO JOIN THE SERVICE:</h5>
            <p className="legal-text">
              2.1 The Service is available for exclusive acquisition by USERS registered with BCX Exchange. Therefore, to join, the USER must have an open and active account, approved KYC, and a balance available in crypto assets.
            </p>
            <p className="legal-text">
              2.2 To join the Service, the USER must accept these Specific Terms and Conditions. After acceptance, the USER must create a management order, informing BCX of the amount of crypto assets from their wallet available for management. The transfer of crypto assets for management will be done automatically.
            </p>

            <h5 className="legal-subtitle">3. SERVICE RENDERING CYCLES</h5>
            <p className="legal-text">
              3.1 At the moment the crypto assets are transferred to BCX, the USER must select the cycle management period.
            </p>
            <p className="legal-text">
              3.1.1 The Service Rendering Cycles will be considered the periods in which the crypto assets must remain under the management of BCX, according to the modality chosen by the USER.
            </p>
            <p className="legal-text">
              3.2 Monthly, a report of transactions and operations carried out by BCX will be generated and sent to the USER.
            </p>

            <h5 className="legal-subtitle">4. FEES AND CHARGES</h5>
            <p className="legal-text">
              4.1 The Infrastructure Fee calculated on the gross value of profitability and the Operational Fee calculated on the net profitability obtained by BCX shall be payable to BCX.
            </p>
            <p className="legal-text">
              4.2 In the case of automatic cycle renewal, the Operational Fee may be changed with each new cycle. Access the updated fees and charges by clicking here.
            </p>

            <h5 className="legal-subtitle">5. PROFITABILITY</h5>
            <p className="legal-text">
              5.1 All profitability will be paid in Bitcoin at the end of the management cycle, through the transfer by BCX to the destination wallet indicated by the USER.
            </p>
            <p className="legal-text">
              5.2 The original quantity of USER's crypto assets will be transferred to their destination wallet. If the USER chooses to sell their crypto assets to BCX, they must indicate their own account for receiving in fiat currency.
            </p>

            <h5 className="legal-subtitle">6. TERMINATION OF THESE TERMS</h5>
            <p className="legal-text">
              6.1 The USER may request the termination of these Specific Terms and Conditions, as follows:
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  a) with a 30 (thirty) days' notice, the USER shall pay BCX the OPERATIONAL FEE of 20% (twenty percent) of the profitability of the period, as well as a penalty of 15% (fifteen percent) on the profitability of the period, with BCX having a period of 7 (seven) business days for payment.
                </li>
                <li>
                  b) without 30 (thirty) days' notice, the OPERATIONAL FEE of 20% (twenty percent) of the profitability shall be due to BCX, as well as a penalty of 40% (forty percent) on the profitability obtained until the date of the request, with BCX having a period of 7 (seven) business days for payment.
                </li>
              </ul>
            </p>
            <p className="legal-text">
              6.2 Pro-rata and grace period in case of termination before the end of the cycle:
            </p>
            <p className="legal-text">
              6.2.1 In both cases, if the request for termination of these Terms occurs before the end of the chosen cycle, the USER will receive as profitability the amount corresponding to the average of the previous months, respecting a minimum of 5% (five percent) for the remaining months until the end of the contract. The Operational Fee is applied to the final value of the estimated profitability, as if the cycle had been completed.
            </p>
            <p className="legal-text">
              6.2.3 If the request for termination is before the end of the cycle, the USER can only rejoin the service after 6 (six) months from termination, unless otherwise determined at the discretion of BCX.
            </p>
            <p className="legal-text">
              6.3 The USER may choose to withdraw only the profitability obtained during each 30-day cycle, keeping the originally contributed crypto assets within the contracted management cycle.
            </p>

            <h5 className="legal-subtitle">7. POSSIBILITY OF TERMINATION OF THE SERVICE BY BCX</h5>
            <p className="legal-text">
              7.1 BCX reserves the right to terminate the provision of the Service at any time, with prior communication to USERS, except in cases of force majeure, and return of the crypto assets placed under management.
            </p>

            <h5 className="legal-subtitle">8. IMPORTANT PROVISIONS TO USERS OF THE SERVICE</h5>
            <p className="legal-text">8.1 Customer Service Channels</p>
            <p className="legal-text">
              8.1.1 To assist the USER in matters related to the Service or these Terms and Conditions, BCX will provide a service channel for problem resolution and/or clarification of doubts, and contact can be made via email support@bcxcorretora.com.br.
            </p>
            <p className="legal-text">8.2 Privacy Policy</p>
            <p className="legal-text">
              8.2.1 The provisions of the PRIVACY POLICY apply to personal data processed due to these Terms.
            </p>
            <p className="legal-text">8.3 Responsibilities and Guarantees:</p>
            <p className="legal-text">
              8.3.1 BCX will make every effort in the provision of the service; however, it cannot be held responsible for (i) any problems arising from the exclusive fault of the USER; (ii) events defined in civil legislation as force majeure or fortuitous event.
            </p>
            <p className="legal-text">
              8.3.2 BCX declares that it has a Reserve Fund, administered with the aim of minimizing negative impacts caused by adverse and emergency situations, at its discretion.
            </p>
            <p className="legal-text">
              8.3.3 The USER WARRANTS AND DECLARES THAT THE ASSETS TRANSFERRED TO BCX FOR MANAGEMENT HAVE A LAWFUL AND PROVEN ORIGIN BEFORE THE FEDERAL REVENUE SERVICE OF BRAZIL, NOT BEING SUBJECT TO CRIMES OR SUSPICIOUS ACTIVITIES OF MONEY LAUNDERING AND TERRORISM FINANCING.
            </p>
            <p className="legal-text">
              8.3.4 BCX informs that in case of suspicion of fraud or any other illicit activity, it may, in addition to resorting to legal measures, at the effective moment of termination, retain USER's resources until the conclusion of any investigations.
            </p>
            <p className="legal-text">
              8.3.5 BCX reserves the right to deny the request for the provision of the Service, as well as to refund any and all amounts transferred by the USER in case of indications of, but not limited to:
            </p>

            <p className="legal-text">8.4 Tax and Legal Obligations</p>
            <p className="legal-text">
              8.4.1 Each Party is responsible for all tax, financial, and legal obligations arising from the execution of these Terms, applicable in their country of origin. Under no circumstances can BCX be held responsible for any taxes or obligations of the USER.
            </p>
            <p className="legal-text">8.5 Intellectual Property:</p>
            <p className="legal-text">
              8.5.1 All intellectual property rights related to the asset management service belong to BCX. Acceptance of these terms and conditions does not grant the USER any ownership rights over BCX services and content.
            </p>
            <p className="legal-text">8.6 Private Offering</p>
            <p className="legal-text">
              8.6.1 The Service's offer is made EXCLUSIVELY AND PRIVATELY to USERS who are already clients of BCX, therefore, it is EXPRESSLY PROHIBITED for them to download any content with the intention of storing it in a database and/or offering it to third parties or disclosing it on any channels, social networks, or any means of public access.
            </p>
            <p className="legal-text">
              8.6.2 If the USER becomes aware of violations of the above, they should forward it to BCX by email.
            </p>

            <h5 className="legal-subtitle">9. USER SECURITY</h5>
            <p className="legal-text">
              9.1 BCX DOES NOT REQUEST OR REQUIRE ITS USERS TO DISCLOSE THEIR PASSWORDS, CREDIT CARD DATA, OR OTHER BANKING INFORMATION BY EMAIL, PHONE, OR ANY OTHER CUSTOMER SERVICE CHANNEL. If you receive any communication with such an approach and content, do not respond, disregard it, and if possible, forward your report to BCX's email.
            </p>

            <h5 className="legal-subtitle">10. FINAL PROVISIONS</h5>
            <p className="legal-text">
              10.1 These Terms and Conditions are governed by the laws of the Federative Republic of Brazil. Any doubts and situations not foreseen in these Terms and Conditions will be first resolved between the USER and BCX and, if they persist, should be resolved by the Forum of the Comarca of Itajaí, state of Santa Catarina, with the exclusion of any other, no matter how privileged it may be or become.
            </p>

            <h5 className="legal-subtitle">
              LAST UPDATE DATE: 06/16/2023
            </h5>
          </div>
        </div>
      ) : (
        <div className="legal-div">
          <h4 className="legal-title">
            TERMOS E CONDIÇÕES EXCLUSIVOS DO SERVIÇO DE “GERENCIAMENTO DE ATIVOS
          </h4>
          <br></br>
          <p className="legal-text">
            Este serviço está disponível para contratação apenas para usuários
            selecionados e que já sejam clientes da BCX CORRETORA
          </p>
          <p className="legal-text">
            AO ADERIR AO SERVIÇO, ENTENDE-SE QUE O USUÁRIO ESTÁ DE ACORDO COM OS
            TERMOS E CONDIÇÕES DETERMINADOS NESTE DOCUMENTO. SENDO ASSIM, FAVOR
            LER COM ATENÇÃO TODAS AS DISPOSIÇÕES DESTES TERMOS ANTES DE
            ACEITÁ-LOS.
          </p>
          <p className="legal-text">
            (i) A BCX, já qualificada nos seus Termos de Uso da Exchange, oferece
            exclusivamente aos seus USUÁRIOS cadastrados na Plataforma, acessível
            mediante login e senha, a possibilidade de adesão ao Serviço de
            Gerenciamento de Ativos (“Serviço”).
          </p>
          <p className="legal-text">
            (ii) AO CLICAR NA CAIXA “EU LI, CONCORDO E ACEITO EXPRESSAMENTE ESTES
            TERMOS E CONDIÇÕES, VOCÊ, USUÁRIO, DECLARA QUE CONCORDA COM AS
            CONDIÇÕES AQUI DISPOSTAS.
          </p>
          <p className="legal-text">
            (iii) CASO NÃO CONCORDE COM TODOS OS TERMOS E CONDIÇÕES A SEGUIR, VOCÊ
            NÃO DEVERÁ ACEITÁ-LOS OU ADERIR AO SERVIÇO.
          </p>
          <p className="legal-text">
            (iv) A BCX PODERÁ, A SEU CRITÉRIO E A QUALQUER MOMENTO, ALTERAR ESTES
            TERMOS E CONDIÇÕES, MEDIANTE PUBLICAÇÃO DA VERSÃO ATUALIZADA NA
            PLATAFORMA. As atualizações destes Termos e Condições entrarão em
            vigor na data de sua publicação, a não ser que seja informado em
            sentido diverso. Os USUÁRIOS serão informados previamente, por meio de
            e- mail OU aviso na Plataforma, sendo requisitado novo aceite a estes
            Termos.
          </p>
          <p className="legal-text">
            (v) Aproveitam-se para esses Termos e Condiçoes, no que forem
            complementares, as mesmas disposições dos Termos de Uso da Exchange.
          </p>
          <br></br>
          <div>
            <h5 className="legal-subtitle">
              1. O QUE É O SERVIÇO DE GERENCIAMENTO DE ATIVOS
            </h5>
            <p className="legal-text">
              1.1 Por meio do Serviço de Gerenciamento de Ativos, os USUÁRIOS têm
              a possibilidde de transferir parte dos seus criptoativos para serem
              gerenciados pela BCX por um período pré-determinado (“ciclo”). A BCX
              realizará operações estratégicas com o objetivo de aumentar a
              quantidade original de criptoativos transferida pelo USUÁRIO.
            </p>
            <p className="legal-text">
              1.2 A BCX oferece três modalidades do serviço, para atender os
              diferentes perfis de USUÁRIOS, de acordo com o nível de risco, as
              estratégias são desenvolvidas por uma equipe analistas especialistas
              no mercado de criptoativos e que indicam sempre o melhor momento
              para as operações, conforme caracteristicas abaixo:
            </p>
            <table>
              <thead>
                <tr>
                  <th>Modalidade</th>
                  <th>Ativo</th>
                  <th>Stop Loss</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Standard ou Clássico</td>
                  <td>Bitcoin</td>
                  <td>1%</td>
                </tr>
                <tr>
                  <td>Moderado, Security ou Alto Valor</td>
                  <td>Bitcoin</td>
                  <td>0,5%</td>
                </tr>
                <tr>
                  <td>High Performance ou Arrojado</td>
                  <td>Bitcoin</td>
                  <td>1,5%</td>
                </tr>
              </tbody>
            </table>
            <p className="legal-text">
              1.3 O USUÁRIO deve ler atentamente as características de cada
              modalidade e considerar o seu nível de risco e a volatilidade que
              envolve as operações com criptoativos. A BCX irá envidar todos os
              seus enforços para obter os melhores resultados com as estratégias
              desenvolvidas, no entanto, a BCX não garante o efetivo aumento da
              quantidade de criptoativos do USUÁRIO e, ao aceitar estes Termos e
              Condições, este está ciente e isenta a BCX de QUALQUER
              responsalibidade em caso de perdas, não cabendo, nesta hipótese,
              qualquer tipo de indenização.
            </p>
            <p className="legal-text">
              1.3.1 A BCX adota medidas de segurança cibernética para a garantir a
              estabilidade da PLATAFORMA, no entanto, o USUÁRIO tem ciência de que
              ambientes virtuais estão, ainda que minimamente, expostos a riscos,
              instalibilidade, invasões (hackeamento) que poderão acarretar na
              perda/roubo parcial ou total dos seus recursos, isentando, de igual
              modo, a BCX da responsabilidade por esses eventos quando esta
              demonstrar que adotou todas as medidas esperadas de uma empresa de
              seu porte e atividade para evitar esse tipo de ação.
            </p>

            <h5 className="legal-subtitle">2. COMO ADERIR AO SERVIÇO: </h5>
            <p className="legal-text">
              2.1 O Serviço é disponibilizado para aquisição, exclusiva e
              privativa, pelos USUÁRIOS cadastrados na Exchange da BCX, portanto,
              para aderir, o USUÁRIO deverá ter uma conta aberta e ativa, KYC
              aprovado e saldo disponível em criptoativos.
            </p>
            <p className="legal-text">
              2.2 Para aderir ao Serviço o USUÁRIO deverá aceitar estes Termos e
              Condições Específicos, após o aceite, deverá criar uma ordem de
              gerenciamento, informando à BCX, a quantidade de criptoativos da sua
              wallet que está disponível para o gerenciamento. A transferência dos
              criptoativos para gerenciamento será realizada de forma automática.
            </p>

            <h5 className="legal-subtitle">
              3. DOS CICLOS DE PRESTAÇÃO DOS SERVIÇOS
            </h5>
            <p className="legal-text">
              3.1 No momento em que os criptoativos forem transferidos para a BCX,
              o USUÁRIO deverá selecionar o período do ciclo de gerenciamento.
            </p>
            <p className="legal-text">
              3.1.1 Os Ciclos de Prestação de Serviços serão considerados os
              períodos em que os criptoativos deverão permanecer sob a gestão da
              BCX, conforme modalidade escolhida pelo USUÁRIO.
            </p>
            <p className="legal-text">
              3.2 Mensalmente, será gerado e encaminhado ao USUÁRIO um relatório
              de transações e operações realizadas pela BCX.
            </p>

            <h5 className="legal-subtitle">4. DAS TAXAS E TARIFAS </h5>
            <p className="legal-text">
              4.1 Será devida à BCX o pagamento da Taxa de Infraestrutura
              calculada sobre o valor bruto da rentabilidade e a Tarifa
              Operacional calculada sobre a rentabilidade líquida obtida pela BCX.
            </p>
            <p className="legal-text">
              4.2 Caso haja renovação automática do ciclo, a Tarifa Operacional
              poderá ser alteradas a cada novo ciclo, acesse as taxas e tarifas
              atualizadas clicando aqui.
            </p>

            <h5 className="legal-subtitle">5. DA RENTABILIDADE</h5>
            <p className="legal-text">
              5.1 Toda a rentabilidade será paga em Bitcoin, ao final do ciclo do
              gerenciamento,mediante a transferência pela BCX para a wallet de
              destino indicada pelo USUÁRIO.
            </p>
            <p className="legal-text">
              5.2 A quantidade original de criptoativos do USUÁRIO será
              transferida para sua wallet de destino. Caso o USUÁRIO opte por
              vender seus criptoativos para a BCX, deverá indicar conta corrente
              de sua própria titularidade para o recebimento em moeda fiduciária.
            </p>

            <h5 className="legal-subtitle">6. DA RESCISÃO DESTES TERMOS </h5>
            <p className="legal-text">
              6.1 O USUÁRIO poderá solicitar a rescisão destes Termos e Condições
              Específicos, conforme disposto abaixo:
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  a) com aviso prévio de 30 (trinta) dias, sendo devido à BCX a
                  TARIFA OPERACIONAL de 20% (vinte por cento) da rentabilidade do
                  período, bem como multa de 15% (quinze por cento) sobre a
                  rentabilidade do período, tendo a BCX o prazo de 7 (sete) dias
                  úteis para pagamento.
                </li>
                <li>
                  b) sem aviso prévio de 30 (trinta) dias, será devida à BCX a
                  Tarifa Operacional de 20% (vinte por cento) da rentabilidade,
                  bem como uma multa de 40% (quarenta por cento) sobre a
                  rentabilidade obtida até a data da solicitação, tendo a BCX um
                  prazo de 7 (sete) dias úteis para pagamento.
                </li>
              </ul>
            </p>
            <p className="legal-text">
              6.2 Da pró-rata e carência em caso de rescisão antes do termino do
              ciclo:
            </p>
            <p className="legal-text">
              6.2.1. Em ambos os casos, se a solicitação de rescisão destes Termos
              ocorrer antes do término do ciclo escolhido, o USUÁRIO receberá como
              rentabilidade o valor correspondente a média dos meses anteriores,
              respeitado o mínimo de 5% (cinco por cento) sobre os meses restantes
              para o término do contrato, aplicada a Tarifa Operacional sobre o
              valor final da rentabilidade estimada, como se o ciclo tivesse sido
              cumprido até o final.
            </p>
            <p className="legal-text">
              6.2.3 Se a solicitação de rescisão for anterior ao término do ciclo,
              o USUÁRIO somente poderá aderir novamente ao serviço após 06 (seis)
              meses da rescisão, salvo disposição em contrário determinada a
              critério da BCX.
            </p>
            <p className="legal-text">
              6.3 O USUÁRIO poderá optar por retirar ao final de cada ciclo de 30
              dias, apenas a rentabilidade obtida no período, mantendo os
              criptoativos originalmente aportados dentro do ciclo de
              gerenciamento contratado.
            </p>

            <h5 className="legal-subtitle">
              7. DA POSSIBILIDADE DE EXTINÇÃO DO SERVIÇO PELA BCX
            </h5>
            <p className="legal-text">
              7.1 A BCX reserva para si o direito de extinguir a prestação do
              Serviço, a qualquer momento, mediante comunicação previa aos
              USUÁRIOS, salvo caso fortuito ou força maior, e devolução dos
              criptoativos colocados sob gerenciamento.
            </p>

            <h5 className="legal-subtitle">
              8. DISPOSIÇÕES IMPORTANTES AOS USUÁRIOS DO SERVIÇO
            </h5>
            <p className="legal-text">8.1 Canais de atendimento</p>
            <p className="legal-text">
              8.1.1 Para atender o USUÁRIO em demandas relacionadas ao Serviço ou
              as estes Termos e Condições, a BCX disponibilizará um canal de
              atendimento para solução de problemas e/ou esclarecimentos de
              dúvidas, podendo ser realizado contato via e-mail
              suporte@bcxcorretora.com.br
            </p>
            <p className="legal-text">8.2 Política de Privacidade</p>
            <p className="legal-text">
              8.2.1 Aplicam-se aos dados pessoais tratados em razão destes Termos,
              as disposições da POLÍTICA DE PRIVACIDADE.
            </p>
            <p className="legal-text">8.3 Responsabilidades e Garantias:</p>
            <p className="legal-text">
              8.3.1 A BCX envidará todos os esforços na prestação do serviço, no
              entanto, não poderá ser responsabilizada por (i) qualquer problema
              decorrente de culpa exclusiva do USUÁRIO; (ii) eventos definidos na
              legislação civil como caso fortuito ou força maior.
            </p>
            <p className="legal-text">
              8.3.2 A BCX declara que possui um Fundo de Reserva, administrado com
              objetivo de minimizar impactos negativos causados por situações
              adversas e emergenciais, a seu critério.
            </p>
            <p className="legal-text">
              8.3.3 O USUÁRIO GARANTE E DECLARA QUE OS ATIVOS TRANSFERIDO À BCX
              PARA GESTÃO POSSUEM ORIGEM LÍCITA E COMPROVADA PERANTE A RECEITA
              FEDERAL DO BRASIL, NÃO SENDO OBJETO DE CRIMES OU ATIVIDADES
              SUSPEITAS DE LAVAGEM DE DINHEIRO E FINANCIAMENTO AO TERRORISMO.
            </p>
            <p className="legal-text">
              8.3.4 A BCX informa que em caso de suspeita de fraude ou qualquer
              outra atividade ilícita, poderá, além de recorrer às medidas legais
              cabíveis, no momento efetivo da rescisão, reter recursos do USUÁRIO
              até a conclusão de eventuais investigaçõess.
            </p>
            <p className="legal-text">
              8.3.5 A BCX reserva para si o direito de negar a solicitação de
              prestação do Serviço, bem como proceder a devolução de todo e
              qualquer valor transferido pelo USUÁRIO em caso de indícios de, mas
              sem se limitar a:
            </p>
            <p className="legal-text">
              a) não atendimento às legislações nacionais sobre lavagem de
              dinheiro e anticorrupção; b) fornecimento de informações falsas,
              imprecisas, incompletas ou enganosas; c) não fornecimento de
              informações e/ou documentos adicionais, após 3 dias da solicitação,
              sem resposta do USUÁRIOS; d) má-fé ou dolo; e) violação destes
              Termos e Condições Específicos, dos Termos e Condições da Exchance
              ou de quaisquer outras políticas da BCX; f) uso dos serviços
              prestados pela BCX em desacordo com a legislação; g) envolvimento em
              condutas fraudulentas ou ilegais;
            </p>
            <p className="legal-text">8.4 Obrigações Fiscais e Legais</p>
            <p className="legal-text">
              8.4.1 Cada uma das Partes é responsável por todas as obrigações
              fiscais, tributárias e legais decorrentes da execução destes Termos,
              aplicadas em seu país de origem. Em nenhuma hipótese a BCX poderá
              ser responsabilizada por quaisquer tributos ou obrigações de
              responsabilidade do USUÁRIO.
            </p>
            <p className="legal-text">8.5 Propriedade Intelectual:</p>
            <p className="legal-text">
              8.5.1. Todos os direitos de propriedade intelectual referente ao
              serviço de gerenciamento de ativos pertencem à BCX. A aceitação a
              estes termos e condições de uso não dá ao USUÁRIO nenhum direito de
              propriedade sobre os serviços e conteúdos da BCX.
            </p>
            <p className="legal-text">8.6 Da Oferta Privada</p>
            <p className="legal-text">
              8.6.1 A oferta do Serviço é feita em caráter EXCLUSIVO E PRIVADO aos
              USUÁRIOS que já são clientes da BCX, portanto, é EXPRESSAMENTE
              PROIBIDO que estes façam ou que permitam que terceiros façam o
              download de qualquer conteúdo com o intuito de armazená-lo em banco
              de dados e/ou para oferecer para terceiros ou para divulgá-los em
              quaisquer canais, redes sociais, ou qualquer meio de acesso público.
            </p>
            <p className="legal-text">
              8.6.2 Caso o USUÁRIO tenha ciência de infrações ao diposto no item
              acima, deverá encaminhar à BCX por e-mail.
            </p>

            <h5 className="legal-subtitle">9. SEGURANÇA DO USUÁRIO</h5>
            <p className="legal-text">
              9.1 A BCX NÃO SOLICITA OU EXIGE QUE SEUS USUÁRIOS DIVULGUEM SUAS
              SENHAS, DADOS DE CARTÃO DE CRÉDITO OU OUTROS DADOS BANCÁRIOS POR
              E-MAIL, TELEFONE OU QUALQUER OUTRO CANAL DE ATENDIMENTO
              PERSONALIZADO. Caso você receba qualquer comunicação com esse tipo
              de abordagem e conteúdo, não responda, desconsidere-o e, se
              possível, encaminhe o seu relato para o e-mail da BCX.
            </p>

            <h5 className="legal-subtitle">10. DISPOSIÇÕES FINAIS</h5>
            <p className="legal-text">
              10.1 Estes Termos e Condições são regidos pelas leis da República
              Federativa do Brasil. Quaisquer dúvidas e situações não previstas
              nestes Termos e Condições serão primeiramente resolvidas entre o
              USUÁRIO e a BCX e, caso persistam, deverão ser solucionadas pelo
              Foro da Comarca de Itajaí, estado de Santa Catarina, com exclusão de
              qualquer outro, por mais privilegiado que seja ou venha a ser.
            </p>

            <h5 className="legal-subtitle">
              DATA DA ÚLTIMA ATUALIZAÇÃO: 16/06/2023
            </h5>
          </div>
        </div>
      )}
    </Panel >
  );
}
