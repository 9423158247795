import React from 'react';
import Panel from '../components/Panel';
import { withTranslation } from 'react-i18next';

class Fees extends React.Component {

    render() {
        return (<Panel history={this.props.history} page="fees" title="Tarifas" hide>
            <div className="text2 mt-3 mb-5">
                <p className="bold">OPERACIONAL</p>
                <p className="">DEPÓSITOS</p>

                <p className="mb-0">NIVEL 1</p>
                <p className="mb-0">Tarifa Operacional: zero</p>
                <p className="mb-0">Valor mínimo: R$ 50,00 ou equivalente em BTC</p>
                <p className="mb-0">Limite diário: R$ 5.000,00</p>
                <p className="">Prazo até 1 dia útil</p>

                <p className="mb-0">NIVEL 2</p>
                <p className="mb-0">Tarifa Operacional: zero</p>
                <p className="mb-0">Valor mínimo: R$ 50,00 ou equivalente em BTC</p>
                <p className="mb-0">Limite diário: R$ 100.000,00</p>
                <p className="">Prazo até 1 dia útil</p>

                <p className="mb-0">NIVEL 3</p>
                <p className="mb-0">Tarifa Operacional: zero</p>
                <p className="mb-0">Valor mínimo: R$ 50,00 ou equivalente em BTC</p>
                <p className="mb-0">Limite diário: livre</p>
                <p className="">Prazo até 1 dia útil</p>

                <p className="">Depósitos efetuados na boca do caixa poderão ser confirmados em até 4 dias úteis</p>

                <p className="">SAQUES</p>

                <p className="mb-0">NIVEL 1</p>
                <p className="mb-0">Tarifa Operacional: R$ 4,90 + 1,5%</p>
                <p className="mb-0">Valor mínimo: R$ 50,00 ou equivalente em BTC</p>
                <p className="mb-0">Limite diário C/C e Poupança: R$ 500,00</p>
                <p className="">Prazo até 3 dia úteis</p>

                <p className="">COMISSIONAMENTO - Executada 0,25% / Executora 0,50%</p>
            </div>
        </Panel >)
    }
}

const TranslatedFees = withTranslation()(Fees);

export default TranslatedFees;