import React from 'react';
import { Table, Button, Col, Row, Form } from "react-bootstrap";

import Panel from '../components/Panel';

const TableRow = (props) => <tr>
    <td>01/10/2019</td>
    <td>01/10/2019</td>
    <td>R$ 35.000,00</td>
    <td>Investimentos</td>
    <td>Concluído</td>
</tr>

export default class Bot extends React.Component {

    render() {
        return (<Panel history={this.props.history} page="bot" title="Aporte Inicial - Bot">
            <Form>
                <Row className="mb-3">
                    <Form.Group as={Col} md="3">
                        <Form.Label>Moeda</Form.Label>
                        <select className="form-control small">
                            <option>Real</option>
                            <option>Dolar</option>
                            <option>Euro</option>
                            <option>Bitcoin</option>
                        </select>
                    </Form.Group>

                    <Form.Group as={Col} md="3">
                        <Form.Label>Valor</Form.Label>
                        <Form.Control className="small" value="R$ 15.000,00" />
                    </Form.Group>

                    <Form.Group as={Col} md="3">
                        <Form.Label>Tempo</Form.Label>
                        <select className="form-control small">
                            <option>12 meses</option>
                            <option>24 meses</option>
                            <option>36 meses</option>
                        </select>
                    </Form.Group>

                    <Form.Group as={Col} md="3">
                        <Button style={{ marginTop: 30 }}>Enviar</Button>
                    </Form.Group>
                </Row>
            </Form>
            <div style={{
                width: '100%',
                backgroundColor: '#50504E',
            }}><span className="bold text1 ml-3 text-mid2">Histórico de Aportes</span></div>
            <Table className="hist-table m-0 p-0" responsive="md" striped hover>
                <thead>
                    <tr>
                        <th>Id#</th>
                        <th>Data</th>
                        <th>Valor</th>
                        <th>Investimento</th>
                        <th>Estado</th>
                    </tr>
                </thead>
                <tbody>
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                    <TableRow />
                </tbody>
            </Table>
        </Panel >)
    }
}
