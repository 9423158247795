import React, { useEffect, useState } from 'react';
import { Col, Spinner, Table, Pagination } from 'react-bootstrap';
import api from "../utils/api";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { formatBTCString, formatEVMString } from "../utils/Helpers/btcFormat";

function TransactionHistoryReceive({ selectedCoin, walletAddress }) {
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    offset: 0,
    limit: 10,
  });
  const [load, setLoad] = useState(true);
  const { t } = useTranslation();

  // Função para buscar transações da API
  const fetchTransactions = async () => {
    console.log("Wallet:", walletAddress);
    console.log("Selected Coin:", selectedCoin);
    try {
      const coin = selectedCoin === "bitcoin" ? "BTC" : selectedCoin.toUpperCase();

      // Chamada para a API para obter as transações de recebimento
      const response = await api.get(`node/transactions-by-wallet?btcWallet=${walletAddress}&coin=${coin.toUpperCase()}`);

      // Atualiza o estado com as transações recebidas
      if (response.data && response.data.data) {
        setTransactions(response.data.data);
        setPagination((prev) => ({
          ...prev,
          lastPage: Math.ceil(response.data.total / pagination.limit),
        }));
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      toast.error(t("failedfetchingtransactions"));
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [selectedCoin, walletAddress, pagination.offset]);

  function translateStatus(status) {
    if (status === "new") return t("new");
    if (status === "confirmed") return t("confirmingtx");
    return t("txconfirmed");
  }

  return (
    <Col lg>
      <div style={{ width: "100%", backgroundColor: "#50504E" }}>
        <span className="bold text1 ml-3 text-mid2">
          {t("txhistory")} {selectedCoin.toUpperCase()}
        </span>
      </div>

      <Table className="hist-table m-0 p-0" responsive="md" striped hover style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th>{t("date")}</th>
            <th>{t("value")}</th>
            <th>Txid</th>
            <th>{t("type")}</th>
            <th>{t("address")}</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {transactions.length > 0 ? (
            transactions.map((tx) => (
              <tr key={tx.id}>
                <td>{dayjs(tx.created_at).format("DD/MM/YYYY HH:mm")}</td>
                <td>
                  {selectedCoin === "bitcoin" ? formatBTCString(tx.amount) : formatEVMString(tx.amount, 3, tx.coin)}
                </td>
                <td style={{ width: "80px", overflow: "auto" }}>
                  {tx.txid ? (
                    <a
                      className="bold"
                      href={
                        tx.coin === "BTC"
                          ? `https://blockstream.info/${process.env.REACT_APP_NETWORK === "Testnet" ? "testnet/" : ""}tx/${tx.txid}`
                          : `https://polygonscan.com/tx/${tx.txid}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {tx.txid}
                    </a>
                  ) : (
                    "Interna"
                  )}
                </td>
                <td title={tx.categoryLabel === "receive" ? t("received") : t("sended")}>
                  {tx.categoryLabel === "receive" ? t("received") : t("sended")}
                </td>
                <td style={{ width: "80px", overflow: "auto" }}>
                  {tx.address}
                </td>
                <td>{translateStatus(tx.status)}</td>
              </tr>
            ))
          ) : load ? (
            <tr>
              <td colSpan="6" className="text-center">
                <Spinner variant="secondary" animation="border" />
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                {t("notxs")}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Col>
  );
}

export default TransactionHistoryReceive;
