import React from "react";
import { Col, Row, Form, Button, Table, Modal } from "react-bootstrap";
import {
  formatStatus,
  currencyConfig,
  DEPOSITS_STATUS_TYPES,
} from "../utils/types";
import Panel from "../components/Panel";
import { toast } from "react-toastify";
import api from "../utils/api";
import IntlCurrencyInput from "react-intl-currency-input";
import InputMask from "react-input-mask";
import dayjs from "dayjs";
import { AppContext } from "../context/AppContext";
import Pagination from "../components/Pagination";
import { numberFormatBRL } from "../components/InvestmentId";
import { withTranslation } from "react-i18next";
// import { numberFormatBRL, removeSymbol } from "../components/InvestmentId";
// import { formatMoney } from "../utils/helper";

class Withdraw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      showModal: false,
      showView: false,
      showModal2: false,
      showModal3: false,
      showModal4: false,
      showModal5: false,
      showModal6: false,
      showModal7: false,
      showModal8: false,
      offset: 0,
      displayBankAccount: "none",
      formViewSelect: {
        agency: "",
        account: "",
        bank: "",
        status: "",
        created: "",
        amount: 0,
        message: "",
      },
      displayBankAccountData: {
        bank: "",
        agency: "",
        account: "",
      },
      form: {
        amount: 0,
        type: "transferencia",
        bank: "",
        agency: "",
        account: "",
      },
      formCreateBankAccount: {
        bank: "",
        agency: "",
        account: "",
        cpfOrCnpj: "",
      },
      rows: [],
      rowsBankAccounts: [],
      withdrawData: [],
      kycLimit: '0',
      kycLevel: 0,
    };
    this.inputDoc = React.createRef();
  }

  static contextType = AppContext;

  componentDidMount = () => {
    this.reloadTable();
    this.reloadTableBankAccounts();

    this.context.setPagination((pagination) => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });

    this.setKYCLimit();
    this.getKYCLevel();

  };

  componentDidUpdate(prevProps, prevState) {
    if (this.context.pagination.offset !== prevState.offset) {
      this.reloadTable();
    }
  }

  reloadTable = async () => {
    this.setState({
      offset: this.context.pagination.offset,
      load: true,
    });

    let req = await api.get(
      `withdraw-users?filter[order]=created%20DESC&filter[skip]=${this.context.pagination.offset}&filter[limit]=${this.context.pagination.limit}`
    );

    if (!req.error) {
      let withdraw = req.data;

      this.context.setPagination((pagination) => {
        return {
          ...pagination,
          limit: withdraw.limit,
          lastPage: Math.ceil(withdraw.total / withdraw.limit),
        };
      });

      this.setState({
        load: false,
        withdrawData: withdraw.data,
      });
    }
  };

  reloadTableBankAccounts = async () => {
    await api.get("bank-accounts").then((req) => {
      if (!req.error) {
        let rows = req.data;
        this.setState({
          rowsBankAccounts: rows,
        });
      }
    });
  };

  handleChangeCurrency = (event, value, maskedValue) => {
    let form = this.state.form;
    form[event.target.name] = value;
    this.setState({ form });
  };

  setType = async (name, type) => {
    let form = this.state.form;
    form[name] = type.status;
    this.setState({ form });
  };
 
  getKYCLevel = () => {
    this.setState({ kycLevel: this.context.loggedInUser?.kycStatus });
  };

  setKYCLimit = () => {
    console.log('kycStatus', this.context.loggedInUser?.kycStatus)
    const kycStatus = this.context.loggedInUser?.kycStatus;
    switch (kycStatus) {
      case "0":
        this.setState({ kycLimit: '0' });
        break;
      case "1":
        this.setState({ kycLimit: '5000' });
        break;
      case "2":
        this.setState({ kycLimit: '5000' });
        break;
      case "3":
        this.setState({ kycLimit: this.props.t("flex") }); /* 'Flexível' */
        break;
      case "4":
        this.setState({ kycLimit: '0' });
        break;
      default:
        this.setState({ kycLimit: '0' });
      break;
    }
  };

  newRequest = async (event) => {
    event.preventDefault();
    if (this.state.rows.length > 0 && this.state.rows[0].status === "waiting") {
      this.setState({
        showModal2: true,
      });
    } else {
      this.newWithdraw(event);
    }
  };

  newWithdraw = async (event) => {
    event.preventDefault();
    this.setState({
      load: true,
      showModal2: false,
    });
    let amount = parseFloat(this.state.form.amount);
    if (amount > 5000 && ( this.state.kycLevel === 1 | this.state.kycLevel === 2 )){
      this.setState({
        load: false,
      });
      this.setState({ showModal3: true });
    } else if (amount < (50)) {
      this.setState({
        load: false,
      });
      this.setState({ showModal5: true });
    } else {
      let today = new Date().toLocaleDateString();
      let withdrawsToday = 0;
      this.state.rows.forEach((row) => {
        if (
          new Date(row.created).toLocaleDateString() === today &&
          row.status !== "canceled" &&
          row.status !== "waiting"
        ) {
          withdrawsToday += row.amount;
        }
      });
      withdrawsToday += amount;
      if (withdrawsToday > 5000 && ( this.state.kycLevel === 1 | this.state.kycLevel === 2 )) {
        this.setState({ showModal4: true, load: false, showModal2: false });
      } else {
        let form = this.state.form;

        if (this.state.displayBankAccountData.account === "") {
          this.setState({
            load: false,
            showModal2: false,
          });
          toast.error(this.props.t("selectbank"), { // Selecione a conta bancária para o saque
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (this.state.displayBankAccountData.account === "") return;

        let req = await api.get("profile/");

        var id = req.data.id;

        req = await api.get(`balances?filter[where][usersid]=${id}`);
        if (req.error) this.setState({ load: false, showModal2: false });
        if (req.error) return;

        const user_balance = (arr) => arr.filter((o) => o.usersId === id);

        var user_currency = user_balance(req.data);

        req = await api.post(`withdraw-users`, {
          amount: Number(this.state.form.amount),
          type: this.state.form.type,
          bank: this.state.form.bank,
          agency: this.state.form.agency,
          account: this.state.form.account,
        });

        if (!req.error) {
          this.setState({
            showModal: false,
            showModal2: false,
            load: false,
            form,
          });
          toast.success(this.props.t("requestwithdrawsuccess")); // `Solicitação de saque efetuada com sucesso, aguarde a aprovação do administrador`
        } else {
          this.setState({
            load: false,
          });
          console.log(req)
          toast.error(`${req.error}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };

  newBankAccount = async (event) => {
    event.preventDefault();
    this.setState({
      load: true,
    });
    let form = this.state.formCreateBankAccount;
    let req = await api.post(`bank-accounts`, form);
    if (!req.error) {
      await this.reloadTableBankAccounts();
      this.setState({
        showModal7: false,
        showModal6: true,
        load: false,
        form,
      });
    } else {
      this.setState({
        load: false,
      });
    }
  };

  editBankAccount = async (event) => {
    event.preventDefault();
    this.setState({
      load: true,
    });

    let req = await api.patch(
      `bank-accounts/${this.state.form.bankAccountId}`,
      {
        bank: this.state.form.bank,
        agency: this.state.form.agency,
        account: this.state.form.account,
        cpfOrCnpj: this.state.form.cpfOrCnpj,
      }
    );
    if (!req.error) {
      await this.reloadTableBankAccounts();
      this.setState({
        showModal8: false,
        showModal6: true,
        load: false,
        form: this.state.form,
      });
    } else {
      this.setState({
        load: false,
      });
    }
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleCloseView = () => {
    this.setState({ showView: false });
  };

  handleClose2 = () => {
    this.setState({ showModal2: false });
  };

  handleClose3 = () => {
    this.setState({ showModal3: false });
  };

  handleClose4 = () => {
    this.setState({ showModal4: false });
  };

  handleClose5 = () => {
    this.setState({ showModal5: false });
  };

  handleOpenBankAccounts = () => {
    this.setState({ showModal6: true });
  };

  handleOpenView = (row) => {
    this.setState({
      formViewSelect: {
        agency: row.agency,
        amount: row.amount,
        created: row.created,
        message: row.message,
        bank: row.bank,
        account: row.account,
        status: row.status,
      },
    });

    this.setState({ showView: true });
  };

  handleCloseBankAccounts = () => {
    this.setState({ showModal6: false });
  };

  handleOpenCreateBankAccount = () => {
    this.setState({ showModal7: true });
  };

  handleOpenEditBankAccount = (row) => {
    this.setState({
      ...this.state,
      showModal8: true,
      showModal6: false,

      form: {
        ...this.state.form,
        bank: row.bank,
        agency: row.agency,
        account: row.account,
        bankAccountId: row.id,
      },
    });
  };

  handleCloseCreateBankAccount = () => {
    this.setState({ showModal7: false });
  };

  handleCloseEditBankAccount = () => {
    this.setState({ showModal8: false });
  };

  handleChange = (event) => {
    let formCreateBankAccount = this.state.formCreateBankAccount;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    formCreateBankAccount[event.target.name] = value;
    this.setState({ formCreateBankAccount });
  };

  handleChangeEdit = (event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [event.target.name]: value,
      },
    });
  };

  selectBankAccount(row) {
    this.setState({
      displayBankAccount: "block",
      showModal6: false,
      displayBankAccountData: {
        bank: row.bank,
        agency: row.agency,
        account: row.account,
      },
      form: {
        amount: this.state.form.amount,
        type: this.state.form.type,
        bank: row.bank,
        agency: row.agency,
        account: row.account,
      },
    });
  }

  calculateMinusFee = (amount) => {
    const amountNumber = Number(amount);
    switch(this.context.fees[1]?.currency) {
      case 'BRL': {
        const value = amountNumber - ((Number(this.context.fees[1]?.value)))
        if (value < 0) {
          return numberFormatBRL(0)
        } return numberFormatBRL(value)
      }
      case 'BTC': {
        const value =  amountNumber - ((Number(this.context.fees[1]?.value) / Number(this.context.btcPrice)))
        if (value < 0) {
          return numberFormatBRL(0)
        } return numberFormatBRL(value)
      }
      default: {
        const value =  amountNumber - (amountNumber * ((this.context.fees[1]?.value) / 100))
        if (value < 0) {
          return numberFormatBRL(0)
        } return numberFormatBRL(value)
      }
    }
  }

  render() {
    const currentLanguage = this.props.i18n.language;

    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="withdraw"
        title={this.props.t("withdrawtitle")}
      >
        <div style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              flexWrap: "wrap",
            }}>
          <Col
            style={{
              minWidth: 300,
              maxWidth: 500,
            }}
            md
          >
            <h1 className="text2 bold text-large2 text-center mt-2 mb-3">
            {this.props.t("requestwithdraw")}  {/* Efetuar Saque */}
            </h1>
            <Form onSubmit={this.newRequest} className="card p-3 mb-4">
              <Row className="text-center mt-2 mb-2">
                <Col>
                  <h5>{this.props.t("minvalue")}  {/* Valor Mínimo */}</h5>
                  <h5>{ numberFormatBRL(50) }</h5>
                  {/* <h5>BTC {50 * this.context.btcPrice}</h5>
                  <p>Referência(BRL): R$ 50,00</p> */}
                  <a href="/panel/tarifas" rel="noopener noreferrer"> {this.props.t("checkfees")}  {/* ver Tarifas */} </a>
                </Col>
              </Row>
              <Row className="text-center mt-2 mb-2">
                {/*
                <Col>
                  <h6>{this.props.t("ccdailylimit")}  </h6>
                  <h5>{this.props.t("flex")}</h5>
                </Col>{/* Limite Diário C/C */}
                <Col>
                  <h6>KYC 1 {this.props.t("and")} 2 {this.props.t("dailylimit")}  {/* Lim. Diário Poupança */}</h6>
                  <h5>{ numberFormatBRL(5000) }</h5>
                  {/* <h5>BTC {Number(5000 / this.context.btcPrice).toFixed(8)}</h5>
                  <p>Referência(BRL): R$ 5.000,00</p> */}
                </Col>
              </Row>
              <Row className="text-center">
                <Col>
                  <h6>{this.props.t("estimatedptime")}  {/* Prazo: em até 03 dias úteis. */} </h6>
                  <hr />
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>{this.props.t("withdrawvalueb")}</Form.Label>
                <Form.Control
                  as={IntlCurrencyInput}
                  currency="BRL"
                  config={currencyConfig}
                  className="input"
                  name="amount"
                  value={this.state.form.amount}
                  onChange={this.handleChangeCurrency}
                  required
                />
                <div style={{ fontWeight :"400", fontStyle: "italic", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                  <span>{this.props.t("valuetoreceive")} {/* Valor a Receber: */} </span>
                  <span>{this.state.form.amount > 0 ? (this.calculateMinusFee(this.state.form.amount)) : `R$ 0,00`}</span>
                </div>
                {/* <div className="orderbook-fees order-container">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="3" className="orderbook-title">Tarifa de Compra da Corretora</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Tarifa:</td>
                        <td>{this.context.fees?.withdrawFee?.value} %</td>
                      </tr>
                      <tr>
                        <td>Valor da Tarifa(BTC):</td>
                        <td>BTC { ((this.state.form.amount) * (Number(this.context.fees?.withdrawFee?.value) / 100)).toFixed(6) }</td>
                      </tr>
                      <tr>
                        <td>Referência (BRL):</td>
                        <td>R$ { formatMoney((this.context.btcPrice * this.state.form.amount) * (Number(this.context.fees?.withdrawFee?.value) / 100)) }</td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
                <br></br>
                <div className="text-center mt-2 mb-2">
                  <Button onClick={() => this.handleOpenBankAccounts()}>
                    {this.props.t("bankaccount")} {/* Conta Bancária */} 
                  </Button>
                </div>
                <Row className="text-center mt-4">
                  <Col style={{ display: this.state.displayBankAccount }}>
                    <h6 className="d-flex justify-content-center">
                    {this.props.t("bank")}: {/* Banco */} <h6>{this.state.displayBankAccountData.bank}</h6>
                    </h6>
                    <h6 className="d-flex justify-content-center">
                    {this.props.t("agency")}{/* Agência: */}{" "}:
                      <h6>{this.state.displayBankAccountData.agency}</h6>
                    </h6>
                    <h6 className="d-flex justify-content-center">
                    {this.props.t("cac")}{/* C/C: */} <h6>{this.state.displayBankAccountData.account}</h6>
                    </h6>
                  </Col>
                </Row>
              </Form.Group>
              <div className="text-center text2 ">
                {/* <div style={{ fontWeight: "bold", fontSize: "1.1rem"}}>Total Recebido (Saque - Tarifa)</div>
                <br></br>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "700"}}>
                  <span>Total (BTC):</span>
                  <span>
                    { Number((this.state.form.amount) - ((this.state.form.amount) * (Number(this.context.fees?.withdrawFee?.value) / 100))).toFixed(8) } BTC
                  </span>
                </div>
                <div style={{ fontWeight :"400", fontStyle: "italic", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                  <span>Referência (BRL):</span>
                  <span>
                    R$ { formatMoney((this.state.form.amount * this.context.btcPrice) - ((this.state.form.amount * this.context.btcPrice) * (Number(this.context.fees?.withdrawFee?.value) / 100))) }
                  </span>
                </div> */}
              </div>
              <div className="text-center mt-3 mb-3">
                <Button type="submit">{this.props.t("confirm")}</Button>
              </div>
            </Form>
          </Col>
          <Col md>
            <div
              style={{
                width: "100%",
                backgroundColor: "#50504E",
              }}
            >
              <span className="bold text1 ml-3 text-mid2">{this.props.t("historic")}</span>
            </div>
            <Table className="hist-table m-0 p-0" responsive="md" striped hover>
              <thead>
                <tr>
                  <th>{this.props.t("date")}</th>
                  <th>{this.props.t("value")}</th>
                  <th>Status</th>
                  <th>{this.props.t("see")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.withdrawData.map((row, i) => (
                  <tr key={row.id}>
                    <td>{dayjs(row.created).format("DD/MM/YYYY")}</td>
                    <td>{numberFormatBRL(row.amount)}</td>
                    <td>{formatStatus(DEPOSITS_STATUS_TYPES, row.status)}</td>
                    <td>
                      <Button onClick={() => this.handleOpenView(row)}>
                      {this.props.t("see")}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {this.state.withdrawData.length > 0 && (
              <Pagination
                pagination={this.context.pagination}
                setPagination={this.context.setPagination}
              />
            )}
          </Col>
        </div>

        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
            {this.props.t("withdrawt")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <h5 className="bold mt-2 mb-3">
            {this.props.t("withdrawsuccess")}
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showView}
          onHide={this.handleCloseView}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
            {this.props.t("visualize")}{/* Vizualizar */} 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <h6 className="">{this.props.t("agency")}: {this.state.formViewSelect.agency}</h6>
            <h6 className="">{this.props.t("account")}: {this.state.formViewSelect.account}</h6>
            <h6 className="">{this.props.t("bank")}: {this.state.formViewSelect.bank}</h6>
            <h6 className="">
              Status:{" "}
              {formatStatus(
                DEPOSITS_STATUS_TYPES,
                this.state.formViewSelect.status
              )}
            </h6>
            <h6 className="">
            {this.props.t("value")}: {numberFormatBRL(this.state.formViewSelect.amount.toFixed(2))}
            </h6>
            <h6 className="">
            {this.props.t("date")}:{" "}
              {new Date(this.state.formViewSelect.created).toLocaleDateString()}
            </h6>
            <h6 className="">
            {this.props.t("commentary")}{/* Comentário */}: {this.state.formViewSelect.message}
            </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleCloseView}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal2}
          onHide={this.handleClose2}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
            {this.props.t("attention")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <h6 className="">{this.props.t("withdrawopen")} {/*Já existe um saque em aberto. */}</h6>
            <h6 className="">
            {this.props.t("withdrawopenwarning")}  {/* Ao criar um novo saque o saque em aberto será automáticamente
              cancelado */}
            </h6>
            <h6 className="">{this.props.t("wishcontinue")}</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.newWithdraw}>
            {this.props.t("continue")}
            </Button>
            <Button variant="primary" onClick={this.handleClose2}>
            {this.props.t("cancel")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal3}
          onHide={this.handleClose3}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
            {this.props.t("attention")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <h6 className="">
            {this.props.t("withdrawabove")} {/* Não é possivel criar saque acima de */} <strong>R$ 5.000,00</strong>
            </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose3}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal4}
          onHide={this.handleClose4}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
            {this.props.t("attention")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <h6 className=""> {this.props.t("dailylimitreached")}</h6>
            <h6 className="">R$ 5.000,00</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose4}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal5}
          onHide={this.handleClose5}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
            {this.props.t("attention")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <h6 className="">
            {this.props.t("withdrawminvalue")} {/* O valor mínimo de saque é */} <strong>R$ 50,00</strong>
            </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose5}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal6}
          onHide={this.handleCloseBankAccounts}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
            {this.props.t("bankaccount")} {/* Conta bancária */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <Table className="hist-table m-0 p-0" responsive="md" striped hover>
              <thead>
                <tr>
                  <th>{this.props.t("bank")}</th>
                  <th>{this.props.t("agency")}</th>
                  <th>{this.props.t("account")}</th>

                  {this.context.loggedInUser?.legalPerson ? (
                    <th>CNPJ</th>
                  ) : (
                    <th>CPF</th>
                  )}

                  <th>{this.props.t("select")} {/* Selecionar */}</th>
                  <th>{this.props.t("edit")} {/* Editar */}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.rowsBankAccounts.map((row, i) => (
                  <tr key={row.id}>
                    <td>{row.bank}</td>
                    <td>{row.agency}</td>
                    <td>{row.account}</td>
                    <td>{row.cpfOrCnpj}</td>
                    <td>
                      <Button onClick={() => this.selectBankAccount(row)}>
                      {this.props.t("select")} {/* Selecionar */}
                      </Button>
                    </td>
                    <td>
                      <Button
                        onClick={() => this.handleOpenEditBankAccount(row)}
                      >
                      {this.props.t("edit")} {/* Editar */}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          <br></br>
          <p>
          {this.props.t("cpfwarning")}
          </p>
          </Modal.Body>
          <Modal.Footer>
            {!this.state.rowsBankAccounts.length > 0 && (
              <Button
                variant="primary"
                onClick={() => {
                  this.handleCloseBankAccounts();
                  this.handleOpenCreateBankAccount();
                }}
              >
               {this.props.t("include")} {/* Incluir */} 
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal7}
          onHide={this.handleCloseCreateBankAccount}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
            {this.props.t("addbankaccount")} {/*  Adicionar conta bancária */}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="">
            <Form onSubmit={this.newBankAccount} className="p-3">
              <Form.Group>
                <Form.Label> {this.props.t("bank")}</Form.Label>
                <Form.Control
                  className="input"
                  name="bank"
                  type="text"
                  value={this.state.formCreateBankAccount.bank}
                  onChange={this.handleChange}
                  required
                  placeholder="Banco"
                />
                <Form.Label>{this.props.t("agency")}</Form.Label>
                <Form.Control
                  className="input"
                  name="agency"
                  type="text"
                  value={this.state.formCreateBankAccount.agency}
                  onChange={this.handleChange}
                  required
                  placeholder="Agencia"
                />
                <Form.Label>{this.props.t("account")}</Form.Label>
                <Form.Control
                  className="input"
                  name="account"
                  type="text"
                  value={this.state.formCreateBankAccount.account}
                  onChange={this.handleChange}
                  required
                  placeholder="Conta"
                />

                {this.context.loggedInUser?.legalPerson ? (
                  <>
                    <Form.Label>CNPJ</Form.Label>
                    <Form.Control
                      as={InputMask}
                      mask="99.999.999/9999-99"
                      className="input"
                      name="cpfOrCnpj"
                      type="text"
                      value={this.state.formCreateBankAccount.cpfOrCnpj}
                      onChange={this.handleChange}
                      required
                      placeholder="CNPJ"
                    />
                  </>
                ) : (
                  <>
                    <Form.Label>CPF</Form.Label>
                    <Form.Control
                      as={InputMask}
                      mask="999.999.999-99"
                      className="input"
                      name="cpfOrCnpj"
                      type="text"
                      value={this.state.formCreateBankAccount.cpfOrCnpj}
                      onChange={this.handleChange}
                      required
                      placeholder="CPF"
                    />
                  </>
                )}
              <Form.Group>
                <p id="exp-timer-txt">
                {this.props.t("sameaccountcpfwarning")} {/* AVISO: O CPF/CNPJ cadastrado deve ser o mesmo da conta de destino. */}
                </p>
              </Form.Group>
              </Form.Group>
              <Button variant="primary" type="submit">
              {this.props.t("save")}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showModal8}
          onHide={this.handleCloseEditBankAccount}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
            {this.props.t("edit")} {this.props.t("bankaccount")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="">
            <Form onSubmit={this.editBankAccount} className="p-3">
              <Form.Group>
                <Form.Label>{this.props.t("bank")}</Form.Label>
                <Form.Control
                  className="input mb-2"
                  name="bank"
                  type="text"
                  value={this.state.form.bank}
                  onChange={this.handleChangeEdit}
                  required
                />
                <Form.Label>{this.props.t("agency")}</Form.Label>
                <Form.Control
                  className="input mb-2"
                  name="agency"
                  type="text"
                  value={this.state.form.agency}
                  onChange={this.handleChangeEdit}
                  required
                />
                <Form.Label>{this.props.t("account")}</Form.Label>
                <Form.Control
                  className="input mb-2"
                  name="account"
                  type="text"
                  value={this.state.form.account}
                  onChange={this.handleChangeEdit}
                  required
                />

                {this.context.loggedInUser?.legalPerson ? (
                  <>
                    <Form.Label>CNPJ</Form.Label>
                    <Form.Control
                      as={InputMask}
                      mask="99.999.999/9999-99"
                      className="input mb-2"
                      name="cpfOrCnpj"
                      type="text"
                      value={this.state.form.cpfOrCnpj}
                      onChange={this.handleChangeEdit}
                      required
                    />
                  </>
                ) : (
                  <>
                    <Form.Label>CPF</Form.Label>
                    <Form.Control
                      as={InputMask}
                      mask="999.999.999-99"
                      className="input mb-2"
                      name="cpfOrCnpj"
                      type="text"
                      value={this.state.form.cpfOrCnpj}
                      onChange={this.handleChangeEdit}
                      required
                    />
                  </>
                )}
              </Form.Group>

              <Button variant="primary" type="submit">
              {this.props.t("save")}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </Panel>
    );
  }
}

export default withTranslation()(Withdraw);