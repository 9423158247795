export const adminPages = [
  {
    name: "Cadastros",
    pages: [
      { page: "admin/employees", name: "Usuários Administradores" },
      { page: "admin/users", name: "Clientes" },
    ],
  },

  { page: "admin/investments", name: "Investimentos" }, //contrato

  { page: "admin/cadastro-rentabilidade", name: "Cadastro de Rentabilidade" },
  
  {
    name: "Aprovações",
    pages: [
      { page: "admin/deposits", name: "Depósitos" },
      { page: "admin/withdraw", name: "Saques" },
      { page: "admin/approveWithdrawTrade", name: "Saques Investimentos" },
    ],
  },

  { page: "admin/historyBTC", name: "Histórico de transações (orderbook)" },
  { page: "admin/doc-history", name: "Histórico < 2023" },

  { page: "admin/kyc", name: "KYC" },

  { page: "admin/manage-fees", name: "Gerenciar Tarifas" },

  { page: "admin/history", name: "Histórico" },

  { page: "profile", name: "Perfil" },

  { page: "admin/twofactor", name: "Ativação 2FA" },

  { page: "admin/maintenance", name: "Manutenção" },
];

// export const userPages = (userInfoKYC) => {
//   const completeRegistration =
//     (userInfoKYC?.kycStatus !== "none")
//       ? [
//           {
//             name: "Meus investimentos",
//             pages: [
//               { page: "trade", name: "Investimentos" },
//               { page: "trade_hist", name: "Histórico de Investimentos" },
//               { page: "doc_history", name: "Histórico < 2023" },
//             ],
//           },
//           {
//             name: "Depósitos / Saques",
//             pages: [
//               { page: "deposits", name: "Depósitos" },
//               { page: "withdraw", name: "Saques" },
//             ],
//           },
//           {
//             name: "Orderbook",
//             pages: [
//               { page: "orderbook", name: "Compra e venda" },
//               { page: "tx_history", name: "Histórico" },
//             ],
//           },
//         ]
//       : [];

export const userPages = (userInfoKYC, hasInvestement) => {
  let completeRegistration = [];
    if (userInfoKYC?.kycStatus !== "none" && hasInvestement) {
      completeRegistration = [
        {
          name: "Meus investimentos",
          pages: [
            { page: "trade", name: "Investimentos" },
            { page: "trade_hist", name: "Histórico de Investimentos" },
            { page: "doc_history", name: "Histórico < 2023" },
          ],
        },
        {
          name: "Depósitos / Saques",
          pages: [
            { page: "deposits", name: "Depósitos" },
            { page: "withdraw", name: "Saques" },
          ],
        },
        {
          name: "Orderbook",
          pages: [
            { page: "orderbook", name: "Compra e venda" },
            { page: "tx_history", name: "Histórico" },
          ],
        },
      ]
    } else if (userInfoKYC?.kycStatus !== "none" && !hasInvestement) {
      completeRegistration = [
        {
          name: "Depósitos / Saques",
          pages: [
            { page: "deposits", name: "Depósitos" },
            { page: "withdraw", name: "Saques" },
          ],
        },
        {
          name: "Orderbook",
          pages: [
            { page: "orderbook", name: "Compra e venda" },
            { page: "tx_history", name: "Histórico" },
          ],
        },
      ]
    } else {
      completeRegistration = [];
    };

  return [
    ...completeRegistration,

    {
      name: "Segurança",
      pages: [
        { page: "kyc", name: "KYC" },
        { page: "twofa", name: "Ativação 2FA" },
      ],
    },
    { page: "support", name: "Suporte" },
    { page: "profile", name: "Perfil" },
  ];
};
