import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../context/AppContext";
import api from "../utils/api";
import { set } from "react-hook-form";

export const TermsTradeEN = ({ showModal }) => {
  const {
    getBalances,
    setShowModalTermsTrade,
    loggedInUser,
    investmentAmount,
    investmentPeriod,
    setCompleteUser,
    setShowInvestmentModal,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheck = () => {
    setIsChecked(!isChecked);
  };

  const saveInvestmentTerms = async () => {
    setLoading(true);
    let req = await api.patch(`users/${loggedInUser.id}`, {
      investmentTerms: isChecked
    });
    if (!req.error) {
      toast.success(`Termos de investimento aceitos!`);
      api.get(`users/${loggedInUser.id}`).then((req) => {
        if (!req.error) {
          setCompleteUser(req.data)
        }
      });
      setLoading(false);
      setShowModalTermsTrade(false);
    } else {
      toast.error(`An error has ocurred when accepting investment terms! Enter in contact with our team.`);
      setLoading(false);
      setShowModalTermsTrade(false);
    }
  }



  const createInvestment = async () => {
    setLoading(true);

    var now = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const periodFormat = parseInt(investmentPeriod);
    const periodEndFormat = dayjs()
      .add(investmentPeriod, "month")
      .format("YYYY-MM-DD HH:mm:ss");

    let investment = {
      createdAt: now,
      end: periodEndFormat,
      usersId: loggedInUser.id,
      type: "trade",
      status: "pending",
      initialAmount: investmentAmount,
      period: periodFormat,
      currency: "BTC",
      currentAmount: 0,
      acceptTerms: true,
    };

    const res = await api.post("investments", investment);

    if (!res.error) {
      toast.success(`Deposit send`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getBalances(loggedInUser.id);
    }

    setShowInvestmentModal(false);
    setShowModalTermsTrade(false);
    setLoading(false);
  };

  const handleClose = () => {
    setShowModalTermsTrade(false);
    navigate("/panel/bem-vindo")
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <h5 className="w-100 ml-3 text-center">
          You need to accept investment terms to proceed.
        </h5>
      </Modal.Header>
      <Modal.Body className="w-100 text2">
        <div
          className="ml-5 mr-1 pr-5"
          style={{
            maxHeight: 400,
            overflowY: "scroll",
          }}
        >
          <div className="legal-div">
            <h4 className="legal-title">
              EXCLUSIVE TERMS AND CONDITIONS OF THE "ASSET MANAGEMENT SERVICE"
            </h4>
            <br></br>
            <p className="legal-text">
              This service is available for contracting only for selected users who are already clients of BCX BROKER.
            </p>
            <p className="legal-text">
              BY SUBSCRIBING TO THE SERVICE, IT IS UNDERSTOOD THAT THE USER AGREES TO THE TERMS AND CONDITIONS SET FORTH IN THIS DOCUMENT. THEREFORE, PLEASE READ ALL THE PROVISIONS OF THESE TERMS CAREFULLY BEFORE ACCEPTING THEM.
            </p>
            <p className="legal-text">
              (i) BCX, already qualified in its Exchange Terms of Use, exclusively offers its registered USERS on the Platform, accessible by login and password, the possibility of joining the Asset Management Service ("Service").
            </p>
            <p className="legal-text">
              (ii) BY CLICKING ON THE BOX "I HAVE READ, AGREE, AND EXPRESSLY ACCEPT THESE TERMS AND CONDITIONS," YOU, THE USER, DECLARE THAT YOU AGREE TO THE CONDITIONS STATED HERE.
            </p>
            <p className="legal-text">
              (iii) IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS BELOW, YOU SHOULD NOT ACCEPT THEM OR JOIN THE SERVICE.
            </p>
            <p className="legal-text">
              (iv) BCX MAY, AT ITS DISCRETION AND AT ANY TIME, CHANGE THESE TERMS AND CONDITIONS BY PUBLISHING THE UPDATED VERSION ON THE PLATFORM. Updates to these Terms and Conditions will take effect on the date of publication unless otherwise informed. USERS will be informed in advance, by email or notice on the Platform, and new acceptance of these Terms will be required.
            </p>
            <p className="legal-text">
              (v) These Terms and Conditions are complementary, insofar as applicable, to the same provisions of the Exchange Terms of Use.
            </p>
            <br></br>
            <div>
              <h5 className="legal-subtitle">
                1. WHAT IS THE ASSET MANAGEMENT SERVICE
              </h5>
              <p className="legal-text">
                1.1 Through the Asset Management Service, USERS have the possibility to transfer part of their crypto assets to be managed by BCX for a predetermined period ("cycle"). BCX will carry out strategic operations with the aim of increasing the original amount of crypto assets transferred by the USER.
              </p>
              <p className="legal-text">
                1.2 BCX offers three modalities of the service, to meet the different profiles of USERS, according to the level of risk. The strategies are developed by a team of analysts who are experts in the crypto asset market and always indicate the best time for operations, as described below:
              </p>
              <table>
                <thead>
                  <tr>
                    <th>Modality</th>
                    <th>Asset</th>
                    <th>Stop Loss</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Standard or Classic</td>
                    <td>Bitcoin</td>
                    <td>1%</td>
                  </tr>
                  <tr>
                    <td>Moderate, Security, or High Value</td>
                    <td>Bitcoin</td>
                    <td>0.5%</td>
                  </tr>
                  <tr>
                    <td>High Performance or Bold</td>
                    <td>Bitcoin</td>
                    <td>1.5%</td>
                  </tr>
                </tbody>
              </table>
              <p className="legal-text">
                1.3 The USER must carefully read the characteristics of each modality and consider their risk level and the volatility involved in crypto asset operations. BCX will make every effort to achieve the best results with the developed strategies; however, BCX does not guarantee the effective increase in the quantity of USER's crypto assets. By accepting these Terms and Conditions, the USER is aware and releases BCX from ANY liability in case of losses, and in this case, no compensation will be applicable.
              </p>
              <p className="legal-text">
                1.3.1 BCX adopts cybersecurity measures to ensure the stability of the PLATFORM. However, the USER is aware that virtual environments are, even minimally, exposed to risks, instability, invasions (hacking) that could result in partial or total loss/theft of their resources. BCX is exempt from responsibility for these events when it demonstrates that it has taken all expected measures of a company of its size and activity to avoid this type of action.
              </p>

              <h5 className="legal-subtitle">2. HOW TO JOIN THE SERVICE:</h5>
              <p className="legal-text">
                2.1 The Service is available for exclusive acquisition by USERS registered with BCX Exchange. Therefore, to join, the USER must have an open and active account, approved KYC, and a balance available in crypto assets.
              </p>
              <p className="legal-text">
                2.2 To join the Service, the USER must accept these Specific Terms and Conditions. After acceptance, the USER must create a management order, informing BCX of the amount of crypto assets from their wallet available for management. The transfer of crypto assets for management will be done automatically.
              </p>

              <h5 className="legal-subtitle">3. SERVICE RENDERING CYCLES</h5>
              <p className="legal-text">
                3.1 At the moment the crypto assets are transferred to BCX, the USER must select the cycle management period.
              </p>
              <p className="legal-text">
                3.1.1 The Service Rendering Cycles will be considered the periods in which the crypto assets must remain under the management of BCX, according to the modality chosen by the USER.
              </p>
              <p className="legal-text">
                3.2 Monthly, a report of transactions and operations carried out by BCX will be generated and sent to the USER.
              </p>

              <h5 className="legal-subtitle">4. FEES AND CHARGES</h5>
              <p className="legal-text">
                4.1 The Infrastructure Fee calculated on the gross value of profitability and the Operational Fee calculated on the net profitability obtained by BCX shall be payable to BCX.
              </p>
              <p className="legal-text">
                4.2 In the case of automatic cycle renewal, the Operational Fee may be changed with each new cycle. Access the updated fees and charges by clicking here.
              </p>

              <h5 className="legal-subtitle">5. PROFITABILITY</h5>
              <p className="legal-text">
                5.1 All profitability will be paid in Bitcoin at the end of the management cycle, through the transfer by BCX to the destination wallet indicated by the USER.
              </p>
              <p className="legal-text">
                5.2 The original quantity of USER's crypto assets will be transferred to their destination wallet. If the USER chooses to sell their crypto assets to BCX, they must indicate their own account for receiving in fiat currency.
              </p>

              <h5 className="legal-subtitle">6. TERMINATION OF THESE TERMS</h5>
              <p className="legal-text">
                6.1 The USER may request the termination of these Specific Terms and Conditions, as follows:
              </p>
              <p className="legal-text">
                <ul>
                  <li>
                    a) with a 30 (thirty) days' notice, the USER shall pay BCX the OPERATIONAL FEE of 20% (twenty percent) of the profitability of the period, as well as a penalty of 15% (fifteen percent) on the profitability of the period, with BCX having a period of 7 (seven) business days for payment.
                  </li>
                  <li>
                    b) without 30 (thirty) days' notice, the OPERATIONAL FEE of 20% (twenty percent) of the profitability shall be due to BCX, as well as a penalty of 40% (forty percent) on the profitability obtained until the date of the request, with BCX having a period of 7 (seven) business days for payment.
                  </li>
                </ul>
              </p>
              <p className="legal-text">
                6.2 Pro-rata and grace period in case of termination before the end of the cycle:
              </p>
              <p className="legal-text">
                6.2.1 In both cases, if the request for termination of these Terms occurs before the end of the chosen cycle, the USER will receive as profitability the amount corresponding to the average of the previous months, respecting a minimum of 5% (five percent) for the remaining months until the end of the contract. The Operational Fee is applied to the final value of the estimated profitability, as if the cycle had been completed.
              </p>
              <p className="legal-text">
                6.2.3 If the request for termination is before the end of the cycle, the USER can only rejoin the service after 6 (six) months from termination, unless otherwise determined at the discretion of BCX.
              </p>
              <p className="legal-text">
                6.3 The USER may choose to withdraw only the profitability obtained during each 30-day cycle, keeping the originally contributed crypto assets within the contracted management cycle.
              </p>

              <h5 className="legal-subtitle">7. POSSIBILITY OF TERMINATION OF THE SERVICE BY BCX</h5>
              <p className="legal-text">
                7.1 BCX reserves the right to terminate the provision of the Service at any time, with prior communication to USERS, except in cases of force majeure, and return of the crypto assets placed under management.
              </p>

              <h5 className="legal-subtitle">8. IMPORTANT PROVISIONS TO USERS OF THE SERVICE</h5>
              <p className="legal-text">8.1 Customer Service Channels</p>
              <p className="legal-text">
                8.1.1 To assist the USER in matters related to the Service or these Terms and Conditions, BCX will provide a service channel for problem resolution and/or clarification of doubts, and contact can be made via email support@bcxcorretora.com.br.
              </p>
              <p className="legal-text">8.2 Privacy Policy</p>
              <p className="legal-text">
                8.2.1 The provisions of the PRIVACY POLICY apply to personal data processed due to these Terms.
              </p>
              <p className="legal-text">8.3 Responsibilities and Guarantees:</p>
              <p className="legal-text">
                8.3.1 BCX will make every effort in the provision of the service; however, it cannot be held responsible for (i) any problems arising from the exclusive fault of the USER; (ii) events defined in civil legislation as force majeure or fortuitous event.
              </p>
              <p className="legal-text">
                8.3.2 BCX declares that it has a Reserve Fund, administered with the aim of minimizing negative impacts caused by adverse and emergency situations, at its discretion.
              </p>
              <p className="legal-text">
                8.3.3 The USER WARRANTS AND DECLARES THAT THE ASSETS TRANSFERRED TO BCX FOR MANAGEMENT HAVE A LAWFUL AND PROVEN ORIGIN BEFORE THE FEDERAL REVENUE SERVICE OF BRAZIL, NOT BEING SUBJECT TO CRIMES OR SUSPICIOUS ACTIVITIES OF MONEY LAUNDERING AND TERRORISM FINANCING.
              </p>
              <p className="legal-text">
                8.3.4 BCX informs that in case of suspicion of fraud or any other illicit activity, it may, in addition to resorting to legal measures, at the effective moment of termination, retain USER's resources until the conclusion of any investigations.
              </p>
              <p className="legal-text">
                8.3.5 BCX reserves the right to deny the request for the provision of the Service, as well as to refund any and all amounts transferred by the USER in case of indications of, but not limited to:
              </p>

              <p className="legal-text">8.4 Tax and Legal Obligations</p>
              <p className="legal-text">
                8.4.1 Each Party is responsible for all tax, financial, and legal obligations arising from the execution of these Terms, applicable in their country of origin. Under no circumstances can BCX be held responsible for any taxes or obligations of the USER.
              </p>
              <p className="legal-text">8.5 Intellectual Property:</p>
              <p className="legal-text">
                8.5.1 All intellectual property rights related to the asset management service belong to BCX. Acceptance of these terms and conditions does not grant the USER any ownership rights over BCX services and content.
              </p>
              <p className="legal-text">8.6 Private Offering</p>
              <p className="legal-text">
                8.6.1 The Service's offer is made EXCLUSIVELY AND PRIVATELY to USERS who are already clients of BCX, therefore, it is EXPRESSLY PROHIBITED for them to download any content with the intention of storing it in a database and/or offering it to third parties or disclosing it on any channels, social networks, or any means of public access.
              </p>
              <p className="legal-text">
                8.6.2 If the USER becomes aware of violations of the above, they should forward it to BCX by email.
              </p>

              <h5 className="legal-subtitle">9. USER SECURITY</h5>
              <p className="legal-text">
                9.1 BCX DOES NOT REQUEST OR REQUIRE ITS USERS TO DISCLOSE THEIR PASSWORDS, CREDIT CARD DATA, OR OTHER BANKING INFORMATION BY EMAIL, PHONE, OR ANY OTHER CUSTOMER SERVICE CHANNEL. If you receive any communication with such an approach and content, do not respond, disregard it, and if possible, forward your report to BCX's email.
              </p>

              <h5 className="legal-subtitle">10. FINAL PROVISIONS</h5>
              <p className="legal-text">
                10.1 These Terms and Conditions are governed by the laws of the Federative Republic of Brazil. Any doubts and situations not foreseen in these Terms and Conditions will be first resolved between the USER and BCX and, if they persist, should be resolved by the Forum of the Comarca of Itajaí, state of Santa Catarina, with the exclusion of any other, no matter how privileged it may be or become.
              </p>

              <h5 className="legal-subtitle">
                LAST UPDATE DATE: 06/16/2023
              </h5>
              <div className="input-legal-div">
                <input
                  type="checkbox"
                  className="input-legal"
                  onChange={handleCheck}
                  checked={isChecked}
                ></input>
                <span className="legal-text">
                  By selecting and clicking "ACCEPT" next to it, the USER agrees to the provisions in these TERMS, accepting and binding themselves to them.
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <div className="text-center">
            <Spinner
              variant="secondary"
              animation="border"
              role="status"
            ></Spinner>
          </div>
        ) : (
          <Button variant="primary" onClick={saveInvestmentTerms} disabled={!isChecked}>
            Aceppt
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
