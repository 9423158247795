import React from "react";
import Panel from "../../components/Panel";

export default class AdminBankRemittance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="bank-remittance"
        title="Remessa Bancária"
        admin={true}
      ></Panel>
    );
  }
}