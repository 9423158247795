import React, { useState, useContext, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { formatCurrency } from "@coingecko/cryptoformat";
import { AppContext } from "../context/AppContext";
import Panel from "../components/Panel";
import TransactionHistoryReceive from "../components/TransactionHistoryRecive";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { ethers } from "ethers";
import api, { microservice } from "../utils/api";
import Swal from 'sweetalert2';

import PolygonLogo from "../assets/polygon.png";

const QRCodeComponent = ({ address, amount = null, selectedCoin }) => {
  const [load, setLoad] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (!window.QRCode) {
      return;
    }

    setLoad(true);

    const qrcodeContainer = document.getElementById("qrcode");
    if (qrcodeContainer) {
      qrcodeContainer.innerHTML = "";
    }

    const qrcode = new window.QRCode("qrcode");

    qrcode.clear();

    let text = address;

    if (amount !== null && amount !== 0) {
      text = `bitcoin:${address}?amount=${amount}`;
      text = encodeURI(text);
    }

    qrcode.makeCode(text);

    setLoad(false);
  }, [address, amount]);

  const styles = {
    qrcodeContainer: {
      position: "relative",
      width: "fit-content",
      margin: "0 auto",
    },
    qrcodeOverlay: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      height: "50%",
      objectFit: "contain",
      pointerEvents: "none",
      zIndex: 1,
    },
    qrcode: {
      position: "relative",
      zIndex: 0,
    }
  };

  return (
    <div style={styles.qrcodeContainer}>
      {load && <p>{t("loading")}</p>}
      <div className="m-3 mx-auto" id="qrcode" style={styles.qrcode}></div>
      {selectedCoin === "usdt" && (
        <img src={PolygonLogo} alt="Polygon Logo" style={styles.qrcodeOverlay} />
      )}
    </div>
  );
};

function ReceiveCrypto() {
  const { userObj, nodeURL } = useContext(AppContext);
  const [selectedCoin, setSelectedCoin] = useState("bitcoin");
  const [form, setFormData] = useState({
    btcWalletAddress: userObj.btcWalletAddress,
    walletAddress: userObj.walletAddress,
    amount: {
      label: formatCurrency(0, selectedCoin === "bitcoin" ? "BTC" : selectedCoin.toLocaleUpperCase()),
      value: 0,
    },
  });

  const { t } = useTranslation();

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setFormData({
      ...form,
      amount: {
        value,
        label: formatCurrency(value, selectedCoin === "bitcoin" ? "BTC" : selectedCoin.toLocaleUpperCase())
      }
    });
  };

  useEffect(() => {
    const provider = new ethers.providers.WebSocketProvider(nodeURL);
    provider.removeAllListeners();
    if (selectedCoin === "bitcoin") {
      return;
    }

    const erc20Abi = [
      "function name() public view returns (string)",
      "function symbol() public view returns (string)",
      "function decimals() public view returns (uint8)",
      "function totalSupply() public view returns (uint256)",
      "function balanceOf(address _owner) public view returns (uint256 balance)",
      "function transfer(address _to, uint256 _value) public returns (bool success)",
      "function transferTruther(address _to, uint256 _value, address) public returns (bool success)",
      "function transferFrom(address _from, address _to, uint256 _value) public returns (bool success)",
      "function approve(address _spender, uint256 _value) public returns (bool success)",
      "function allowance(address _owner, address _spender) public view returns (uint256 remaining)",
      "event Transfer(address indexed src, address indexed dst, uint val)",
      "event transferTruther(address indexed src, address indexed dst, uint val)",
    ];

    const USDTADDRESS = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";

    // Initialize event listening for USDT transfers
    const tokenContract = new ethers.Contract(USDTADDRESS, erc20Abi, provider);

    const handleEvent = async (event, txhash, balance) => {
      const amount = ethers.utils.formatUnits(event.args[2], 6);
      const receiver = event.args[1];
      const sender = event.args[0];

      try {
        const response = await api.post('node/receiveEVM', {
          amount,
          contract: selectedCoin.toLocaleUpperCase() === "USDT" ? USDTADDRESS : "",
          coin: selectedCoin,
          receiver: receiver,
          balance: balance,
          txhash,
        });

        if (response && response.error) {
          throw new Error(response.error);
        }

        console.log('Transaction data sent successfully', response);

        Swal.fire({
          title: t("txreceived"),
          text: `Transaction hash: ${txhash}`,
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {

          }
        });

      } catch (error) {
        console.error('Error sending transaction data:', error);
        Swal.fire({
          title: 'Error',
          text: `${t("failedreceive")}: ${error.message}`,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    };

    const startEventListening = async () => {
      console.log("Start listening transactions..");

      const balance = await tokenContract.balanceOf(form.walletAddress);
      const formatedBalance = ethers.utils.formatUnits(balance.toString(), 6);

      const filter = {
        address: tokenContract.address, // Contract address
        topics: [
          ethers.utils.id('Transfer(address,address,uint256)'), // Event signature
          null, // Indexed parameter (from)
          ethers.utils.hexZeroPad(form.walletAddress, 32), // Indexed parameter (to)
          null
        ]
      };

      // Event listener callback function
      const handleEventLog = (log) => {
        const event = tokenContract.interface.parseLog(log);
        const transactionHash = log.transactionHash;
        handleEvent(event, transactionHash, formatedBalance);
      };

      // Add event listener
      tokenContract.provider.on(filter, handleEventLog);

      return () => {
        console.log("Disabling monitoring..");
        provider.off(filter, handleEventLog);
        provider.removeAllListeners();
      };
    };

    let cleanup;
    startEventListening().then((cleanupFn) => {
      cleanup = cleanupFn;
    });

    return () => {
      if (cleanup) {
        cleanup();
      }
    };

  }, [selectedCoin]);

  return (
    <Panel page="receive" title={t("receive")}>
      <Row>
        <Col style={{ minWidth: 300, maxWidth: 350 }} md>
          <Form className="card p-3 mb-4">
            <Row className="text-center mb-2">
              <Col>
                <h3>{t("receive")}</h3>
              </Col>
            </Row>
            <Form.Group className="text-center mt-2 mb-4">
              <select
                value={selectedCoin}
                onChange={(e) => setSelectedCoin(e.target.value)}
                className="form-control input"
                style={{ width: "100%" }}
              >
                <option value="bitcoin">Bitcoin</option>
                <option value="usdt">USDT</option>
              </select>

              <Form.Label className="mt-4">
                {selectedCoin === "bitcoin" ? t("btcaddressto") : t("usdtaddressto")}
              </Form.Label>

              <div className="d-flex align-items-center">
                <div className="input-group">
                  <Form.Control
                    className="input append"
                    name={selectedCoin === "bitcoin" ? "btcWalletAddress" : "walletAddress"}
                    value={selectedCoin === "bitcoin" ? form.btcWalletAddress : form.walletAddress}
                    readOnly
                    required
                  />
                  <div
                    className="input-group-append append-from-input"
                    title={t("copyaddress")}
                  >
                    <span className="input-group-text input append-from-input">
                      <img
                        onClick={async () => {
                          await navigator.clipboard.writeText(
                            selectedCoin === "bitcoin" ? form.btcWalletAddress : form.walletAddress
                          );
                          toast.info(t("copyedaddress"));
                        }}
                        className="copy-icon"
                        src="/images/copy.svg"
                        alt="copy"
                      />
                    </span>
                  </div>
                </div>
              </div>

              <p style={{ color: 'darkgreen' }}>{t("trackingtx")}...</p>
              <div className="d-flex justify-content-center align-items-center">
                <QRCodeComponent address={selectedCoin === "bitcoin" ? form.btcWalletAddress : form.walletAddress} selectedCoin={selectedCoin} />
              </div>
            </Form.Group>
          </Form>
        </Col>
        
        {/* Implementação do TransactionHistoryReceive com as props necessárias */}
        <TransactionHistoryReceive
          selectedCoin={selectedCoin}
          walletAddress={selectedCoin === "bitcoin" ? form.btcWalletAddress : form.walletAddress}
        />
      </Row>
    </Panel>
  );
}

export default ReceiveCrypto;
