import dayjs from "dayjs";

const findMonths = (startDate, periodEndDate, duration) => {
  const ranges = [];
  let currentDate = dayjs(startDate).startOf('month');
  let endDate = dayjs(periodEndDate).startOf('month'); // dayjs(startDate).add(duration, 'months').format('YYYY-MM-DD')
  endDate = endDate.add(1, 'month').startOf('month');

  for (let i = 1; i <= duration; i++) {
    if(i === 1 ){
      const startOfMonth = dayjs(startDate).format('YYYY-MM-DD');
      const endOfMonth = currentDate.endOf('month').format('YYYY-MM-DD');
      const baseDate = dayjs(startDate).format('YYYY-MM-DD');
      
      ranges.push({
        start: startOfMonth,
        end: endOfMonth,
        base: baseDate,
      });
      
      currentDate = currentDate.add(1, 'month').startOf('month');
    }

    const startOfMonthDate = currentDate.startOf('month').format('YYYY-MM-DD');
    const endOfMonthDate = currentDate.endOf('month').format('YYYY-MM-DD');
    const baseDate = dayjs(startDate).set('month', dayjs(startDate).month() + i).format('YYYY-MM-DD');

    if (dayjs(endOfMonthDate).isAfter(endDate)) {
      ranges.push({
        start: startOfMonthDate,
        end: endDate,
        base: baseDate,
      });
    } else {
      ranges.push({
        start: startOfMonthDate,
        end: endOfMonthDate,
        base: baseDate,
      });
    }
  
    currentDate = currentDate.add(1, 'month').startOf('month');
  }
  return ranges;
};

const findNewRanges = (startDate, endDate, baseDay) => {
  const ranges = [];

  let currentDate = dayjs(startDate).startOf('month');
  const month = dayjs(startDate).month();
  const year = dayjs(startDate).year();
  const startDay = dayjs(startDate).format('YYYY-MM-DD');
  const endDay = dayjs(endDate).format('YYYY-MM-DD');
  const baseDate = dayjs(baseDay).set('month', month).set('year', year).format('YYYY-MM-DD');
  const durationBreak = (dayjs(endDate).diff(dayjs(startDate), 'month', true));
  const duration = Math.ceil(durationBreak);


  if (duration <= 1 && durationBreak < 1) {
    ranges.push({
      start: startDay,
      end: endDay,
      base: baseDate,
    });
  } else {
    for (let i = 0; i <= duration; i++) {
      const startOfMonthDate = currentDate.startOf('month').format('YYYY-MM-DD');
      const endOfMonthDate = currentDate.endOf('month').format('YYYY-MM-DD');
      const baseDateMonthAdd = dayjs(baseDate).set('month', dayjs(startDate).month() + i).format('YYYY-MM-DD');
  
      if(dayjs(startOfMonthDate).isAfter(endDay)) {
        continue;
      }
      
      if (i === 0 ){
        ranges.push({
          start: startDay,
          end: endOfMonthDate,
          base: baseDateMonthAdd,
        });
      } else {
        if (dayjs(endOfMonthDate).isAfter(endDay)) {
          ranges.push({
            start: startOfMonthDate,
            end: endDay,
            base: baseDateMonthAdd,
          });
        } else {
          ranges.push({
            start: startOfMonthDate,
            end: endOfMonthDate,
            base: baseDateMonthAdd,
          });
        }
      }
  
      currentDate = currentDate.add(1, 'month').startOf('month');
    }
  }

  // console.log(ranges);

  return ranges;
};


function isDateInRange(date, r) {
   
  // const fDate = new Date(date);  
  // const startOfMonth = new Date(r.start);
  // const endOfMonth = new Date(r.end);
  const fDate = dayjs(date);
  const startOfMonth = dayjs(r.start);
  const endOfMonth = dayjs(r.end);

  if (fDate >= startOfMonth && fDate <= endOfMonth) {
    return (true);
  }  return (false)
   
};

function formatDateToBrazilian(date) {
  const inputDate = date;
  const parts = inputDate.split("-");
  const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
  return formattedDate;
}

export {
    isDateInRange,
    findMonths,
    formatDateToBrazilian,
    findNewRanges,
};
