import React, { useState, useContext, useEffect, useCallback } from "react";
import { Button, Col, Row, Form, Modal } from "react-bootstrap";
import Panel from "../components/Panel";
import InputMask from "react-input-mask";

import "react-image-crop/dist/ReactCrop.css";
import api from "../utils/api";

import { AppContext } from "../context/AppContext";
import _isEqual from "lodash/isEqual";
import _debounce from "lodash/debounce";
import ChangePhoto from "../components/ChangePhoto";
import { useTranslation, Trans } from 'react-i18next';
import '../i18n'
import CopyButton from "../components/CopyButton";

export const checkNullField = (value) => (value ? value : "");

export const validateContain = (value) => {
  if (value && value.trim() !== "") {
    return "";
  } else {
    return "Campo Inválido - Invalid Field"; // "Campo inválido";
  }
};

export const validateRG = (value) => {
  if (value === "" || value.trim() === "") {
    return ""; // Aceita campos vazios sem erro
  }
  // Se houver um valor, ainda assim deve ser válido (você pode adicionar outras regras se necessário)
  if (value && value.trim() !== "") {
    return "";
  } else {
    return "Campo Inválido - Invalid Field"; // "Campo inválido";
  }
};

export const validateMultipleStrings = (value) => {
  if (!value) {
    return "Campo Inválido - Invalid Field"; // "Campo inválido";
  }
  if (/^(.+ .+)+$/.test(value.trim()) || value.trim() !== "") {
    return "";
  } else {
    return "Campo Inválido - Invalid Field"; // "Campo inválido";
  }
};

export const validateSize = (value, min, max) => {
  if (
    (value.trim().length >= min && value.trim().length <= max) ||
    value.trim() !== ""
  ) {
    return "";
  } else {
    return "Tamanho do Campo Inválido - Invalid Field Size" // "Campo inválido";
  }
};



export default function Profile(props) {
  const { setLoggedInUser, legalPerson, setLegalPerson, photoId, setPhotoId } =
    useContext(AppContext);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;
  const [buttonText, setButtonText] = useState("Default Text");
  const [disableBtn, setDisableBtn] = useState(true);
  const [formInitialState, setFormInitialState] = useState({
    name: "",
    tradingName: "",
    email: "",
    motherName: "",
    cpf: "",
    cnpj: "",
    corporateName: "",
    rg: "",
    birthDate: "",
    phone: "",
    postalCode: "",
    streetAddress: "",
    streetAddress2: "",
    streetAddressNumber: "",
    district: "",
    city: "",
    stateOrProvince: "",
    country: "",
  });
  const [form, setForm] = useState(formInitialState);
  const [errors, setErrors] = useState({
    name: "",
    tradingName: "",
    email: "",
    motherName: "",
    cpf: "",
    cnpj: "",
    corporateName: "",
    rg: "",
    birthDate: "",
    phone: "",
    postalCode: "",
    streetAddress: "",
    streetAddress2: "",
    streetAddressNumber: "",
    district: "",
    city: "",
    stateOrProvince: "",
    country: "",
  });
  const [load, setLoad] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [firstTrySubmit, setFirstTrySubmit] = useState(true);
  const [disablePhone, setDisablePhone] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [type, setType] = useState("");
  const [alertChangeKYC, setAlertChangeKYC] = useState(false);
  const [validations, setValidations] = useState({
    name: validateContain,
    tradingName: validateContain,
    email: validateContain,
    motherName: validateContain,
    corporateName: validateContain,
    cpf: (value) => validateSize(value, 14, 14),
    cnpj: (value) => validateSize(value, 18, 18),
    rg: validateRG,
    birthDate: (value) => validateSize(value, 10, 10),
    phone: (value) => validateSize(value, 15, 16),
    postalCode: (value) => validateSize(value, 9, 9),
    streetAddress: validateMultipleStrings,
    streetAddress2: validateMultipleStrings,
    streetAddressNumber: (value) => validateSize(value, 1, 4),
    district: validateContain,
    city: validateContain,
    stateOrProvince: (value) => validateSize(value, 2, 2),
    country: validateContain,
  });


  useEffect(() => {
    reloadPage();
  }, []);

  useEffect(() => {
    if (_isEqual(form, formInitialState)) {
      setDisableBtn(true);
    }
    if (!_isEqual(form, formInitialState)) {
      setDisableBtn(false);
    }
  }, [form, formInitialState]);


  const debounceFn = useCallback(_debounce(handleDebounceFn, 1500), []);

  function handleDebounceFn(postalCode, formState) {
    if (postalCode !== "_____-___" && postalCode !== "") {
      fetch(`https://viacep.com.br/ws/${postalCode}/json/`)
      .then((response) => response.json())
          .then((data) => {
            setForm({
              ...formState,
              postalCode,
              streetAddress: data.logradouro,
              district: data.bairro,
              city: data.localidade,
              stateOrProvince: data.uf,
              country: "Brasil",
            });
          });
    }
  }

  const handleChangeCEP = (event) => {
    let value = event.target.value;

    if (firstTrySubmit) {
      setErrors({
        ...errors,
        [event.target.name]: validations[event.target.name](value),
      });
    }

    setForm({ ...form, [event.target.name]: value });

    debounceFn(value, form);
  };

  const reloadPage = async () => {
    await api.get("profile").then((req) => {
      if (req.error) return false;
      let photoId = req.data.photoId;
      let type = "";
      if (req.data.roles.name !== "user") {
        type = req.data.roles.name.toLocaleUpperCase();
      }
      let disablePhone = false;
      let disableForm = false;

      if (req.data.roles.name !== "user") {
        type = req.data.roles.name.toLocaleUpperCase();
      }

      setDisablePhone(disablePhone);
      setDisableForm(disableForm);
      setLoad(false);
      setType(type);
      setPhotoId(photoId);

      setForm({
        ...form,
        email: checkNullField(req.data.email, t),
        name: checkNullField(req.data.name, t),
        tradingName: checkNullField(req.data.tradingName, t),
        motherName: checkNullField(req.data.motherName, t),
        cpf: checkNullField(req.data.cpf, t),
        cnpj: checkNullField(req.data.cnpj, t),
        corporateName: checkNullField(req.data.corporateName, t),
        rg: checkNullField(req.data.rg, t),
        birthDate: checkNullField(req.data.birthDate, t),
        phone: checkNullField(req.data.phone, t),
        postalCode: checkNullField(req.data.postalCode, t),
        streetAddress: checkNullField(req.data.streetAddress, t),
        streetAddress2: checkNullField(req.data.streetAddress2, t),
        streetAddressNumber: checkNullField(req.data.streetAddressNumber, t),
        district: checkNullField(req.data.district, t),
        city: checkNullField(req.data.city, t),
        stateOrProvince: checkNullField(req.data.stateOrProvince, t),
        country: checkNullField(req.data.country, t),
      });

      setButtonText(req.data.referralCode, t || "Default Code");
      setLegalPerson(req.data.legalPerson);

      setFormInitialState({
        ...form,
        name: checkNullField(req.data.name, t),
        tradingName: checkNullField(req.data.tradingName, t),
        email: checkNullField(req.data.email, t),
        motherName: checkNullField(req.data.motherName, t),
        cpf: checkNullField(req.data.cpf, t),
        cnpj: checkNullField(req.data.cnpj, t),
        corporateName: checkNullField(req.data.corporateName, t),
        rg: checkNullField(req.data.rg, t),
        birthDate: checkNullField(req.data.birthDate, t),
        phone: checkNullField(req.data.phone, t),
        postalCode: checkNullField(req.data.postalCode, t),
        streetAddress: checkNullField(req.data.streetAddress, t),
        streetAddress2: checkNullField(req.data.streetAddress2, t),
        streetAddressNumber: checkNullField(req.data.streetAddressNumber, t),
        district: checkNullField(req.data.district, t),
        city: checkNullField(req.data.city, t),
        stateOrProvince: checkNullField(req.data.stateOrProvince, t),
        country: checkNullField(req.data.country, t),
      });
    });
  };

  const handleChange = (event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (firstTrySubmit) {
      setErrors({
        ...errors,
        [event.target.name]: validations[event.target.name](value),
      });
    }

    setForm({ ...form, [event.target.name]: value });
  };
  const handleClose = () => {
    setShowModal(false);
  };


  const handleCloseAlertKYC = () => {
    setAlertChangeKYC(false);
    setForm(formInitialState);
    setDisableBtn(true);
  };

  const onSubmit = async () => {
    let formParams = Object.keys(form);
    let foundError = false;

    if (!legalPerson) {
      formParams = formParams.filter(
        (el) => el !== "tradingName" && el !== "corporateName" && el !== "cnpj"
      );
    }

    formParams.forEach((key) => {

      let error = validations[key](form[key]);
      if (error) console.log("What !!!?", error, key);
      errors[key] = error;
      if (error !== "") {
        foundError = true;
      }
    });

    if (!foundError) {
      setForm({ ...form, locale: currentLanguage });
      let req = await api.post("profile", form, true);
      if (!req.error) {
        let me = await api.get(`me`);
        if (!me.error) {
          setShowModal(true);
          setLoggedInUser(me.data);
          setLegalPerson(me.data.legalPerson);
          window.location.reload();        // reload page after submit
        }
      }
    } else {
      setErrors(errors);
      setFirstTrySubmit(true);
    }
  };

  return (
    <Panel
      load={load}
      history={props.history}
      page="profile"
      title={t("profilet")}
      admin={type === "ADMIN" ? true : false}
    >
      <Form className="w-100 card p-3 mb-5">
        <Row className="mb-3">
          <Col>
            <ChangePhoto />
          </Col>
          <Col className="d-flex justify-content-center align-items-end flex-column">
            <p>{t("referralcode")}</p>
            <CopyButton text={buttonText} />
          </Col>
        </Row>


        <Row className="mb-3">
          <Col md>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>{t("fulln")}</Form.Label>
                <Form.Control
                  className="input"
                  name="name"
                  type="text"
                  value={form.name}
                  isInvalid={errors.name}
                  onChange={handleChange}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>{t("mothername")} {/* Nome da Mãe*/}</Form.Label>
                <Form.Control
                  className="input"
                  name="motherName"
                  type="text"
                  value={form.motherName}
                  isInvalid={errors.motherName}
                  onChange={handleChange}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className="input"
                  name="email"
                  type="email"
                  value={form.email}
                  isInvalid={errors.email}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              {legalPerson && (
                <Form.Group as={Col}>
                  <Form.Label>CNPJ</Form.Label>
                  <Form.Control
                    as={InputMask}
                    className="input"
                    name="cnpj"
                    mask="99.999.999/9999-99"
                    type="text"
                    value={form.cnpj}
                    isInvalid={errors.cnpj}
                    onChange={handleChange}
                    disabled={disableForm}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cnpj}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Row>

            {legalPerson && (
              <>
                <Row className="mb-1">
                  <Form.Group as={Col}>
                    <Form.Label>{t("fantasyname")}</Form.Label>
                    <Form.Control
                      className="input"
                      name="tradingName"
                      type="text"
                      value={form.tradingName}
                      isInvalid={errors.tradingName}
                      onChange={handleChange}
                      disabled={disableForm}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.tradingName}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>{t("socialreason")} {/* Razão Social*/}</Form.Label>
                    <Form.Control
                      className="input"
                      name="corporateName"
                      type="text"
                      value={form.corporateName}
                      isInvalid={errors.corporateName}
                      onChange={handleChange}
                      disabled={disableForm}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.corporateName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </>
            )}

            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>CPF</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="999.999.999-99"
                  className="input"
                  name="cpf"
                  type="text"
                  value={form.cpf}
                  isInvalid={errors.cpf}
                  onChange={handleChange}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.cpf}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{currentLanguage == "en" ? "(PASSPORT,OTHERS..)" : "(RG,CNH,PASSAPORTE,OUTROS..)"}</Form.Label>
                <Form.Control
                  className="input"
                  name="rg"
                  type="number"
                  value={form.rg}
                  isInvalid={errors.rg}
                  onChange={handleChange}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.rg}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>{t('state')} {/* Estado*/}</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="aa"
                  className="input"
                  type="text"
                  name="stateOrProvince"
                  value={form.stateOrProvince}
                  isInvalid={errors.stateOrProvince}
                  onChange={handleChange}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.stateOrProvince}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{t('country')} {/* País*/}</Form.Label>
                <Form.Control
                  className="input"
                  name="country"
                  type="text"
                  value={form.country}
                  isInvalid={errors.country}
                  onChange={handleChange}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.country}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Col>

          <Col md>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>{t('postalcode')} {/* CEP*/}</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="99999-999"
                  className="input"
                  name="postalCode"
                  type="text"
                  value={form.postalCode}
                  isInvalid={errors.postalCode}
                  onChange={handleChangeCEP}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.postalCode}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>{t('address')} {/* Endereço */}</Form.Label>
                <Form.Control
                  className="input"
                  name="streetAddress"
                  type="text"
                  value={form.streetAddress}
                  isInvalid={errors.streetAddress}
                  onChange={handleChange}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.streetAddress}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>n°</Form.Label>
                <Form.Control
                  className="input"
                  name="streetAddressNumber"
                  type="number"
                  value={form.streetAddressNumber}
                  isInvalid={errors.streetAddressNumber}
                  onChange={handleChange}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.streetAddressNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{t('complementadd')} {/* Complemento*/}</Form.Label>
                <Form.Control
                  className="input"
                  name="streetAddress2"
                  type="text"
                  value={form.streetAddress2}
                  isInvalid={errors.streetAddress2}
                  onChange={handleChange}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.streetAddress2}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>{t('neighbour')} {/* Bairro*/}</Form.Label>
                <Form.Control
                  className="input"
                  type="text"
                  name="district"
                  value={form.district}
                  isInvalid={errors.district}
                  onChange={handleChange}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.district}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{t('city')} {/* Cidade*/} </Form.Label>
                <Form.Control
                  className="input"
                  name="city"
                  type="text"
                  value={form.city}
                  isInvalid={errors.city}
                  onChange={handleChange}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>{t('bday')} {/* Data de Nasc.*/}</Form.Label>
                <Form.Control
                  className="input"
                  name="birthDate"
                  type="date"
                  value={form.birthDate}
                  isInvalid={errors.birthDate}
                  onChange={handleChange}
                  disabled={disableForm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.birthDate}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{t('phone')} {/* Telefone*/}</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask="+99 (99) 99999-9999"
                  className="input"
                  name="phone"
                  type="text"
                  value={form.phone}
                  isInvalid={errors.phone}
                  onChange={handleChange}
                  disabled={disablePhone}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center">
          <Button
            className="ml-2"
            onClick={(e) => {
              e.preventDefault();
              setAlertChangeKYC(true);
            }}
            disabled={disableBtn}
          >
            {t('save')} {/* Salvar*/}
          </Button>
        </Row>
      </Form>



      <Modal show={alertChangeKYC} onHide={handleCloseAlertKYC}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100 ml-3 text-center">{t('warning')} {/* Aviso*/}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {t('editwarning')} {/* AO EDITAR OS DADOS DE CADASTRO E TELEFONE, É NECESSÁRIO FAZER
          NOVAMENTE A CONFIRMAÇÃO DE DADOS POR ENVIO DE DOCUMENTOS (KYC). SAQUES
          E RETIRADAS DE FUNDOS DE INVESTIMENTO ESTARÃO BLOQUEADOS ATÉ QUE SEJA
          FEITA A CONFIRMAÇÃO! DESEJA PROSSEGUIR?*/}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={async () => {
              await onSubmit();
              setAlertChangeKYC(false);
              setDisableBtn(true);
            }}
          >
            {t('yes')}
          </Button>
          <Button variant="primary" onClick={handleCloseAlertKYC}>
            {t('no')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100 ml-3 text-center"> {t('success')}  {/*Sucesso*/} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {t('profiledataupdate')}  {/*Dados pessoais atualizados*/}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Panel>
  );
}