import { useContext, useState, useEffect, useRef } from "react";
import { Button, Table, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Pagination from "../../components/Pagination";
import Panel from "../../components/Panel";
import { AppContext } from "../../context/AppContext";
import api from "../../utils/api";
import { disableAction } from "../../utils/helper";
import { DEPOSITS_STATUS_TYPES, formatStatus } from "../../utils/types";
import dayjs from "dayjs";
import { setAmount } from "../../pages/SendCrypto";
import { toast } from "react-toastify";
import { formatBTCString, formatEVMString } from "../../utils/Helpers/btcFormat";
import { numberFormatBRL } from "../../components/InvestmentWithdrawModal";
import { CSVLink } from "react-csv";

const getCountPendingStatus = async (setCountPendingStatus) => {
  await api.get("stats").then((req) => {
    if (!req.error) {
      setCountPendingStatus(req.data.data);
    }
  });
};

const ApproveModal = ({ reloadTable }) => {
  const {
    modalApproveBTC,
    setModalApproveBTC,
    approveBTCSelectedRow: row,
    setApproveBTCSelectedRow,
    setCountPendingStatus,
  } = useContext(AppContext);

  const amountInput = useRef(null);

  const [loading, setLoading] = useState(false);

  const messageRequired = "Por favor, preencha este campo.";

  const approveSchema = yup.object().shape({
    amount: yup
      .string()
      .required(messageRequired)
      .test(
        "minimum-amount",
        "O valor mínimo é de 0.000001 (100 satoshi)",
        (val) => val != 0
      )
      .test(
        "maxLength",
        "O valor máximo é de 13 caracteres.",
        (val) => val.length <= 13
      )
      .test(
        "minLength",
        "O valor mínimo é de 1 caractere.",
        (val) => val.length >= 1
      ),
    // twoFactorAuth: yup
    //   .string()
    //   .required(messageRequired)
    //   .test("valid-code", "Código inválido.", (val) => {
    //     if (/^[0-9]{6}$/.test(val)) {
    //       return true;
    //     }

    //     if (val === "") {
    //       return false;
    //     }

    //     return false;
    //   }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(approveSchema),
  });

  const { ref: amountRHFRef, ...theArgs } = register("amount");

  const confirm = async (data) => {
    try {
      let req = await api.patch("deposits/" + row.id, {
        status: "confirmed",
        amount: data.amount,
      });
      if (req.error) return;
      close();
      reloadTable();
      getCountPendingStatus(setCountPendingStatus);

      setApproveBTCSelectedRow(null);
      toast.success("Compra BTC confirmada com sucesso!");
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.data ?? "Erro ao confirmar compra BTC!");
    }
  };

  const close = () => {
    setModalApproveBTC(false);
  };

  useEffect(() => {
    setValue("amount", String(row.amount));
  }, []);

  return (
    <Modal show={modalApproveBTC} onHide={close} size="sm" centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 ml-3 text-center">
          Confirme o valor
        </Modal.Title>
      </Modal.Header>
      <Form className="w-100" onSubmit={handleSubmit(confirm)}>
        <Modal.Body className="w-100 pb-0 pt-0">
          <Row
            className="mb-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Form.Group as={Col}>
              <Form.Label>Valor</Form.Label>
              <div className="input-group">
                <input
                  className="input append form-control"
                  name="amount"
                  type="text"
                  minLength={1}
                  maxLength={13}
                  {...register("amount", {
                    setValueAs: (value) => setAmount(amountInput, value),
                  })}
                  ref={(e) => {
                    amountRHFRef(e);
                    amountInput.current = e;
                  }}
                />
                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                    BTC
                  </span>
                </div>
              </div>
              {errors.amount && (
                <span className="invalid-feedback show">
                  {errors.amount.message}
                </span>
              )}
            </Form.Group>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {false ? (
            <div className="text-center">
              <Spinner
                variant="secondary"
                animation="border"
                role="status"
              ></Spinner>
            </div>
          ) : (
            <>
              {loading ? (
                <div className="text-center mt-3 mb-3">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : (
                <Button variant="primary" type="submit">
                  Confirmar
                </Button>
              )}

              <Button onClick={close} variant="primary">
                Cancelar
              </Button>
            </>
          )}

          {false && (
            <div className="kyc-icon-miss mt-4" style={{ fontSize: "16px" }}>
              <i className="fas fa-exclamation-triangle"></i>
              <span className="ml-1">{"error"}</span>
            </div>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

function ApproveBTC() {
  const [load, setLoad] = useState(false);
  const [btcDeposits, setBtcDeposits] = useState([]);
  const {
    setPagination,
    pagination,
    loggedInUser,
    setCountPendingStatus,
    setApproveBTCSelectedRow,
    approveBTCSelectedRow,
    setModalApproveBTC,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const loadBTCDeposits = async () => {
    setLoad(true);

    try {
      // const res = await api.get("/deposits-users", {});
      const req = await api.get(
        `orders?&filter[order]=created_at%20DESC&filter[skip]=${pagination.offset}&filter[limit]=${pagination.limit}`
      );

      let deposits = req.data;

      const withNames = await Promise.all(
        deposits.map(async (row) => {
          const username = await getUserById(row.usersId);
            return {
              ...row,
              username,
            };
        })
      );

      console.log('withNames >>>>>>>', withNames);
      
      setBtcDeposits(withNames);

      const count = await api.get(`orders/count`);
      console.log('count >>>>', count);

      const pagesNumber = await Math.ceil(count.data.count / 10);
      console.log('pagesNumber >>>>', pagesNumber);

      setPagination((pagination) => {
        return {
          ...pagination,
          lastPage: pagesNumber,
        };
      });

      setLoad(false);

      if (req.error) return;
    } catch (e) {
      setLoad(false);
    }
  };

  const getUserById = async (id) => {
    const username = await api.get(`users/${id}`)
    if (!username.error) {
      return username.data.name;
    } else {
      return ""
    }
  };

  const cancel = async (row) => {
    let req = await api.patch("deposits/" + row.id, {
      status: "canceled",
    });
    if (req.error) return;
    loadBTCDeposits();
    getCountPendingStatus(setCountPendingStatus);
  };

  const goTo = (row) => {
    navigate("/panel/admin/user/" + row.usersId);
  };

  useEffect(() => {
    loadBTCDeposits();
  }, []);

  useEffect(() => {
    loadBTCDeposits();
  }, [pagination.offset]);

  return (
    <Panel
      load={load}
      page="ApproveBTC"
      title="Histórico de transações (Orderbook)"
      admin
    >
      <div className="div-export-csv">
  <CSVLink 
    className="export-csv btn btn-primary" 
    data={btcDeposits.map((row) => ({
      Date: dayjs(row.createdAt).format("DD/MM/YYYY HH:mm:ss"),
      Cliente: row.username || '',
      Valor: row.pair === "btc" ? formatBTCString(row.amount) : formatEVMString(row.amount, 3, row.pair.toLocaleUpperCase()),
      Tipo: row.type,
      Preço: numberFormatBRL(row.price),
      Status: row.status,
    }))}
    filename={"btc_deposits.csv"}
  >
    Exportar CSV
  </CSVLink>
</div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#50504E",
        }}
      >
        <span className="bold text1 ml-3 text-mid2">Histórico de transações (compra e venda)</span>
      </div>
      <Table
        className="hist-table m-0 p-0 text-mid"
        responsive="md"
        striped
        hover
      >
        <thead>
          <tr>
            <th>Data</th>
            <th>Cliente</th>
            <th>Valor</th>
            <th>Tipo</th>
            <th>Preço</th>
            <th>Status</th>
            <th>Ver cliente</th>
          </tr>
        </thead>
        <tbody>
          {btcDeposits.map((row) => (

            <tr key={row.id}>
              <td>{dayjs(row.createdAt).format("DD/MM/YYYY HH:mm:ss")}</td>
              <td>{row.username ?  row.username : ""}</td>
              <td>{row.pair === "btc" ? formatBTCString(row.amount) : formatEVMString(row.amount,3,row.pair.toLocaleUpperCase())}</td>
              <td>{row.type}</td>
              <td>{numberFormatBRL(row.price)}</td>
              <td>{row.status}</td>
              {/* <td>{formatStatus(DEPOSITS_STATUS_TYPES, row.status)}</td>*/}

              <td>
                <Button onClick={() => goTo(row)}>Ver</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {btcDeposits.length > 0 && (
        <Pagination pagination={pagination} setPagination={setPagination} />
      )}

      {approveBTCSelectedRow && <ApproveModal reloadTable={loadBTCDeposits} />}
    </Panel>
  );
}

export default ApproveBTC;
