import React from "react";
import { Table, Button } from "react-bootstrap";
import api from "../utils/api";
import { formatMoney } from "../utils/helper";
import {
  INVESTMENT_STATUS_TYPES,
  INVESTMENTS_TYPES,
  INVESTMENT_STATUS_TYPES_EN,
  INVESTMENTS_TYPES_EN,
  formatStatus,
} from "../utils/types";
import Panel from "../components/Panel";
import dayjs from "dayjs";
import { AppContext } from "../context/AppContext";
import Pagination from "../components/Pagination";
import { CSVLink } from "react-csv";
import { formatBTCString } from "../utils/Helpers/btcFormat";
import { numberFormatBRL } from "../components/InvestmentId";
import { withTranslation } from 'react-i18next';

class TradeHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      historyTradeMaster: [],
    };
  }

  static contextType = AppContext;

  componentDidMount = () => {
    this.getHistoryMyInvestments();

    this.context.setPagination((pagination) => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.context.pagination.offset !== prevState.offset) {
      this.getHistoryMyInvestments();
    }
  }

  getHistoryMyInvestments = async () => {
    this.setState({
      offset: this.context.pagination.offset,
      load: true,
    });

    if (!this.context.allInvestments || this.context.allInvestments?.length === 0) {
      try {
        await this.context.getAllInvestments(this.context.loggedInUser.id).then((res) => {
          this.setState({
            load: false,
            historyTradeMaster: this.context.allInvestments,
          });
        });
      } catch (e) {
        this.setState({ load: false, historyTradeMaster: [] });
      }
    } else {
      this.setState({
        load: false,
        historyTradeMaster: this.context.allInvestments,
      });
    }


    // api
    //   .get(
    //     `my-investments?filter[order]=createdAt%20DESC&filter[skip]=${this.context.pagination.offset}&filter[limit]=${this.context.pagination.limit}`
    //   )
    //   .then((req) => {
    //     if (!req.error) {
    //       let tradeHistory = req.data;

    //       this.context.setPagination((pagination) => {
    //         return {
    //           ...pagination,
    //           limit: tradeHistory.limit,
    //           lastPage: Math.ceil(tradeHistory.total / tradeHistory.limit),
    //         };
    //       });

    //       this.setState({
    //         load: false,
    //         historyTradeMaster: tradeHistory.data,
    //       });
    //     }
    //   });
  };


  createCsv = () => {
    console.log('call csv')

    // console.log(this.state.historyTradeMaster)
  }

  render() {
    const currentLanguage = this.props.i18n.language;

    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="trade_hist"
        title={this.props.t("investhistorytitle")} // "Histórico - Investimentos"
      >
        <div className="div-export-csv" >
          <CSVLink
            data={this.state.historyTradeMaster}
            filename={"my-file.csv"}
            className="export-csv"
            target="_blank"
            style={{ backgroundColor: '#011535', padding: 5, borderRadius: 10 }}
          >
            {this.props.t("exportcsv")}  {/* Exportar CSV */}
          </CSVLink>
          <div
            onClick={(event) => {
              this.getHistoryMyInvestments();
            }
            }
            className="btn-menu d-flex ml-3 text-mid bold"
            style={{ alignItems: "center", justifyContent: "center", height: "33px", width: "33px", borderRadius: "4px", backgroundColor: "#ceba85" }}
            name="balances"
          >
            <img
              className="mr-1"
              alt="bitcoin icon"
              src="/images/refresh-button.svg"
              style={{ width: "16px", padding: "3px" }}
              name="balances"
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            backgroundColor: "#50504E",
          }}
        >
          <span className="bold text1 ml-3 text-mid2">
            {this.props.t("deposithistory")}  {/* Histórico de Aportes */}
          </span>
        </div>
        <Table className="hist-table m-0 p-0" responsive="md" striped hover>
          <thead>
            <tr>
              <th>{this.props.t("id")} {/* ID */}</th>
              <th>{this.props.t("date")} {/* Data */}</th>
              <th>{this.props.t("initialvalue")} {/* Valor Inicial */}</th>
              <th>{this.props.t("initialvaluebrl")} {/* Valor Inicial BRL */}</th>
              <th>{this.props.t("currentvalue")} {/* Valor Atual */}</th>
              <th>{this.props.t("investment")} {/* Investimento */}</th>
              <th>{this.props.t("status")} {/* Estado */}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.historyTradeMaster.map((row) => (
              <tr key={row._id}>
                <td style={{ maxWidth: '250px', overflowX: 'scroll' }}>{row._id}</td>
                <td>{dayjs(row.createdAt).format("DD/MM/YYYY")}</td>
                <td>{formatBTCString(row.initialAmount / this.context.btcPrice)}</td>
                <td>{numberFormatBRL(row.initialAmount)}</td>
                <td>{formatBTCString(row.currentAmount / this.context.btcPrice)}</td>
                {currentLanguage == "en" ? (<>
                  <td>{formatStatus(INVESTMENTS_TYPES_EN, row.modality)}</td>
                  <td>{formatStatus(INVESTMENT_STATUS_TYPES_EN, row.status)}</td>
                </>
                ) : (<>
                  <td>{formatStatus(INVESTMENTS_TYPES, row.modality)}</td>
                  <td>{formatStatus(INVESTMENT_STATUS_TYPES, row.status)}</td>  </>
                )
                }
              </tr>
            ))}
          </tbody>
        </Table>

        {this.state.historyTradeMaster.length > 0 && (
          <Pagination
            pagination={this.context.pagination}
            setPagination={this.context.setPagination}
          />
        )}
      </Panel>
    );
  }
}

export default withTranslation()(TradeHistory);