import React from 'react';
import { Button } from 'react-bootstrap';

function CopyButton({ text }) {
  const handleCopy = () => {
    const textToCopy = " bcxcorretora.com.br?ref=" + text;
    navigator.clipboard.writeText(textToCopy).then(() => {
    });
  };

  return (
    
    <Button onClick={handleCopy} style={{ backgroundColor: '#ceba85', borderRadius: '10px', border: 'none', color: '#000', fontWeight: 'bold' }}>
        {text}
      <span style={{ marginLeft: '10px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-clipboard"
          viewBox="0 0 16 16"
        >
          <path d="M10 1.5v1H6v-1a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5zM5 0a2 2 0 0 0-2 2v1H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-1V2a2 2 0 0 0-2-2H5zM1 5h14v9a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5zm3-2V2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v1H4z" />
        </svg>
      </span>
    </Button>
  );
}

export default CopyButton;