import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { formatDate } from "../../../utils/helper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { set, useForm } from "react-hook-form";
import api, { microservice } from "../../../utils/api";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import {numberFormatBRL} from "../../../utils/Helpers/btcFormat"

export default function ConfirmRenew({ renew, setRenew, exchangeRate, from }) {
  const approveInvestmentRenewSchema = yup.object().shape({
    // operatingFee: yup.string().default(20),
    // operatingFee: yup.string().when("", {
      //   is: (val) => val != 0,
      //   then: (schema) => schema.string().test("minimum-amount", "O valor mínimo é de 0.1", (val) => val != 0)
      //     .test("maxLength", "O valor máximo é de 100%", (val) => val <= 100)
      // }),
      // proRata: yup.string().when("proRata", {
        //   is: (val) => val != 0,
        //   then: (schema) => schema.string().test("minimum-amount", "O valor mínimo é de 0.1", (val) => val != 0)
        //     .test("maxLength", "O valor máximo é de 100%", (val) => val <= 100)
        // }),
        // penalty: yup.string().when("penalty", {
          //   is: (val) => val != 0,
          //   then: (schema) => schema.string().test("minimum-amount", "O valor mínimo é de 0.1", (val) => val != 0)
          //     .test("maxLength", "O valor máximo é de 100%", (val) => val <= 100)
          // }),
        });
  const [loading, setLoading] = useState(false);
  const [opFee, setOpFee] = useState('20');
  const [pRFee, setPRFee] = useState('0');
  const [penFee, setPenFee] = useState('0');
  const [message, setMessage] = useState('Confirmação de Renovação de investimento');
  const [investmentStartDate, setInvestmentStart] = useState(null);
  const [investmentEndDate, setInvestmentEnd] = useState(null);
  const [investmentProfit, setInvestmentProfit] = useState(null);
  const [investmentInitial, setInvestmentInitial] = useState(null);
  const [investmentCurrent, setInvestmentCurrent] = useState(null);
  const today = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  // const getDiffDays = () => {
  //   const days = dayjs(withdraw.withdraws.requestedTo).diff(dayjs(withdraw.withdraws.createdAt), 'day');
  //   if(days > 30) {
  //     setTirthy(true);
  //   } else {
  //     setTirthy(false);
  //   }
  // }

  // const initialPenalty = () => {
  //   if (tirthy) {
  //     setPenFee('40');
  //   } else {
  //     setPenFee('15');
  //   }
  // }


  useEffect(() => {
    console.log(renew)
    if(from == "liquidating"){
      async function getInvestment(){
        const res = await microservice.get(`investments/${renew.investmentId}`);
        console.log("GETTING INVESTTMENT >> ", res);
        setInvestmentStart(res.createdAt);
        setInvestmentEnd(res.endDate);
        setInvestmentProfit(res.acumulatedProfit);
        setInvestmentInitial(res.initialAmount);
        setInvestmentCurrent(res.currentAmount);
      }
      getInvestment();
    } else {
      setInvestmentStart(renew.createdAt);
      setInvestmentEnd(renew.endDate);
      setInvestmentProfit(renew.acumulatedProfit);
      setInvestmentCurrent(renew.currentAmount);
      setInvestmentInitial(renew.initialAmount);
    }
    // const daysDiff = dayjs(renew?.withdraws.requestedTo).diff(dayjs(renew?.withdraws.createdAt), 'day');
    // console.log(daysDiff)
  }, [renew]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(approveInvestmentRenewSchema),
  });

  const handleClose = () => setRenew(null);

  const onSubmit = async (data) => {

    setLoading(true);
    // console.log({
    //   "investmentId": withdraw.investmentId,
    //   "withdrawId": withdraw.withdraws.id,
    //   "requestedTo": withdraw.withdraws.requestedTo,
    //   "penalty": (penFee / 100),
    //   "proRata": (pRFee / 100),
    //   "operatingFee": (opFee / 100),
    //   "exchangeRate": withdraw.withdraws.exchangeRate,
    //   "userId": withdraw.userId,
    // })


    const response = await api.patch('investments/renew', {    // alterado de microservice para api patch
      "investmentId": from === "liquidating" ? renew.investmentId : renew?._id,
      "withdrawId": from === "liquidating" ? renew.withdraws.id : 0,
      "requestedTo": today,
      "penalty": (penFee / 100),
      "proRata": (pRFee / 100),
      "operatingFee": (opFee / 100),
      "exchangeRate": exchangeRate,
      "userId": renew?.userId,
    })
    // const response = await api.patch(
    //   `investments-withdraws/${withdraw.id}`,
    //   data
    // );

    setLoading(false);

    // console.log("RESPONSE with FALSE: ", response);

    if (response.error !== '') {
      toast.error("Erro ao confirmar renovação!");
      return console.log(response.error)
    }

    toast.success("Renovação confirmada com sucesso!");

    handleClose();
    window.location.reload();

  };

  const show = renew ? true : false;

  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Form
        className="w-100 form-group col m-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="w-100 ml-3 text-center"
            style={{
              textDecoration: "underline",
              textTransform: "uppercase",
              color: "#ceba85",
            }}
          >
            Confirmar Renovação
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group mb-4">
            <div>
              <p
                className="text-center bold mb-4"
                style={{ textTransform: "uppercase" }}
              >
                {message}
              </p>
              <p>
                Data do inicio do contrato:{" "}
                <strong>{formatDate(investmentStartDate)}</strong>
              </p>
              <p>
                Data do fim do contrato:{" "}
                <strong>{formatDate(investmentEndDate)}</strong>
              </p>
              <p>
                Capital Investido:{" "}
                <strong>
                  {numberFormatBRL(Number(investmentInitial).toFixed(2))}
                </strong>
              </p>
              <p>
                Montante Atual:{" "}
                <strong>
                   {numberFormatBRL(Number(investmentCurrent).toFixed(2))}
                </strong>
              </p>
              <p>
                Rentabilidade:{" "}
                <strong>
                  {numberFormatBRL(Number(investmentCurrent - investmentInitial).toFixed(2))}
                </strong>
              </p>
            </div>
          </div>
          <Form.Group>
            <Form.Label>Cotação BTC/BRL</Form.Label>
            <div className="d-flex align-items-center">
              <input
                className="form-control input append"
                name="exchangeRate"
                type="text"
                value={numberFormatBRL(exchangeRate.toFixed(2))}
                readOnly 
              />
            </div>
          </Form.Group>
          <Form.Group>
            {/* {
              !withdraw.profitPercentage && (
                <p id="exp-timer-txt">Atenção: Rentabilidade diária cadastrada é 0%</p>
              )
            } */}
            <Form.Label>Tarifa de corretagem</Form.Label>
            <div className="d-flex align-items-center">
              <input
                className="form-control input append"
                name="operatingFee"
                type="number"
                max="100"
                value={opFee}
                onChange={(e) => setOpFee(e.target.value)}
              // {...register("operatingFee")}
              />
              <div className="input-group-append append-from-input">
                <span className="input-group-text input append-from-input">
                  %
                </span>
              </div>
            </div>
            {errors.operatingFee && (
              <p className="text-danger">{errors.operatingFee.message}</p>
            )}
          </Form.Group>
          {renew && renew.withdraws && renew.withdraws.type === "anticipated" ? (

            <>
              <Form.Group>
                <Form.Label>Pró-rata</Form.Label>
                <div className="d-flex align-items-center">
                  <input
                    className="form-control input append"
                    name="proRata"
                    type="number"
                    max="100"
                    value={pRFee}
                    onChange={(e) => setPRFee(e.target.value)}
                  // {...register("proRata")}
                  />
                  <div className="input-group-append append-from-input">
                    <span className="input-group-text input append-from-input">
                      %
                    </span>
                  </div>
                </div>
                {errors.proRata && (
                  <p className="text-danger">{errors.proRata.message}</p>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label>Multa</Form.Label>
                <div className="d-flex align-items-center">
                  <input
                    className="form-control input append"
                    name="penalty"
                    type="number"
                    max="100"
                    value={penFee}
                    onChange={(e) => setPenFee(e.target.value)}
                  // {...register("penalty")}
                  />
                  <div className="input-group-append append-from-input">
                    <span className="input-group-text input append-from-input">
                      %
                    </span>
                  </div>
                </div>
                {errors.penalty && (
                  <p className="text-danger">{errors.penalty.message}</p>
                )}
              </Form.Group>
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <div className="text-center">
              <Spinner
                variant="secondary"
                animation="border"
                role="status"
              ></Spinner>
            </div>
          ) : (
            <>
              <Button variant="primary" type="submit">
                Confirmar
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Cancelar
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
