import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { AppContext } from "../context/AppContext";
import api, { microservice } from "../utils/api";
import { toast } from "react-toastify";
import { useTranslation, Trans } from 'react-i18next';
import '../i18n'

function InvestmentRentabilityModal({ investment, showModal, deadlineWarning, renewOption , monthlyProfitWithdraw }) {

  const [selectedOption, setSelectedOption] = useState(monthlyProfitWithdraw);
  const [selectedRenew, setSelectedRenew] = useState(renewOption);

  const { t, i18n } = useTranslation();

  const {
    setShowRentabilityModal,
  } = useContext(AppContext);

  useEffect(() => {
  }, []);

  const handleCloseModal = () => {
    setShowRentabilityModal(false);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  }
  const handleSelectRenew = (event) => {
    setSelectedRenew(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    submit();
  }

  const submit = async () => {
    const res = await api.patch(`investments/options`, {
      investmentId: investment._id,
      monthlyProfitWithdraw: selectedOption,
      renew: selectedRenew,
      userId: investment.userId,
    });

    if (!res.error) {
      toast.success(t("rentabilityconfsuccess"), {  // "Configuração de Saque Rentabilidade efetuada com sucesso."
        position: "top-right",
        theme: "colored",
      });
    } else {
      toast.error(t("rentabilityconfdenied"), {  // "Configuração de Saque Rentabilidade negada."
        position: "top-right",
        theme: "colored",
      });
    }
    handleCloseModal();
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title
          className="w-100 ml-3 text-center"
          style={{
            textDecoration: "underline",
            textTransform: "uppercase",
            color: "#ceba85",
          }}
        >
          {t("rentabilityconf")} {/* Configuação de Saque de Rentabilidade */}
        </Modal.Title>
      </Modal.Header>
      <Form className="w-100" onSubmit={handleSubmit}>
        <Modal.Body className="w-100 pb-0 pt-0">
          <Row
            className="mb-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >

            <div className="form-group col">
              <div className="mt-3 mb-2">
                <Form.Group className="mt-4 mb-2">
                  <Form.Label style={{ fontWeight: "bold", color: "black", textAlign: "center" }}> {t("monthlywithdraw")} {/* Deseja Sacar Mensalmente a Rentabilidade do Seu Investimento? */}</Form.Label>
                  <div className="input-group">
                    <select name="select" className="select-input" value={selectedOption} onChange={handleSelectChange}>
                      <option value="true" selected={selectedOption === "true"}>{t("yes")}</option>
                      <option value="false" selected={selectedOption === "false"}>{t("no")}</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <br></br>
              {deadlineWarning <= 45 && deadlineWarning > 30 && (
                <div className="mt-3 mb-2">
                  <Form.Group className="mt-4 mb-2">
                    <Form.Label style={{ fontWeight: "bold", color: "black", textAlign: "center" }}>  {t("liquidateinvestment")} {/* Você deseja sacar o valor total do seu investimento no fim do período? */} </Form.Label>
                    <div className="input-group">
                      <select name="select" className="select-input" value={selectedRenew} onChange={handleSelectRenew}>
                        <option value="true" selected={selectedRenew === "true"}>
                          {t("yes")}
                        </option>
                        <option value="false" selected={selectedRenew === "false"}> 
                          {t("no")}
                        </option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
              )}
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" type="submit">
            {t("save")}  {/* Salvar */}
          </Button>
          <Button onClick={handleCloseModal} variant="primary">
            {t("cancel")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default InvestmentRentabilityModal;
