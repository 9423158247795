import React, { useContext, useEffect, useState } from "react";
import { Col, Pagination, Spinner, Table } from "react-bootstrap";
import api from "../utils/api";
import { AppContext } from "../context/AppContext";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { formatBTCString, formatEVMString } from "../utils/Helpers/btcFormat";
import { useTranslation, Trans } from 'react-i18next';

const TransactionHistory = ({ data, category, local, selectedCoin }) => {
  const { setNodeTransactions, pagination, setPagination, loggedInUser } =
    useContext(AppContext);

  const { t } = useTranslation();

  const [load, setLoad] = useState(true);
  const [nodeTransactionsArr, setNodeTransactionsArr] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setPagination(() => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });
  }, []);

  useEffect(() => {
    setNodeTransactions([]);
    setNodeTransactionsArr([]);
    getNodeTransactions();
  }, [pagination.offset, selectedCoin]);

  async function getNodeTransactions() {
    setLoad(true);

    const req = await api.get(
      `node/transactions?category=${category}&pair=${selectedCoin}&filter[order]=createdAt%20DESC&filter[skip]=${pagination.offset}&filter[limit]=${pagination.limit}`
    );
    const data = req.data;

    console.log(data.data);
    // const filteredData = data.data.filter(tx => {

    //   if(category == 'receive'){
    //     if(!tx?.btcwalletaddress) {  return false }
    //     console.log({ tx, category, address: tx.address, status: tx.status, btcWalletAddress: loggedInUser?.btcWalletAddress, condition:  tx.address == loggedInUser?.btcWalletAddress })
    //     if(tx.address == loggedInUser?.btcWalletAddress) {
    //       return true;
    //     }
    //   }

    //   if(category == 'send'){
    //     if(!tx?.btcwalletaddress) {  return false }
    //     if(tx.address != loggedInUser?.btcWalletAddress) {
    //       return true;
    //     }
    //   }
    //   return false;
    // })
    const filteredData = data.data;
    setNodeTransactions(filteredData);
    setNodeTransactionsArr(filteredData);

    setPagination((pagination) => {
      return {
        ...pagination,
        limit: data.limit,
        lastPage: Math.ceil(data.total / data.limit),
      };
    });

    setLoad(false);
  }

  function translateStatus(status) {
    if (status === "new") {
      // 0, unconfirmed/new
      return t("new"); // "novo";
    }

    if (status === "confirmed") {
      // 1,2 confirming
      return t("confirmingtx"); //"confirmando x/3";
    }

    //3+ done
    return t("txconfirmed"); // "concluído";
  }

  return (
    <Col lg>
      <div
        style={{
          width: "100%",
          backgroundColor: "#50504E",
        }}
      >
        {local ? (
          <span className="bold text1 ml-3 text-mid2">
            {t("txhistory")} {/* Histórico de Transações */} {local}
          </span>
        ) : (<span className="bold text1 ml-3 text-mid2">
          {t("txhistory")} {selectedCoin.toLocaleUpperCase()}
          {/* {" "} */}
          {/* {`${category == "send" ? "Envio" : "Recebimento"}`} de Bitcoin (BTC) */}
        </span>)}
      </div>

      <Table
        className="hist-table m-0 p-0"
        responsive="md"
        striped
        hover
        style={{
          tableLayout: "fixed",
        }}
      >
        <thead>
          <tr>
            <th>{t("date")}</th>
            <th>{t("value")}</th>
            <th>Txid</th>
            <th>{t("type")} {/* Tipo */}</th>
            <th>{t("address")} {/* Endereço */}</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {nodeTransactionsArr.length > 0 ? (
            nodeTransactionsArr.map((transaction) => {
              return (
                <tr key={transaction.id}>
                  <td>
                    {dayjs(transaction.created_at).format("DD/MM/YYYY HH:mm")}
                  </td>
                  <td>{selectedCoin === "bitcoin" ? formatBTCString(transaction.amount) : formatEVMString(transaction.amount,3,transaction.coin)}</td>
                  <td style={{ width: "80px", overflow: "auto" }}>
                    {transaction.txid && process.env.REACT_APP_NETWORK === "Mainnet" ? (
                      transaction.coin === "BTC" ? (
                        <a
                          className="bold"
                          href={`https://blockstream.info/tx/${transaction.txid}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {transaction.txid}
                        </a>
                      ) : (
                        <a
                          className="bold"
                          href={`https://polygonscan.com/tx/${transaction.txid}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {transaction.txid}
                        </a>
                      )
                    ) : transaction.txid && process.env.REACT_APP_NETWORK === "Testnet" ? (
                      transaction.coin === "BTC" ? (
                        <a
                          className="bold"
                          href={`https://blockstream.info/testnet/tx/${transaction.txid}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {transaction.txid}
                        </a>
                      ) : (
                        <a
                          className="bold"
                          href={`https://polygonscan.com/tx/${transaction.txid}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {transaction.txid}
                        </a>
                      )
                    ) : (
                      "Interna"
                    )}
                  </td>
                  <td
                    title={
                      transaction.category === "receive" ? t("received") : t("sended")  // "Recebida" : "Enviada" 
                    }
                  >
                    {transaction.category === "receive" ? t("received") : t("sended")}

                  </td>
                  <td
                    title={
                      transaction.category === "receive"
                        ? transaction.btcwalletaddress
                        : transaction.address
                    }
                    style={{ width: "80px", overflow: "auto" }}
                  >
                    {transaction.category === "receive"
                      ? transaction.coin === "BTC" ? transaction.btcwalletaddress : transaction.walletaddress
                      : transaction.address}
                  </td>

                  <td>{translateStatus(transaction.status)}</td>
                </tr>
              );
            })
          ) : load ? (
            <div className="mt-3 text-center">
              <Spinner variant="secondary" animation="border"></Spinner>
            </div>
          ) : (
            <tr>
              <td className="text-center" colSpan={6}>
                {t("notxs")} {/* Sem transações para mostrar. */}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {nodeTransactionsArr.length > 0 && (
        <Pagination pagination={pagination} setPagination={setPagination} />
      )}
    </Col>
  );
};

export default TransactionHistory;
