import React from "react";
import { Container, Button, Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import HeaderBar from "../components/HeaderBar";
import BottomBar from "../components/BottomBar";
import api from "../utils/api";
import { withTranslation } from 'react-i18next';

class RequestResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      title: "",
      subTitle: "",
      showForm: true,
      form: {
        email: "",
        token: "",
      },
    };
  }

  confirmCode = async () => {
    let req = await api.post("request-new-password", {
      email: this.state.form.email,
      recaptcha: this.state.form.token,
      locale: this.props.i18n.resolvedLanguage.toString()
    });
    if (!req.error) {
      this.setState({
        showForm: false,
        success: true,
        title: this.props.t('success'),
        subTitle: this.props.t('checkemail') // "Confira seu email e siga as instruções.",
      });
    }
  };

  handleChange = (event) => {
    let form = this.state.form;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    form[event.target.name] = value;
    this.setState({ form });
  };

  recapchaOnChange = (value) => {
    this.handleChange({
      target: {
        type: "text",
        name: "token",
        value: value,
      },
    });
  };

  render() {
    return (
      <Container className="p-0 d-table" fluid>
        <HeaderBar hide />
        <Container
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
          }}
          className="d-table-row"
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: 30,
              paddingBottom: 50,
            }}
          >
            <div
              style={{
                paddingTop: "auto",
                paddingBottom: "auto",
              }}
            >
              {this.state.showForm === false && (
                <>
                  <h1 className="mt-5">{this.state.title}</h1>
                  <h2 className="font-weight-light">{this.state.subTitle}</h2>
                </>
              )}
              {this.state.showForm === true && (
                <>
                  <h1 className="mt-3"> {this.props.t('forgotpass')}</h1>
                  <h2 className="font-weight-light mb-3"> {this.props.t('insertemail')}</h2>
                  <Form className="" onSubmit={this.sendCode}>
                    <Form.Group
                      style={{
                        width: 300,
                        marginRight: "auto",
                        marginLeft: "auto",
                      }}
                    >
                      <Form.Control
                        className="input"
                        name="email"
                        type="email"
                        value={this.state.form.email}
                        onChange={this.handleChange}
                        required
                        placeholder="E-mail"
                      />
                    </Form.Group>
                    <Form.Group className="text-left pl-4">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        onChange={this.recapchaOnChange}
                      />
                    </Form.Group>
                    <Button
                      className="btn-primary mt-2"
                      onClick={this.confirmCode}
                    >
                       {this.props.t('confirm')}
                    </Button>
                  </Form>
                </>
              )}
            </div>
          </div>
        </Container>
        <BottomBar />
      </Container>
    );
  }
}

export default withTranslation()(RequestResetPassword);