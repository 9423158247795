import React, { useContext, useState } from "react";
import { disableAction } from "../../utils/helper";
import { AppContext } from "../../context/AppContext";
import { Button, Col, Row, Form, Collapse } from "react-bootstrap";
import api from "../../utils/api";
import { toast } from "react-toastify";

function AdminChangelevelPermission({ id }) {
  const { loggedInUser, userSelected, levelPermissionSelected, setLevelPermissionSelected } =
    useContext(AppContext);
  const [show, setShow] = useState(true);

  const submit = async () => {
    await api.post("users/changeAdminLevel", {
      usersId: id,
      level: levelPermissionSelected,
    });

    toast("Nível de permissão alterado com sucesso!")
  };

  const handleChange = (e) => {
    setLevelPermissionSelected(e.target.value);
  };

  const toggleShow = () => {
    setShow(!show);
  };

  if(userSelected.role !== "admin"){
    return null
  }

  return (
    <Form className="w-100 card p-3 mb-5">
      <div
        className="text-large bold mb-2 w-100 d-flex btn"
        onClick={toggleShow}
      >
        <span className="">Alterar nível de permissão</span>
        <i className="fas fa-caret-down text-large mr-3 ml-auto"></i>
      </div>
      <div
        style={{ width: "100%", height: 1, backgroundColor: "#50504E" }}
      ></div>
      <Collapse in={show}>
        <div>
          <Row className="mb-1 mt-3">
            <Form.Group as={Col}>
              <Form.Label>Nível de Permissão</Form.Label>
              <Form.Control
                className="input"
                as="select"
                name="levelPermission"
                onChange={(e) => handleChange(e)}
                value={levelPermissionSelected}
              >
                <option value="">Escolha um nível</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Button
            disabled={disableAction(
              loggedInUser?.permission?.level,
              false,
              false,
              false
            )}
            className="ml-auto mr-auto"
            onClick={submit}
          >
            Salvar
          </Button>
        </div>
      </Collapse>
    </Form>
  );
}

export default AdminChangelevelPermission;
