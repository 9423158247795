function FAQItem({ faq, toggleActive }) {
    const handleClick = () => {
      toggleActive(faq.id);
    };
  
    return (
      <> 
      {faq.active &&
        <div className="s7-clicked" onClick={ handleClick }>
                <p className="s7-clicked-text">{faq.question}</p>
                <div className="clicked-icon"><i className="fa fa-halfx fa-angle-right"></i></div>
            </div>
      }
      {!faq.active &&
        <div className="s7-hold" onClick={ handleClick }>
                <p className="s7-hold-text">{faq.question}</p>
                <div className="hold-icon"><i className="fa fa-halfx fa-angle-down"></i></div>
            </div>
      }
    </>
    );
  }

export default FAQItem;

<div className="s7-clicked">
    
</div>