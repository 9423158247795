import React from "react";
import {
  Col,
  Row,
  Form,
  DropdownButton,
  Dropdown,
  Button,
  Table,
  Modal,
} from "react-bootstrap";
import {
  formatStatus,
  DEPOSITS_DESTINY_TYPES,
  DEPOSITS_TYPE_TYPES,
  currencyConfig,
  DEPOSITS_STATUS_TYPES,
  DEPOSITS_STATUS_TYPES_EN
} from "../utils/types";
import Panel from "../components/Panel";
import { toast } from "react-toastify";
import api from "../utils/api";
import IntlCurrencyInput from "react-intl-currency-input";
import { AppContext } from "../context/AppContext";
import dayjs from "dayjs";
import Pagination from "../components/Pagination";
import { numberFormatBRL } from "../components/InvestmentId";
import { withTranslation } from "react-i18next";
// import { numberFormatBRL, removeSymbol } from "../components/InvestmentId";
import { FaCopy } from "react-icons/fa";

class Deposits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      showModal: false,
      showModal2: false,
      showModal3: false,
      showModal4: false,
      showModal5: false,
      showModalDisclaimer: false,
      form: {
        amount: 0,
        destiny: DEPOSITS_DESTINY_TYPES[0].status,
        type: DEPOSITS_TYPE_TYPES[0].status,
      },
      offset: 0,

      depositsData: [],
      kycLimit: '0',
      kycLevel: 0,
    };
    this.inputDoc = React.createRef();
  }

  static contextType = AppContext;

  componentDidMount = () => {
    this.reloadTable();

    this.context.setPagination((pagination) => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });

    this.setKYCLimit();
    this.getKYCLevel();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.context.pagination.offset !== prevState.offset) {
      this.reloadTable();
    }
  }

  copyChavePix = () => {
    const chavePixCNPJ = "21.830.817/0001-67";
    navigator.clipboard.writeText(chavePixCNPJ).then(() => {
      toast.success(this.props.t("copychavepixsuccess")); 
    }).catch((error) => {
      toast.error(this.props.t("copychavepixfailed")); 
    });
  };

  reloadTable = async () => {
    this.setState({
      offset: this.context.pagination.offset,
      load: true,
    });

    let req = await api.get(
      `deposits-users?filter[where][currency]=BRL&filter[order]=created%20DESC&filter[skip]=${this.context.pagination.offset}&filter[limit]=${this.context.pagination.limit}`
    );

    if (!req.error) {
      let deposits = req.data;

      this.context.setPagination((pagination) => {
        return {
          ...pagination,
          limit: deposits.limit,
          lastPage: Math.ceil(deposits.total / deposits.limit),
        };
      });

      this.setState({
        load: false,
        depositsData: deposits.data,
      });
    }
  };

  getKYCLevel = () => {
    this.setState({ kycLevel: this.context.loggedInUser?.kycStatus });
  };

  setKYCLimit = () => {
    console.log('kycStatus', this.context.loggedInUser?.kycStatus)
    const kycStatus = this.context.loggedInUser?.kycStatus;
    switch (kycStatus) {
      case "0":
        this.setState({ kycLimit: '0' });
        break;
      case "1":
        this.setState({ kycLimit: '5000' });
        break;
      case "2":
        this.setState({ kycLimit: '5000' });
        break;
      case "3":
        this.setState({ kycLimit: this.props.t("flex") }); /* 'Flexível' */
        break;
      case "4":
        this.setState({ kycLimit: '0' });
        break;
      default:
        this.setState({ kycLimit: '0' });
        break;
    }
  };

  handleChangeCurrency = (event, value, maskedValue) => {
    let form = this.state.form;
    form[event.target.name] = value;
    this.setState({ form });
  };

  setType = async (name, type) => {
    let form = this.state.form;
    form[name] = type.status;
    this.setState({ form });
  };

  newDeposit = async (event) => {
    event.preventDefault();

    if (
      this.state.depositsData.length > 0 &&
      this.state.depositsData[0].status === "waiting"
    ) {
      this.setState({
        showModal2: true,
      });
    } else {
      this.newConfirmDeposit(event);
    }
  };

  newConfirmDeposit = async (event) => {
    event.preventDefault();
    let amount = parseFloat((this.state.form.amount));
    if (amount > 5000 && (this.state.kycLevel === 1 | this.state.kycLevel === 2)) {
      this.setState({ showModal3: true });
    } else if (amount < 50) {
      this.setState({ showModal5: true });
    } else {
      this.setState({ showModal2: false, showModalDisclaimer: true });
    }
  };

  onFileChange = async (event) => {
    let files = [];
    for (let i = 0; i < event.target.files.length; i++) {
      files.push(event.target.files[`${i}`]);
    }
    try {
      if (files.length > 0) {
        let req = await api.postFiles(
          `deposits-users/${this.state.depositsData[0].id}/files`,
          files
        );
        if (req.error) return;
        this.reloadTable();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleClose2 = () => {
    this.setState({ showModal2: false });
  };

  handleClose3 = () => {
    this.setState({ showModal3: false });
  };

  handleClose4 = () => {
    this.setState({ showModal4: false });
  };

  handleClose5 = () => {
    this.setState({ showModal5: false });
  };

  handleDisclaimer = async () => {
    let amount = parseFloat((this.state.form.amount));
    let today = new Date().toLocaleDateString();
    let depositsToday = 0;

    this.state.depositsData.forEach((row) => {
      if (
        new Date(row.created).toLocaleDateString() === today &&
        row.status !== "canceled" &&
        row.status !== "waiting"
      ) {
        depositsToday += row.amount;
      }
    });

    depositsToday += amount;

    if (depositsToday > 5000 && (this.state.kycLevel === 1 | this.state.kycLevel === 2)) {
      this.setState({ showModal4: true });
    } else {
      let form = this.state.form;
      let req = await api.post(`deposits-users`, {
        amount: amount,
        destiny: this.state.form.destiny,
        type: this.state.form.type,
      });
      if (!req.error) {
        this.setState({
          showModal: true,
          showModal2: false,
          form,
        });
      }
      this.reloadTable();
    }

    this.setState({ showModalDisclaimer: false });
  };

  render() {
    const currentLanguage = this.props.i18n.language;
    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="deposits"
        title={this.props.t("deposits")} // "Depósitos"
      >
        <Row>
          <Col
            style={{
              minWidth: 300,
              maxWidth: 500,
            }}
            md
          >
            <div className="d-flex justify-content-around align-items-center">
              <h1 className="text2 bold text-large2 text-center mt-2 mb-3">
                {this.props.t("makedeposit")} {/* Efetuar Depósito */}
              </h1>
              {/* <div className="text-center mt-3 mb-3">
                <Button onClick={() => this.setState({ showModal: true })}>
                  Dados para depósito
                </Button>
              </div> */}
            </div>
            <Form onSubmit={this.newDeposit} className="card p-3 mb-4">
              <Col>
                {this.context.loggedInUser?.kycStatus === "1" ? (
                  <>
                    <h6 style={{ textAlign: 'center' }}>
                      {this.props.t("limitedtx")} {/* Transações limitadas a */}  {numberFormatBRL(50000.00)} {this.props.t("byyear")} {/* por ano. */}
                    </h6>
                    {/* <h6>
                      Transações limitadas a BTC {50000 / this.context.btcPrice} por ano
                    </h6>
                    <p>
                      Referência(BRL): R$ 50.000,00
                    </p> */}
                  </>
                ) : this.context.loggedInUser?.kycStatus === "2" ? (<>
                  <h6 style={{ textAlign: 'center' }}>
                    {this.props.t("txbetween")} {/*  Transações entre */}  {numberFormatBRL(50001.00)} {this.props.t("and")} {numberFormatBRL(99999.99)} {this.props.t("byyear")} {/* por ano. */}
                  </h6>
                  {/* <h6>
                    Transações entre BTC {50001 / this.context.btcPrice} e BTC {99999 / this.context.btcPrice} por ano
                  </h6> */}
                  {/* <p>
                    Referência(BRL): de R$ 50.001,00 a R$ 99.999,99
                  </p> */}
                </>
                ) : (
                  this.context.loggedInUser?.kycStatus === "3" && (
                    <>
                      <h6 style={{ textAlign: 'center' }}>
                        {this.props.t("txabove")} {/*  Transações acima de */}  {numberFormatBRL(100000)} {this.props.t("byyear")}
                      </h6>
                      {/* <h6>
                      Transações acima de BTC {100000 / this.context.btcPrice} por ano
                    </h6>
                    <p>
                      Referência(BRL): R$ 100.000,00
                    </p> */}
                    </>
                  )
                )}
                <br></br>
              </Col>

              <Row className="text-center mt-2 mb-2">
                <Col>
                  <h5>{this.props.t("dailylimit")} {/* Limite Diário */}  </h5>
                  {
                    (this.state.kycLimit === 'Flexível') ? (
                      <h5>{this.state.kycLimit}</h5>
                    ) : (
                      <h5>{numberFormatBRL(Number(this.state.kycLimit))}</h5>
                    )
                  }
                  {/* <h5>{ numberFormatBRL(5000) }</h5> */}
                  {/* <h5>BTC {5000 / this.context.btcPrice}</h5>
                  <p>Referência(BRL): R$ 5.000,00</p> */}
                </Col>
                <Col>
                  <h5>{this.props.t("minvalue")} {/* Valor Mínimo */} </h5>
                  <h5>{numberFormatBRL(50)}</h5>
                  {/* <h5>BTC {50 / this.context.btcPrice}</h5>
                  <p>Referência(BRL): R$ 50,00</p> */}
                </Col>
              </Row>
              <br></br>
              <Row className="text-center">
                <Col>
                  <h5>{this.props.t("estimatedptime")} {/*  Prazo: em até 03 dias úteis. */}</h5>
                  <hr />
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>{this.props.t("depositvaluein")} {/*  Valor do depósito */}</Form.Label>
                <Form.Control
                  as={IntlCurrencyInput}
                  currency="BRL"
                  config={currencyConfig}
                  className="input"
                  name="amount"
                  value={this.state.form.amount}
                  onChange={this.handleChangeCurrency}
                  required
                />
                {/* <div style={{ fontWeight :"400", fontStyle: "italic", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                  <span>Referência (BTC): </span>
                  <span>{this.state.form.amount > 0 ? (Number(this.state.form.amount / this.context.btcPrice)).toFixed(8) : 0} BTC</span>
                </div> */}
                {/* <div style={{ fontWeight :"400", fontStyle: "italic", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                  <span>Referência (BRL): </span>
                  <span>R$ {this.state.form.amount > 0 ? removeSymbol(numberFormatBRL(this.state.form.amount * this.context.btcPrice)) : 0}</span>
                </div> */}
              </Form.Group>
              {/* <Form.Group>
                <Form.Label>Destino</Form.Label>
                <DropdownButton
                  className="form-dropdown"
                  title={` ${formatStatus(
                    DEPOSITS_DESTINY_TYPES,
                    this.state.form.destiny
                  )}`}
                >
                  {DEPOSITS_DESTINY_TYPES.map((type) => (
                    <Dropdown.Item
                      onClick={() => this.setType("destiny", type)}
                      key={`destiny-${type.name}`}
                    >
                      {type.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group> */}
              {/* <Form.Group>
                <Form.Label>Tipo</Form.Label>
                <DropdownButton
                  className="form-dropdown"
                  title={` ${formatStatus(
                    DEPOSITS_TYPE_TYPES,
                    this.state.form.type
                  )}`}
                >
                  {DEPOSITS_TYPE_TYPES.map((type) => (
                    <Dropdown.Item
                      onClick={() => this.setType("type", type)}
                      key={`type-${type.name}`}
                    >
                      {type.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group> */}
              <div className="text-center text2 mt-3">
                <p>
                  <b>{this.props.t("cpfwarning")}</b> {/*                    Atenção! Os dados e CPF do titular da conta de Envio/Destino,
                  devem ser os mesmo desta conta BCCoin! */}
                </p>
              </div>
              <div className="text-center mt-3 mb-3">
                <Button type="submit">{this.props.t("confirm")}</Button>
              </div>
            </Form>
          </Col>
          <Col md>
            <div
              style={{
                width: "100%",
                backgroundColor: "#50504E",
              }}
            >
              <span className="bold text1 ml-3 text-mid2">{this.props.t("history")}</span>
            </div>
            <Table className="hist-table m-0 p-0" responsive="md" striped hover>
              <thead>
                <tr>
                  <th>{this.props.t("date")}</th>
                  <th>{this.props.t("value")} {/* Valor */}</th>
                  <th>{this.props.t("depositproof")} {/* Comprovante */}</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {this.state.depositsData.map((row, i) => (
                  <tr key={row.id}>
                    <td>{dayjs(row.created).format("DD/MM/YYYY")}</td>
                    <td>{numberFormatBRL(row.amount.toFixed(2))}</td>
                    <td>
                      {row.filesId === "" && row.status === "waiting" ? (
                        <>
                          <input
                            ref={this.inputDoc}
                            className="d-none"
                            type="file"
                            name="idFiles"
                            onChange={this.onFileChange}
                            multiple
                          />
                          <Button onClick={() => this.inputDoc.current.click()}>
                            {this.props.t("sendproof")} {/* Enviar Comprovante */}
                          </Button>
                        </>
                      ) : row.filesId === "" ? (
                        this.props.t("notsended") // "Não enviado"
                      ) : (
                        this.props.t("sendedm") // "Enviado"
                      )}
                    </td>
                    {currentLanguage == "en" ? (<td>{formatStatus(DEPOSITS_STATUS_TYPES_EN, row.status)}</td>) : (<td>{formatStatus(DEPOSITS_STATUS_TYPES, row.status)}</td>)}
                  </tr>
                ))}
              </tbody>
            </Table>

            {this.state.depositsData.length > 0 && (
              <Pagination
                pagination={this.context.pagination}
                setPagination={this.context.setPagination}
              />
            )}
          </Col>
        </Row>

        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
              Dados para Depósito
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-account">
            <h5 className="bold mt-2 mb-3">BC Coins Intermediadora LTDA</h5>
            <h6 className="bold mt-2">Banco Bradesco (n237)</h6>
            <h6 className="">Chave PIX/CNPJ: 21.830.817/0001-67 <Button variant="primary" size={'xs'} onClick={this.copyChavePix}><FaCopy className="ml-1" /></Button>
            </h6>
            <h6 className="">Agência: 0332</h6>
            <h6 className="">Conta Corrente 78700-0</h6>
          </Modal.Body>
          <Modal.Body>
            <p id="exp-timer-txt">
              {this.props.t("depositwarning")} {/* AVISO: Valores diferentes (maiores ou menores) do que o solicitado serão REJEITADOS pelo admin. */}
            </p>
            <p id="warning-black-regular">
              {this.props.t("depositrejectionwarning")} <span id="warning-black-bold">{this.props.t("extortionfee")}</span> {this.props.t("depositrejectionwarning2")} {/* Na rejeição será cobrada uma <span id="warning-black-bold">taxa de estorno</span> no valor de R$ 3,50 para Pessoa Física e R$ 15,00 para Pessoa Jurídica. */}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal2}
          onHide={this.handleClose2}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
              {this.props.t("attention")} {/* Atenção */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <h6 className=""> {this.props.t("depositopen")} {/* Já existe um depósito em aberto. */} </h6>
            <h6 className="">
              {this.props.t("depositopenwarning")} {/* Ao criar um novo depósito o depósito em aberto será
              automáticamente cancelado */}
            </h6>
            <h6 className="">{this.props.t("wishcontinue")} {/* Deseja continuar? */}  </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.newConfirmDeposit}>
              {this.props.t("continue")} {/* Continuar */}
            </Button>
            <Button variant="primary" onClick={this.handleClose2}>
              {this.props.t("cancel")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal3}
          onHide={this.handleClose3}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
              {this.props.t("attention")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <h6 className="">
              {this.props.t("notdepositabove")} {/* Não é possivel criar depósitos acima de */}{" "}
              <strong>R$ 5.000,00</strong>
            </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose3}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal4}
          onHide={this.handleClose4}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
              {this.props.t("attention")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <h6 className="">{this.props.t("dailylimitreached")} {/* Limite diário atingido */}</h6>
            <h6 className="">5.000,00</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose4}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal5}
          onHide={this.handleClose5}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
              {this.props.t("attention")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <h6 className="">
              {this.props.t("minimumdeposit")} {/* O valor mínimo de depósito é */} <strong>R$ 50,00</strong>
            </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose5}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModalDisclaimer}
          onHide={this.handleDisclaimer}
          size="md"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-left bold text2 text-large2">
              {this.props.t("attention")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <h6 className="">
              {this.props.t("confirmdepositvalue")} {/* Você confirma o depósito no valor de */}{" "}
              <strong>{numberFormatBRL(parseFloat(this.state.form.amount))}</strong>?
            </h6>
          </Modal.Body>
          <Modal.Footer className="d-flex">
            <Button variant="primary" onClick={this.handleDisclaimer}>
              {this.props.t("yes")}
            </Button>
            <Button
              variant="primary"
              onClick={() => this.setState({ showModalDisclaimer: false })}
            >
              {this.props.t("no")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Panel>
    );
  }
}

export default withTranslation()(Deposits);