import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Button, Col, Row, Form, Collapse } from "react-bootstrap";
import api from "../../utils/api";
import { disableAction } from "../../utils/helper";
import { toast } from "react-toastify";

const AdminChangePassword = ({ id }) => {
  const { loggedInUser } = useContext(AppContext);
  const [show, setShow] = useState(true);
  const [password, setPassword] = useState("");
  const [foundError, setFoundError] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    if (!password) {
      setFoundError(true);
      return false;
    }
    setFoundError(false);

    await api.post("users/changePassword", {
      usersId: id,
      password,
    });

    toast("Senha alterada com sucesso!");
    setPassword("");
  };

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <Form className="w-100 card p-3 mb-5" onSubmit={submit}>
      <div
        className="text-large bold mb-2 w-100 d-flex btn"
        onClick={toggleShow}
      >
        <span className="">Alterar senha</span>
        <i className="fas fa-caret-down text-large mr-3 ml-auto"></i>
      </div>
      <div
        style={{ width: "100%", height: 1, backgroundColor: "#50504E" }}
      ></div>
      <Collapse in={show}>
        <div>
          <Row className="mb-1 mt-3">
            <Form.Group as={Col}>
              <Form.Label>Alterar senha</Form.Label>
              <Form.Control
                isInvalid={foundError}
                className="input"
                as="input"
                type="password"
                name="password"
                onChange={(e) => handleChange(e)}
                value={password}
                placeholder="Digite uma nova senha"
                minLength={6}
              ></Form.Control>
            </Form.Group>
          </Row>
          <Button
            disabled={disableAction(
              loggedInUser?.permission?.level,
              false,
              false,
              false
            )}
            className="ml-auto mr-auto"
            type="submit"
          >
            Salvar
          </Button>
        </div>
      </Collapse>
    </Form>
  );
};

export default AdminChangePassword;
