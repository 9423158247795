import React from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import CurrenciesBar from "./../components/CurrenciesBar";
import CurrencyWidget from "./CurrenciesWidget";

export default class HeaderBar extends React.Component {
  render() {
    return (
      <Container className="header-bg p-0 pt-1" fluid>
        <Container>
          <Navbar
            className="p-0"
            collapseOnSelect
            expand="lg"
            bg="transparent"
            variant="dark"
          >
            <Navbar.Brand className="d-flex p-0">
              <LinkContainer to="/">
                <img
                  src="/images/bcx-logo-gold.svg"
                  width="100"
                  height="100"
                  className="d-inline-block align-top"
                  alt="BC Coins Logo"
                />
              </LinkContainer>
            </Navbar.Brand>
            {this.props.hide === undefined && (
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            )}
            {this.props.hide_buttons === true && <div id="currecies-card-login">
                <CurrenciesBar />
              </div>}
            {this.props.hide === undefined && (
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto"></Nav>
                <Nav>
                  <LinkContainer
                    className="btn-primary mr-3 mb-1"
                    to="/panel/signin"
                  >
                    <Nav.Link>Entrar</Nav.Link>
                  </LinkContainer>
                  <LinkContainer
                    className="btn-primary mr-3 mb-1"
                    to="/panel/signup"
                  >
                    <Nav.Link>Registre-se</Nav.Link>
                  </LinkContainer>
                  <NavDropdown className="btn-primary mr-3 mb-1" title="PT">
                    <NavDropdown.Item>Português - PT</NavDropdown.Item>
                    <NavDropdown.Item>English - EN</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            )}
          </Navbar>
        </Container>
        <div
          style={{
            width: "100%",
            height: "3px",
            backgroundColor: "#CEBA85",
            marginTop: 5,
          }}
        >
        </div>
        <div style={{ width: "100%", height: "5px" }}></div>
      </Container>
    );
  }
}
