import React from "react";
import { Table } from "react-bootstrap";
import Panel from "../../components/Panel";
import api from "../../utils/api";
import { AppContext } from "../../context/AppContext";
import Pagination from "../../components/Pagination";
import dayjs from "dayjs";

export default class AdminHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
    };
  }

  static contextType = AppContext;

  componentDidMount() {
    this.reloadTable();

    this.context.setPagination((pagination) => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.context.pagination.offset !== prevState.offset) {
      this.reloadTable();
    }
  }

  reloadTable = async () => {
    this.setState({
      offset: this.context.pagination.offset,
      load: true,
    });

    const res = await api.get(
      `event-logs?filter[skip]=${this.context.pagination.offset}&filter[limit]=${this.context.pagination.limit}`
    );
    console.log(res.data)
    this.setState({ history: res.data.data });

    this.context.setPagination((pagination) => {
      return {
        ...pagination,
        limit: res.data.limit,
        lastPage: Math.ceil(res.data.total / res.data.limit),
      };
    });

    this.setState({
      load: false,
      history: res.data.data,
    });
  };

  renderTypes = (type) => {
    if (
      type == "user-signin" ||
      type == "user-signup" ||
      type == "user-confirm-email" ||
      type == "user-activation" ||
      type == "user-password-reset"
    ) {
      return "Autenticação";
    }

    if (type == "user-notifications") {
      return "Notificação";
    }

    if (
      type == "user-kyc" ||
      type == "admin-user-kyc" ||
      type == "user-confirm-phone"
    ) {
      return "KYC";
    }

    if (type == "user-withdraws" || type == "user-withdraw-trade") {
      return "Saque";
    }

    if (
      type == "user-deposit-balance" ||
      type == "admin-user-deposit-balance"
    ) {
      return "Depósito";
    }

    if (type == "user-trade") {
      return "Investimento";
    }


  };

  render() {
    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="employees"
        title="Histórico"
        admin={true}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#50504E",
          }}
        >
          <span className="bold text1 ml-3 text-mid2">Histórico</span>
        </div>
        <Table
          className="hist-table m-0 p-0"
          responsive="md"
          striped
          hover
        >
          <thead>
            <tr>
              <th>Data</th>
              <th>Usuário</th>
              <th>Admin</th>
              <th>Operação</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            {this.state.history.map((row) => {
              return (
                <tr key={row.id}>
                  <td>{row.created_at ? dayjs(row.created_at).format("DD/MM/YYYY HH:mm:ss") : '-'}</td>
                  <td>{row?.user_name}</td>
                  <td>{row?.admin_name ? row?.admin_name : "-"}</td>
                  <td>{row.description}</td>
                  <td>{this.renderTypes(row.type)}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        {this.state.history.length > 0 && (
          <Pagination
            pagination={this.context.pagination}
            setPagination={this.context.setPagination}
          />
        )}
      </Panel>
    );
  }
}
