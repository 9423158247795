import React from "react"
import { Container, Button, Form } from "react-bootstrap";
import HeaderBar from '../components/HeaderBar';
import BottomBar from '../components/BottomBar';
import api from "../utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            success: false,
            title: '',
            subTitle: '',
            showForm: true,
            firstTrySubmit: false,
            errors: {
                pass: '',
                confirmPass: '',
            },
            form: {
                pass: '',
                confirmPass: '',
            },
            validations: {
                pass: this.validatePass,
                confirmPass: this.validateConfirmPass,
            }
        }
    }

    validatePass = (value) => {
        if (/^.{6,100}$/.test(value)) {
            return '';
        } else {
            return this.props.t('invalidpass')  //'Senha inválida || Invalid Password'
        }
    }

    validateConfirmPass = (value) => {
        if (this.state.form.pass === value && value !== '') {
            return '';
        } else {
            return this.props.t('passnotmatch') // 'Senhas não coincidem || Passwords dont Match'
        }
    }

    onSubmit = async (event) => {
        event.preventDefault();
        let form = this.state.form
        let errors = this.state.errors
        let validations = this.state.validations
        let formParams = Object.keys(form);
        let foundError = false;
        formParams.forEach(key => {
            let error = validations[key](form[key]);
            errors[key] = error;
            if (error !== '') {
                foundError = true;
            }
        })
        if (!foundError) {
            let query = new URLSearchParams(this.props.location.search);
            let email = query.get('email');
            let code = query.get('code');
            let req = await api.post('password-reset', {
                sentTo: email,
                code: code,
                password: form.pass,
                locale: this.props.i18n.resolvedLanguage.toString()
            });
            if (!req.error) {
                this.setState({ showForm: false, success: true, title: this.props.t('success'), subTitle: this.props.t('successchangepass')}); // Senha alterada com sucesso.
            } else {
                this.setState({ showForm: false, success: false, title: this.props.t('timeexpired'), subTitle: this.props.t('failedchangepass')}); // 'Tempo expirado' 'Tente novamente. Volte para o site vá em recuperar senha.'
            }
        } else {
            this.setState({ errors, firstTrySubmit: true });
        }
    }

    signin = () => {
        this.props.navigate('/panel/signin');
    }

    recover = () => {
        this.props.navigate('/panel/recover');
    }

    handleChange = (event) => {
        let form = this.state.form
        let errors = this.state.errors
        let validations = this.state.validations
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        form[event.target.name] = value
        if (this.state.firstTrySubmit) {
            errors[event.target.name] = validations[event.target.name](value)
        }
        this.setState({ form, errors })
    }

    render() {
        return (<Container className="p-0 d-table" fluid>
            <HeaderBar hide />
            <Container style={{
                width: '100%',
                justifyContent: 'center',
                textAlign: 'center',
            }} className="d-table-row">
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    paddingTop: 30,
                    paddingBottom: 50,
                }}>
                    <div style={{
                        paddingTop: 'auto',
                        paddingBottom: 'auto',
                    }}>
                        {this.state.showForm === false && <>
                            <h1 className="mt-5">{this.state.title}</h1>
                            <h2 className="font-weight-light">{this.state.subTitle}</h2>
                            {this.state.success ?
                                <Button className="btn-primary mt-3" onClick={this.signin}>{this.props.t('enter')}</Button>
                                :
                                <Button className="btn-primary mt-3" onClick={this.recover}>{this.props.t('recoverpass')}</Button>
                            }
                        </>}
                        {this.state.showForm === true && <>
                            <h1 className="mt-3 mb-4">{this.props.t('newpassword')} {/* Crie uma nova senha */}</h1>
                            <Form className="" onSubmit={this.onSubmit}>
                                <Form.Group>
                                    <Form.Control
                                        className="input"
                                        name="pass"
                                        type="password"
                                        value={this.state.form.pass}
                                        onChange={this.handleChange} required
                                        isInvalid={this.state.errors.pass}
                                        placeholder={this.props.t('phnewpassword')}
                                    />
                                    <Form.Control.Feedback type="invalid">{this.state.errors.pass}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control
                                        className="input"
                                        name="confirmPass"
                                        type="password"
                                        value={this.state.form.confirmPass}
                                        onChange={this.handleChange} required
                                        isInvalid={this.state.errors.confirmPass}
                                        placeholder={this.props.t('confirmpass')}
                                    />
                                    <Form.Control.Feedback type="invalid">{this.state.errors.confirmPass}</Form.Control.Feedback>
                                </Form.Group>
                                <Button className="btn-primary mt-3" type="submit">{this.props.t('confirm')}</Button>
                            </Form>
                        </>}
                    </div>
                </div>
            </Container>
            <BottomBar />
        </Container>)
    }
}

export default withTranslation()(props => <ResetPassword {...props} location={useLocation()} navigate={useNavigate()}/>);