import React from "react"
import { Container, Button, Form } from "react-bootstrap";
import InputMask from "react-input-mask";
import HeaderBar from '../components/HeaderBar';
import BottomBar from '../components/BottomBar';
import api from "../utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class ConfirmCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            success: false,
            title: '',
            subTitle: '',
            showForm: false,
            form: {
                code: ''
            }
        }
    }

    componentDidMount = async () => {
        let query = new URLSearchParams(this.props.location.search);
        let email = query.get('email');
        let code = query.get('code');
        if (code) {
            let req = await api.post('confirm', {
                sentTo: email,
                code,
                locale: this.props.i18n.resolvedLanguage.toString()
            });
            if (req.error) {
                this.setState({ success: false, title: `${this.props.t("error")}`, subTitle: `${this.props.t("emailconffailed")}`}); // 'Não foi possível confirmar o email'
            } else {
                this.setState({ success: true, title: `${this.props.t("success")}`, subTitle: `${this.props.t("emailconfsuccess")}` });  // 'Email confirmado com sucesso.'
            }
        } else {
            this.setState({ showForm: true })
        }
    }

    confirmCode = async () => {
        let query = new URLSearchParams(this.props.location.search);
        let email = query.get('email');
        let req = await api.post('confirm', {
            sentTo: email,
            code: this.state.form.code,
            locale: this.props.i18n.resolvedLanguage.toString()
        });
        if (!req.error) {
            this.setState({ showForm: false, success: true, title: `${this.props.t("success")}`, subTitle: `${this.props.t("emailconfsuccess")}` });  // 'Email confirmado com sucesso.'
        }
    }

    signin = () => {
        this.props.navigate('/panel/signin');
    }

    handleChange = (event) => {
        let form = this.state.form
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        form[event.target.name] = value
        this.setState({ form })
    }

    render() {
        return (<Container className="p-0 d-table" fluid>
            <HeaderBar hide />
            <Container style={{
                width: '100%',
                justifyContent: 'center',
                textAlign: 'center',
            }} className="d-table-row">
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    paddingTop: 30,
                    paddingBottom: 50,
                }}>
                    <div style={{
                        paddingTop: 'auto',
                        paddingBottom: 'auto',
                    }}>
                        {this.state.showForm === false && <>
                            <h1 className="mt-5">{this.state.title}</h1>
                            <h2 className="font-weight-light">{this.state.subTitle}</h2>
                            <Button className="btn-primary mt-3" onClick={this.signin}>{this.props.t("enter")} {/* Entrar */}</Button>
                        </>}
                        {this.state.showForm === true && <>
                            <h1 className="mt-3">{this.props.t("confirmyemail")} {/* Confirme seu email */}</h1>
                            <h2 className="font-weight-light mb-3">{this.props.t("inputcodeemail")} {/* Digite o código enviado no seu email */} </h2>
                            <Form className="" onSubmit={this.sendCode}>
                                <Form.Group style={{ width: 120, marginRight: 'auto', marginLeft: 'auto' }}>
                                    <Form.Control
                                        as={InputMask}
                                        mask="999999"
                                        className="small text-center"
                                        name="code"
                                        type="numb"
                                        value={this.state.form.code}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                                <Button className="btn-primary mt-2" onClick={this.confirmCode}>{this.props.t("confirm")}</Button>
                            </Form>
                        </>}
                    </div>
                </div>
            </Container>
            <BottomBar />
        </Container>)
    }
}

export default withTranslation()(props => <ConfirmCode {...props} location={useLocation()} navigate={useNavigate()} />);