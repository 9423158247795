import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button, Form, Spinner } from "react-bootstrap";
import { get, set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Panel from "../../components/Panel";
import api, { microservice } from "../../utils/api";
import dayjs from "dayjs";
import { AppContext } from "../../context/AppContext";

const ServiceFees = () => {
  // buy - coin - service - fee

  // sell - coin - service - fee
  const buyCoinServiceFeeInput = useRef(null);
  const sellCoinServiceFeeInput = useRef(null);
  const slippageServiceFeeInput = useRef(null);

  const [loading, setLoading] = useState(false);
  const [buyOrderbook, setBuyOrderbook] = useState({
    value: `0`,
    category: `orderbook_buy`,
    currency: `%`,
  });
  const [sellOrderbook, setSellOrderbook] = useState({
    value: `0`,
    category: `orderbook_sell`,
    currency: `%`,
  });

  const [slippageOrderbook, setSlippageOrderbook] = useState({
    value: `0`,
    category: `orderbook_slippage`,
    currency: `%`,
  });

  const messageRequired = "Por favor, preencha este campo.";

  const { fees, setFees } =
  useContext(AppContext);

  const buySchema = yup.object().shape({
    buyCoinServiceFee: yup
      .string()
      .required(messageRequired)
      .test("minimum-amount", "O valor mínimo é de 0.1", (val) => val != 0)
      .test("maxLength", "O valor máximo é de 100%", (val) => val <= 100),
    sellCoinServiceFee: yup
      .string()
      .required(messageRequired)
      .test("minimum-amount", "O valor mínimo é de 0.1", (val) => val != 0)
      .test("maxLength", "O valor máximo é de 100%", (val) => val <= 100),
    slippageServiceFee: yup
      .string()
      .required(messageRequired)
      .test("minimum-amount", "O valor mínimo é de 0.1", (val) => val != 0)
      .test("maxLength", "O valor máximo é de 100%", (val) => val <= 100),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(buySchema),
  });

  const setFormattedValue = (refParam, amount) => {
    var value = amount.includes(",") ? amount.replace(",", ".") : amount;
    if (!value) {
      value = "";
    }
    if (!refParam?.current) {
      return amount;
    }
    var smallestUnit = "0.01";

    if (value.includes(".")) {
      var decimal = value.split(".")[1];
      value = value;
      if (decimal.length <= 2) {
        value =
          value.split(".")[0].slice(0, 3) +
          "." +
          value.split(".")[1].slice(0, 2);
        refParam.current.value = value;
        if (value.split(".")[1] === "00") {
          value = value.split(".")[0];
        }
        return value;
      } else {
        value =
          value.split(".")[0].slice(0, 3) +
          "." +
          value.split(".")[1].slice(0, 2);
        if (value === "0.00") {
          value = smallestUnit;
        }
        if (value.split(".")[1] === "00") {
          value = value.split(".")[0];
        }
        refParam.current.value = value;
        return value;
      }
    }

    if (value.length > 1) {
      if (value == 0 || value === "0") {
        refParam.current.value = smallestUnit;
        return smallestUnit;
      }
    }

    if (value.length >= 6) {
      value = value.slice(0, 6);
      refParam.current.value = value;
      return value;
    }

    if (value > 100) {
      value = "100.00";
      refParam.current.value = value;
      return value;
    }

    if (refParam.current) {
      refParam.current.value = value;
    }

    return value;
  };

  function verifyNull (fee, category, currency) {
    if(fee === null) {
      return {
        value: `0`,
        category: category,
        currency: currency,
      };
    } else {
      return fee;
    }
  }

  async function getFeeValues(reload) {
    setLoading(true);
    
    if(fees.length > 0 && reload === false) {
      console.log("get fee with fees length", fees);
      setValue("buyCoinServiceFee", fees[5]?.value);
      setBuyOrderbook(verifyNull(fees[5], 'orderbook_buy', '%'));
      setValue("sellCoinServiceFee", fees[6]?.value);
      setSellOrderbook(verifyNull(fees[6], 'orderbook_sell', '%'));
      setValue("slippageServiceFee", fees[8]?.value);
      setSlippageOrderbook(verifyNull(fees[8], 'orderbook_slippage', '%'));
      setLoading(false);
    } else {
      try {
        const response = await api.get("fees/serviceFees");
        const data = response.data;
        
        console.log("FEES OBJECT > ", data);
        
        setValue("buyCoinServiceFee", data[5].value);
        setBuyOrderbook(verifyNull(data[5], 'orderbook_buy', '%'));
        setValue("sellCoinServiceFee", data[6].value);
        setSellOrderbook(verifyNull(data[6], 'orderbook_sell', '%'));
        setValue("slippageServiceFee", data[8].value);
        setSlippageOrderbook(verifyNull(data[8], 'orderbook_slippage', '%'));
        setFees(data);
      } catch (error) {
        toast.error("Ocorreu um erro ao buscar as taxas.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  async function saveFee(data) {
    setLoading(true);
    try {
      const response = await api.post("fees", {
        value: `${data.buyCoinServiceFee}`,
        category: 'orderbook_buy',
        currency: `%`,
        // buyValue: data.buyCoinServiceFee,
        // sellValue: data.sellCoinServiceFee,
      });
      const response2 = await api.post("fees", {
        value: `${data.sellCoinServiceFee}`,
        category: 'orderbook_sell',
        currency: `${sellOrderbook.currency}`,
      });
      const response3 = await api.post("fees", {
        value: `${data.slippageServiceFee}`,
        category: 'orderbook_slippage',
        currency: `${slippageOrderbook.currency}`,
      });
      if (!response.error && !response2.error && !response3.error) {
        toast.success("Taxas salvas com sucesso!");
        getFeeValues(true);
      }
    } catch (error) {
      toast.error("Ocorreu um erro ao salvar as taxas.");
    } finally {
      setLoading(false);
    }
  }

  const { ref: buyCoinServiceFeeRef, ...theArgs } =
    register("buyCoinServiceFee");
  const { ref: sellCoinServiceFeeRef, ...theArgs2 } =
    register("sellCoinServiceFee");
  const { ref: slippageServiceFeeRef, ...theArgs3 } =
    register("slippageServiceFee");

  useEffect(() => {
    getFeeValues(false);
  }, []);

  const handleOptionChange = (event) => {
    const { value }  = event.target;
    const { name } = event.target;
    switch(name) {
      case 'buyOrderbook':
        setBuyOrderbook({ ...buyOrderbook, currency: '%' });
        break;
      case 'sellOrderbook':
        setSellOrderbook({ ...sellOrderbook, currency: '%' });
        break;
      case 'slippageOrderbook':
        setSlippageOrderbook({ ...slippageOrderbook, currency: '%' });
        break;
      default:
        break;
    }
  }

  return (
    <Form className="w-100" onSubmit={handleSubmit(saveFee)}>
      <h5 className="fees-title">Transações Orderbook</h5>
      <Form.Group>
        <Form.Label>
          Comprador (Taker)
        </Form.Label>
        <div className="d-flex align-items-center">
          <Form.Control
            className="input append"
            name="buyCoinServiceFee"
            type="number"
            {...register("buyCoinServiceFee", {
              setValueAs: (value) =>
                setFormattedValue(buyCoinServiceFeeInput, value),
            })}
            ref={(e) => {
              buyCoinServiceFeeRef(e);
              buyCoinServiceFeeInput.current = e;
            }}
            step="any"
          />

          <div className="input-group-append append-from-input">
            <span className="input-group-text input append-from-input">
              <select className="select-manage-fees" name="buyOrderbook" value={ buyOrderbook?.currency } onChange={handleOptionChange}>
                {/* <option value="BTC">BTC</option>
                <option value="BRL">BRL</option> */}
                <option value="%">%</option>
              </select>
            </span>
          </div>
        </div>
        {errors.buyCoinServiceFee && (
          <span className="invalid-feedback show">
            {errors.buyCoinServiceFee.message}
          </span>
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label>
          Vendedor (Maker)
        </Form.Label>
        <div className="d-flex align-items-center">
          <Form.Control
            className="input append"
            name="sellCoinServiceFee"
            type="number"
            {...register("sellCoinServiceFee", {
              setValueAs: (value) =>
                setFormattedValue(sellCoinServiceFeeInput, value),
            })}
            ref={(e) => {
              sellCoinServiceFeeRef(e);
              sellCoinServiceFeeInput.current = e;
            }}
            step="any"
          />

          <div className="input-group-append append-from-input">
            <span className="input-group-text input append-from-input">
              <select className="select-manage-fees" name="sellOrderbook" value={ sellOrderbook?.currency } onChange={handleOptionChange}>
                {/* <option value="BTC">BTC</option>
                <option value="BRL">BRL</option> */}
                <option value="%">%</option>
              </select>
            </span>
          </div>
        </div>
        {errors.sellCoinServiceFee && (
          <span className="invalid-feedback show">
            {errors.sellCoinServiceFee.message}
          </span>
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label>
          Margem (Tolerância)
        </Form.Label>
        <div className="d-flex align-items-center">
          <Form.Control
            className="input append"
            name="slippageServiceFee"
            type="number"
            {...register("slippageServiceFee", {
              setValueAs: (value) =>
                setFormattedValue(slippageServiceFeeInput, value),
            })}
            ref={(e) => {
              slippageServiceFeeRef(e);
              slippageServiceFeeInput.current = e;
            }}
            step="any"
          />

          <div className="input-group-append append-from-input">
            <span className="input-group-text input append-from-input">
              <select className="select-manage-fees" name="slippageOrderbook" value={ slippageOrderbook?.currency } onChange={handleOptionChange}>
                {/* <option value="BTC">BTC</option>
                <option value="BRL">BRL</option> */}
                <option value="%">%</option>
              </select>
            </span>
          </div>
        </div>
        {errors.slippageServiceFee && (
          <span className="invalid-feedback show">
            {errors.slippageServiceFee.message}
          </span>
        )}
      </Form.Group>

      {loading ? (
        <div className="d-flex justify-content-center w-100">
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : (
        <Button className="btn" type="submit" style={{ height: 35 }}>
          Salvar
        </Button>
      )}
    </Form>
  );
};

export default function AdminManageFees() {
  const [loading, setLoading] = useState(false);
  const [withdrawFee, setWithdrawFee] = useState({
    value: `0`,
    category: 'withdraw',
    currency: 'BRL',
  });
  const [sendBTC, setSendBTC] = useState({
    value: '0',
    category: 'send_btc',
    currency: 'BTC',
  });
  const [sendUSDT, setSendUSDT] = useState({
    value: '0',
    category: 'send_usdt',
    currency: 'USDT',
  });
  const [buyBTC, setBuyBTC] = useState({
    value: '0',
    category: 'buy_btc',
    currency: 'BTC',
  });
  const [depositFee, setdepositFee] = useState({
    value: '0',
    category: 'deposit',
    currency: 'BRL',
  });
  const [p2pFee, setp2pFee] = useState({
    value: '0',
    category: 'p2p',
    currency: 'BTC',
  });

  function verifyNull (fee, category, currency) {
    if(fee === null) {
      return {
        value: `0`,
        category: category,
        currency: currency,
      };
    } else {
      return fee;
    }
  }

  async function getFeeValues(reload) {
    setLoading(true);

    if(fees.length > 0 && reload === false) {
      setdepositFee(verifyNull(fees[0], 'deposit', 'BRL'));
        setWithdrawFee(verifyNull(fees[1], 'withdraw', 'BRL'));
        setBuyBTC(verifyNull(fees[2], 'buy_btc', 'BTC'));
        setSendBTC(verifyNull(fees[3], 'send_btc', 'BTC'));
        setSendUSDT(verifyNull(fees[4], 'send_usdt', 'USDT'));
        setp2pFee(verifyNull(fees[7], 'p2p', 'BTC'));

        console.log("fees > ", fees);

      setLoading(false);
    } else {
      try {
        const response = await api.get("fees/serviceFees");
        const data = response.data;
        setdepositFee(verifyNull(data[0], 'deposit', 'BRL'));
        setWithdrawFee(verifyNull(data[1], 'withdraw', 'BRL'));
        setBuyBTC(verifyNull(data[2], 'buy_btc', 'BTC'));
        setSendBTC(verifyNull(data[3], 'send_btc', 'BTC'));
        setSendUSDT(verifyNull(data[4], 'send_usdt', 'USDT'));
        setp2pFee(verifyNull(data[7], 'p2p', 'BTC'));

        console.log("data fees > ", data);

        setFees(data);
      } catch (error) {
        toast.error("Ocorreu um erro ao buscar as taxas.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    getFeeValues(false);
  }, []);

  const handleOptionChange = (event) => {
    const { value }  = event.target;
    const { name } = event.target;
    switch(name) {
      case 'withdrawFee':
        setWithdrawFee({ ...withdrawFee, currency: value });
        break;
      case 'sendBTC':
        setSendBTC({ ...sendBTC, currency: value });
        break;
      case 'sendUSDT':
        setSendUSDT({ ...sendUSDT, currency: value });
        break;
      case 'buyBTC':
        setBuyBTC({ ...buyBTC, currency: value });
        break;
      case 'depositFee':
        setdepositFee({ ...depositFee, currency: value });
        break;
      case 'p2pFee':
        setp2pFee({ ...p2pFee, currency: value });
        break;
      default:
        break;
    }
  }

  const { loggedInUser, fees, setFees } =
        useContext(AppContext);

  const handleWithdrawFee = (event) => {
    let { value } = event.target;

    const newValue = value.replace(/[^0-9.,]/g, "");

    const newValue2 = newValue.replace(/,/g, ".");

    const dotIndex = newValue2.indexOf(".");
    if (dotIndex !== -1) {
      const beforeDot = newValue2.slice(0, dotIndex);
      const afterDot = newValue2.slice(dotIndex + 1).replace(/\./g, "");
      const finalValue = `${beforeDot}.${afterDot}`;
      setWithdrawFee({ ...withdrawFee, value: finalValue });
    } else { 
      setWithdrawFee({ ...withdrawFee, value: newValue2 });
    }
  };

  const handleBuyBTCInput = (event) => {
    let { value } = event.target;

    const newValue = value.replace(/[^0-9.,]/g, "");

    const newValue2 = newValue.replace(/,/g, ".");

    const dotIndex = newValue2.indexOf(".");
    if (dotIndex !== -1) {
      const beforeDot = newValue2.slice(0, dotIndex);
      const afterDot = newValue2.slice(dotIndex + 1).replace(/\./g, "");
      const finalValue = `${beforeDot}.${afterDot}`;
      setBuyBTC({ ...buyBTC, value: finalValue });
    } else { 
      setBuyBTC({ ...buyBTC, value: newValue2 });
    }
  };

  const handleBTCInput = (event) => {
    let { value } = event.target;

    const newValue = value.replace(/[^0-9.,]/g, "");

    const newValue2 = newValue.replace(/,/g, ".");

    const dotIndex = newValue2.indexOf(".");
    if (dotIndex !== -1) {
      const beforeDot = newValue2.slice(0, dotIndex);
      const afterDot = newValue2.slice(dotIndex + 1).replace(/\./g, "");
      const finalValue = `${beforeDot}.${afterDot}`;
      setSendBTC({ ...sendBTC, value: finalValue });
    } else { 
      setSendBTC({ ...sendBTC, value: newValue2 });
    }
  };

  const handleUSDTInput = (event) => {
    let { value } = event.target;

    const newValue = value.replace(/[^0-9.,]/g, "");

    const newValue2 = newValue.replace(/,/g, ".");

    const dotIndex = newValue2.indexOf(".");

    if (dotIndex !== -1) {
      const beforeDot = newValue2.slice(0, dotIndex);
      const afterDot = newValue2.slice(dotIndex + 1).replace(/\./g, "");
      const finalValue = `${beforeDot}.${afterDot}`;
      setSendUSDT({ ...sendUSDT, value: finalValue });
    } else { 
      setSendUSDT({ ...sendUSDT, value: newValue2 });
    }
  };
  // const handleDepositInput = (event) => {
  //   setDepositFee({ ...depositFee, value: event.target.value });
  // };

  const handleP2PInput = (event) => {
    let { value } = event.target;

    const newValue = value.replace(/[^0-9.,]/g, "");

    const newValue2 = newValue.replace(/,/g, ".");

    const dotIndex = newValue2.indexOf(".");
    if (dotIndex !== -1) {
      const beforeDot = newValue2.slice(0, dotIndex);
      const afterDot = newValue2.slice(dotIndex + 1).replace(/\./g, "");
      const finalValue = `${beforeDot}.${afterDot}`;
      setp2pFee({ ...p2pFee, value: finalValue });
    } else { 
      setp2pFee({ ...p2pFee, value: newValue2 });
    }
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();
    const name = event.target.name;

    let submitObject = {};

    switch(name) {
      case 'withdrawFee':
        submitObject = {
          value: `${withdrawFee.value}`,
          category: `${withdrawFee.category}`,
          currency: `${withdrawFee.currency}`,
        }
        break;
      case 'sendBTC':
        submitObject = {
          value: `${sendBTC.value}`,
          category: `${sendBTC.category}`,
          currency: `${sendBTC.currency}`,
        }
        break;
      case 'sendUSDT':
        submitObject = {
          value: `${sendUSDT.value}`,
          category: `${sendUSDT.category}`,
          currency: `${sendUSDT.currency}`,
        }
        break;
      case 'buyBTC':
        submitObject = {
          value: `${buyBTC.value}`,
          category: `${buyBTC.category}`,
          currency: `${buyBTC.currency}`,
        }
        break;
      case 'depositFee':
        submitObject = {
          value: `${depositFee.value}`,
          category: `${depositFee.category}`,
          currency: `${depositFee.currency}`,
        }
        break;
      case 'p2pFee':
        submitObject = {
          value: `${p2pFee.value}`,
          category: `${p2pFee.category}`,
          currency: `${p2pFee.currency}`,
        }
        break;
      default:
        break;
    }

    api.post('fees', submitObject).then((response) => {
      if(!response.error) {
        toast.success('Taxa de saque salva com sucesso!');
        getFeeValues(true);
      } else {
        toast.error('Ocorreu um erro ao salvar a taxa de saque.');
      }
    });
  };

  return (
    <Panel page="manage-fees" title="Gerenciar Tarifas" admin={true}>
      <div className="fees-container">
        <div className="fees-card">
          <ServiceFees />
        </div>
        <div className="fees-card">
          <Form className="w-100" name="withdrawFee" onSubmit={ handleSubmitForm }>
            <h5 className="fees-title">
              Saques{" "}
              <span className="block" style={{ fontSize: "16px" }}>
                (conta corrente)
              </span>
            </h5>
            <Form.Group>
              <Form.Label>Tarifa operacional</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  className="input append"
                  name="withdrawFee"
                  type="string"
                  value={ withdrawFee?.value }
                  onChange={ handleWithdrawFee}
                />

                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                  <select className="select-manage-fees" name="withdrawFee" value={ withdrawFee?.currency } onChange={handleOptionChange}>
                    <option value="BRL">BRL</option>
                    <option value="%">%</option>
                  </select>
                  </span>
                </div>
              </div>
            </Form.Group>
            {loading ? (
            <div className="d-flex justify-content-center w-100">
              <Spinner animation="border" variant="secondary" />
            </div>
              ) : (
              <Button className="btn" style={{ height: 35 }} type="submit">
                Salvar
              </Button>
            )} 
          </Form>
        </div>
        <div className="fees-card">
          <Form className="w-100" name="sendBTC" onSubmit={ handleSubmitForm }>
            <h5 className="fees-title">
              Envio de BTC
            </h5>
            <Form.Group>
              <Form.Label>Taxa Envio de BTC</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  className="input append"
                  name="sendBTC"
                  type="text"
                  value={ sendBTC?.value }
                  onChange={ handleBTCInput }
                />
                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                  <select className="select-manage-fees" name="sendBTC" value={ sendBTC?.currency } onChange={handleOptionChange}>
                    <option value="BTC">BTC</option>
                    <option value="%">%</option>
                  </select>
                  </span>
                </div>
              </div>
            </Form.Group>
            {loading ? (
            <div className="d-flex justify-content-center w-100">
              <Spinner animation="border" variant="secondary" />
            </div>
              ) : (
              <Button className="btn" style={{ height: 35 }} type="submit">
                Salvar
              </Button>
            )}
          </Form>
        </div>
        <div className="fees-card">
          <Form className="w-100" name="sendUSDT" onSubmit={ handleSubmitForm }>
            <h5 className="fees-title">
              Envio de USDT
            </h5>
            <Form.Group>
              <Form.Label>Taxa Envio de USDT</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  className="input append"
                  name="sendUSDT"
                  type="text"
                  value={ sendUSDT?.value }
                  onChange={ handleUSDTInput }
                />
                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                  <select className="select-manage-fees" name="sendUSDT" value={ sendUSDT?.currency } onChange={handleOptionChange}>
                    <option value="USDT">USDT</option>
                    <option value="%">%</option>
                  </select>
                  </span>
                </div>
              </div>
            </Form.Group>
            {loading ? (
            <div className="d-flex justify-content-center w-100">
              <Spinner animation="border" variant="secondary" />
            </div>
              ) : (
              <Button className="btn" style={{ height: 35 }} type="submit">
                Salvar
              </Button>
            )}
          </Form>
        </div>
        <div className="fees-card">
          <Form className="w-100" name="buyBTC" onSubmit={ handleSubmitForm }>
            <h5 className="fees-title">
              Comprar BTC
            </h5>
            <Form.Group>
              <Form.Label>Taxa Compra de BTC</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  className="input append"
                  name="buyBTC"
                  type="text"
                  value={ buyBTC?.value }
                  onChange={ handleBuyBTCInput }
                />

                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                  <select className="select-manage-fees" name="buyBTC" value={ buyBTC?.currency } onChange={handleOptionChange}>
                    <option value="BRL">BRL</option>
                    <option value="%">%</option>
                  </select>
                  </span>
                </div>
              </div>
            </Form.Group>
            <Button className="btn" style={{ height: 35 }} type="submit">
              Salvar
            </Button>
          </Form>
        </div>
        <div className="fees-card">
          <Form className="w-100" name="p2pFee" onSubmit={ handleSubmitForm }>
            <h5 className="fees-title">
              P2P Garantido
            </h5>
            <Form.Group>
              <Form.Label>Taxa P2P</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  className="input append"
                  name="p2pFee"
                  type="text"
                  value={ p2pFee?.value }
                  onChange={ handleP2PInput }
                />

                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                  <select className="select-manage-fees" name="p2pFee" value={ p2pFee?.currency } onChange={handleOptionChange}>
                    <option value="BTC">BTC</option>
                    <option value="%">%</option>
                  </select>
                  </span>
                </div>
              </div>
            </Form.Group>
            <Button className="btn" style={{ height: 35 }} type="submit">
              Salvar
            </Button>
          </Form>
        </div>
        {/* <div className="fees-card">
          <Form className="w-100" onSubmit={ handleSubmitForm }>
            <h5 className="fees-title">
              Depósito
            </h5>
            <Form.Group>
              <Form.Label>Taxa de Depósito</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  className="input append"
                  name="depositFee"
                  type="text"
                  value={ depositFee?.value }
                  onChange={ handleDepositFee }
                />

                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                    <select className="select-manage-fees" name="depositFee" value={ depositFee?.currency } onChange={handleOptionChange}>
                      <option value="BRL">BRL</option>
                      <option value="%">%</option>
                    </select>
                  </span>
                </div>
              </div>
            </Form.Group>
            <Button className="btn" style={{ height: 35 }}>
              Salvar
            </Button>
          </Form>
        </div> */}
      </div>
    </Panel>
  );
}
