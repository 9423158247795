import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import ModalKYC from "../../components/Admin/KYC/ModalKYC";
import Panel from "../../components/Panel";

import api from "../../utils/api";

import { AppContext } from "../../context/AppContext";
import Pagination from "../../components/Pagination";

export default function AdminKYC() {

  const { setModalType, pagination, setPagination, modalKYC, setModalKYC } = useContext(AppContext);

  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [activeFileId, setActiveFileId] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  // const [modalKYC, setModalKYC] = useState(false);

  useEffect(() => {

    setPagination(() => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });

  }, []);

  useEffect(() => {
    reloadTable();
  }, [pagination.offset]);

  const reloadTable = async () => {
    setLoad(true);

    setTimeout(await api.get(
      `users-kyc?filter[order]=kycStatus%20DESC&filter[skip]=${pagination.offset}&filter[limit]=${pagination.limit}`
    ).then((req) => {
      if (!req.error) {

        let userskyc = req.data;

        setPagination((pagination) => {
          return {
            ...pagination,
            limit: userskyc.limit,
            lastPage: Math.ceil(userskyc.total / userskyc.limit),
          };
        });

        setData(userskyc.data);

        setLoad(false);
      }
    }), 5000);
  };

  const getUsersKyc = async () => {
    setLoad(true);

    const req = await api.get(
      `users-kyc?filter[order]=kycStatus%20DESC&filter[skip]=${pagination.offset}&filter[limit]=${pagination.limit}`
    );

    // console.log("USERSS: " , req);

    if (!req.error) {

      let kycData = req.data;

      setData(kycData.data);

      setPagination((pagination) => {
        return {
          ...pagination,
          limit: kycData.limit,
          lastPage: Math.ceil(kycData.total / kycData.limit),
        };
      });
    }

    setLoad(false);
    return req;
  };

  function listDocsByType(user) {
    if (user.legalPerson) {
      return [
        "id",
        "selfie",
        "address",
        "socialContract",
        "cnpjCard",
        "balanceSheet",
        "Apenas PF",
        "Apenas PF",
      ];
    }

    return [
      "id",
      "selfie",
      "address",
      "Apenas PJ",
      "Apenas PJ",
      "Apenas PJ",
      "incomeTax",
      "deliveryReceipt",
    ];
  }

  const modalProps = {
    // modalKYC,
    // setModalKYC,
    selectedUser,
    setSelectedUser,
    activeFileId,
    getUsersKyc,
    reloadTable,
    setData,
    data,
  };

  return (
    <Panel load={load} page="admin/kyc" title="KYC" admin={true}>
      <div
        style={{
          width: "100%",
          backgroundColor: "#50504E",
          textAlign: "center",
        }}
      >
        <span className="bold text1 text-mid2">Clientes</span>
      </div>

      <Table>
        <thead>
          <tr>
            <th>Cliente</th>
            <th>Identidade</th>
            <th>Selfie com identidade</th>
            <th>Comprovante de residência</th>
            <th>Contrato Social</th>
            <th>Cartão CNPJ</th>
            <th>Balanço Patrimonial</th>
            <th>Imposto de Renda</th>
            <th>Recibo de entrega</th>
            <th>Nível</th>
          </tr>
        </thead>
        <tbody>
          {data.map((user) => {
              const kycLevelStyle = user.kycStatus === "1" || user.kycStatus === "0" ? { backgroundColor: "#b1acac",color: "white" } : {}; // Add class for KYC level 1
              const showModalKYCById = (desc) => {
                setModalKYC(!modalKYC);
                setActiveFileId(filterKycByDesc(desc).id);
                setSelectedUser(user);
                setModalType(desc);
              };

              const filterKycByDesc = (desc) =>
                user.kycFiles?.filter((el) => el.desc === desc).length > 0
                  ? user.kycFiles.filter((el) => el.desc === desc)[0]
                  : null;

              return (
                <tr key={user.id} style={kycLevelStyle}>

                  <td>{user.name}</td>
                  {listDocsByType(user).map((desc, i) => (
                    <td key={i}>
                      {filterKycByDesc(desc) ? (
                        <Button
                          onClick={() => {
                            showModalKYCById(desc);
                          }}
                        >
                          Ver
                        </Button>
                      ) : desc?.includes("Apenas") ? (
                        desc
                      ) : (
                        "Não enviado"
                      )}
                    </td>
                  ))}
                  <td>{user.kycStatus === "none" ? 0 : user.kycStatus}</td>
                </tr>
              );
            })}
        </tbody>

      </Table>

      {data.length > 0 && (
        <Pagination pagination={pagination} setPagination={setPagination} />
      )}

      <ModalKYC {...modalProps} />
    </Panel>
  );
}
