import React from "react"
import { Container } from "react-bootstrap";
import HeaderBar from '../components/HeaderBar';
import BottomBar from '../components/BottomBar';

export default class NotFound extends React.Component {

    render() {
        return (<Container className="p-0 d-table" fluid>
            <HeaderBar hide />
            <Container style={{
                width: '100%',
                justifyContent: 'center',
                textAlign: 'center',
            }} className="d-table-row">
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    paddingTop: 30,
                    paddingBottom: 50,
                }}>
                    <div style={{
                        paddingTop: 'auto',
                        paddingBottom: 'auto',
                    }}>
                        <h1 className="mt-5">Error 404</h1>
                        <h2 className="font-weight-light">Page Not Found</h2>
                    </div>
                </div>
            </Container>
            <BottomBar />
        </Container>)
    }
}
