 import React from "react";
import { Container, Image, Col, Row, Collapse, Button } from "react-bootstrap";
import { Drawer } from "react-bootstrap-drawer";
import HeaderBarIn from "./HeaderBarIn";
import SideBar from "./SideBar";
import SideBarAdmin from "./SideBarAdmin";
import Loading from "./Loading";
import BottomBar from "./BottomBar";
import { useNavigate } from "react-router-dom";

class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNavigation: true,
    };
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions);
  };

  updateWindowDimensions = () => {
    if (window.innerWidth < 768) {
      this.setState({ openNavigation: false });
    } else if (window.innerWidth > 768) {
      this.setState({ openNavigation: true });
    }
  };

  toggleNavigation = () => {
    if (window.innerWidth >= 768) {
      this.setState({ openNavigation: !this.state.openNavigation });
    }
  };

  goTo = (page) => {
    this.props.navigate(page);
  };

  render() {
    return (
      <>
        <Container
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            overflow: 'hidden'
          }}
          fluid
        >
          <HeaderBarIn hide={this.props.hide} admin={this.props.admin} />
          <Container>
            <Row className="flex-md-nowrap">
              {!this.props.hideBar && (
                <Drawer>
                  <Collapse
                    id="sidebar"
                    dimension="width"
                    in={this.state.openNavigation}
                  >
                    <Drawer.Overflow>
                      <Drawer.ToC>
                        {this.props.admin !== true &&
                          this.props.hide === undefined && (
                            <SideBar
                              history={this.props.history}
                              page={this.props.page}
                            />
                          )}
                        {this.props.admin === true && (
                          <SideBarAdmin
                            history={this.props.history}
                            page={this.props.page}
                          />
                        )}
                        {this.props.admin !== true && this.props.hide && (
                          <div style={{ width: 200 }}></div>
                        )}
                      </Drawer.ToC>
                    </Drawer.Overflow>
                  </Collapse>
                </Drawer>
              )}
              <Col>
                <div
                  style={{
                    marginTop: 40,
                    marginBottom: 11,
                    paddingLeft: 0,
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={this.toggleNavigation}
                >
                  <Image
                    style={{
                      width: 30,
                      cursor: "pointer",
                    }}
                    src="/images/bracket.svg"
                  />
                  <span className="text-large2 bold text2">
                    {this.props.title}
                  </span>
                </div>
                <div
                  style={{
                    width: "800%",
                    height: 1.5,
                    backgroundColor: "#CEBA85",
                    marginBottom: 5,
                    marginTop: 11,
                    position: "relative",
                    right: "300%",
                  }}
                >
                </div>
                <div
                  style={{
                    width: "100%",
                    height: window.innerHeight,
                    overflowY: "auto",
                    paddingRight: 30,
                    marginBottom: 10,
                    paddingLeft: 15,
                  }}
                >
                  {!this.props.load && this.props.children}
                </div>
              </Col>
            </Row>
          </Container>
          <BottomBar />
        </Container>
        <Loading show={this.props.load} />
      </>
    );
  }
}

export default (props) => <Panel {...props} navigate={useNavigate()} />;
