import React from "react";
import { Form, Button, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import Panel from "../components/Panel";
import { formatStatus, SUPPORT_SUBJECT_TYPES, SUPPORT_THEME_TYPES, SUPPORT_SUBJECT_TYPES_EN, SUPPORT_THEME_TYPES_EN } from '../utils/types';
import api from "../utils/api";
import { withTranslation } from 'react-i18next';

class Suport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      showModal: false,
      form: {
        name: "",
        email: "",
        theme: SUPPORT_THEME_TYPES[0].status,
        subject: SUPPORT_SUBJECT_TYPES[0].status,
        text: "",
      },
    };
  }

  componentDidMount = () => {
    api.get("profile").then((req) => {
      let form = this.state.form;
      form.name = req.data.name;
      form.email = req.data.email;
      this.setState({
        load: false,
        form,
      });
    });
  };

  handleChange = (event) => {
    let form = this.state.form;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    form[event.target.name] = value;
    this.setState({ form });
  };

  setType = async (name, type) => {
    let form = this.state.form;
    form[name] = type.status;
    this.setState({ form });
  };

  sendEmail = async (event) => {
    event.preventDefault();
    let form = this.state.form;
    let req = await api.post(`support/ticket`, form);
    if (!req.error) {
      form.text = "";
      this.setState({
        showModal: true,
        form,
      });
    }
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  render() {
    const currentLanguage = this.props.i18n.language;
    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="support"
        title="Suporte"
      >
        <Form
          onSubmit={this.sendEmail}
          style={{
            maxWidth: 250,
            margin: 'auto'
          }}
        >
          <h1 className="text2 bold text-large2 text-center mt-2 mb-3">
            Ticket
          </h1>
          <Form.Group>
            <Form.Label>{this.props.t("name")}</Form.Label>
            <Form.Control
              className="input"
              name="name"
              type="text"
              value={this.state.form.name}
              onChange={this.handleChange}
              disabled
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              className="input"
              name="email"
              type="text"
              value={this.state.form.email}
              onChange={this.handleChange}
              disabled
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t("theme")} </Form.Label>
            {currentLanguage == "en" ? (<DropdownButton className="form-dropdown" title={` ${formatStatus(SUPPORT_THEME_TYPES_EN, this.state.form.theme)}`}>
                        {SUPPORT_THEME_TYPES_EN.map(type =>
                            <Dropdown.Item onClick={() => this.setType('theme', type)} key={`theme-${type.name}`}>
                                {type.name}
                            </Dropdown.Item>)}
                    </DropdownButton>) : (<DropdownButton className="form-dropdown" title={` ${formatStatus(SUPPORT_THEME_TYPES, this.state.form.theme)}`}>
                        {SUPPORT_THEME_TYPES.map(type =>
                            <Dropdown.Item onClick={() => this.setType('theme', type)} key={`theme-${type.name}`}>
                                {type.name}
                            </Dropdown.Item>)}
                    </DropdownButton>)}
          </Form.Group>
          <Form.Group>
          <Form.Label>{this.props.t("subject")} {/*Assunto*/}</Form.Label>
                    {currentLanguage == "en" ? (<DropdownButton className="form-dropdown" title={` ${formatStatus(SUPPORT_SUBJECT_TYPES_EN, this.state.form.subject)}`}>
                        {SUPPORT_SUBJECT_TYPES_EN.map(type =>
                            <Dropdown.Item onClick={() => this.setType('subject', type)} key={`subject-${type.name}`}>
                                {type.name}
                            </Dropdown.Item>)}
                    </DropdownButton>) : (<DropdownButton className="form-dropdown" title={` ${formatStatus(SUPPORT_SUBJECT_TYPES, this.state.form.subject)}`}>
                        {SUPPORT_SUBJECT_TYPES.map(type =>
                            <Dropdown.Item onClick={() => this.setType('subject', type)} key={`subject-${type.name}`}>
                                {type.name}
                            </Dropdown.Item>)}
                    </DropdownButton>)}
          </Form.Group>
          <Form.Group>
          <Form.Label>{this.props.t("description")} {/*Descrição*/}</Form.Label>
                    <Form.Control
                        as="textarea"
                        className="input"
                        name="text"
                        style={{ height: '100px' }}
                        value={this.state.form.text}
                        onChange={this.handleChange}
                        required
                    />
          </Form.Group>
          <div className="text-center mt-3 mb-5">
          <Button type="submit">{this.props.t("open")} {/*Abrir*/} ticket</Button>
          </div>
        </Form>
        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          size="lg"
          centered
        >
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 ml-3 text-center">{this.props.t("emailsendsuccess")} {/*Email enviado com sucesso*/}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-100 text-center text-large">
                    <p>{this.props.t("sooncontact")} {/*Em breve entraremos em contato*/}</p>
                </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </Panel>
    );
  }
}

export default withTranslation()(Suport);