import React from "react";
import Panel from "../../components/Panel";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation, Trans } from 'react-i18next';
import '../../i18n'

export default function PolicyKYC() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  return (
    <Panel page="politicakyc" title={t('kycpolicy')}>
      <div className="legal-back-btn">
        <Link to="/panel/kyc" className="legal-link">
          <Button>{t('back')}</Button>
        </Link>
      </div>

      {currentLanguage === 'en' ? (
        <div className="legal-div">
          <h4 className="legal-title">
            KNOW YOUR CLIENT ("KYC") POLICY - ANTI-MONEY LAUNDERING AND COMBATING THE FINANCING OF TERRORISM
          </h4>

          <br></br>
          <div>
            <h5 className="legal-subtitle">1. Objective</h5>
            <p className="legal-text">
              1.1. Always prioritizing client safety and the reliability of operations, as well as compliance with applicable regulations, BC COINS has implemented this Policy. Its objective is to establish guidelines and measures for Anti-Money Laundering and Combating the Financing of Terrorism ("AML/CFT") and other crimes against the National Financial System.
            </p>

            <h5 className="legal-subtitle">2. Reference Legislation</h5>
            <p className="legal-text">
              <ul>
                <li>
                  Law 9.613 of March 3, 1998: defines the crime of money laundering or concealment of assets, rights, and values, and establishes measures that increase the responsibility of entities within the financial system; and its amendment;
                </li>
                <li>
                  BACEN Circular No. 3.978/20 - Provides for the procedures to be adopted in the prevention and combat of activities related to crimes provided for in Law No. 9.613/98;
                </li>
                <li>
                  Regulations issued by COAF – Financial Activities Control Board.
                </li>
                <li>
                  Circular No. 3,461, issued on July 24, 2009: consolidates the rules on procedures to be adopted in the prevention and combat of activities related to crimes provided for in Law No. 9.613/1998;
                </li>
                <li>
                  Integrity Manuals of the Comptroller General of the Union ("CGU");
                </li>
                <li>
                  BC Circular Letter No. 4,001 issued on January 29, 2020: discloses a list of operations and situations that may indicate the occurrence of "money laundering" or concealment of assets, rights, and values, as per Law No. 9.613, of March 3, 1998, and financing of terrorism, as per Law No. 13,260, of March 16, 2016, subject to communication to the Financial Activities Control Board (Coaf).
                </li>
                <li>
                  Law 12,846 of August 1, 2013: establishes the administrative and civil liability of legal entities for acts against public administration, national or foreign, and provides other measures;
                </li>
                <li>
                  Decree No. 8,420, of March 18, 2015: provides for the administrative liability of legal entities for acts against public administration, national or foreign.
                </li>
                <li>
                  Resolution No. 4,595, of August 28, 2017, from BACEN: Provides for the compliance policy of financial institutions and other institutions authorized to operate by the Central Bank of Brazil.
                </li>
                <li>
                  FATF Recommendations: International standards for combating money laundering and the financing of terrorism and proliferation;
                </li>
                <li>
                  Law 14,478 of December 21, 2022: Provides guidelines to be observed in the provision of virtual asset services and in the regulation of virtual asset service providers.
                </li>
              </ul>
            </p>

            <h5 className="legal-subtitle">3. Responsibilities</h5>
            <p className="legal-text">
              3.1. The Compliance department is responsible for:
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  a) Disseminating, supervising, training, and enforcing internal rules and procedures;
                </li>
                <li>
                  b) Compliance with the provisions of this document, such as: customer analysis through document verification, origin of funds, monitoring of transactions, and sending information about suspicious transactions to its regulated partners for formal communication to COAF;
                </li>
                <li>
                  c) Mediating the relationship between the User and its regulated Partners and addressing requests for clarification and the submission of information and documents, taking necessary measures to block and suspend service provision when recommended by the Partners;
                </li>
                <li>
                  d) Defining measures to mitigate operational, regulatory, reputational, and legal risks of its operation;
                </li>
                <li>
                  e) Transmitting the culture of combating crimes, money laundering, and financing of terrorism to everyone;
                </li>
                <li>
                  f) Adequately implementing segregation of functions to avoid conflicts of interest.
                </li>
              </ul>
            </p>

            <h5 className="legal-subtitle">4. Duration</h5>
            <p className="legal-text">
              4.1. The Policy will come into effect on the date of its approval and will remain in effect indefinitely.
            </p>
            <p className="legal-text">
              4.2. The Policy applies to all Users who register with BC COINS and use its services.
            </p>
            <p className="legal-text">
              4.3. Any changes or revisions to this Policy must be submitted for approval by the Compliance Committee and communicated to the concerned parties.
            </p>

            <h5 className="legal-subtitle">5. Procedures</h5>
            <p className="legal-text">
              5.1. BC COINS conducts procedures for crime prevention on its platform globally, such as money laundering and financing of terrorism, adopting procedures known as KYC ("Know Your Customer") and AML ("Anti-Money Laundering"), and, in Brazil, adopts the procedures detailed below.
            </p>

            <h5 className="legal-subtitle">
              6. KYC - Know Your Customer - REGISTRATION
            </h5>
            <p className="legal-text">
              6.1. To access the services described in the Terms of Use, Users must first complete their Registration, providing personal information and supplying documents for their correct identification, known as Know Your Client (KYC).
            </p>
            <p className="legal-text">
              6.2. Users must provide truthful and up-to-date information, as clearly and objectively as possible, and are fully responsible for such information. However, BC COINS reserves the right, whenever applicable, to request additional information and documents for Registration analysis, check this information through queries, as well as refuse Registration and transactions.
            </p>
            <p className="legal-text">
              6.3. The requested documents, for greater security in this process, are as follows:
            </p>

            <p className="legal-text">
              <strong>KYC LEVEL 1: Transactions limited to R$ 50,000.00 per year:</strong>
            </p>
            <p className="legal-text">
              <strong>
                a) Individual:
              </strong>
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  (i) User must fill out the registration form on the Platform with the following information: Full name, email, address, and identification document number.
                </li>
              </ul>
            </p>
            <p className="legal-text">
              <strong>
                b) Legal Entity:
              </strong>
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  (i) Company's social contract signed by all partners involved with the entity.
                </li>
                <li>
                  (ii) CNPJ card (To obtain the CNPJ card, access the website of the Brazilian Federal Revenue and in "Issuance of Certificate of Registration and Situational Status," enter the CNPJ. We emphasize that it will only be possible to offer a legal account if the status is "active";
                </li>
                <li>
                  (iii) Documentation from item a), (i) of all partners of the entity, as per the social contract.
                </li>
              </ul>
            </p>

            <p className="legal-text">
              <strong>KYC LEVEL 2 - Transactions between R$ 50,001.00 and R$ 99,999.99 per year:</strong>
            </p>
            <p className="legal-text">
              <strong>
                a) Individual:
              </strong>
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  (i) Same as item a), (i) of KYC LEVEL 1 above; and,
                </li>
                <li>
                  (ii) User must send a "selfie" photo holding the personal document and send a copy of the personal document - for registration purposes only RG, CNH, and/or RNE, containing a photo and the CPF/ID of the holder. Note that the document must be valid, according to current legislation.
                </li>
                <li>
                  (iii) User must send a photo of the residence proof in their name or in the name of first-degree relatives, issued in the last 3 months. If the document is in the name of a third party, family ties must be proven (marriage certificate, birth, notarized lease agreement, also issued within 3 months).
                </li>
              </ul>
            </p>
            <p className="legal-text">
              <strong>
                b) Legal Entity:
              </strong>
            </p>
            <p className="legal-text">
              Same as item b), (i), (ii), and (iii) of KYC LEVEL 1 above.
            </p>

            <p className="legal-text">
              <strong>KYC LEVEL 3: Transactions above R$ 100,000.00 per year:</strong>
            </p>
            <p className="legal-text">
              <strong>
                a) Individual:
              </strong>
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  (i) Same as items a), (i) of KYC LEVEL 1 and a), (ii) and (iii) of KYC LEVEL 2; and
                </li>
                <li>
                  (ii) For proof of income for individuals, the delivery of the Income Tax Return + Delivery Receipt is mandatory.
                </li>
                <li>
                  (iii) Additionally, BC COINS may also request: Pay stubs, Purchase and Sale Contracts, Inventory, Bank statements, among other documents that support the investor's financial capacity and the origin of funds invested in the platform.
                </li>
              </ul>
            </p>
            <p className="legal-text">
              <strong>
                b) Legal Entity:
              </strong>
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  (i) Same as item b), (i), (ii), and (iii) of KYC LEVEL 1 above and b) of KYC LEVEL 2.
                </li>
                <li>
                  (ii) For legal entities, the complete Balance Sheet must be submitted. Additionally, additional documents may be requested: revenue reports, bank and investment statements, Corporate Income Tax Return (IRPJ).
                </li>
                <li>
                  (iii) For companies dealing with cryptocurrencies, the specific KYC and AML questionnaire must be presented.
                </li>
              </ul>
            </p>

            <p className="legal-text">
              6.3.1. We suggest that, before sending, you assess whether the attached documents are legible, within validity, unprotected by a password for viewing, and meeting the registration instructions.
            </p>
            <p className="legal-text">
              6.3.2. If the documents do not meet the specifications described above, it is the User's responsibility to upload the files again.
            </p>
            <p className="legal-text">
              6.4. User registration information must be updated annually and whenever there are any changes.
            </p>
            <p className="legal-text">
              6.5. High Sensitivity Users: Considering the main guidelines and rules in the financial market, as well as the analysis of the main cases of money laundering, certain user profiles more sensitive to involvement in this type of crime can be related. These will be internally classified by BC COINS as High Sensitivity.
            </p>
            <p className="legal-text">
              6.6. Politically Exposed Persons: Politically exposed persons are those who have or have had, in the last 5 (five) years, relevant public positions, jobs, or functions, in Brazil or in other countries, territories, and foreign dependencies, as well as their representatives, family members, and other persons in their close relationship.
            </p>
            <p className="legal-text">
              6.6.1. In addition, examples of situations that characterize a close relationship and lead to the user being permanently classified as a politically exposed person include:
            </p>
            <p className="legal-text">
              • Appointment of a politically exposed person as a proxy or representative;
            </p>
            <p className="legal-text">
              • Direct or indirect control of a legal entity by a politically exposed person;
            </p>
            <p className="legal-text">
              6.6.2. All BC COINS employees will pay special attention to users classified as politically exposed and of High Sensitivity.
            </p>
            <p className="legal-text">
              6.6.3. All BC COINS Users must self-declare, if applicable, as politically exposed persons at the time of Registration. In addition, BC COINS may contract diligence systems (including Background Check) to compare its User base with a list of politically exposed persons. If a User is identified as politically exposed, even if not self-declared, they will be considered as such in money laundering evidence analysis.
            </p>
            <p className="legal-text">
              6.6.4. All politically exposed users are defined by the system as high-risk for involvement in crimes associated with money laundering and will have their personal information analyzed for approval or refusal of registration and/or operation.
            </p>
            <p className="legal-text">
              6.6.5. The Compliance department analyzes the occurrence data and may request clarification or additional documents from the User, at its sole discretion. If the evidence is consistent, BC COINS may refuse the user and/or the transaction and communicate it to regulatory bodies.
            </p>


            <h5 className="legal-subtitle">
              7. AML - Anti-Money Laundering Procedures
            </h5>
            <p className="legal-text">
              7.1. In KYC Level 3 transactions and transactions carried out by Politically Exposed Persons, regardless of the amount, the origin of the funds used in the account will be analyzed by Users for the purpose of preventing money laundering, and due diligence may be carried out through systems contracted for Background Check.
            </p>
            <p className="legal-text">
              7.2. BC COINS reserves the right to refuse the user's transaction if it is considered suspicious, as well as to inform the relevant authorities.
            </p>

            <h5 className="legal-subtitle">8. Fund Transfer</h5>
            <p className="legal-text">
              8.1. After attaching the documents to our platform, the data for the bank transfer will be available. Only TED or PIX bank transfers are accepted. Cash deposits directly at the counter are not accepted.
            </p>
            <p className="legal-text">
              8.2. Transfers must be made from the user's own checking account. Transfers from third-party bank accounts will not be accepted.
            </p>
            <p className="legal-text">
              8.2.1. Transfers made by third parties, in cash or above daily/annual limits, will be reversed to the sender, and a return fee of R$ 20.00 will be charged.
            </p>

            <h5 className="legal-subtitle">9. Segregation of Duties</h5>
            <p className="legal-text">
              9.1. BC COINS has implemented controls to monitor the execution of activities to ensure information security and prevent fraud and errors. Thus, BC COINS adopts a policy of segregation of duties, which stipulates that each operational activity is carried out by one or more employees, each of whom is separately responsible for the execution and approval/authorization of the procedure.
            </p>
            <p className="legal-text">
              9.2. The respective policy of segregation of duties minimizes the operational risk to which BC COINS is exposed, as it does not allow relationships based solely on trust or driven by self-interest, and it inhibits procedures from being performed without proper review.
            </p>
            <p className="legal-text">
              9.3. It is worth noting that for the correct application of the segregation of duties policy, all Employees have their physical and logical accesses restricted to the functions and activities performed.
            </p>

            <h5 className="legal-subtitle">
              10. Risk Analysis and Monitoring and Effectiveness Assessment
            </h5>
            <p className="legal-text">
              10.1. BC COINS conducts risk analysis of Users and keeps records of their respective operations to mitigate threats or actions that may impact its operations and the security of the market and the chain responsible for providing services. It monitors User behavior and provides information requested by competent authorities in investigation processes, and may send transaction history records for monitoring and communication to official bodies.
            </p>

            <h5 className="legal-subtitle">11. Training</h5>
            <p className="legal-text">
              11.1. All employees are periodically trained on this Policy to review its concepts and encourage its adoption, aiming to fulfill the Policy's objective.
            </p>
            <p className="legal-text">
              11.2. Annually, Compliance reviews the materials and promotes recycling programs.
            </p>

            <h5 className="legal-subtitle">12. Amendment</h5>
            <p className="legal-text">
              12.1. This Policy may be changed and updated at any time by BC COINS Compliance, with the importance of disseminating and training its content throughout the company.
            </p>

            <h5 className="legal-subtitle">
              LAST UPDATED: 02/08/2023
            </h5>
          </div>
        </div>
      ) : (
        <div className="legal-div">
          <h4 className="legal-title">
            POLÍTICA DE CONHEÇA O SEU CLIENTE (“KNOW YOUR CLIENTE -KYC”) PREVENÇÃO À LAVAGEM DE DINHEIRO E FINANCIAMENTO À TERRORISMO
          </h4>

          <br></br>
          <div>
            <h5 className="legal-subtitle">1. Objetivo</h5>
            <p className="legal-text">
              1.1. Por prezar sempre pela segurança do cliente e confiabilidade das suas operações, bem como para o cumprimento da regulamentação aplicável, a BC COINS implantou essa Política, que tem como objetivo, definir diretrizes e medidas de Prevenção à Lavagem de Dinheiro e Financiamento ao Terrorismo (“PLD/FT”) e demais crimes contra o Sistema Financeiro Nacional.
            </p>

            <h5 className="legal-subtitle">2. Legislação de referência</h5>
            <p className="legal-text">
              <ul>
                <li>
                  Lei 9.613 de 03 de março de 1998: tipifica o crime de lavagem de
                  dinheiro ou ocultação de bens, direitos e valores, e institui
                  medidas que conferem maior responsabilidade aos entes que
                  compõem o sistema financeiro; e sua alteração;
                </li>
                <li>
                  BACEN Circular n. 3.978/20 - Dispõe sobre os procedimentos a
                  serem adotados na prevenção e combate às atividades relacionadas
                  com os crimes previstos na Lei no 9.613/98;
                </li>
                <li>
                  Normas emitidas pelo COAF – Conselho de Controle de Atividades Financeiras.
                </li>
                <li>
                  Circular nº 3.461, emitida em 24 de julho de 2009: que consolida as regras sobre os procedimentos a serem adotados na prevenção e combate às atividades relacionadas com os crimes previstos na lei nº 9.613/1998;
                </li>
                <li>
                  Manuais de Integridade da Controladoria Geral da União (“CGU”);
                </li>
                <li>
                  Carta-Circular BC 4.001 emitida em 29 de janeiro de 2020:
                  divulga relação de operações e situações que podem configurar
                  indícios de ocorrência dos crimes de “lavagem” ou ocultação de
                  bens, direitos e valores, de que trata a Lei nº 9.613, de 3 de
                  março de 1998, e de financiamento ao terrorismo, previstos na
                  Lei nº 13.260, de 16 de março de 2016, passíveis de comunicação
                  ao Conselho de Controle de Atividades Financeiras (Coaf).
                </li>
                <li>
                  Lei 12.846 de 1º de agosto de 2013: dispõe sobre a
                  responsabilização administrativa e civil de pessoas jurídicas
                  pela prática de atos contra a administração pública, nacional ou
                  estrangeira, e dá outras providências;
                </li>
                <li>
                  Decreto nº 8.420, de 18 de março de 2015: dispõe sobre a
                  responsabilização administrativa de pessoas jurídicas pela
                  prática de atos contra a administração pública, nacional ou
                  estrangeira.
                </li>
                <li>
                  Resolução nº 4.595, de 28 de agosto de 2017, do BACEN: Dispõe
                  sobre a política de conformidade (compliance) das instituições
                  financeiras e demais instituições autorizadas a funcionar pelo
                  Banco Central do Brasil.
                </li>
                <li>
                  Recomendações do GAFI: Padrões internacionais de combate à lavagem de dinheiro e ao financiamento do Terrorismo e da Proliferação;
                </li>
                <li>
                  Lei 14.478 de 21 de dezembro de 2022: Dispõe sobre diretrizes a serem observadas na prestação de serviços de ativos virtuais e na regulamentação das prestadoras de serviços de ativos virtuais.
                </li>
              </ul>
            </p>

            <h5 className="legal-subtitle">3. Responsabilidades</h5>
            <p className="legal-text">
              3.1. A área de Compliance é a responsável:
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  a) Pela disseminação, supervisão, treinamento e aplicação das
                  regras e procedimentos internos;
                </li>
                <li>
                  b) Pelo cumprimento das disposições previstas neste documento, como: análise do cliente através da verificação de documentos, origem de recursos, monitoramento de transações e de envio de infamações sobre as transações suspeitas às suas parceiras para comunicação formal ao COAF;
                </li>
                <li>
                  c) Por intermediar a relação entre o Usuário e suas Parceiras reguladas e atender solicitações de esclarecimentos e envio de informações e documentos, adotando as medidas necessárias para bloqueio e suspensão da prestação de serviços quando recomendado pelas Parceiras;
                </li>
                <li>
                  d) Definir medidas para mitigar riscos operacionais, regulatórios, reputacionais e legais de sua operação;
                </li>
                <li>
                  e) Transmitir a todos a cultura de combate aos crimes, lavagem de
                  dinheiro e financiamento ao terrorismo;
                </li>
                <li>
                  f) Pela adequada implementação de segregação de funções a fim de
                  evitar conflitos de interesses.
                </li>
              </ul>
            </p>

            <h5 className="legal-subtitle">4. Vigência </h5>
            <p className="legal-text">
              4.1. A Política entrará em vigor na data de sua aprovação e permanecerá em vigor por prazo indeterminado.
            </p>
            <p className="legal-text">
              4.2. A Política aplica-se a todos os Usuários que se cadastrarem na BC COINS e que utilizarem serviços.
            </p>
            <p className="legal-text">
              4.3. Qualquer alteração ou revisão desta Política deverá ser submetida à aprovação do Comitê de Compliance e comunicada aos interessados.
            </p>

            <h5 className="legal-subtitle">5. Procedimentos</h5>
            <p className="legal-text">
              5.1. A BC COINS realiza procedimentos para prevenção de crimes em sua plataforma a nível global, tais como lavagem de dinheiro e financiamento ao terrorismo, adotando procedimentos conhecidos como KYC (“Conheça seu Cliente”) e AML (“Contra Lavagem de Dinheiro”), e, no Brasil, adota os procedimentos que serão detalhados a seguir.
            </p>

            <h5 className="legal-subtitle">
              6. KYC – Conheça seu Cliente – CADASTRO
            </h5>
            <p className="legal-text">
              6.1. Para ter acesso aos serviços descritos nos Termos de Uso, os Usuários devem primeiro realizar seu Cadastro, prestando informações pessoais e fornecendo documentos para sua correta identificação, o que é conhecido como Know Your Client (KYC), ou “Conheça seu Cliente”.
            </p>
            <p className="legal-text">
              6.2. Os Usuários deverão fornecer informações verídicas e atualizadas, da forma mais clara e objetiva possível, sendo estes totalmente responsáveis por tais informações. No entanto, a BC COINS reserva para si o direito de, sempre que julgar aplicável, solicitar informações e documentos adicionais para análise do Cadastro, checar essas informações por meio de consultas, bem como recusar o Cadastro e transações.
            </p>
            <p className="legal-text">
              6.3. Os documentos solicitados, para que haja maior segurança neste processo, são os seguintes:
            </p>

            <p className="legal-text">
              <strong>KYC NÍVEL 1: Transações limitadas a R$ 50.000,00 por ano:</strong>
            </p>
            <p className="legal-text">
              <strong>
                a) Pessoa Física:
              </strong>
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  (i) Usuário deve preencher o formulário de cadastro na Plataforma com as seguintes informações: Nome completo, e-mail, endereço e número do documento de identificação.
                </li>
              </ul>
            </p>
            <p className="legal-text">
              <strong>
                b) Pessoa Jurídica:
              </strong>
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  (i) Contrato social da empresa assinado por todos os sócios envolvidos com a entidade.
                </li>
                <li>
                  (ii) Cartão do CNPJ (Para obter o cartão CNPJ, acesse o site da Receita Federal do Brasil e em "Emissão de Comprovante de Inscrição e de Situação Cadastral" digite o CNPJ. Destacamos que somente será possível oferecer uma conta jurídica se a situação cadastral estiver "ativa";
                </li>
                <li>
                  (iii) Documentação do item a), (i) de todos os sócios da entidade, conforme o contrato social.
                </li>
              </ul>
            </p>


            <p className="legal-text">
              <strong>KYC NÍVEL 2 - Transações entre R$ 50.001,00 e R$ 99.999,99 por ano:</strong>
            </p>
            <p className="legal-text">
              <strong>
                a) Pessoa Física:
              </strong>
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  (i) Idem item a), (i) do KYC NÍVEL 1 acima; e,
                </li>
                <li>
                  (ii) Usuário deve enviar foto do tipo “selfie” segurando o documento de pessoal e enviar uma cópia do documento pessoal - para fins de cadastro apenas RG, CNH e/ou RNE, que contenha foto e o CPF/ID do titular. Destacamos que o documento deve estar dentro da validade, conforme legislação vigente.
                </li>
                <li>
                  (iii) Usuário deve enviar foto do comprovante de residência em seu nome ou em nome de parentes de primeiro grau, cuja emissão seja datada dos últimos 3 meses. Caso o documento esteja em nome de terceiro, deve ser comprovado vínculo familiar (certidão de casamento, nascimento, contrato de locação reconhecido em cartório, cuja emissão também deverá ser de até 3 meses).
                </li>
              </ul>
            </p>
            <p className="legal-text">
              <strong>
                b) Pessoa Jurídica:
              </strong>
            </p>
            <p className="legal-text">
              Idem item b), (i), (ii) e (iii) do KYC NÍVEL 1 acima.
            </p>

            <p className="legal-text">
              <strong>KYC NÍVEL 3: Transações acima de R$ 100.000,00 por ano:</strong>
            </p>
            <p className="legal-text">
              <strong>
                a) Pessoa Física:
              </strong>
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  (i) Idem itens a), (i) do KYC NÍVEL 1 e a), (ii) e (iii) do KYC NÍVEL 2; e
                </li>
                <li>
                  (ii) Para comprovação de renda de pessoas físicas é obrigatória a entrega da Declaração de Imposto de Renda + Recibo de entrega.
                </li>
                <li>
                  (iii) Adicionalmente, a BC COINS também poderá solicitar: Holerites, Contratos de Compra e Venda, Inventário, Extratos bancários, entre outros documentos que corroborem a capacidade financeira do investidor e a origem dos recursos aportados na plataforma.
                </li>
              </ul>
            </p>
            <p className="legal-text">
              <strong>
                b) Pessoa Jurídica:
              </strong>
            </p>
            <p className="legal-text">
              <ul>
                <li>
                  (i) Idem item b), (i), (ii) e (iii) do KYC NÍVEL 1 acima e b) do KYC NÍVEL 2.
                </li>
                <li>
                  (ii) Para pessoas jurídicas é obrigatório a entrega do Balanço Patrimonial completo. Poderão ser solicitados, de forma adicional: relatórios de faturamento, extratos bancários e de investimentos, IRPJ.
                </li>
                <li>
                  (iii) Para empresas que atuam com criptomoedas, é obrigatória a apresentação do questionário específico sobre KYC e AML.
                </li>
              </ul>
            </p>

            <p className="legal-text">
              6.3.1. Sugerimos que, antes de fazer o envio, avaliem se os documentos anexados estão legíveis, dentro da validade, desprotegidos de senha para visualização e atendendo as instruções de cadastro.
            </p>
            <p className="legal-text">
              6.3.2. Caso os documentos não atendam as especificações descritas acima, caberá ao Usuário realizar novo upload dos arquivos.
            </p>
            <p className="legal-text">
              6.4. As informações cadastrais deverão ser atualizadas pelos usuários anualmente e sempre que houver quaisquer alterações.
            </p>
            <p className="legal-text">
              6.5. Usuários de Alta Sensibilidade: Considerando as principais diretrizes e regras existentes no mercado financeiro, bem como a análise dos principais casos de lavagem de dinheiro, é possível relacionar determinados perfis de usuários mais sensíveis de envolvimento com esse tipo de delito. Estes serão classificados internamente pela BC COINS como de Alta Sensibilidade.
            </p>
            <p className="legal-text">
              6.6. Pessoas Politicamente Expostas: São consideradas politicamente expostas aquelas pessoas que desempenham ou tenham desempenhado, nos últimos 5 (cinco) anos, cargos, empregos ou funções públicas relevantes, no Brasil ou em outros países, territórios e dependências estrangeiros, assim como seus representantes, familiares e outras pessoas de seu relacionamento próximo.
            </p>
            <p className="legal-text">
              6.6.1. Além disso, são exemplos de situações que caracterizam relacionamento próximo e acarretam o enquadramento do usuário permanente como pessoa politicamente exposta:
            </p>
            <p className="legal-text">
              • Constituição de pessoa politicamente exposta como procurador ou
              preposto;
            </p>
            <p className="legal-text">
              • Controle, direto ou indireto, cliente pessoa jurídica por pessoa
              politicamente exposta;
            </p>
            <p className="legal-text">
              6.6.2. Todos os colaboradores da BC COINS dedicarão atenção especial aos usuários classificados como politicamente expostos e de Alta Sensibilidade.
            </p>
            <p className="legal-text">
              6.6.3. Todos os Usuários da BC COINS devem se autodeclarar, caso se apliquem, como pessoa politicamente exposta, no momento do Cadastro. Além disso, a BC COINS poderá contratar sistemas de diligência (incluindo Background Check) para confrontar de sua base de Usuários com uma lista de pessoas politicamente expostas. Caso um Usuário que seja identificado como politicamente exposto, ainda que não se tenha autodeclarado, será assim considerado nas análises de indícios de lavagem de dinheiro.
            </p>
            <p className="legal-text">
              6.6.4. Todos os Usuários politicamente expostos são definidos pelo sistema como de alto risco de envolvimento com ilícitos associados a lavagem de dinheiro e terão suas informações pessoais analisada para aprovação ou recusa de cadastro e/ou operação.
            </p>
            <p className="legal-text">
              6.6.5. A área de Compliance analisa os dados da ocorrência e poderá solicitar esclarecimentos ou documentos adicionais ao Usuário, a seu exclusivo critério. Caso os indícios sejam consistentes, a BC COINS poderá recusar o usuário e/ou a transação e comunicar aos órgãos reguladores.
            </p>

            <h5 className="legal-subtitle">
              7. PLD – Procedimentos Contra Lavagem de Dinheiro
            </h5>
            <p className="legal-text">
              7.1. Nas transações do KYC Nível 3 e nas transações realizadas por Pessoas Politicamente Expostas, independentemente do valor, os Usuários terão analisadas a origem dos recursos utilizados na conta para fins de prevenção à lavagem de dinheiro, podendo realizar due diligences por meios de sistemas contratados para Background Check.
            </p>
            <p className="legal-text">
              7.2. A BC COINS se reserva ao direito de recusar a transação do usuário caso a considere suspeita, bem como informar as autoridades cabíveis.
            </p>


            <h5 className="legal-subtitle">8. Transferência de Recursos</h5>
            <p className="legal-text">
              8.1. Após anexar os documentos em nossa plataforma, os dados para a transferência bancária estarão disponíveis. Somente são aceitas transferências bancárias do tipo TED ou PIX. Não são aceitos depósitos em dinheiro direto no caixa.
            </p>
            <p className="legal-text">
              8.2. As transferências deverão ser realizadas da conta corrente do próprio usuário. Não serão aceitas transferências de contas bancárias de terceiros.
            </p>
            <p className="legal-text">
              8.2.1. Transferências realizadas por terceiros, em dinheiro ou acima dos limites diários/anuais, serão estornadas ao remetente e será cobrada taxa de devolução no valor de R$ 20,00.
            </p>

            <h5 className="legal-subtitle">9. Segregação de Funções</h5>
            <p className="legal-text">
              9.1. A BC COINS tem como medida de prevenção a implementação de controles que monitorem a execução das atividades para garantir a segurança das informações e impedir a ocorrência de fraudes e erros. Desta forma, a BC COINS adota política de segregação de funções que prevê que cada atividade operacional é exercida por um ou mais colaboradores, sendo cada um deles responsável separadamente pela execução e aprovação/autorização do procedimento.
            </p>
            <p className="legal-text">
              9.2. A respectiva política de segregação de funções minimiza o risco operacional a que a BC COINS está exposta, uma vez que não permite que ocorram relações baseadas meramente em confiança ou amparadas em interesses próprios, bem como inibe que procedimentos sejam realizados sem a devida revisão.
            </p>
            <p className="legal-text">
              9.3 Cabe destacar que para a correta aplicação da política de segregação de funções, todos os Colaboradores têm seus acessos físicos e lógicos restritos às funções e às atividades exercidas.
            </p>

            <h5 className="legal-subtitle">
              10. Análise e Monitoramento de Riscos e Avaliação de Efetividade
            </h5>
            <p className="legal-text">
              10.1. A BC COINS realiza a análise de risco dos Usuários e mantem registros das respectivas operações, a fim de mitigar ameaças ou ações que possam impactar suas operações e a segurança do mercado e da cadeia responsável pela prestação dos serviços e para acompanhar o comportamento do Usuário e fornecer subsídios solicitados pelas autoridades competentes em processos de investigação, podendo enviar registros de histórico de transações para monitoramento e comunicação aos órgãos oficiais.
            </p>

            <h5 className="legal-subtitle">11. Treinamentos</h5>
            <p className="legal-text">
              11.1. Todos os colaboradores são treinados periodicamente sobre essa Política, no sentido de revisar seus conceitos e incentivar sua adoção, visando o cumprimento do objetivo da Política.
            </p>
            <p className="legal-text">
              11.2. Anualmente, o Compliance revisa os materiais e promove programas de reciclagem.
            </p>

            <h5 className="legal-subtitle">12. Alteração</h5>
            <p className="legal-text">
              12.1. Essa Política pode ser alterada e atualizada a qualquer momento pela Área de Compliance da BC COINS, resguardada a importância de disseminação e treinamento de seu conteúdo para toda a empresa.
            </p>

            <h5 className="legal-subtitle">
              DATA DA ÚLTIMA ATUALIZAÇÃO: 02/08/2023
            </h5>
          </div>
        </div>
      )}
    </Panel>
  );
}
