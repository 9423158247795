import React, { useState, useEffect, useRef, useContext } from "react";
import api from "../utils/api";
import { toast } from "react-toastify";
import ConfirmModal from "./ConfirmModal";
import { numberFormatBRL } from "./InvestmentId";
import { formatBTCString, formatEVMString } from "../utils/Helpers/btcFormat";
import { AppContext } from "../context/AppContext";
import { useTranslation, Trans } from 'react-i18next';

const OrderBook = (props) => {
    const { selectedCoin } = props;
    const confirm = useRef(null);
    const [orders, setOrders] = useState([]);
    const [userId, setUserId] = useState('');
    const currencyPair = "btcr$";

    const { t } = useTranslation();

    const {
        loggedInUser,
      } = useContext(AppContext);

    const currencyArray = currencyPair.toUpperCase().match(/.{1,3}/g);

    useEffect(() => {
        const getOrders = async () => {
            if (!document.hidden) {
                const response = await api.get(`orders?filter[where][status][nin]=done&filter[where][status][nin]=canceled&filter[where][pair]=${selectedCoin.toLocaleLowerCase()}`);
                const data = response.data;
                setOrders(data);
            }
        }
        getOrders();

        const interval = setInterval(getOrders, 1000);

        return () => {
            clearInterval(interval);
            document.removeEventListener('visibilitychange', getOrders);
        };
    }, [selectedCoin]);

    const deleteOrder = async (id) => {
        confirm.current.show(
            t("attention"),
            <>
                <h3 className="text-center mb-2"> {t("wishexcludeorder")} {/* Deseja excluir esta ordem? */}</h3>
            </>,
            async () => {
                const res = await api.del(`/orders/${id}`)
                if(!res.error){
                    toast.success(t("successexcludeorder")) /* Ordem excluída com sucesso */
                } else {
                    toast.error(t("error.excludeorder")) /* Erro ao excluir ordem */
                }
            }
        );
    };

    const bids = orders
    .filter((order) => order.type === 'bid')
    .map((order) => [order.price, order.amount, order.currentAmount, order.id, order.usersId, order.createdAt]) // Include createdAt in the mapped array
    .sort((a, b) => {
      if (b[0] !== a[0]) {
        return b[0] - a[0]; // Sort by price from high to low
      }
      return new Date(a[5]) - new Date(b[5]); // If prices are equal, sort by createdAt in ascending order
    });
  
  
    const asks = orders
    .filter((order) => order.type === 'ask')
    .map((order) => [order.price, order.amount, order.currentAmount, order.id, order.usersId, order.createdAt]) // Include createdAt in the mapped array
    .sort((a, b) => {
      if (a[0] !== b[0]) {
        return a[0] - b[0]; // Sort by price from low to high
      }
      return new Date(a[5]) - new Date(b[5]); // If prices are equal, sort by createdAt in ascending order
    });
  

    const orderRows = (arr) =>
        arr &&
        arr.map((item, index) => {

            return (
                <tr key={index}>
                    <td> { selectedCoin === "BTC" ? formatBTCString(item[1] - item[2]) : formatEVMString(item[1] - item[2],3,selectedCoin)} </td>
                    <td> { numberFormatBRL(Number(item[0])) } </td>
                    <td>
                        { (item[4] === loggedInUser?.id) &&
                            <button onClick={ () => deleteOrder(item[3])} style={{ all: "unset" }}>
                                <i className="fa fa-x fa-trash"></i>
                            </button>
                        }
                    </td>
                </tr>
            );
        });

    const orderHead = (title) => (
        <thead>
            <tr>
                { title === "Compras" || title === "Buys" ? (
                    <th colSpan="3" className="orderbook-title" style={{ color: "#11581f"}}>
                        {title}
                    </th>
                ) : (
                    <th colSpan="3" className="orderbook-title" style={{ color: "#c60000"}}>
                        {title}
                    </th>
                )}
                {/* <th colSpan="3" className="orderbook-title">
                    {title}
                </th> */}
            </tr>
            <tr>
                <th>{t("qty")}({selectedCoin})</th>
                <th>{t("coinprice")} ({currencyArray[1]})</th>
                <th>{t("cancel")}</th>
            </tr>
        </thead>
    );

    return (
        <>
        <div className="order-container">
            <table>
                {orderHead(t("buys"))} {/*Compras*/}
                <tbody>{orderRows(bids)}</tbody>
            </table>

            <table>
                {orderHead(t("sells"))} {/*Vendas*/}
                <tbody>{orderRows(asks)}</tbody>
            </table>
        </div>
        <ConfirmModal ref={confirm} />
        </>
    );
};

export default OrderBook;
