import dayjs from "dayjs";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmWithdraw from "../../components/Admin/Investment/ConfirmWithdraw";
import ConfirmRenew from "../../components/Admin/Investment/ConfirmRenew";
import ConfirmModal from "../../components/ConfirmModal";
import Pagination from "../../components/Pagination";
import Panel from "../../components/Panel";
import { AppContext } from "../../context/AppContext";
import api, { microservice } from "../../utils/api";
import { formatStatus, WITHDRAW_STATUS } from "../../utils/types";
import { set } from "react-hook-form";
import WithdrawBeforeDate from "../../components/Admin/Investment/WithdrawBeforeDate";

function AdminApproveWithdrawTrade() {
  const [loading, setLoading] = useState(false);
  const [withdraws, setWithdraws] = useState([]);
  const [userById, setUserById] = useState('');
  const [selectWithdraw, setSelectWithdraw] = useState(null);
  const [selectRenew, setSelectRenew] = useState(null);
  const [selectReject, setSelectReject] = useState(null);
  const { pagination, setPagination, reloadWithdraws } = useContext(AppContext);
  const confirm = useRef(null);

  async function getWithdraws() {
    setLoading(true);

    setPagination(() => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 100000000000000,
      };
    });

    // const response = await api.get(
    //   `investments-withdraws?filter[order]=createdAt%20DESC&filter[skip]=${pagination.offset}&filter[limit]=${1000000000000}`
    // );
    const response = await api.get('investment/withdraws');

    if (response.error) {
      setLoading(false);
      return console.log(response.error);
    }
    
    // console.log(response);
    // setWithdraws(response);

    const ordered = await response.data.sort((a, b) => {
      return dayjs(b.withdraws.requestedTo).unix() - dayjs(a.withdraws.requestedTo).unix();
    });

    setWithdraws(ordered);  

    setLoading(false);
  }

  const getUserById = async (id) => {
    const username = await api.get(`users/${id}`)
    if (username) {
      setUserById(username.data.name)
      return true
    } else {
      return false
    }
  };

  useEffect(() => {
    setPagination(() => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 100000000000000,
      };
    });
  }, []);

  useEffect(() => {
    getWithdraws();
  }, []);

  useEffect(() => {
    getWithdraws();
  }, [reloadWithdraws]);

  const ConfirmWithdrawProps = {
    withdraw: selectWithdraw,
    setWithdraw: setSelectWithdraw,
  };

  const ConfirmRenewProps = {
    renew: selectRenew,
    setRenew: setSelectRenew,
    exchangeRate: selectRenew?.withdraws?.exchangeRate, 
  };

  return (
    <Panel
      load={loading}
      page="ApproveWithdrawTrade"
      title="Saques Investimentos"
      admin
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "#50504E",
        }}
      >
        <span className="bold text1 ml-3 text-mid2">Saques Investimentos</span>
      </div>
      <Table
        className="hist-table m-0 p-0 text-mid"
        responsive="md"
        striped
        hover
      >
        <thead>
          <tr>
            <th>Contrato nº</th>
            <th>Data</th>
            <th>Cliente</th>
            <th>Valor</th>
            <th>Tipo</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {withdraws?.length > 0 && withdraws?.map((withdraw, i) => {
            return(
            <tr key={i}>
              <td>{withdraw.investmentId}</td>
              <td>{dayjs(withdraw.withdraws.requestedTo).format("DD/MM/YYYY")}</td>
              <td>{withdraw.userName}</td>
              <td>
                R$ {Number(withdraw.withdraws.requestedAmount).toFixed(2)}
              </td>
              <td>
                { withdraw.withdraws.type === 'anticipated' ? 'Saque Antecipado' : withdraw.withdraws.type === 'liquidation' ? 'Saque de Encerramento' : 'Saque de Rentabilidade' }
              </td>
              { withdraw.withdraws.status === 'pending' &&
                <td>Pendente</td>
              }
              { withdraw.withdraws.status === 'approved' &&
                <td>Aprovado</td>
              }
              { withdraw.withdraws.status === 'rejected' &&
                <td>Rejeitado</td>
              }
              <td>
                <Link
                  to={`/panel/admin/investment/${withdraw.investmentId}`}
                  className="btn text-white btn-success"
                  style={{ fontSize: "12px", margin: "10px",  }}
                >
                  Ver contrato
                </Link>
              </td>

                {(withdraw.withdraws.status !== "pending" || (dayjs(withdraw.withdraws.requestedTo).isBefore(dayjs(), 'day'))) && <td></td>}
                
                {withdraw.withdraws.status === "pending" && (dayjs(withdraw.withdraws.requestedTo).isSameOrAfter(dayjs(), 'day')) && (<td>
                  <Button
                    className={withdraw.withdraws.status !== "pending" && "d-none"}
                    onClick={() => {
                      setSelectWithdraw(withdraw);
                      // console.log(withdraw);
                      // patchWithdraw(withdraw);
                    }}
                    variant="success"
                    style={{ fontSize: "12px", margin: "10px", marginBottom: "5px", height: "30px", width: "90px"}}
                  >
                    Confirmar
                  </Button>
                <Button
                  className={withdraw.withdraws.status !== "pending" || withdraw.withdraws.type === "liquidation" && "d-none"}
                  onClick={() => {
                    setSelectReject(withdraw);
                    confirm.current.show(
                      "Atenção",
                      <h3 className="text-center">
                        Deseja rejeitar este saque?
                      </h3>,
                      // async () => {
                      //   const res = await api.put(
                      //     `investments/withdraws/`, {
                      //     "investmentId": withdraw.investmentId,
                      //     "withdrawId" : withdraw.withdraws.id,
                      //     "requestedTo": this.props.withdraw.withdraws.requestedTo,
                      //     }
                      //   );
                      //   if (res.data) {
                      //     toast.success(res.data.message);
                      //     getWithdraws();
                      //   }
                      // }
                    );
                  }}
                  variant="danger"
                  style={{ fontSize: "12px", margin: "10px", marginTop: "0px", height: "30px", width: "90px" }}
                >
                  Rejeitar
                </Button>
                <Button
                  className={withdraw.withdraws.status !== "pending" || withdraw.withdraws.type !== "liquidation" && "d-none"}
                  onClick={() => {
                    setSelectRenew(withdraw);
                  }}
                  variant="danger"
                  style={{ fontSize: "12px", margin: "10px", marginTop: "0px", height: "30px", width: "90px" }}
                >
                  Renovar
                </Button>
                </td>)}
            </tr>
          )})}
        </tbody>
      </Table>

      {withdraws.length > 0 && (
        <Pagination pagination={pagination} setPagination={setPagination} />
      )}

      {selectWithdraw && <ConfirmWithdraw {...ConfirmWithdrawProps} withdraw={ selectWithdraw }/>}
      {selectRenew && <ConfirmRenew {...ConfirmRenewProps} renew={ selectRenew} setRenew={setSelectRenew} exchangeRate={selectRenew.withdraws.exchangeRate} from={"liquidating"} />}
      {/* {selectWithdraw && (dayjs(selectWithdraw?.withdraws?.requestedTo).isSame(dayjs(), 'day')) && <ConfirmWithdraw {...ConfirmWithdrawProps} withdraw={ selectWithdraw } />}
    
      {selectWithdraw && (dayjs(selectWithdraw?.withdraws?.requestedTo).isAfter(dayjs(), 'day')) && <WithdrawBeforeDate {...ConfirmWithdrawProps} withdraw={ selectWithdraw } />} */}

      <ConfirmModal ref={confirm} withdraw={ selectReject }/>
      
    </Panel>
  );
}

export default AdminApproveWithdrawTrade;
