import React from "react";
import { Button, Row, Form, Table, Collapse } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { disableAction, formatStatusHelper } from "../../utils/helper";
import { AppContext } from "../../context/AppContext";
import dayjs from "dayjs";
import { numberFormatBRL } from "../InvestmentWithdrawModal";

class AdminUserInvestments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      rows: [],
      filteredRows: [],
      page: 0,
      pages: 0,
    };
  }

  static contextType = AppContext;

  componentDidMount = () => {
    this.toggleShow();
  };

  toggleShow = async () => {
    if (this.state.show) {
      this.setState({ show: false });
    } else {
      let id = this.props.id;
      console.log('id >>>>',  id)
      this.context.getAllInvestments(id)
      this.setState({ show: true });
    }
  };

  setPage = (page) => {
    if (page < 0) {
      page = 0;
    } else if (page > this.state.pages) {
      page = this.state.pages;
    } else {
      this.setState({
        page: page,
        filteredRows: this.state.rows.slice(page * 20, page * 20 + 20),
      });
    }
  };

  goTo = (row) => {
    this.props.navigate("/panel/admin/investment/" + row._id);
  };

  newInvestment = () => {
    let id = this.props.id;
    this.props.navigate("/panel/admin/investment/new/" + id);
  };

  render() {
    
    return (
      <>
        <Form className="w-100 card p-3 mb-5">
          <div
            className="text-large bold mb-2 w-100 d-flex btn"
            onClick={this.toggleShow}
          >
            <span className="">Investimentos</span>
            <i className="fas fa-caret-down text-large mr-3 ml-auto"></i>
          </div>
          <div
            style={{ width: "100%", height: 1, backgroundColor: "#50504E" }}
          ></div>
          <Collapse in={this.state.show}>
            <div>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#50504E",
                }}
              >
                <span className="bold text1 ml-3 text-mid2">
                  Lista de investimentos feitos
                </span>
              </div>
              <Table
                className="hist-table m-0 p-0 text-mid"
                responsive="md"
                striped
                hover
              >
                <thead>
                  <tr>
                    <th>N</th>
                    <th>Tipo</th>
                    <th>Valor Inicial</th>
                    <th>Referência Inicial</th>
                    <th>Valor Atual</th>
                    <th>Referência Atual</th>
                    <th>Início</th>
                    <th>Fim</th>
                    <th>Status</th>
                    <th>Ver</th>
                  </tr>
                </thead>
                <tbody>
                  { this.context.allInvestments && this.context.allInvestments?.map((row, i) => (
                    <tr key={row._id}>
                      <td>{i + 1}</td>
                      <td>{row.type === "trade" ? "Investimentos" : row.type}</td>
                      <td>
                        { Number(row.initialAmount / this.context.btcPrice).toFixed(8) } BTC
                      </td>
                      <td>
                        { numberFormatBRL(row.initialAmount) }
                      </td>
                      {row.dailyReports?.length === 0 ? (<>
                        <td>
                          { (Number(row.currentAmount) / this.context.btcPrice).toFixed(8) } BTC
                        </td>
                        <td>
                          { numberFormatBRL(row.currentAmount) }
                        </td>
                        </>
                      ) : (<>
                        <td>
                          { Number((row.dailyReports[row.dailyReports.length - 1].finalAmount) / this.context.btcPrice).toFixed(8) } BTC
                        </td>
                        <td>
                          { numberFormatBRL(row.dailyReports[row.dailyReports.length - 1].finalAmount) }
                        </td>
                      </>
                      )}
                      <td>{dayjs(row.createdAt).format('DD/MM/YYYY')}</td>
                      <td>{dayjs(row.endDate).format('DD/MM/YYYY')}</td>
                      <td>{formatStatusHelper(row.status)}</td>
                      <td>
                        <Button onClick={() => this.goTo(row)}>Ver</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="text-center text-large mt-3 mb-3">
                <span
                  onClick={() => this.setPage(this.state.page - 1)}
                  className="pr-2 btn"
                >
                  <i className="fas fa-caret-left"></i>
                </span>
                <span>
                  Página {this.state.page + 1} de {this.state.pages + 1}
                </span>
                <span
                  onClick={() => this.setPage(this.state.page + 1)}
                  className="pl-2 btn"
                >
                  <i className="fas fa-caret-right"></i>
                </span>
              </div>
              <Row>
                <Button
                  disabled={disableAction(
                    this.context.loggedInUser?.permission?.level,
                    false,
                    false,
                    false
                  )}
                  className="ml-auto mr-auto"
                  onClick={this.newInvestment}
                >
                  Novo Investimento
                </Button>
              </Row>
            </div>
          </Collapse>
        </Form>
      </>
    );
  }
}

export default (props) => (
  <AdminUserInvestments {...props} navigate={useNavigate()} />
);
