/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Card, Form, Button, Container } from "react-bootstrap";
import Particles from "react-particles-js";
import HeaderBar from "../components/HeaderBar";
import BottomBar from "../components/BottomBar";
import ReCAPTCHA from "react-google-recaptcha";
import { saveToken } from "../utils/auth";
import api from "../utils/api";
import Privacy from "../components/Privacy";
import Terms from "../components/Terms";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
// import CurrencyWidget from "../components/CurrenciesWidget";
import CookieConsent from "react-cookie-consent";
import { withTranslation } from 'react-i18next';

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstTrySubmit: false,
      errors: {
        email: "",
        pass: "",
        auth: "",
        token: "",
        terms: true,
      },
      form: {
        email: "",
        pass: "",
        auth: "",
        token: "",
        terms: "",
      },
      validations: {
        email: this.validateEmail,
        pass: this.validatePass,
        auth: this.validateAuth,
        token: this.validateToken,
        terms: this.validateTerms,
      },
    };
  }

  static contextType = AppContext;

  recapchaOnChange = (value) => {
    this.handleChange({
      target: {
        type: "text",
        name: "token",
        value: value,
      },
    });
  };

  validateEmail = (value) => {
    if (/^.+@(\w+\.\w+)+$/.test(value)) {
      return "";
    } else {
      return this.props.t('invalidemail');
    }
  };

  validatePass = (value) => {
    if (/^.{6,100}$/.test(value)) {
      return "";
    } else {
      return this.props.t('invalidpass');
    }
  };

  validateAuth = (value) => {
    if (/^[0-9]{6}$/.test(value) || value === "") {
      return "";
    } else {
      return "";
    }
  };

  validateToken = (value) => {
    if (value !== null && value !== "") {
      return "";
    } else {
      return this.props.t('validatetoken');
    }
  };

  validateTerms = (value) => {
    if (value) {
      return "";
    } else {
      return "";
    }
  };

  handleChange = (event) => {
    let form = this.state.form;
    let errors = this.state.errors;
    let validations = this.state.validations;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    form[event.target.name] = value;
    if (this.state.firstTrySubmit) {
      errors[event.target.name] = validations[event.target.name](value);
    }
    this.setState({ form, errors });
  };

  onSubmit = async (event) => {
    event.preventDefault();
    let form = this.state.form;
    let errors = this.state.errors;
    let validations = this.state.validations;
    let formParams = Object.keys(form);
    let foundError = false;
    formParams.forEach((key) => {
      let error = validations[key](form[key]);
      errors[key] = error;
      if (error !== "") {
        foundError = true;
      }
    });
    if (!foundError) {

      let req = await api.post("signin", {
        email: form.email,
        password: form.pass,
        recaptcha: form.token,
        twoFactor: form.auth,
        locale: this.props.i18n.resolvedLanguage.toString()
      });
      if (!req.error) {
        saveToken(req.data.token);
        this.context.setLoggedInUser(req.data.infoUser);
        // await this.context.getSeeInvestment(req.data.infoUser.id);

        if (req.data.infoUser.role === "user") {
          if (!req.data.infoUser.emailConfirm) {
            this.props.navigate(
              "/panel/request-email?email=" + req.data.infoUser.email
            );
          }
          if (req.data.infoUser.kycStatus === "none") {
            this.props.navigate("/panel/kyc");
          }
          // else if (await this.context.hasInvestmentPermission) {
          //   this.props.navigate("/panel/trade");
          // } 
          else {
            this.props.navigate("/panel/bem-vindo");
          }
        }

        if (req.data.infoUser.role === "admin") {
          this.props.navigate("/panel/admin");
        }
      }
    } else {
      this.setState({ errors, firstTrySubmit: true });
    }
  };

  render() {

    return (
      <Container className="p-0 d-table" fluid>
        <HeaderBar hide hide_buttons />
        <div
          className=""
          style={{
            position: "absolute",
            width: "100vw",
            height: "100%",
            top: 0,
            left: 0,
            padding: 0,
            margin: 0,
            zIndex: -1,
            opacity: 0.5,
            overflow: "hidden",
          }}
        >
          <Particles
            style={{
              height: "100%",
            }}
            width="100%"
            height="100%"
            params={{
              fps_limit: 28,
              particles: {
                color: "rgba(0, 0, 0, 1)",
                links: {
                  color: "rgba(0, 0, 0, .7)",
                },
                move: {
                  speed: 0.5,
                },
                number: {
                  value: 50,
                },
                size: {
                  value: 3,
                },
              },
            }}
          />
        </div>
        <Container
          style={{
            width: "100%",
            height: "100%",
          }}
          className="d-table-row"
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: 30,
              paddingBottom: 50,
            }}
          >
            <div
              style={{
                paddingTop: "auto",
                paddingBottom: "auto",
              }}
            >
              <Card
                className="sign-card"
                style={{
                  maxWidth: 500,
                  margin: "auto",
                  marginTop: 30,
                  width: "90%",
                }}
              >
                <Card.Title
                  style={{
                    fontWeight: "bold",
                    fontSize: 22,
                  }}
                >
                  Login
                </Card.Title>
                <Card.Body className="pb-0">
                  <Form onSubmit={this.onSubmit}>
                    <Form.Group>
                      <Form.Control
                        className="input"
                        name="email"
                        type="email"
                        value={this.state.form.email}
                        onChange={this.handleChange}
                        required
                        isInvalid={this.state.errors.email}
                        placeholder="E-mail"
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        className="input"
                        name="pass"
                        type="password"
                        value={this.state.form.pass}
                        onChange={this.handleChange}
                        required
                        isInvalid={this.state.errors.pass}
                        placeholder={this.props.t('pass')}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.pass}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        className="bold text-muted"
                        style={{ fontSize: 16 }}
                      >
                        {this.props.t('auth2FA')}
                      </Form.Label>
                      <Form.Control
                        className="input"
                        name="auth"
                        type="number"
                        value={this.state.form.auth}
                        onChange={this.handleChange}
                        isInvalid={this.state.errors.auth}
                        placeholder=""
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.errors.auth}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="recaptcha_style">
                      <ReCAPTCHA
                        style={{
                          transform: "scale(0.85)",
                          transformOrigin: "0 0",
                          paddingLeft: "80px",
                        }}
                        sitekey={process.env.REACT_APP_RECAPTCHA}
                        onChange={this.recapchaOnChange}
                      />
                      <Form.Label className="text-danger">
                        {this.state.errors.token}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group style={{ width: 1000 }}></Form.Group>
                    <Button className="btn-primary mt-1 mb-3" type="submit">
                      {this.props.t('send')}
                    </Button>
                  </Form>
                  <p className="mb-1">
                    <a href="/panel/recover" style={{ fontSize: 12 }}>
                      {this.props.t('recoverpass')}
                    </a>
                  </p>
                  <span className="bold" style={{ fontSize: 13 }}>
                    {this.props.t('nclient')} <a href="/panel/signup">{this.props.t('register')}</a>
                  </span>

                </Card.Body>
              </Card>
            </div>
          </div>
        </Container>
        <BottomBar />
        <CookieConsent
          location="bottom"
          buttonText={this.props.t('acceptcookie')}
          cookieName="cookieConsent"
          style={{ background: "#011838" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          onAccept={(acceptedByScrolling) => {
            if (acceptedByScrolling) {
              // triggered if user scrolls past threshold
             // alert("Accept was triggered by user scrolling");
            } else {
             // alert("Accept was triggered by clicking the Accept button");
            }
          }}
        >{this.props.t('cookie')} <a href="/politica-de-privacidade-exchange" target="_blank"><span className="bt-bar-text">
                   {this.props.t('ppolicy')}
                </span></a></CookieConsent>
        <Terms ref={(ref) => (this.terms = ref)} />
        <Privacy ref={(ref) => (this.privacy = ref)} />
      </Container>
    );
  }
}

const TranslatedSignin = withTranslation()(Signin);

export default (props) => <TranslatedSignin {...props} navigate={useNavigate()} />;
