import { toast } from 'react-toastify';
import { getToken, logout } from "./auth"

const API = process.env.REACT_APP_API_URL;
const MICROSERVICE = process.env.REACT_APP_MICROSERVICE_URL;

const makeMicroserviceRequest = async (endpoint, params) => {
    try {  
     let req;
     if(MICROSERVICE == "http://localhost:3001"){
        req = await fetch(MICROSERVICE + '/' + endpoint, params);
     } else{
        req = await fetch(MICROSERVICE + '/api/' + endpoint, params); // --> usar para os servidores
     }
      // let req = await fetch(MICROSERVICE + '/api/' + endpoint, params); // --> usar para os servidores
      //  let req = await fetch(MICROSERVICE + '/' + endpoint, params);
        const data = await req.json();
        return data;
    } catch (error) {
        console.error(error);
    }
}

export class microservice {

    static post = async (endpoint, params, showError = true) => {
        return await makeMicroserviceRequest(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(params)
        }, showError);
    }

    static get = async (endpoint, params = '', showError = true) => {
        return await makeMicroserviceRequest(endpoint + params, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
        }, showError);
    }

    static patch = async (endpoint, params, showError = true) => {
        return await makeMicroserviceRequest(endpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(params)
        }, showError);
    }

    static put = async (endpoint, params, showError = true) => {
        return await makeMicroserviceRequest(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(params)
        }, showError);
    }
}


















const makeRequest = async (endpoint, params, showError) => {
    const response = {
        error: 'Connection fail',
        data: 'Connection fail'
    };
    try {
        let req = await fetch(API + '/api/' + endpoint, params);
        let text = await req.text();
        let json = text !== '' ? JSON.parse(text) : {};
        response.data = json;
        // console.log({p: params, e: endpoint, r: response.data})
        if (req.status < 200 || req.status > 210) {
            response.error = json.error.message
            if (req.status === 401) {
                logout();
                window.location.reload(false);
            }
        } else {
            response.error = ''
        }
    } catch (err) {
        console.error(err);
    }
    if (response.error && showError) {
        toast.error(`${response.error}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    return response;
}


export default class api {

    static postFile = async (endpoint, file, email, title, showError = true) => {
        let formData = new FormData();
        formData.append(`doc`, file);
        formData.append(`email`, email);
        formData.append(`title`, title);
        return await makeRequest(endpoint, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${getToken()}`
            },
            body: formData
        }, showError);
    }


    static postFiles = async (endpoint, files, showError = true) => {
        let formData = new FormData();
        files.forEach((file, i) => {
            formData.append(`file-${i + 1}`, file, file.name);
        });
        return await makeRequest(endpoint, {
            method: 'POST',
            headers: {
                'authorization': `Bearer ${getToken()}`
            },
            body: formData
        }, showError);
    }

    static post = async (endpoint, params, showError = true) => {
        return await makeRequest(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(params)
        }, showError);
    }

    static get = async (endpoint, params = '', showError = true) => {
        if (params) {
            params = '?filter=' + encodeURI(JSON.stringify(params));
        }
        return await makeRequest(endpoint + params, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
        }, showError);
    }

    static patch = async (endpoint, params, showError = true) => {
        return await makeRequest(endpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(params)
        }, showError);
    }

    static put = async (endpoint, params, showError = true) => {
        return await makeRequest(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
            body: JSON.stringify(params)
        }, showError);
    }

    static del = async (endpoint, showError = true) => {
        return await makeRequest(endpoint, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${getToken()}`
            },
        }, showError);
    }

    static getURL = () => API + '/api/'
}
