import React from "react";
import Panel from "../components/Panel";
import { Table, Button } from "react-bootstrap";
import api from "../utils/api";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Pagination from "../components/Pagination";

class ReferralUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      usersList: [],
      userBalances: {},
      page: 0,
      pages: 0,
      offset: 0,
      userCount: 0,
    };
  }

  static contextType = AppContext;

  componentDidMount = () => {
    this.fetchReferredUsers();
  };

  componentDidUpdate(_, prevState) {
    if (this.context.pagination.offset !== prevState.offset) {
      this.fetchReferredUsers();
    }
  }

  fetchReferredUsers = () => {
    const referralCode = this.context.referralCode;

    this.setState({
      offset: this.context.pagination.offset,
      load: true,
    });

    api
      .get(`users/referredBy/${referralCode}`)
      .then((req) => {
        if (!req.error) {
          let users = req.data;
          this.setState({
            load: false,
            usersList: users,
            userCount: users.length,
          });

          this.fetchUserBalances(users.map((user) => user.id));

          this.context.setPagination((pagination) => {
            return {
              ...pagination,
              lastPage: Math.ceil(users.length / pagination.limit),
            };
          });
        }
      });
  };

  fetchUserBalances = (userIds) => {
    const referralCode = this.context.referralCode;
    api
      .get(`users/balances-by-referral/${referralCode}`)
      .then((req) => {
        if (!req.error) {
          this.setState({
            userBalances: req.data,
          });
        }
      });
  };

  formatStatusKYC = (kycStatus) => {
    return kycStatus === "none" ? "0" : kycStatus;
  };

  handleInputChange = (event) => {
    const value = event.target.value.toLowerCase();
    const filteredUsers = this.state.usersList.filter((user) =>
      user.name.toLowerCase().includes(value) || user.email.toLowerCase().includes(value)
    );

    this.setState({ usersList: filteredUsers });

    if (value === "") {
      this.fetchReferredUsers();
    }
  };

  goToHistory = (row) => {
    this.context.setSelectedUser(row);
    this.props.navigate("/panel/client_tx_history/" + row.id);
  };

  render() {
    return (
      <Panel
        load={this.state.load}
        page="ReferralUsers"
        title="Clientes cadastrados"
        admin={false}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#50504E",
            height: "60px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "10px",
            alignItems: "center",
          }}
        >
          <span className="bold text1 ml-3 text-mid2">Lista de Clientes</span>
          <div>
            <span style={{ color: "white" }}>Qtd Total: {this.state.userCount}</span>
            <span className="bold text1 ml-3 text-mid2">Buscar: </span>
            <input
              type="text"
              onChange={this.handleInputChange}
              style={{ borderRadius: "5px", border: "none", paddingLeft: "5px" }}
            />
            <button
              style={{ borderRadius: "5px", margin: "3px", border: "none", color: "#ceba85" }}
              onClick={this.fetchReferredUsers}
            >
              X
            </button>
          </div>
        </div>
        <Table
          className="hist-table m-0 p-0 text-mid"
          responsive="md"
          striped
          hover
        >
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>KYC</th>
              <th>PJ</th>
              <th>Wallet</th>
              <th>Saldo BTC</th>
              <th>Investimentos</th> {/* Nova coluna adicionada */}
            </tr>
          </thead>
          <tbody>
            {this.state.usersList.map((row) => (
              <tr key={row.id}>
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.phone}</td>
                <td>{this.formatStatusKYC(row.kycStatus)}</td>
                <td>{row.legalPerson ? "Sim" : "Não"}</td>
                <td>{row.btcWalletAddress}</td>
                <td>{(this.state.userBalances[row.id] || 0).toFixed(9)}</td>
                <td>
                  <Button
                    variant="primary"
                    size="sm"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => this.goToHistory(row)}
                  >
                    Ver
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {this.state.usersList.length > 0 && (
          <Pagination
            pagination={this.context.pagination}
            setPagination={this.context.setPagination}
          />
        )}
      </Panel>
    );
  }
}

export default (props) => <ReferralUsers {...props} navigate={useNavigate()} />;
