import React, { useState, useEffect } from "react";
import { Button, Col, Row, Card, Form } from "react-bootstrap";
import InputMask from "react-input-mask";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { AppContext } from "../../context/AppContext";
import { countries } from "countries-list";
import Select from "react-select";
import { useTranslation, Trans } from 'react-i18next';
import '../../i18n'
const CustomCard = (props) => (
  <>
    {/* <div className="align-center">
      <div className="kyc-title">
        <span>{props.title}</span>
      </div>
    </div> */}
    <Card className="kyc-card"
    style={{
      height: 500,
    }}
    >{props.children}</Card>
    {props.ok === true && props.waiting === false && (
      <div className="kyc-icon-ok">
        <i className="fas fa-check-circle"></i>
      </div>
    )}
    {props.ok === false && props.waiting === false && (
      <div className="kyc-icon-miss">
        <i className="fas fa-times-circle"></i>
      </div>
    )}
    {props.waiting === true && (
      <div className="kyc-icon-waiting">
        <i className="fas fa-exclamation-triangle"></i>
      </div>
    )}
  </>
);

// all Countries codes
/*
const countryCodes = Object.keys(countries).map((code) => ({
  value: `+${countries[code].phone}`,
  label: countries[code].name,
}));
*/

const selectedCountries = ["BR", "IT"];

const countryCodes = Object.keys(countries)
  .filter(code => selectedCountries.includes(code))
  .map((code) => ({
    value: `+${countries[code].phone}`,
    label: countries[code].name,
  }));
 

const ConfirmCode = ({ text, typeUrl }) => {
  const [code, setCode] = useState("");

  const { t , i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  const checkCode = async (event) => {
    event.preventDefault();
    // let form = state.form;
    // let errors = state.errors;
    // let validations = state.validations;
    // let formParams = ["phone"];
    // let foundError = false;
    // formParams.forEach((key) => {
    //   let error = validations[key](form[key]);
    //   errors[key] = error;
    //   if (error !== "") {
    //     foundError = true;
    //   }
    // });
    // if (!foundError) {
    let req = await api.post(`profile/${typeUrl}/confirm`, {
      code,locale: currentLanguage
    });
    if (!req.error) {
      toast.success(t("confirmedcode"), {  // "Código confirmado"
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.location.reload();
    }
  };

  return (
    <>
      <p className="mb-2">
        <span>{t("insertconfcode")} {/* Insira o código de confirmação */}</span>
      </p>
      <Form className="" onSubmit={checkCode}>
        <Form.Group
          style={{
            width: 200,
            height: 300,
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Form.Control
            as={InputMask}
            placeholder={t("code")} /* Código */
            className="small text-center"
            name="code"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Button
            className="pl-0 pr-0"
            type="submit"
            style={{
              width: "100%",
              marginTop: 5,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {t("confirm")}
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

const PanelKYC = (props) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState(countryCodes[0]);
  
  const { t , i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;
  
  const [state, setState] = useState({
    errors: {
      phone: "",
      code: "",
    },
    form: {
      email: "",
      phone: "",
      code: "",
    },
    validations: {
      email: (value) => validateEmail(value),
      phone: (value) => validateSize(value, 7, 16),
      code: (value) => validateSize(value, 6, 6),
    },
    firstTrySubmit: false,
  });

  const context = React.useContext(AppContext);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      form: { ...prevState.form, phone: context.loggedInUser.phone },
    }));
  }, [context.loggedInUser.phone]);

  const validateSize = (value, min, max) => {
    if ((value.length >= min && value.length <= max) || value === "") {
      return "";
    } else {
      return t("invalidfield"); // Campo inválido
    }
  };

  const validateEmail = (email) => {
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return "";
    } else {
      return t("invalidfield"); // Campo inválido
    }
  };

  const handleChange = (event) => {
    let form = state.form;
    let errors = state.errors;
    let validations = state.validations;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    form[event.target.name] = value;
    if (state.firstTrySubmit) {
      errors[event.target.name] = validations[event.target.name](value);
    }
    setState({ form, errors });
  };

  /*
  const sendEmailVerification = async (event) => {
    event.preventDefault();
  }; */

  const sendCode = async (event) => {
    event.preventDefault();
    let form = state.form;
    let errors = state.errors;
    let validations = state.validations;
    let formParams = form.phone;
    let foundError = false;
    
    let error = validateSize(formParams,7,16);

    if(error == "Campo inválido" || error == "Invalid Field"){
      foundError = true;
    }
   
    /*
    formParams.forEach((key) => {
      let error = validations[key](form[key] ?? ''); // Add the nullish coalescing operator
      errors[key] = error;
      if (error !== "") {
        foundError = true;
      }
    });
    */

    if (!foundError) {
      let req = await api.post("profile/phone/code", {
        phone: selectedCountryCode.value + form.phone ?? '', // Add the nullish coalescing operator
      });
  
      if (!req.error) {
        toast.success(t("sentcodephone",{phone: selectedCountryCode.value + form.phone}), { //  `Código enviado para ${selectedCountryCode.value + form.phone}`
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      setState((prevState) => ({ ...prevState, errors, firstTrySubmit: true }));
    }
  };
  
  

 const sendEmailVerification = async (evt, currentLanguage) => {
    evt.preventDefault();
    let form = state.form;
    let errors = state.errors;
  
    let validations = state.validations;
    let formParams = form.email;
    let foundError = false;

    let error = validateEmail(formParams);
    if(error == "Campo inválido" || error == "Invalid Field"){
      foundError = true;
    }
    /* mParams.forEach((key) => {
      let error = validations[key](form[key]);
      errors[key] = error;
      if (error !== "") {
        foundError = true;
      }
    }); */
    if (!foundError) {
      let req = await api.post("verify-email", {
        sendTo: form.email,
        locale: currentLanguage
      });
      if (!req.error) {
        toast.success(t("sentcodeemail",{email: form.email}), { // Código enviado para ${form.email}
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      setState({ errors, firstTrySubmit: true });
    }
  };

  return (
    
    <Row>
      <Col md>
        <CustomCard
          title={t("basic")}
          ok={context.loggedInUser?.emailConfirm}
          waiting={false}
        >
          <img
            src="/images/kyc-email.jpg"
            width="50px"
            className="mr-auto ml-auto mb-2"
            alt="BC Coins Logo"
          />
          {context.loggedInUser?.emailConfirm === true && (
            <div>
              <span>{t("emailconfirmation")} {/* Confirmação de e-mail */}</span>
              <br />
              <span>{t("confirmationsuccess")} {/* confirmado com sucesso. */} </span>
            </div>
          )}

          {context.loggedInUser?.emailConfirm === false && (
            <>
              <span>{t("inputemail")} {/* Digite o email */}</span>
              <Form className="" onSubmit={(event) => sendEmailVerification(event, currentLanguage)}>
                <Form.Group
                  style={{
                    width: 200,
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <Form.Control
                    className="small"
                    name="email"
                    type="email"
                    value={state.form.email}
                    isInvalid={state.errors.email}
                    onChange={handleChange}
                  />

                  <Button
                    type="submit"
                    style={{ width: "100%", marginTop: 5 }}
                  >
                   {t("resendcode")} {/* Reenviar código*/} 
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {state.errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>

              <ConfirmCode text="recebido no email" typeUrl="email" />
            </>
          )}
        </CustomCard>
      </Col>

      <Col md>
        <CustomCard
          title="KYC nível 1"
          ok={context.loggedInUser?.phoneConfirm}
          waiting={false}
        >
          <img
            src="/images/kyc-phone.jpg"
            width="25px"
            className="mr-auto ml-auto mb-2"
            alt="BC Coins Logo"
          />
          
          {context.loggedInUser?.phoneConfirm === true && (
          <span>  {t("phoneconfirmsuccess")} {/* Número de celular confirmado com sucesso.*/}</span>
          )}
          {context.loggedInUser?.phoneConfirm === false && (
            <>
              <span>{t("inputphone")} {/* Digite o número do celular */}</span>
              <Form className="" onSubmit={sendCode}>
                <Form.Group
                  style={{
                    width: 200,
                    marginRight: "auto",
                    marginLeft: "auto"
                  }}
                >
                  <Select
                    options={countryCodes}
                    value={selectedCountryCode}
                    onChange={(selectedOption) => setSelectedCountryCode(selectedOption)}
                  />
                  <Form.Control
                    as={InputMask}
                    className="small text-center"
                    name="phone"
                    type="text"
                    value={state.form.phone}
                    isInvalid={state.errors.phone}
                    onChange={handleChange}
                  />
                  <Button
                    type="submit"
                    style={{ width: "100%", marginTop: 5 }}
                  >
                   {t("sendsms")} {/*  Enviar SMS */}
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {state.errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>

              <ConfirmCode text={t("receivedsms")} typeUrl="phone" />  {/* "recebido por SMS" */}
            </>
          )}
        </CustomCard>
      </Col>

      <Col md>
        <CustomCard
          title="KYC nível 2"
        // ok={context.loggedInUser?.kycStatus === "accepted"}
        // waiting={}
        >
          <img
            src="/images/kyc-master.jpg"
            width="45px"
            className="mr-auto ml-auto mb-2"
            alt="BC Coins Logo"
          />

          {context.loggedInUser?.kycStatus === "none" ? (
            <span>
              {t("requiredlvl1")} {/* Envie a documentação necessária para aprovarmos seu KYC nível 1 */} 
            </span>
          ) : context.loggedInUser?.kycStatus === "1" ? (
            <span>
             {t("requiredlvl2")} {/* Envie a documentação necessária para aprovarmos seu KYC nível 2 */} 
            </span>
          ) : context.loggedInUser?.kycStatus === "2" ? (
            <span>
             {t("requiredlvl3")} {/* Envie a documentação necessária para aprovarmos seu KYC nível 3 */} 
            </span>
          ) : (
            <span>{t("kyclvl3")} {/* Você está no KYC nível 3! */}</span>
          )}

          <div className="mt-auto"></div>

          <Button
            onClick={() => props.setTab(1)}
            style={{ width: 85, marginLeft: "auto", marginRight: "auto" }}
          >
           {t("see")}
          </Button>
        </CustomCard>
      </Col>
    </Row>
  );
};
export default PanelKYC;