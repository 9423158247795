import React, { useContext, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import Panel from '../../components/Panel';
import api from "../../utils/api";
import { AppContext } from "../../context/AppContext";

const Maintenance = () => {
    const { maintenanceMode, setMaintenanceMode } = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);

    const toggleMaintenanceModeHandler = async () => {
        try {
            const response = await api.post('maintenance/toggle', {
                active: !maintenanceMode,
            });
            if (response) {
                await setMaintenanceMode(!maintenanceMode);
                setShowModal(true);
            }
        } catch (error) {
            console.error("Erro ao desligar manutenção:", error);
        }
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <Panel title="Modo Manutenção" admin={true}>
            <p>Ativa ou Desativa o modo Manutenção para desenvolvimento (atualmente só orderbook)</p>
            <Button variant="primary" onClick={toggleMaintenanceModeHandler}>
                {maintenanceMode ? 'Desligar Manutenção' : 'Ligar Manutenção'}
            </Button>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modo Manutenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Manutenção foi {maintenanceMode ? 'Ativada' : 'Desativada'}.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Panel>
    );
};

export default Maintenance;
