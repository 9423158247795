import React from "react";
import {
  Col,
  Row,
} from "react-bootstrap";
import Panel from "../../components/Panel";
import { AppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
import api from "../../utils/api";

export default class AdminDocHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docs: [],
      docsByUser: [],
      allDocs: [],
      load: true,
      title: '',
      email: '',
      file: '',
      isAdmin: false,
    };
  }

  static contextType = AppContext;
  componentDidMount = () => {
    this.getDocs();
    this.getAllUploadedDocs();
  };

  compararPorPropriedade(a, b) {
    if (a.userName < b.userName) {
      return -1;
    }
    if (a.userName > b.userName) {
      return 1;
    }
    return 0;
  }

  getDocs = async () => {
    this.setState({
      offset: this.context.pagination.offset,
      load: true,
    });

    await api.get('users-relatories/list').then((req) => {
      if (!req.error) {
        let tradeHistory = req.data;
        this.setState({
          docs: req.data,
          load: false,
          historyTradeMaster: tradeHistory.data,
        });
      }
    });
  };

  getDocsByUser = async (id) => {
    await api.get(`users-relatories?filter={"where":{"usersId": ${id}}}`)
      .then((req) => {
        if (!req.error) {
          this.setState({
            docsByUser: req.data,
          });
        }
      });
  };

  getAllUploadedDocs = async () => {
    await api.get(`users-relatories`)
      .then((req) => {
        console.log(req)
        if (!req.error) {
          const sorted = req.data.sort(this.compararPorPropriedade);
          this.setState({
            allDocs: sorted,
          });
        }
      });
  };

  async callDownload(id) {
    await api.get(`users-relatories/${id}/download`);
  }

  async callSaveDoc() {
    let resp = await api.postFile(`users-relatories`, this.state.file, this.state.email, this.state.title);
    if(resp.data){
      toast.success("Success upload archive");
      this.getAllUploadedDocs();
    }else{
      toast.error("Success upload archive");
    }
  }

  setValue(evt) {
    const val = evt.target.value;
    this.setState({
      [evt.target.name]: val
    });
  }

  handleFileChange = (e) => {
    if (e.target.files) {
      this.setState({file : e.target.files[0]})
    }
  };

  deleteDocument = (id) => {
    api.del(`users-relatories/${id}`)
      .then((req) => {
        if (!req.error) {
          toast.success("Documento deletado com sucesso");
          this.getAllUploadedDocs();
        } else {
          toast.error("Erro ao deletar documento");
        }
      });
  };

  // seeDocument = async (id) => {
  //   await api.get(`users-relatories/${id}/download`)
  //     .then((req) => { console.log(req) });
  // };

  render() {
    return (<>
          <Panel
            load={this.state.load}
            history={this.props.history}
            page="doc_history"
            title="Histórico < 2023"
            admin={true}
          >
          <Row>
          <Col className="text2 mt-3 mb-5">
            <p className="bold">Upload documentos</p>
            <div>
              <input className="input form-control" name='title' type="text" value={this.state.title} onChange={evt => this.setValue(evt)} placeholder="Doc title"></input>
            </div>
            <div className="mt-2">
              <input className="input form-control" name='email' type="email" value={this.state.email} onChange={evt => this.setValue(evt)} placeholder="E-mail"></input>
            </div>
            <div className="mt-2">
              <input type="file" onChange={(evt) => this.handleFileChange(evt)} className="input form-control"/>
            </div>
            <div className="mt-2 ">
              <button className="btn btn-primary" onClick={() => this.callSaveDoc()}>Upload</button>
            </div>
          </Col>
        </Row>
        <Row className="">
            <Col className="doc_list">
              <p className="bold bg-red">Documentos</p>
            </Col>
          </Row>
          <Row className="" style={{ display: "flex", justifyContent: "center", flexDirection: "row",  flexWrap: "wrap" }}>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", flexWrap: "wrap" }}>
              { this.state.allDocs?.length > 0 && (this.state.allDocs.map((doc) => (
                <div className="doc_list" style={{ margin:  "20px", minWidth: "250px", maxWidth: "250px", minHeight: "270px", display: "flex", justifyContent: "center", alignItems:"center", flexDirection: "column", backgroundColor: "white", borderRadius: "20px", boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.4)", padding: "10px", textAlign: "center" }}>
                  <i className="fa fa-3x fa-file" style={{ marginBottom: "20px", color:"#001535" }}></i>
                  <h6>{doc.title}</h6>
                  <p>{doc.userName}</p>
                  <p>{doc.userEmail}</p>
                  {/* <p>UserId: {doc.usersId}</p> */}
                  <Row>
                  <a
                  className="link-file"
                  href={`${api.getURL()}users-relatories/${doc.id}/download`}
                >
                  <button className="btn btn-primary" style={{margin: "10px"}}>
                    Ver
                  </button>
                </a>
                  <button className="btn btn-primary" onClick={() => this.deleteDocument(doc.id)} style={{margin: "10px"}}>
                    Deletar
                  </button>
                  </Row>
                </div>
              )))}
            </div>
          </Row>
        </Panel>
      </>
    );
  }
}
