/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
// import { LinkContainer } from 'react-router-bootstrap';
import api from "../utils/api";
import dayjs from "dayjs";
import { formatDate } from "../utils/helper";
import { Button, Spinner } from "react-bootstrap";
import { TermsTrade } from "./TermsTrade";
import { TermsTradeEN } from "./TermsTradeEN";
import hpInvHistory from "../assets/images/investment/hist-hp-1.png";
import modOne from "../assets/images/investment/hist-moderado-1.png";
import modTwo from "../assets/images/investment/hist-moderado-2.png";
import modThree from "../assets/images/investment/hist-moderado-3.png";
import standOne from "../assets/images/investment/hist-standard-1.png";
import standTwo from "../assets/images/investment/hist-standard-2.png";
import standThree from "../assets/images/investment/hist-standard-3.png";
import standFour from "../assets/images/investment/hist-standard-4.png";
import standFive from "../assets/images/investment/hist-standard-5.png";
import hpGraphOne from "../assets/images/investment/hp-graph-1.png";
import modGraphOne from "../assets/images/investment/mod-graph-1.png";
import modGraphTwo from "../assets/images/investment/mod-graph-2.png";
import modGraphThree from "../assets/images/investment/mod-graph-3.png";
import standGraphOne from "../assets/images/investment/stand-graph-1.png";
import standGraphTwo from "../assets/images/investment/stand-graph-2.png";
import standGraphThree from "../assets/images/investment/stand-graph-3.png";
import standGraphFour from "../assets/images/investment/stand-graph-4.png";
import standGraphFive from "../assets/images/investment/stand-graph-5.png";
import { formatBTCString } from "../utils/Helpers/btcFormat";
import { get, set } from "react-hook-form";
import { getToken, getUserDataFromJWT } from "../utils/auth";
import { toast } from "react-toastify";
import { useTranslation, Trans } from 'react-i18next';

export const prepareValue = (value) => {
  return String(value).replace(".", ",");
};

export const numberFormatBRL = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

function InvestmentsSumary({ title }) {

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  const {
    prices,
    allInvestments,
    getAllInvestments,
    setInvestmentIdSelected,
    setNotifications,
    setCountNotifications,
    showModalTermsTrade,
    setShowModalTermsTrade,
    completeUser,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [activeInvestments, setActiveInvestments] = useState([]);
  const [selectedModality, setSelectedModality] = useState(null);
  const [allInvestmentsState, setAllInvestmentsState] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});

  const getAllInvestmentsOnReload = async () => {
    const userObj = getUserDataFromJWT()
    const userId = userObj.id
    if (allInvestments === null) {
      await api.get(`users/investments/${userId}`).then((req) => {
        if (req.data.error === "Investment not found" || req.error) {
          setAllInvestmentsState([]);
          verifyModal([], userId);
        }
        else {
          setAllInvestmentsState(req.data);
          verifyModal(req.data, userId);
        }
      });
    } else {
      setAllInvestmentsState(allInvestments);
      verifyModal(allInvestments, userId);
    }
  };

  const verifyModal = async (investments, id) => {
    console.log('chegando na VerifyModal')
    if (allInvestmentsState.length === 0) {
      if (completeUser === null) {
        api.get(`users/${id}`).then((req) => {
          if (!req.error) {
            setSelectedUser(req.data)
            req.data.investmentTerms === null || req.data.investmentTerms === false ? setShowModalTermsTrade(true) : setShowModalTermsTrade(false);
            getActiveInvestiments(investments);
          } else {
            toast.error(t("error.loaduser")); // Erro ao carregar dados do usuário
          }
        });
      } else {
        setSelectedUser(completeUser);
        completeUser.investmentTerms === null || completeUser.investmentTerms === false ? setShowModalTermsTrade(true) : setShowModalTermsTrade(false);
        getActiveInvestiments(investments);
      }
    } else {
      getActiveInvestiments(investments);
    }
  };

  const getActiveInvestiments = (investments) => {
    console.log('chegando na getActiveInvestiments', investments)
    const active = investments.filter((i) => {
      if (i.status === "active" || i.status === "renewing" || i.status === "liquidating") {
        return i;
      } else {
        return null;
      }
    });
    console.log('active', active)
    setActiveInvestments(active);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    setAllInvestmentsState([]);
    setShowModalTermsTrade(false);
    getAllInvestmentsOnReload();
  }, []);

  const handleClick = ({ target }) => {
    setInvestmentIdSelected(target.name);
  };

  const getWarningNotifications = async (days) => {
    const filterString = `filter[where][type]=warning-${days}-days`;
    // const filterString = `filter[where][type]=warning-${days}-days&filter[where][read]=false`;

    try {
      const response = await api.get(`users-notifications?${filterString}`);
      const data = response.data;
      // console.log('data', data.data);
      if (data.data?.length > 0) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const sendNotification = async (investment) => {
    const not45 = await getWarningNotifications(45);
    const not31 = await getWarningNotifications(31);

    const deadlineWarning = dayjs(investment.endDate)
      .subtract(30, "day")
      .format("DD/MM/YYYY");
    const daysDifference = dayjs(investment.endDate).diff(dayjs(), "day");
    const todayIsBeforeDDL = dayjs().isSameOrBefore(
      dayjs(investment.endDate).subtract(30, "day")
    );
    const warning = investment.options.renewWarning;
    const todayIsAfterRenewWarning = dayjs().isSameOrAfter(
      dayjs(investment.options.renewWarning)
    );

    if (!not45 && daysDifference <= 45 && daysDifference > 31) {
      const notification = {
        event: t("investwithdrawwarning", { daysDifference, investmentId: investment._id, deadlineWarning }),
        usersId: investment.userId,
        type: "warning-45-days",
        read: false,
      };

      if (warning && todayIsBeforeDDL && todayIsAfterRenewWarning) {
        const req = await api.post("users-notifications", notification);
        if (req) {
          setCountNotifications((countNotifications) => countNotifications + 1);
          setNotifications((notifications) => [...notifications, req.data]);
        }
      }
    }

    if (!not31 && daysDifference === 31) {
      const notification = {
        event: t("investwithdrawwarning", { daysDifference, investmentId: investment._id, deadlineWarning }),
        usersId: investment.userId,
        type: "warning-31-days",
        read: false,
      };

      if (warning && todayIsBeforeDDL && todayIsAfterRenewWarning) {
        const req = await api.post("users-notifications", notification);
        if (req) {
          setCountNotifications((countNotifications) => countNotifications + 1);
          setNotifications((notifications) => [...notifications, req.data]);
        }
      }
    }
  };

  if (allInvestmentsState && allInvestmentsState?.length > 0) {
    allInvestmentsState.forEach((investment) => {
      sendNotification(investment);
    });
  }

  return (
    <>
      {loading && (
        <div className="text-center mt-3 mb-3">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}
      {currentLanguage === 'en' ?
        <TermsTradeEN showModal={showModalTermsTrade} />
        :
        <TermsTrade showModal={showModalTermsTrade} />
      }
      {!loading && (!allInvestmentsState || allInvestmentsState?.length === 0 || !activeInvestments || activeInvestments?.length === 0) ? (
        <div className="inv-sum-div">
          <div className="inv-sum-no-investment">
            <div
              className="no-investment-cards"
              style={{
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
                width: "100%",
              }}
            >
              <div
                style={{
                  borderRadius: "15px",
                  backgroundColor: "#FFFFFF",
                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.4)",
                  width: "270px",
                  minHeight: "430px",
                  padding: "15px",
                  textAlign: "center",
                  margin: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ fontWeight: "bold", color: "#CEBA86" }}>
                    Standard:
                  </div>
                  <br></br>
                  <p>
                    {t("standardstrg")} {/* Estratégia: Os criptoativos são negociados em corretoras utilizando estratégias de baixo risco, recomendado para quem quer iniciar investimentos nesse mercado. */}
                  </p>
                </div>
                <br></br>
                <button id="bnt-start" name="Standard" onClick={(e) => setSelectedModality(e.target.name)}>
                  {t("checkhistory")}  {/*  Ver Histórico */}
                </button>
              </div>
              <div
                style={{
                  borderRadius: "15px",
                  backgroundColor: "#FFFFFF",
                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.4)",
                  width: "270px",
                  minHeight: "430px",
                  padding: "15px",
                  textAlign: "center",
                  margin: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ fontWeight: "bold", color: "#CEBA86" }}>
                    {t("highvalue")}:  {/* Alto Valor */}
                  </div>
                  <br></br>
                  <p>
                    {t("highvaluestrg")} {/* Estratégia: Os criptoativos são negociados em corretoras utilizando estratégias de risco moderado, recomendado para quem já se sente confortável em arriscar um pouco mais. */}
                  </p>
                </div>
                <br></br>
                <button id="bnt-start" name="Alto Valor" onClick={(e) => setSelectedModality(e.target.name)}>
                  {t("checkhistory")}
                </button>
              </div>
              <div
                style={{
                  borderRadius: "15px",
                  backgroundColor: "#FFFFFF",
                  boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.4)",
                  width: "270px",
                  minHeight: "430px",
                  padding: "15px",
                  textAlign: "center",
                  margin: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ fontWeight: "bold", color: "#CEBA86" }}>
                    {t("highperformance")}:  {/* Alta Performance */}
                  </div>
                  <br></br>
                  <p>
                    {t("highperformancestrg")}  {/* Estratégia: Os criptoativos são negociados em corretoras utilizando estratégias de alto risco, recomendado para quem já possui parte do seu capital alocado em investimentos de baixo risco e buscar diversificar a exposição ao risco. */}
                  </p>
                </div>
                <br></br>
                <button id="bnt-start" name="Alta Performance" onClick={(e) => setSelectedModality(e.target.name)}>
                  {t("checkhistory")}
                </button>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <div className="inv-sum-no-investment">
            {selectedModality && <>
              <h4 className="inv-sum-no-investment-text" style={{ fontWeight: "bolder" }}>
                {t("modalityhistory")} {/* Histórico da Modalidade */} {selectedModality}
              </h4>
              <br></br>
            </>
            }
            {selectedModality === "Standard" && (
              <>
                <h5>Standard {t("in")} {/* em */} 2019</h5>
                <img className="inv-hist-images"
                  src={standOne}
                  alt="Histórico de Investimento"
                />
                <img className="inv-hist-graphs"
                  src={standGraphOne}
                  alt="Histórico de Investimento"
                />
                <h5>Standard {t("in")} {/* em */} 2020</h5>
                <img className="inv-hist-images"
                  src={standTwo}
                  alt="Histórico de Investimento"
                />
                <img className="inv-hist-graphs"
                  src={standGraphTwo}
                  alt="Histórico de Investimento"
                />
                <h5>Standard {t("in")} {/* em */} 2021</h5>
                <img className="inv-hist-images"
                  src={standThree}
                  alt="Histórico de Investimento"
                />
                <img className="inv-hist-graphs"
                  src={standGraphThree}
                  alt="Histórico de Investimento"
                />
                <h5>Standard {t("in")} {/* em */} 2022</h5>
                <img className="inv-hist-images"
                  src={standFour}
                  alt="Histórico de Investimento"
                />
                <img className="inv-hist-graphs"
                  src={standGraphFour}
                  alt="Histórico de Investimento"
                />
                <h5>Standard {t("in")} {/* em */} 2023</h5>
                <img className="inv-hist-images"
                  src={standFive}
                  alt="Histórico de Investimento"
                />
                <img className="inv-hist-graphs"
                  src={standGraphFive}
                  alt="Histórico de Investimento"
                />
              </>
            )}
            {selectedModality === "Alto Valor" && (
              <>
                <h5>{t("highvalue")}  {t("in")} {/* em */} 2021</h5>
                <img className="inv-hist-images"
                  src={modOne}
                  alt="Histórico de Investimento"
                />
                <img className="inv-hist-graphs"
                  src={modGraphOne}
                  alt="Histórico de Investimento"
                />
                <h5>{t("highvalue")} {t("in")} {/* em */} 2022</h5>
                <img className="inv-hist-images"
                  src={modTwo}
                  alt="Histórico de Investimento"
                />
                <img className="inv-hist-graphs"
                  src={modGraphTwo}
                  alt="Histórico de Investimento"
                />
                <h5>{t("highvalue")} {t("in")} {/* em */} 2023</h5>
                <img className="inv-hist-images"
                  src={modThree}
                  alt="Histórico de Investimento"
                />
                <img className="inv-hist-graphs"
                  src={modGraphThree}
                  alt="Histórico de Investimento"
                />
              </>
            )}
            {selectedModality === "Alta Performance" && (
              <>
                <h5>{t("highperformance")} {t("in")} {/* em */} 2023</h5>
                <img className="inv-hist-images"
                  src={hpInvHistory}
                  alt="Histórico de Investimento"
                />
                <img className="inv-hist-graphs"
                  src={hpGraphOne}
                  alt="Histórico de Investimento"
                />
              </>
            )}
          </div>
        </div>
      ) : (
        activeInvestments?.map((investment, i) => (
          <div className="inv-sum-container" key={i}>
            <div className="inv-sum-title text-large2 bold text2">
              <span>{t("highcapinvestment")} {/* INVESTIMENTO */} nº {investment._id}</span>
            </div>
            <div className="inv-sum-div" key={i + 1}>
              <div className="inv-sum-item">
                <span className="inv-sum-item-title">
                  {t("initialdepositdate")}: {/* Data do Aporte Inicial */}
                </span>
                <span className="inv-sum-item-text">
                  {formatDate(investment.createdAt)}
                </span>
              </div>
              <div className="inv-sum-item">
                <span className="inv-sum-item-title">
                  {t("initialinvestedvalue")}: {/*Valor Inicial Investido */}
                </span>
                <span className="inv-sum-item-text">
                  {/* {prepareValue(investment.initialAmount / prices.btc)} */}
                  {formatBTCString(investment.initialAmount / prices.btc)}
                </span>
                <div className="inv-sum-item-refBRL">
                  <span>
                    {t("refference")} (R$):{" "}
                    {numberFormatBRL(investment.initialAmount)}
                  </span>
                </div>
              </div>
              <div className="inv-sum-item">
                <span className="inv-sum-item-title">
                  {t("investmenttype")} {/* Tipo de Investimento: */}
                </span>
                <span className="inv-sum-item-text">
                  {investment.modality} {investment.type}
                </span>
              </div>
              <div className="inv-sum-item">
                <span className="inv-sum-item-title">
                  Status: {/* Status: */}
                </span>
                <span className="inv-sum-item-text">
                  {currentLanguage === "pt" ? (
                    investment.status === 'active' ? 'Ativo' :
                      investment.status === 'liquidating' ? 'Encerrando' :
                        'Renovando'
                  ) : (
                    investment.status === 'active' ? 'Active' :
                      investment.status === 'liquidating' ? 'Ending' :
                        'Renewing'
                  )}
                </span>

              </div>
            </div>
            <Link
              className="inv-sum-item-link"
              to={`/panel/trade/${investment._id}`}
              onClick={handleClick}
              name={investment._id}
            >
              {t("completerelatory")} {/* Relatório Completo */}
            </Link>
          </div>
        ))
      )}
      <div>
        <div className="kyc-policy">
          <h4 className="kyc-policy-title">{t("investmentterms")} {/* Termos do Investimento */}</h4>
          <p style={{ textAlign: "center" }}>
            {t("investmenttermstext")} {/* Está com dúvidas? Consulte nossos Termos de Investimento clicando no
            botão abaixo: */}
          </p>
          <Link to="/panel/politica-de-privacidade-produto-inevstimento">
            <Button>{t("investmentterms")}</Button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default InvestmentsSumary;
