export default function Pagination({ pagination, setPagination }) {
  return (
    <div className="text-center text-large mt-3 mb-3">
      <span
        onClick={() => {
          if (pagination.currentPage == 1) {
            return false;
          }

          setPagination((pagination) => {
            return {
              ...pagination,
              offset: pagination.offset - pagination.limit,
              currentPage: pagination.currentPage - 1,
            };
          });
        }}
        className="pr-2 btn"
      >
        <i className="fas fa-caret-left"></i>
      </span>
      <span>
        Página {pagination.currentPage} de {pagination.lastPage}
      </span>
      <span
        onClick={() => {
          if (pagination.lastPage == pagination.currentPage) {
            return false;
          }

          setPagination((pagination) => {
            return {
              ...pagination,
              offset: pagination.offset + pagination.limit,
              currentPage: pagination.currentPage + 1,
            };
          });
        }}
        className="pl-2 btn"
      >
        <i className="fas fa-caret-right"></i>
      </span>
    </div>
  );
}
