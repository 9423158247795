import React from "react";
import { formatMoney } from "../utils/helper";

function CurrencyCard(props) {
  return(
    <div
      className="currency-card"
      style={{
      }}
    >
      {props.load && (
        <div
          style={{
            height: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        </div>
      )}
      {props.load === false && (
        <div style={{textAlign:'left' }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            { props.price.symbol.toLocaleLowerCase() === 'usd' 
              ? <img 
                src={`/icons/usdt.svg`}
                alt={`tether-icon`}
              />
              : <img 
                src={`/icons/${props.price.symbol.toLocaleLowerCase()}.svg`}
                alt={`${props.price.symbol}-icon`}
              /> 
            }
            <div
              className="price-variation"
              style={ props.price.variation > 0 ? { backgroundColor:'#e6ffe5' } : { backgroundColor:'#fee8e8' }}
            >
              <span style={ props.price.variation > 0 ? { color:'green' } : { color:'red' }}>{ props.price.variation.toFixed(2) }%</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <span className="mt-1 currency-text-name">{props.price.name}</span>
            {props.price.symbol === 'USD'
              ? <span className="mt-1 currency-text-name">USDT</span>
              : <span className="mt-1 currency-text-name">{props.price.symbol}</span>}
          </div>
          <div
            className="currency-text-info"
          >
            <span className="bold">
              { props.price.base === "USD"
                ? `R$  ${formatMoney(props.price.price * props.brlPrice)}`
                : `R$  ${formatMoney(props.price.price)}` }
            </span>
          </div>
        </div>
      )}
    </div>
  )
};

export default CurrencyCard;