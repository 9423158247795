import dayjs from "dayjs";
import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Spinner, Table, Modal, Dropdown, DropdownButton } from "react-bootstrap";
import Pagination from "../components/Pagination";
import api from "../utils/api";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import Panel from "../components/Panel";
import { formatBTCString, formatEVMString } from "../utils/Helpers/btcFormat";
import { useTranslation, Trans } from 'react-i18next';
import { numberFormatBRL } from "../components/InvestmentId";
import { useParams } from "react-router-dom"; // Importa useParams
import InvestmentList from "../components/UserInvestments"; // Importa o componente de lista de investimentos

const ClientInvestments = () => {
  const { pagination, setPagination, loggedInUser } = useContext(AppContext);
  const { id } = useParams();
  console.log(id);
  const [txHistory, setTxHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const navigate = useNavigate();
 
  const { t } = useTranslation();

  async function loadTxHistory() {
    setLoading(true);
    const count = await api.get(`orders/count?[where][usersId]=${id}`);
  //  console.log('count >>>>', count);

    const pagesNumber = await Math.ceil(count.data.count / 10);
    console.log(pagesNumber);

    setPagination((pagination) => {
      return {
        ...pagination,
        lastPage: pagesNumber
      };
    });

    try {
      const url = `orders/${id}?limit=${pagination.limit}&offset=${pagination.offset}`;
      const response = await api.get(url);
      const data = response.data;
      console.log(data);
      setTxHistory(data);
      setLoading(false);
    } catch (e) {
      setTxHistory([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadTxHistory();
    setPagination(() => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });
  }, []);

  useEffect(() => {
    loadTxHistory();
  }, [pagination.offset]);

  const itHasNFSe = (row) => (row.nfeid ? true : false);

  const rowStatus = {
    done: t("statusdone"), // "Confirmado",
    canceled: t("statuscanceled"), // "Cancelado",
    open: t("statusopen"), // "Aberto",
    failed: t("statusfailed"), //  "Falha",
  };


  const handleOpenNFeModal = (orderId) => {
    setSelectedOrderId(orderId);
  };

  const handleCloseNFeModal = () => {
    setSelectedOrderId(null);
  };

  const handleSelectNFe = (nfeId) => {

      // Construct the URL for downloading the PDF
      const pdfUrl = `${api.getURL()}notas/${nfeId}/download`;

      // Open the PDF in a new tab
      window.open(pdfUrl, "_blank");
    

    // Close the modal
    handleCloseNFeModal();
  };

  return (
    <Panel page="tx_history" title={t("InvestmenntsList")}> {/* "Histórico de transações" */}
      {!loading ? (
        <div className="row">
          <Col>
            <div className="div-export-csv" >
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#50504E",
              }}
            >
            </div>
            <InvestmentList id={id} /> {/* Passa o ID da URL para o componente */}
            {txHistory.length > 0 && (
              <Pagination pagination={pagination} setPagination={setPagination} />
            )}
          </Col>
        </div>
      ) : (
        <div className="text-center mt-3 mb-3">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}

      {/* Modal to display NFes */}
      <Modal show={selectedOrderId !== null} onHide={handleCloseNFeModal}>
        <Modal.Header closeButton>
          <Modal.Title>NFes {t("fororder")} {/* para a Ordem */} #{selectedOrderId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrderId !== null && (
            <ul>
              {txHistory
                .filter((order) => order.id === selectedOrderId)
                .map((order) => (
                  order.nfes.map((nfe) => (
                    <li key={nfe.nfeid}>
                      <Button
                        onClick={() => handleSelectNFe(nfe.nfeid)}
                        variant="link"
                      >
                        {t("see")} NFe #{nfe.nfeid}
                      </Button>
                    </li>
                  ))
                ))}
            </ul>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNFeModal}>
          {t("close")} {/* Fechar */}
          </Button>
        </Modal.Footer>
      </Modal>

    </Panel>
  );
}

export default ClientInvestments;
