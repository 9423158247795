import React, { useContext, useState } from "react";
import Panel from "../components/Panel";
import { AppContext } from "../context/AppContext";
import OrderBook from "../components/Orderbook";
import CreateBuyOrder from "../components/CreateBuyOrder";
import CreateSellOrder from "../components/CreateSellOrder";
import MaintenancePage from "./MaintenancePage";
import { useTranslation, Trans } from 'react-i18next';
import { Form } from "react-bootstrap";

function Orderbook() {
  const { maintenanceMode } = useContext(AppContext);
  const [selectedCoin, setSelectedCoin] = useState("BTC");
  const { t } = useTranslation();
  const handleCoinChange = (event) => {
    setSelectedCoin(event.target.value);
  };

  return (
    <Panel page="orderbook" title="Orderbook">
      {maintenanceMode ? (
        <MaintenancePage />
      ) : (
        <>
          <div>
            <Form.Label>{t("selectcoin")} {/* Selecione a moeda */}</Form.Label>
            <select id="coin-select" value={selectedCoin} onChange={handleCoinChange}
              className="form-control input"
              style={{ width: "20%" }}>
              <option value="BTC">BTC</option>
              { <option value="USDT">USDT</option> }
            </select>
          </div>
          <div className="orderbook mt-3 mb-4 justify-content-around">
            <CreateBuyOrder selectedCoin={selectedCoin} />
            <CreateSellOrder selectedCoin={selectedCoin} />
          </div>
          <OrderBook selectedCoin={selectedCoin} />
        </>
      )}
    </Panel>
  );
}

export default Orderbook;
