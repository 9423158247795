import React, { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { Container } from "react-bootstrap";
import HeaderBar from "../components/HeaderBar";
import BottomBar from "../components/BottomBar";
import { logout } from "../utils/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Logout = (props) => {
  const { setLoggedInUser } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    setLoggedInUser({});
    toast.dismiss();
    logout();
    navigate("/");
  }, []);

  return (
    <Container className="p-0" fluid>
      <HeaderBar hide />
      <Container
        style={{
          height: 600,
          width: "100%",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: 150,
        }}
      ></Container>
      <BottomBar />
    </Container>
  );
};

export default Logout;
