import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import api from "../../../../utils/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { disableAction } from "../../../../utils/helper";
import { AppContext } from "../../../../context/AppContext";
import {
  formatStatus,
  WITHDRAW_TYPES,
  WITHDRAW_STATUS,
} from "../../../../utils/types";
import { numberFormatBRL } from "../../../../utils/Helpers/btcFormat";

export default function Withdraws({ withdraws }) {

  const { investment } = useContext(AppContext);

  // const create = async (table) => {
  //   let message;
  //   if (table === 0) {
  //     message = `Deseja inserir um depósito adicional ao contrato?`;
  //   } else if (table === 1) {
  //     message = `Deseja registrar uma API?`;
  //   } else {
  //     message = `Deseja informar um novo saque ao contrato?`;
  //   }
  //   confirm.current.show(
  //     "Atenção",
  //     <>
  //       <h3 className="text-center mb-2">{message}</h3>
  //     </>,
  //     async () => {
  //       if (table === 0) {
  //         deposits.show(params.id, "");
  //       } else if (table === 1) {
  //         apiRef.current.show(withdraws.type, "");
  //       } else {
  //         withdraw.show(params.id, "");
  //       }
  //     }
  //   );
  // };

  // const deleteRow = async (table, row) => {
  //   let message;
  //   if (table === 0) {
  //     message = `o depósito?`;
  //   } else if (table === 1) {
  //     message = `a API?`;
  //   } else {
  //     message = `o saque?`;
  //   }
  //   confirm.current.show(
  //     "Atenção",
  //     <>
  //       <h3 className="text-center mb-2">Deseja excluir {message}</h3>
  //       <h6 className="text-center text-danger">
  //         Esta ação não pode ser desfeita
  //       </h6>
  //     </>,
  //     async () => {
  //       let url;
  //       if (table === 0) {
  //         url = `investments-deposits/${row.id}`;
  //       } else if (table === 1) {
  //         url = `apis/${row.id}`;
  //       } else {
  //         url = `investments-withdraws/${row.id}`;
  //       }
  //       let req = await api.del(url);
  //       if (!req.error) {
  //         // reloadInvestment();
  //       }
  //     }
  //   );
  // };

  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "#50504E",
          marginTop: 30,
        }}
      >
        <span className="bold text1 ml-3 text-mid2">HISTÓRICO DE SAQUES</span>
      </div>
      <Table className="hist-table m-0 p-0 text-mid" responsive striped hover>
        <thead>
          <tr>
            <th>Data</th>
            <th>Tipo</th>
            <th>Status</th>
            <th>Valor Final</th>
          </tr>
        </thead>
        { (withdraws?.length > 0) ? (<tbody>
          { withdraws.map((dr) => {
              return (dr.withdraws.map((row) => (
                <tr key={row.id}>
                  <td>{dayjs(dr.date).format("DD/MM/YYYY")}</td>
                  <td>{(row.type === "anticipated" ? "antecipado" : "de rentabilidade")}</td>
                  {(row.status === "approved") && <td>aprovado</td>}
                  {(row.status === "pending") && <td>pendente</td>}
                  {(row.status === "rejected") && <td>rejeitado</td>}
                  <td>{numberFormatBRL(row.totalAmount)}</td>
                </tr>
              )))
          })}
          {/* {withdraws.map((row) => (
            <tr key={row.id}>
              <td>{dayjs(row.createdAt).format("DD/MM/YYYY")}</td>
              <td>{formatStatus(WITHDRAW_TYPES, row.type)}</td>
              <td>{formatStatus(WITHDRAW_STATUS, row.status)}</td>
              <td>{row.requestedAmount} BTC</td>
              <td>{row.operatingFee}%</td>
              <td>{row.proRata}%</td>
              <td>{row.penalty}%</td>
            </tr>
          ))} */}
        </tbody>)
        : (<tbody>
          <tr>
            <td className="text-center" colSpan={6}>
              Sem saques para mostrar
            </td>
          </tr>
        </tbody>) 
      }
      </Table>
      {/* <div className="text-center d-flex text-large mt-3 mb-5">
        <Button
          disabled={disableAction(
            context.loggedInUser?.permission?.level,
            false,
            false,
            false
          )}
          className="ml-3 mr-auto"
          onClick={() => create(2)}
        >
          Novo Saque
        </Button>
      </div> */}
      </>
  );
}
