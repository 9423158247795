import dayjs from "dayjs";
import React from "react";
import { Col, Table, Button } from "react-bootstrap";

import Panel from "../components/Panel";
import api from "../utils/api";
import { DEPOSITS_STATUS_TYPES, formatStatus } from "../utils/types";

export default class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      rows: [],
      filteredRows: [],
      rows2: [],
      filteredRows2: [],
      page: 0,
      pages: 0,
    };
    this.inputDoc = React.createRef();
  }

  componentDidMount = async () => {
    let rows;
    await api.get("deposits-users").then((req) => {
      if (!req.error) {
        rows = req.data;
      }
    });

    let rows2;
    await api.get("withdraw-users").then((req) => {
      if (!req.error) {
        rows2 = req.data;
      }
    });

    let r = rows.concat(rows2);

    r = r.sort((a, b) => b.created.localeCompare(a.created));
    
    this.setState({
      rows: r,
      filteredRows: r.slice(0, 20),
      load: false,
      page: 0,
      pages: Math.floor(r.length / 20),
    });
  };

  setPage = (page) => {
    if (page < 0) {
      page = 0;
    } else if (page > this.state.pages) {
      page = this.state.pages;
    } else {
      this.setState({
        page: page,
        filteredRows: this.state.rows.slice(page * 20, page * 20 + 20),
      });
    }
  };

  render() {
    return (
      <Panel history={this.props.history} page="history" title="Histórico">
        <div className="row">
          <Col>
            <div className="div-export-csv" >
              <Button className="export-csv">
                Exportar CSV
              </Button>
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#50504E",
              }}
            >
              <span className="bold text1 ml-3 text-mid2">Histórico</span>
            </div>
            <Table className="hist-table m-0 p-0" responsive="md" striped hover>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Tipo de transação</th>
                  <th>Moeda</th>
                  <th>Quantidade</th>
                  <th>Descrição</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {this.state.filteredRows.map((row, i) => {
                  return (
                    <tr key={row.id}>
                      <td>{dayjs(row.created_at).format("DD/MM/YYYY HH:mm:ss")}</td>
                      <td>{row.final_value ? "Saque" : "Depósito"}</td>
                      <td>Real</td>
                      <td>R$ {row.amount.toFixed(2)}</td>
                      <td>Dep. C/C Itaú Ag</td>
                      <td>{formatStatus(DEPOSITS_STATUS_TYPES, row.status)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <div className="text-center text-large mt-3 mb-5">
              <span
                onClick={() => this.setPage(this.state.page - 1)}
                className="pr-2 btn"
              >
                <i className="fas fa-caret-left"></i>
              </span>
              <span>
                Página {this.state.page + 1} de {this.state.pages + 1}
              </span>
              <span
                onClick={() => this.setPage(this.state.page + 1)}
                className="pl-2 btn"
              >
                <i className="fas fa-caret-right"></i>
              </span>
            </div>
          </Col>
        </div>
      </Panel>
    );
  }
}
