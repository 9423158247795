import React from "react";
import api from "../utils/api";
// import { getStockQuotes } from "../utils/fetchNasdaq";
import { AppContext } from "../context/AppContext";
import CurrencyCard from "./CurencyCard";
export default class CurrenciesBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stocks:  [],
      load: true,
      stockload: true,
      brlPrice: 0,
      prices: [
        {
          symbol: "BTC",
          base: "USD",
          name: "Bitcoin",
          price: 0,
          variation: 0,
        },
        {
          symbol: "ETH",
          base: "USD",
          name: "Ethereum",
          price: 0,
          variation: 0,
        },
        {
          symbol: "USD",
          base: "BRL",
          name: "Dolar",
          price: 0,
          variation: 0,
        },

      ],
    };
  }

  static contextType = AppContext;

  componentDidMount = () => {
    
    api.get("prices").then((req) => {
      if (!req.error) {
        let prices = req.data;

        let brlPrice = 0;
        prices.forEach((price) => {
          if (price.symbol === "USD" && price.base === "BRL") {
            brlPrice = price.price;
          }
        });

        const btcPrice = req.data.filter((coin) => {
          return coin.symbol === "BTC";
        });

        this.context.setBtcPrice(btcPrice[0].price * brlPrice);
        this.context.setUsdtPrice(brlPrice);

        this.setState({
          load: false,
          brlPrice,
          prices,
        });
      }
    });
    // getStockQuotes().then((req) => {
    //   if (!req.error) {
    //     let stocks = req;
        
    //     this.setState({
    //       stocks: stocks,
    //       stockload: false,
    //     });
    //   }
    // })
  };

  render() {
    return (
      <div className="currency-card-row">
          {this.state.prices.map((price, index) => (
            <div className="currency-card-component" key={`curr-card=${index}`}>
              <CurrencyCard
                price={price}
                brlPrice={this.state.brlPrice}
                load={this.state.load}
                />
            </div>
          ))}
          {/* {console.log(this.state.stocks)} */}
          {/* {this.state.stocks?.map((s) => (
            <div className="currency-card-component">
              <CurrencyCard
                price={{symbol: 'NASDAQ', base: 'BRL', name: s.symbol, price: s.price, variation: s.changesPercentage}}
                brlPrice={this.state.brlPrice}
                load={this.state.stockload}
                />
            </div>
          ))} */}
      </div>
    );
  }
}
