import React from "react";
import Panel from "../../components/Panel";

export default class AdminEmployees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="tickets"
        title="Tickets"
        admin={true}
      ></Panel>
    );
  }
}
