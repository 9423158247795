import React, { useContext, useEffect, useState } from "react";
import Profitabilities from "./profitabilities";
import Withdraws from "./withdraws";
import Deposits from "./deposits";
import { microservice } from "../../../utils/api";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";

export default function Investment() {
  const params = useParams();

  const [profitabilities, setProfitabilities] = useState([]);
  const [withdraws, setWithdraws] = useState([]);
  const [deposits, setDeposits] = useState([]);

  const {
    setInvestment,
  } = useContext(AppContext);

  async function getInvestmentInfos() {
    try {
      // const res = await api.get(`investments/${params.id}/profitabilities`);
      // setProfitabilities(res.data);
      const res = await microservice.get(`investments/${params.id}`);
      setInvestment(res);
      if (res.dailyReports?.length > 0) {
        const rentability = res.dailyReports.filter((dr) => { 
          if (dr.appliedProfit) {
            return true
          } else return null
        })
        setProfitabilities(rentability);
        const withdraws = res.dailyReports.filter((dr) => {
          if (dr.withdraws?.length > 0) {
            return true
          } else return null
        })
        setWithdraws(withdraws);
        const deposits = res.dailyReports.filter((dr) => {
          if (dr.deposits?.length > 0) {
            return true
          } else return null
        })
        setDeposits(deposits);
      }
    } catch (err) { }
  }

  useEffect(() => {
    getInvestmentInfos()
  }, []);


  return (
    <>
      <Profitabilities profitabilities={profitabilities}/>
      <Withdraws withdraws={withdraws}/>
      <Deposits deposits={deposits}/>
    </>
  );
}
