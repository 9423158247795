import React from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

const LPBottomBar = () => {
  const { t } = useTranslation();

  return (
    <div className="lp-bottom-bar">
      <CookieConsent
        location="bottom"
        buttonText={t('acceptcookie')}
        cookieName="cookieConsent"
        style={{ background: "#011838" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        onAccept={(acceptedByScrolling) => {
          if (acceptedByScrolling) {
            // triggered if user scrolls past threshold
            // alert("Accept was triggered by user scrolling");
          } else {
            // alert("Accept was triggered by clicking the Accept button");
          }
        }}
      >
        {t('cookie')} <a href="/politica-de-privacidade-exchange" target="_blank"><span className="bt-bar-text">
          {t('ppolicy')}
        </span></a>
      </CookieConsent>
      <div className="bt-bar-content">
        <a><img
          src="/images/logo-gold.png"
          width="60"
          height="60"
          className="d-inline-block align-top"
          alt="BC Coins Logo"
        /></a>
        <div className="bt-bar-links">
          <a href="/tarifas" target="_blank"><span className="bt-bar-text">
            {t('taxes')}
          </span></a>
          <a href="/termos-de-uso-exchange" target="_blank"><span className="bt-bar-text">
            {t('terms')}
          </span></a>
          <a href="/politica-de-privacidade-exchange" target="_blank"><span className="bt-bar-text">
            {t('ppolicy')}
          </span></a>
          <a><span className="bt-bar-text">
            CNPJ 21.830.817/0001-67
          </span></a>
          <a><span className="bt-bar-text">
            {t('copyright')} ©
          </span></a>
        </div>
        <div className="social-media">
          <a href="https://twitter.com/bcxcorretora" className="bt-bar-icon"><i className="fab fa-2x fa-twitter"></i></a>
          {/* <div href="/panel/" className="bt-bar-icon"><i className="fab fa-2x fa-facebook-f"></i></div> */}
          <a href="https://www.instagram.com/bcxcorretora" className="bt-bar-icon"> <i className="fab fa-2x fa-instagram"></i></a>
        </div>
      </div>
      <div className="address-container">
        <span className="address-text">
          BCX CORRETORA Av. Osvaldo Reis, 3385 Sala 1407 Praia Brava - Itajaí - SC CEP: 88306-773
        </span>
      </div>
    </div>
  );
};

export default LPBottomBar;
