export function formatBTCString(value) {
  value = String(value);

  const matches = value.match(/e-(\d+)/);

  if (matches) {
    const numberAfterE = matches[1];
    const zeros = '0'.repeat(numberAfterE - 1);
    const cleanValue = value.replace(/[.,]/g, '');
    value = `0.${zeros}${cleanValue.replace(/e-(\d+)/, '')}`;
  }

  const cleanedValue = value.replace(/\s/g, '').replace(/[^0-9.]/g, '');

  const [integerPart, decimalPart] = cleanedValue.split('.');

  const trimmedIntegerPart = parseInt(integerPart, 10).toString();

  let formattedDecimalPart = decimalPart ? decimalPart.substr(0, 8) : '00000000';
  formattedDecimalPart = formattedDecimalPart.padEnd(8, '0');

  return `${trimmedIntegerPart}.${formattedDecimalPart} BTC`;
  // value = String(value)

  // const cleanedValue = value.replace(/\s/g, '').replace(/[^0-9.]/g, '');
  
  // const [integerPart, decimalPart] = cleanedValue.split('.');

  // const trimmedIntegerPart = parseInt(integerPart, 10).toString();

  // const formattedDecimalPart = decimalPart ? decimalPart.padEnd(8, '0') : '00000000';

  // return `${trimmedIntegerPart}.${formattedDecimalPart} BTC`;
}

export function formatEVMString(value,decimals,coin) {
  value = String(value);

  const matches = value.match(/e-(\d+)/);

  if (matches) {
    const numberAfterE = matches[1];
    const zeros = '0'.repeat(numberAfterE - 1);
    const cleanValue = value.replace(/[.,]/g, '');
    value = `0.${zeros}${cleanValue.replace(/e-(\d+)/, '')}`;
  }

  const cleanedValue = value.replace(/\s/g, '').replace(/[^0-9.]/g, '');

  const [integerPart, decimalPart] = cleanedValue.split('.');

  const trimmedIntegerPart = parseInt(integerPart, 10).toString();

  let formattedDecimalPart = decimalPart ? decimalPart.substr(0, decimals) : '000000';
  formattedDecimalPart = formattedDecimalPart.padEnd(decimals, '0');

  return `${trimmedIntegerPart}.${formattedDecimalPart} ${coin.toLocaleUpperCase()}`;
  // value = String(value)

  // const cleanedValue = value.replace(/\s/g, '').replace(/[^0-9.]/g, '');
  
  // const [integerPart, decimalPart] = cleanedValue.split('.');

  // const trimmedIntegerPart = parseInt(integerPart, 10).toString();

  // const formattedDecimalPart = decimalPart ? decimalPart.padEnd(8, '0') : '00000000';

  // return `${trimmedIntegerPart}.${formattedDecimalPart} BTC`;
}

export function numberFormatBRL (value) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}