import React from 'react';
import Panel from '../components/Panel';


export default class Mining extends React.Component {

    render() {
        return (<Panel history={this.props.history} page="mining" title="Investimentos - Mineração">
            <h1 className="text2 text-center mt-5">Em breve</h1>
        </Panel >)
    }
}
