import React from "react";
import Panel from "../../components/Panel";
import { Table, Button, Modal, Form } from "react-bootstrap";
import api from "../../utils/api";
import {
  DEPOSITS_STATUS_TYPES,
  formatStatus,
  currencyConfig,
} from "../../utils/types";
import IntlCurrencyInput from "react-intl-currency-input";
import { useNavigate } from "react-router-dom";
import { disableAction } from "../../utils/helper";
import { AppContext } from "../../context/AppContext";
import Pagination from "../../components/Pagination";
import { numberFormatBRL } from "../../components/InvestmentId";

class WithdrawDeposits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      rows: [],
      withdrawList: [],
      showModal: false,
      usersId: null,
      withdrawsId: null,
      tax_value: 0,
      pro_rata_value: 0,
      message: "",
      offset: 0,
      form: {
        amount: 0,
        tax: 0,
        pro_rata: 0,
        final_value: 0,
      },
    };
  }

  static contextType = AppContext;

  componentDidMount = async () => {
    this.reloadTable();

    this.context.setPagination((pagination) => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });
  };

  componentDidUpdate(_, prevState) {
    if (this.context.pagination.offset !== prevState.offset) {
      this.reloadTable();
    }
  }

  reloadTable = async () => {
    this.setState({
      offset: this.context.pagination.offset,
      load: true,
    });

    let req = await api.get("users");

    if (req.error) return;
    let users = req.data.data;
    req = await api.get(
      `withdraws?filter[order]=created%20DESC&filter[skip]=${this.context.pagination.offset}&filter[limit]=${this.context.pagination.limit}`
    );

    this.context.setPagination((pagination) => {
      return {
        ...pagination,
        limit: req.data.limit,
        lastPage: Math.ceil(req.data.total / req.data.limit),
      };
    });

    if (req.error) return;
    let data = req.data.data.map((deposit) => {
      users.forEach((user) => {
        if (user.id === deposit.usersId) {
          deposit.user = user;
        }
      });
      return deposit;
    });

    this.setState({
      load: false,
      withdrawList: data,
    });
  };

  goTo = (row) => {
    this.props.navigate("/panel/admin/user/" + row.user.id);
  };

  cancel = async (row) => {
    let req = await api.patch("withdraws/" + row.id, {
      status: "canceled",
    });
    if (req.error) return;
    this.reloadTable();
    this.getCountPendingStatus();
  };

  btnConfirm = async (row) => {
    this.setState({
      showModal: true,
      usersId: row.usersId,
      withdrawsId: row.id,
      form: {
        amount: row.amount,
        final_value: row.amount,
      },
    });
  };

  confirm = async (row) => {
    this.setState({
      showModal: false,
    });
    
    this.reloadTable();
      this.getCountPendingStatus();

    let req = await api.patch("withdraws/" + this.state.withdrawsId, {
      status: "confirmed",
      amount: this.state.form.amount,
      pro_rata_value: this.state.pro_rata_value,
      tax_value: this.state.tax_value,
      message: this.state.message,
      final_value: (
        this.state.form.amount -
        this.state.tax_value -
        this.state.pro_rata_value
      ).toFixed(2),
    }).then((req) => {
  
      this.reloadTable();
      this.getCountPendingStatus();
    });
    if (req.error) return;

    req = await api.get("investments");
    if (req.error) return;
    var investement = await req.data.data.filter(
      (x) => x.usersId === this.state.usersId
    );
    if (investement[0]) {
      await api.post(`investments/${investement[0].id}/investments-withdraws`, {
        type: "withdraw",
        fee:
          parseInt(this.state.form.amount.toFixed(2)) -
          parseInt(
            (
              this.state.form.amount -
              this.state.tax_value -
              this.state.pro_rata_value
            ).toFixed(2)
          ),
        amount: parseInt(this.state.form.amount.toFixed(2)),
        investmentsId: investement[0].id,
      });
    }
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  applyTax = async (event, value) => {
    var p = event.target.value;

    var percent = (this.state.form.amount / 100) * p;
    percent = percent.toFixed(2);

    this.setState({
      tax_value:
        this.state.form.amount - (this.state.form.amount - percent).toFixed(2),
    });
  };

  applyMessage = async (event, value) => {
    var p = event.target.value;

    this.setState({ message: p });
  };

  applyProRata = async (event, value) => {
    var p = event.target.value;

    var percent = (this.state.form.amount / 100) * p;
    percent = percent.toFixed(2);

    this.setState({
      pro_rata_value:
        this.state.form.amount - (this.state.form.amount - percent).toFixed(2),
    });
  };

  handleChangeCurrency = (event, value, maskedValue) => {
    let form = this.state.form;
    form[event.target.name] = value;
    this.setState({ form });
  };

  getCountPendingStatus = async () => {
    await api.get("stats").then((req) => {
      if (!req.error) {
        this.context.setCountPendingStatus(req.data.data);
      }
    });
  };

  render() {
    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="withdraw"
        title="Aprovação de Saques"
        admin
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#50504E",
          }}
        >
          <span className="bold text1 ml-3 text-mid2">Saques</span>
        </div>
        <Table
          className="hist-table m-0 p-0 text-mid"
          responsive="md"
          striped
          hover
        >
          <thead>
            <tr>
              <th>Data</th>
              <th>Cliente</th>
              <th>Valor Solicitado</th>
              <th>Tarifas</th>
              <th>Valor Final</th>
              <th>Banco</th>
              <th>Agencia</th>
              <th>Conta</th>
              <th>Status</th>
              <th>Nota fiscal</th>
              <th>Ver Cliente</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.withdrawList.map((row) => (
              <tr key={row.id}>
                { console.log('ROW >>>>', row) }
                <td>{new Date(row.created).toLocaleString()}</td>
                <td>{row.user ? row.user.name : ""}</td>
                <td>{numberFormatBRL(row.amount)}</td>
                <td>{numberFormatBRL(row.tax_value)}</td>
                <td>{numberFormatBRL(row.final_value)}</td>
                <td>{row.bank}</td>
                <td>{row.agency}</td>
                <td>{row.account}</td>
                <td>{formatStatus(DEPOSITS_STATUS_TYPES, row.status)}</td>
                <td>
                  {row.nfseId === "" || row.nfseId === null ? (
                    "Não enviado"
                  ) : (
                    <a
                      className="link-file"
                      href={`${api.getURL()}notas/${row.nfseId}/download`}
                    >
                      download
                    </a>
                  )}
                </td>
                <td>
                  <Button onClick={() => this.goTo(row)}>Ver</Button>
                </td>
                <td>
                  <Button
                    className={
                      row.status !== "waiting" && row.status !== "sent"
                        ? "d-none"
                        : ""
                    }
                    variant="success"
                    onClick={() => this.btnConfirm(row)}
                    disabled={disableAction(
                      this.context.loggedInUser?.permission?.level,
                      false,
                      true,
                      false
                    )}
                  >
                    Confirmar
                  </Button>
                </td>
                <td>
                  <Button
                    className={
                      row.status !== "waiting" && row.status !== "sent"
                        ? "d-none"
                        : ""
                    }
                    variant="danger"
                    onClick={() => this.cancel(row)}
                    disabled={disableAction(
                      this.context.loggedInUser?.permission?.level,
                      false,
                      true,
                      false
                    )}
                  >
                    Cancelar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {this.state.withdrawList.length > 0 && (
          <Pagination
            pagination={this.context.pagination}
            setPagination={this.context.setPagination}
          />
        )}

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-center">
              Confirme o valor
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <Form className="m-0" onSubmit={this.onSubmit}>
              <Form.Group>
                <Form.Label className="mt-3 d-flex justify-content-between">
                  <p>Valor Do Saque (BRL)</p>
                </Form.Label>
                <Form.Control
                  as={IntlCurrencyInput}
                  currency="BRL"
                  disabled={true}
                  config={currencyConfig}
                  className="input"
                  name="amount"
                  value={this.state.form.amount}
                />
                {/* <Form.Label className="mt-3 d-flex justify-content-between">
                  <p>Tarifa de corretagem</p>
                  <p>%</p>
                </Form.Label>
                <Form.Control
                  className="input"
                  name="tax"
                  type="number"
                  value={this.state.form.tax}
                  onChange={this.applyTax}
                  required
                />

                <Form.Label className="mt-3 d-flex justify-content-between">
                  <p>Pro rata</p>
                  <p>%</p>
                </Form.Label>
                <Form.Control
                  className="input"
                  type="number"
                  name="pro_rata"
                  value={this.state.form.pro_rata}
                  onChange={this.applyProRata}
                  required
                />

                <Form.Label className="mt-3 d-flex justify-content-between">
                  <p>Comentário</p>
                </Form.Label>
                <Form.Control
                  className="input"
                  type="text"
                  name="message"
                  value={this.state.message}
                  onChange={this.applyMessage}
                  required
                />

                <Form.Label className="mt-3 d-flex justify-content-between">
                  <p>Valor Final</p>
                  <p>
                    R${" "}
                    {(
                      this.state.form.amount -
                      this.state.tax_value -
                      this.state.pro_rata_value
                    ).toFixed(2)}
                  </p>
                </Form.Label> */}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.confirm}>
              OK
            </Button>
            <Button variant="primary" onClick={this.handleClose}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </Panel>
    );
  }
}

export default (props) => (
  <WithdrawDeposits {...props} navigate={useNavigate()} />
);
