import React from "react";
import {
  Col,
  Row,
} from "react-bootstrap";
import Panel from "../components/Panel";
import { AppContext } from "../context/AppContext";
import api from "../utils/api";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

class DocHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docs: [],
      docsByUser: [],
      allDocs: [],
      load: true,
      title: '',
      email: '',
      file: '',
      isAdmin: false,
    };
  }

  static contextType = AppContext;
  componentDidMount = () => {
    this.getDocs();
  };

  compararPorPropriedade(a, b) {
    if (a.userName < b.userName) {
      return -1;
    }
    if (a.userName > b.userName) {
      return 1;
    }
    return 0;
  }

  getDocs = async () => {
    this.setState({
      offset: this.context.pagination.offset,
      load: true,
    });

    await api.get('users-relatories/list').then((req) => {
      if (!req.error) {
        let tradeHistory = req.data;
        this.setState({
          docs: req.data,
          load: false,
          historyTradeMaster: tradeHistory.data,
        });
      }
    });
  };

  getDocsByUser = async (id) => {
    await api.get(`users-relatories?filter={"where":{"usersId": ${id}}}`)
      .then((req) => {
        if (!req.error) {
          this.setState({
            docsByUser: req.data,
          });
        }
      });
  };

  getAllUploadedDocs = async () => {
    await api.get(`users-relatories`)
      .then((req) => {
        console.log(req)
        if (!req.error) {
          const sorted = req.data.sort(this.compararPorPropriedade);
          this.setState({
            allDocs: sorted,
          });
        }
      });
  };

  async callDownload(id) {
    await api.get(`users-relatories/${id}/download`);
  }

  async callSaveDoc() {
    let resp = await api.postFile(`users-relatories`, this.state.file, this.state.email, this.state.title);
    if(resp.data){
      toast.success("Success upload archive");
      this.getAllUploadedDocs();
    }else{
      toast.error("Success upload archive");
    }
  }

  setValue(evt) {
    const val = evt.target.value;
    this.setState({
      [evt.target.name]: val
    });
  }

  handleFileChange = (e) => {
    if (e.target.files) {
      this.setState({file : e.target.files[0]})
    }
  };

  deleteDocument = (id) => {
    api.del(`users-relatories/${id}`)
      .then((req) => {
        if (!req.error) {
          toast.success(this.props.t("docdeletesuccess")) // "Documento deletado com sucesso"
          this.getAllUploadedDocs();
        } else {
          toast.error(this.props.t("error.docdelete")); // "Erro ao deletar documento"
        }
      });
  };

  render() {
    return (<>
          <Panel
              load={this.state.load}
              history={this.props.history}
              page="doc_history"
              title={this.props.t("docshistorytitle")}
            >
            <Row className="">
              <Col className="doc_list">
                <p className="bold bg-red">{this.props.t("docs")} {/* Documentos */}</p>
                {this.state.docs.map((item, index) => (
                  <a
                    key={item.id}
                    className="link-file"
                    href={`${api.getURL()}users-relatories/${item.id}/download`}
                  >
                    {item.title}
                  </a>
                ))}
              </Col>
            </Row>
          </Panel>
      </>
    );
  }
}

export default withTranslation()(DocHistory);