import React from "react";
import Panel from "../components/Panel";
import { AppContext } from "../context/AppContext";
import InvestmentsSumary from "../components/InvestmentsSummary";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';


class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      disable: false,
      rows: [],
      hasInvestmentActive: false,
    };
  }

  static contextType = AppContext;

  componentDidMount = async () => {
    this.setState({
      load: false,
      disable: this.context.allInvestments?.length === 0,
    });
  };

  render() {
    return (
      <Panel
        history={this.props.history}
        page="bem-vindo"
        title={
         this.props.t('welcome')
        }
      > 
      <div style={{ width: "100%", display: "flex", justifyContent: "center",  marginTop: "30px", marginBottom: "20px"}}>
        <img  style={{width: "250px"}} src={'/images/bull-head.svg'} alt="BCX Bull Logo"/>
      </div>
      <h2 style={{marginBottom: "20px"}}> {this.props.t('be')} {this.props.t('welcome')}</h2>
      <div style={{ textAlign: "center" }}>
        <p>{this.props.t('helptext')}</p>
      </div>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", margin: "20px", justifyContent: "space-evenly" }}>
        {this.context.hasInvestmentPermission ? (<div>
          <Link to="/panel/trade">
            <button className="btn btn-primary welcome-btn">{this.props.t('checkinvest')}</button>
          </Link>
        </div>) : (
          <></>
        )}
        <div>
          <Link to="/panel/deposits">
            <button className="btn btn-primary welcome-btn">{this.props.t('deposit')}</button>
          </Link>
        </div>
        <div>
          <Link to="/panel/orderbook">
            <button className="btn btn-primary welcome-btn">{this.props.t('orderbook')}</button>
          </Link>
        </div>
        <div>
          <button
            className="btn btn-primary welcome-btn"
            onClick={() => {
              this.context.setModalBuyBTC(true);
            }}
          >
            {this.props.t('buywbalance')}
          </button>
        </div>
      </div>
      </Panel>
    );
  }
}

export default withTranslation()(Welcome);