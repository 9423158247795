import React from "react";
import Panel from "../../components/Panel";
import { Table, Button } from "react-bootstrap";
import api from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { disableAction, disableActionAdminUsers } from "../../utils/helper";
import Pagination from "../../components/Pagination";
import { toast } from 'react-toastify';

class AdminUsers extends React.Component {

  
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      rows: [],
      usersList: [],
      allUsersList: [],
      page: 0,
      pages: 0,
      offset: 0,
      userCount: 0
    };
  }
  static contextType = AppContext;

  componentDidMount = () => {
    this.reloadTable();
    this.getAllUsers();

    this.context.setPagination(() => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });
  };

  componentDidUpdate(_, prevState) {
    if (this.context.pagination.offset !== prevState.offset) {
      this.reloadTable();
    }
  }

  getAllUsers = () => {
    api
      .get(
        `users?filter[order]=createdAt%20DESC&filter[skip]=0&filter[limit]=1000000&filter[where][rolesId]=f1915543-5015-45c6-959c-e5d9417d2053`
      )
      .then((req) => {
        if (!req.error) {
          let users = req.data;
          let usersCount = users.total;

          this.setState({userCount: usersCount});

          this.setState({
            load: false,
            allUsersList: users.data,
          });
        }
      });
  };

  reloadTable = () => {
    this.setState({
      offset: this.context.pagination.offset,
      load: true,
    });

    api
      .get(
        `users?filter[order]=createdAt%20DESC&filter[skip]=${this.context.pagination.offset}&filter[limit]=${this.context.pagination.limit}&filter[where][rolesId]=f1915543-5015-45c6-959c-e5d9417d2053`
      )
      .then((req) => {
        if (!req.error) {
          let users = req.data;

          this.context.setPagination((pagination) => {
            return {
              ...pagination,
              limit: users.limit,
              lastPage: Math.ceil(users.total / users.limit),
            };
          });

          this.setState({
            load: false,
            usersList: users.data,
          });
        }
      });
  };

  formatStatusKYC = (kycStatus) => {
    if (kycStatus === "none") {
      return "0";
    } else {
      return kycStatus;
    }
  };

  deleteUser = async (user) => {
    let response = window.confirm(
      "ATENÇÂO!!! Esta ação não pode ser desfeita.\nDeletar usuário: " +
        user.name
    );
    if (response) {
      let req = await api.del(`users/${user.id}`);
      if (!req.error) {
        this.reloadTable();
      }
    }
  };

  goToEdit = (row) => {
    this.context.setSelectedUser(row);
    this.props.navigate("/panel/admin/user/" + row.id);
  };

  goToReferal = (row) => {
    this.context.setSelectedUser(row);
    console.log("referral: " + row.referralCode)
    if (row.referralCode != null){
      this.props.navigate("/panel/admin/usersreferral/" + row.referralCode);
    } else {this.toasterror("User has no referal code")}
  };

  changeStatus = (id, status) => {
    api
      .patch("users/activate", {
        id,
        active: status ? true : false,
      })
      .then((res) => {
        this.reloadTable();
      });
  };

  handleInputChange = (event) => {
    console.log(event.target.value);
    let value = event.target.value;
    let users = this.state.allUsersList;
    let filteredUsers = users.filter((user) => {
      return (
        user.name.toLowerCase().includes(value.toLowerCase()) ||
        user.email.toLowerCase().includes(value.toLowerCase())
      );
    });

    this.setState({
      usersList: filteredUsers,
    });

    if (value === "") {
      this.reloadTable();
    };
  };

  toasterror = (error) => {
    toast.error(`${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
  });
  } 

  render() {
    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="admin/users"
        title="Clientes cadastrados"
        admin={true}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#50504E",
            height: "60px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "10px",
            alignItems: "center",
          }}
        >
          <span className="bold text1 ml-3 text-mid2">Lista de Clientes</span>
          <div>
            <span style={{color:"white"}}>Qtd Total: {this.state.userCount}</span>
            <span className="bold text1 ml-3 text-mid2">Buscar: </span>
            <input
            type="text"
            onChange={this.handleInputChange}
            style={{ borderRadius: "5px", border: "none", paddingLeft: "5px"}}
            />
            <button style={{ borderRadius: "5px", margin: "3px", border: "none", color: "#ceba85"}} onClick={this.reloadTable}>
              X
            </button>
          </div>
        </div>
        <Table
          className="hist-table m-0 p-0 text-mid"
          responsive="md"
          striped
          hover
        >
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>KYC</th>
              <th>PJ</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.usersList.map((row) => (
              <tr key={row.id}>
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.phone}</td>

                <td>{this.formatStatusKYC(row.kycStatus)}</td>
                <td>{row.legalPerson ? "Sim" : "Não"}</td>

                <td>
                  <Button
                    disabled={disableActionAdminUsers(
                      this.context.loggedInUser?.permission?.level,
                      false,
                      false,
                      false
                    )}
                    onClick={() => this.goToEdit(row)}
                  >
                    Editar
                  </Button>
                </td>
                <td>
                  <Button
                    disabled={disableActionAdminUsers(
                      this.context.loggedInUser?.permission?.level,
                      false,
                      false,
                      false
                    )}
                    onClick={() => this.changeStatus(row.id, row.deletedAt)}
                  >
                    {row.deletedAt ? "Ativar" : "Inativar"}
                  </Button>
                </td>
                <td>
                  <Button
                    disabled={disableActionAdminUsers(
                      this.context.loggedInUser?.permission?.level,
                      false,
                      false,
                      false
                    )}
                    onClick={() => this.goToReferal(row)}
                  >
                    Indicados
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {this.state.usersList.length > 0 && (
          <Pagination
            pagination={this.context.pagination}
            setPagination={this.context.setPagination}
          />
        )}
      </Panel>
    );
  }
}

export default (props) => <AdminUsers {...props} navigate={useNavigate()} />;
