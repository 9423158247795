import React, { useContext, useEffect, useState } from "react";
import Panel from "../components/Panel";
import { AppContext } from "../context/AppContext";
import api from "../utils/api";
import PanelKYC from "../components/KYC/PanelKYC";
import UploadKYC from "../components/KYC/UploadKYC";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation, Trans } from 'react-i18next';

export default function KYC(props) {
  const { setLoggedInUser } = useContext(AppContext);
  const [load, setLoad] = useState(true);
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    updateUser().then(() => {
      setLoad(false);
    });
  }, []);

  const updateUser = async () => {
    let req = await api.get("profile");
    if (!req.error) {
      setLoggedInUser(req.data);
    }
  };

  return (
    <Panel
      load={load}
      history={props.history}
      page="kyc"
      title={t("kyctitle")} // "Níveis de Segurança (KYC)"
    >
      {tab === 0 && <PanelKYC setTab={setTab} />}
      {tab === 1 && <UploadKYC setTab={setTab} />}
      <div>
        <div className="kyc-policy">
        <h4 className="kyc-policy-title">{t("withdoubt")} {/* Está com Dúvidas? */}</h4>
        <p> {t("consultkycpolicy")} {/* Consulte nossa Política de KYC clicando no botão abaixo: */} </p>
        <Link to="/panel/politica-kyc">
          <Button>{t("kycpolicy")} {/* Política do KYC */}</Button>
        </Link>
        </div>
      </div>
    </Panel>
  );
}
