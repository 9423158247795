import React, { useContext, useEffect, useState, useRef } from "react";
import Panel from "../../components/Panel";
import dayjs from "dayjs";
import { Col, Table, Button } from "react-bootstrap";
import api from "../../utils/api";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";
import ConfirmModal from "../../components/ConfirmModal";

export default function Notifications() {
  const {
    setNotifications,
    setCountNotifications,
    loggedInUser,
    pagination,
    setPagination,
  } = useContext(AppContext);

  const confirm = useRef(null);

  const [load, setLoad] = useState(true);
  const [notificationsArr, setNotificationArr] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setPagination(() => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });
  }, []);

  useEffect(() => {
    getNotifications();
  }, [pagination.offset]);

  const redirectLink = (type) => {
    if (type == "deposit-balance") {
      navigate("/panel/admin/deposits");
    }

    if (type == "withdraw-trade") {
      navigate("/panel/admin/approveWithdrawTrade");
    }

    if (type == "kyc") {
      navigate("/panel/admin/kyc");
    }

    if (type == "deposit-trade") {
      navigate("/panel/admin/investments");
    }

    if (type == "users") {
      navigate("/panel/admin/users");
    }
  };

  async function getNotifications() {
    setLoad(true);

    const req = await api.get(
      `users-notifications?filter[order]=createdAt%20DESC&filter[skip]=${pagination.offset}&filter[limit]=${pagination.limit}`
    );

    const data = req.data;

    setNotifications(data.data);
    setNotificationArr(data.data);

    setPagination((pagination) => {
      return {
        ...pagination,
        limit: data.limit,
        lastPage: Math.ceil(data.total / data.limit),
      };
    });

    setLoad(false);
  }

  async function getCountNotifications() {
    const req = await api.get(
      `users-notifications/count?where[read]=false&usersId=${loggedInUser?.id}`
    );
    setCountNotifications(req.data.count);
    setLoad(false);
  }

  const readNotification = async (id) => {
    setLoad(true);

    await api.post(`/users-notifications/${id}/read`, {
      read: true,
    });

    await getNotifications();
    await getCountNotifications();
  };

  const readAllNotifications = async () => {
    setLoad(true);

    await api.post(`users-notifications/read-all`);

    await getNotifications();
    await getCountNotifications();
  };

  const deleteNotification = async (id) => {
    setLoad(true);

    await api.del(`/users-notifications/${id}`);

    await getNotifications();
    await getCountNotifications();
  };

  return (
    <Panel page="notifications" title="Notificações" load={load} admin>
      <div className="row">
        <Col>
          <div
            style={{
              width: "100%",
              backgroundColor: "#50504E",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span className="bold text1 ml-3 text-mid2">Notificações</span>
            <div class="btn-menu text-mid">
              <span
                class="text0 mr-1"
                onClick={() => {
                  if (!confirm.current) return;
                  confirm.current.show(
                    "Atenção",
                    <h3 className="text-center">
                      Deseja marcar todas as notificações como lidas?
                    </h3>,
                    () => {
                      readAllNotifications();
                    }
                  );
                }}
              >
                Marcar todas como lido
              </span>
            </div>
          </div>
          <Table className="hist-table m-0 p-0" responsive="md" striped hover>
            <thead>
              <tr>
                <th>Data</th>
                <th>Flag</th>
                <th>Descrição</th>
                <th>Marcar como lido</th>
                <th>Ver</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody>
              {notificationsArr.length > 0 ? (
                notificationsArr.map((notification) => {
                  return (
                    <tr key={notification.id} className={`notif-row-${notification.important}`}>
                      <td>
                        {dayjs(notification.createdAt).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </td>
                      { notification.important ? (
                        <td>
                          ⚠️
                        </td>
                        ) : (
                          <td></td>
                      )}
                      <td>{notification.event}</td>
                      {notification.read ? (
                        <td>Lida</td>
                      ) : (
                        <td>
                          <Button
                            onClick={() => readNotification(notification.id)}
                          >
                            Ler
                          </Button>
                        </td>
                      )}

                      <td>
                        <Button onClick={() => redirectLink(notification.type)}>
                          Ver
                        </Button>
                      </td>

                      <td>
                        <Button
                          variant="danger"
                          onClick={() => deleteNotification(notification.id)}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center" colSpan={6}>
                    Sem notificações para mostrar.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {notificationsArr.length > 0 && (
            <Pagination pagination={pagination} setPagination={setPagination} />
          )}
        </Col>
        <ConfirmModal ref={confirm} />
      </div>
    </Panel>
  );
}
