import React, { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { numberFormatBRL } from "./InvestmentId";
import api, { microservice } from "../utils/api";
import { AppContext } from "../context/AppContext";
import { useTranslation, Trans } from 'react-i18next';
import '../i18n'

export default function InvestmentModal({ investment, showModal }) {
  const { t , i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage.toString();
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const [inputValue, setInputValue] = useState(0);

  const {
    setShowInvestmentModal,
    btcPrice,
  } = useContext(AppContext);


  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
  });


  useEffect(() => {
    setValue("amount", "0");
  }, []);

  const handleCloseModal = () => {
    setValue("amount", "0");
    setShowInvestmentModal(false);
  };

  const onInputChange = (event) => {
    setInputValue(event.target.value);
    console.log(inputValue)
  }

  const submit = async () => {
    setLoadingChangeStatus(true);
    let requestedDepositAmount = inputValue * btcPrice;
    console.log(requestedDepositAmount)
      const res = await api.post(`deposits`, {
        investmentId: investment._id,
        amount: requestedDepositAmount,
        userId: investment.userId,
        exchangeRate: btcPrice,
        locale: currentLanguage
      });
  
      if (!res.data.error && !res.error ) {
        toast.success(t("depositsuccess"), {
          position: "top-right",
          theme: "colored",
        });
      } else {
        toast.error(t("depositfailed"), {
          position: "top-right",
          theme: "colored",
        });
      }
  
      setLoadingChangeStatus(false);
      handleCloseModal();
  };

  return (
    <Modal show={ showModal } onHide={ handleCloseModal } size="sm" centered>
      <Modal.Header closeButton>
        <Modal.Title
          className="w-100 ml-3 text-center"
          style={{
            textDecoration: "underline",
            textTransform: "uppercase",
            color: "#d3b25b",
          }}
        >
          {t("deposittitle")}
        </Modal.Title>
      </Modal.Header>
      <Form className="w-100" onSubmit={handleSubmit(submit)}>

        <Modal.Body className="w-100 pb-0 pt-0">
          <Row
            className="mb-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Form.Group as={Col}>
              <Form.Label>{t("depositvalue")} {/* Valor do Aporte */}</Form.Label>
              <div className="input-group">
                <input
                  className="input append form-control"
                  name="amount"
                  type="number"
                  onChange={ onInputChange }
                  value= { inputValue }
                />
                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                    BTC
                  </span>
                </div>
              </div>
              <div style={{fontWeight: "400", display: "flex", justifyContent: "space-between", flexDirection: "row !important !important", fontStyle: "italic"}}>
                <span style={{}}>
                {t("refference")} (R$):
                </span>
                  <span>
                    { numberFormatBRL(inputValue * btcPrice) }
                  </span>
              </div>
              {errors.amount && (
                <span className="invalid-feedback show">
                  {errors.amount.message}
                </span>
              )}
            </Form.Group>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {loadingChangeStatus ? (
            <div className="text-center">
              <Spinner
                variant="secondary"
                animation="border"
                role="status"
              ></Spinner>
            </div>
          ) : (
            <>
              <Button variant="primary" type="submit">
              {t("deposit")}
              </Button>
              <Button onClick={handleCloseModal} variant="primary">
              {t("cancel")} {/* Cancelar */}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
