import React, { useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import Panel from "../../components/Panel";
import { toast } from "react-toastify";
import api, { microservice } from "../../utils/api";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "../../context/AppContext";
import CurrencyInput from "../../components/CurrencyInput"


function AdminNewInvestment(props) {
  const { btcPrice } = useContext(AppContext);

  const [selectedOption, setSelectedOption] = useState('standard');
  const [periodState, setPeriodState] = useState(0);
  const [renewFee, setRenewFee] = useState(20);
  const [initialAmountState, setInitialAmountState] = useState(0);
  const [initialAmountStateDisplay, setInitialAmountStateDisplay] = useState(0);

  const { id } = useParams();
  const navigate = useNavigate();

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  }

  const handlePeriod = (event) => {
    setPeriodState(event.target.value);
  }

  const handleRenew = (event) => {
    setRenewFee(event.target.value);
  }

  const handleInitialAmount = (event) => {
    let { value } = event.target;
    // Remove any characters that are not numbers, commas, or dots
    value = value.replace(/[^0-9.,]/g, "");


    // Replace dots with an empty string to join numbers
    value = value.replace(/\./g, "");

    setInitialAmountStateDisplay(value);

    value = value.replace(/,/g, ".");
    setInitialAmountState(value);

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    submit();
  }

  const submit = async () => {

    if (periodState < 1) {
      toast.error(`Periodo minimo permitido é de 1 mês`, {
        position: "top-right",
        theme: "colored",
      });
      return;
    }

    const res = await api.post(`investments`, {
      userId: id,
      type: "trade",
      modality: selectedOption,
      period: periodState,
      initialAmount: initialAmountState,
      "currency": "BTC",
      exchangeRate: btcPrice,
      renewFee: renewFee
    });

    if (!res.data.error) {
      toast.success("Novo investimento criado com sucesso!", {
        position: "top-right",
        theme: "colored",
      });
      navigate("/panel/admin/user/" + id);
    } else {
      console.log(res.error);
      toast.error(`Houve um problema e o novo investimento não foi registrado. ${res.data.error}.`, {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  return (
    <Panel
      load={false}
      navigate={props.navigate}
      page="admin/user"
      title="Novo Investimento"
      admin={true}
    >

      <div className="new-inv-div">
        <div className="new-inv-user">
          Usuário  nº {id}
        </div>

        <Form className="w-100 new-inv-forms" onSubmit={handleSubmit}>
          <Form.Group className="mt-4 mb-2">
            <br></br>
            <Form.Label style={{ fontWeight: "bold", color: "black", textAlign: "center" }}>
              Tipo de Investimento
            </Form.Label>
            <div className="new-inv-input-group">
              <select name="select" className="new-inv-select-input" value={selectedOption} onChange={handleSelectChange}>
                <option value="standard">
                  Standard
                </option>
                <option value="alto valor">
                  Alto Valor
                </option>
                <option value="high performance">
                  High Performance
                </option>
              </select>
            </div>
            <br></br>
            <Form.Label style={{ fontWeight: "bold", color: "black", textAlign: "center" }}>
              Período (em meses)
            </Form.Label>
            <div className="input-group">
              <input
                className="input form-control"
                type="number"
                name="period"
                onChange={handlePeriod}
                value={periodState}
                style={{ width: "467px", height: "38px", borderColor: "#B4A680 !important" }}
              />
            </div>
            <br></br>
            <Form.Label style={{ fontWeight: "bold", color: "black", textAlign: "center" }}>
              Tarifa de Renovação
            </Form.Label>
            <div className="input-group">
              <input
                className="input form-control"
                type="number"
                name="period"
                onChange={handleRenew}
                value={renewFee}
                style={{ width: "467px", height: "38px", borderColor: "#B4A680 !important" }}
              />
            </div>
            <br></br>

            <Form.Label style={{ fontWeight: "bold", color: "black", textAlign: "center" }}>
              Aporte Inicial (BRL)
            </Form.Label>
            <div className="input-group">
              <CurrencyInput
                className="input append form-control"
                name="initialAmount"
                type="text"
                min={1}
                value={initialAmountStateDisplay}
                onChange={handleInitialAmount}
                style={{ width: "467px", height: "38px", borderColor: "#B4A680 !important" }}
                // You can pass additional props if necessary
                maskOptions={{
                  prefix: '',
                  allowNegative: false,
                  decimalLimit: 2,
                  integerLimit: 10,
                }}
              />
              {/*
              <input
                className="input form-control"
                type="number" 
                name="initialAmount"
                onChange={ handleInitialAmount }
                value={ initialAmountState }
                style={{ width :"467px", height:  "38px",  borderColor:  "#B4A680 !important"}}
                /> */}
            </div>
          </Form.Group>
          <div className="new-inv-btn-div">
            <Button variant="primary" type="submit" className="new-inv-btns">
              Criar
            </Button>
            <LinkContainer to={"/panel/admin/user/" + id}>
              <Button variant="primary" className="new-inv-btns cancel-btn">
                Cancelar
              </Button>
            </LinkContainer>
          </div>
        </Form>
      </div>
    </Panel>
  );
}

export default AdminNewInvestment;
