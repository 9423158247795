import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import api from "../../utils/api";

export default class FormPerfilKYC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  getInfoPerfil = () => {
    api.get(`users/${this.props.id}`).then((req) => {
      if (!req.error) {
        let data = req.data;

        this.setState({
          data,
        });
      }
    });
  };

  componentDidMount = () => {
    this.getInfoPerfil();
  };

  render() {
    if (!this.state.data) {
      return null;
    }

    return (
      <Form className="w-100 card p-3">
        <div>
          <Row className="mb-1 mt-3">
            <Form.Group as={Col}>
              <Form.Label>Nome Completo</Form.Label>
              <Form.Control
                className="input"
                name="name"
                type="text"
                value={this.state.data.name}
                readOnly
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Nome da Mãe</Form.Label>
              <Form.Control
                className="input"
                name="motherName"
                type="text"
                value={this.state.data.motherName}
                readOnly
              />
            </Form.Group>
          </Row>

          {this.state.data.legalPerson && (
            <>
              <Row className="mb-1">
                <Form.Group as={Col}>
                  <Form.Label>Nome Fantasia</Form.Label>
                  <Form.Control
                    className="input"
                    name="tradingName"
                    type="text"
                    value={this.state.data.tradingName}
                    readOnly
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Razão Social</Form.Label>
                  <Form.Control
                    className="input"
                    name="corporateName"
                    type="text"
                    value={this.state.data.corporateName}
                    readOnly
                  />
                </Form.Group>
              </Row>

              <Row className="mb-1">
                <Form.Group as={Col}>
                  <Form.Label>CNPJ</Form.Label>
                  <Form.Control
                    className="input"
                    name="cnpj"
                    type="text"
                    value={this.state.data.cnpj}
                    readOnly
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    className="input"
                    name="email"
                    type="email"
                    value={this.state.data.email}
                    readOnly
                  />
                </Form.Group>
              </Row>
            </>
          )}

          <Row className="mb-1">
            <Form.Group as={Col}>
              <Form.Label>CPF</Form.Label>
              <Form.Control
                as={InputMask}
                mask="999.999.999-99"
                className="input"
                name="cpf"
                type="text"
                value={this.state.data.cpf}
                readOnly
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>RG</Form.Label>
              <Form.Control
                className="input"
                name="rg"
                type="number"
                value={this.state.data.rg}
                readOnly
              />
            </Form.Group>
          </Row>
          <Row className="mb-1">
            <Form.Group as={Col}>
              <Form.Label>Data de Nasc.</Form.Label>
              <Form.Control
                className="input"
                name="birthDate"
                type="date"
                value={this.state.data.birthDate}
                readOnly
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Telefone</Form.Label>
              <Form.Control
                as={InputMask}
                mask="(99) 99999-9999"
                className="input"
                name="phone"
                type="text"
                value={this.state.data.phone}
                readOnly
              />
            </Form.Group>
          </Row>
          <Row className="mb-1">
            <Form.Group as={Col}>
              <Form.Label>CEP</Form.Label>
              <Form.Control
                as={InputMask}
                mask="99999-999"
                className="input"
                name="postalCode"
                type="text"
                value={this.state.data.postalCode}
                readOnly
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Endereço</Form.Label>
              <Form.Control
                className="input"
                name="streetAddress"
                type="text"
                value={this.state.data.streetAddress}
                readOnly
              />
            </Form.Group>
          </Row>
          <Row className="mb-1">
            <Form.Group as={Col}>
              <Form.Label>n°</Form.Label>
              <Form.Control
                className="input"
                name="streetAddressNumber"
                type="number"
                value={this.state.data.streetAddressNumber}
                readOnly
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Endereço</Form.Label>
              <Form.Control
                className="input"
                name="streetAddress2"
                type="text"
                value={this.state.data.streetAddress2}
                readOnly
              />
            </Form.Group>
          </Row>
          <Row className="mb-1">
            <Form.Group as={Col}>
              <Form.Label>Bairro</Form.Label>
              <Form.Control
                className="input"
                type="text"
                name="district"
                value={this.state.data.district}
                readOnly
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Cidade</Form.Label>
              <Form.Control
                className="input"
                name="city"
                type="text"
                value={this.state.data.city}
                readOnly
              />
            </Form.Group>
          </Row>
          <Row className="mb-1">
            <Form.Group as={Col}>
              <Form.Label>Estado</Form.Label>
              <Form.Control
                as={InputMask}
                mask="aa"
                className="input"
                type="text"
                name="stateOrProvince"
                value={this.state.data.stateOrProvince}
                readOnly
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>País</Form.Label>
              <Form.Control
                className="input"
                name="country"
                type="text"
                value={this.state.data.country}
                readOnly
              />
            </Form.Group>
          </Row>
        </div>
      </Form>
    );
  }
}
