import React, { useEffect, useState, useContext } from "react";
import { formatMoney } from "../utils/helper";
import api from "../utils/api";
// import { getStockQuotes } from "../utils/fetchNasdaq";
import { AppContext } from "../context/AppContext";

function CurrencyWidget() {

  const [loadingPrice, setLoadingPrice] = useState(true);
  const [loadingStocks, setLoadingStocks] = useState(true);
  const [brlPrice, setbrlPrice] = useState(0);
  const [stocks, setStocks] = useState([
    {
      avgVolume: 0,
      change: 0,
      changesPercentage: 0,
      dayHigh: 0,
      dayLow: 0,
      earningsAnnouncement: null,
      eps: null,
      exchange: "INDEX",
      marketCap: null,
      name: "NASDAQ Composite",
      open: 0,
      pe: null,
      previousClose: 0,
      price: 0,
      priceAvg50: 0,
      priceAvg200: 0,
      sharesOutstanding: null,
      symbol: "^IXIC",
      timestamp: 0,
      volume: 0,
      yearHigh: 0,
      yearLow: 0,
    },
    {
      avgVolume: 0,
      change: 0,
      changesPercentage: 0,
      dayHigh: 0,
      dayLow: 0,
      earningsAnnouncement: null,
      eps: null,
      exchange: "INDEX",
      marketCap: null,
      name: "S&P 500",
      open: 0,
      pe: null,
      previousClose: 0,
      price: 0,
      priceAvg50: 0,
      priceAvg200: 0,
      sharesOutstanding: null,
      symbol: "^GSPC",
      timestamp: 0,
      volume: 0,
      yearHigh: 0,
      yearLow: 0,
    },
  ]);
  const [prices, setPrices] = useState([
    {
      symbol: "BTC",
      base: "USD",
      name: "Bitcoin",
      price: 0,
      variation: 0,
    },
    {
      symbol: "ETH",
      base: "USD",
      name: "Ethereum",
      price: 0,
      variation: 0,
    },
    {
      symbol: "USD",
      base: "BRL",
      name: "Dolar",
      price: 0,
      variation: 0,
    },
  ]);

  const { setBtcPrice } = useContext(AppContext);

  const getPrices = async () => {
    try {
      const req = await api.get('prices');
      if (!req.error) {
        const prices = req.data;
        let brlPrice = 0;

        prices.forEach((price) => {
          if (price.symbol === 'USD' && price.base === 'BRL') {
            brlPrice = price.price;
          }
        });

        const btcPrice = prices.find((coin) => coin.symbol === 'BTC');

      //  console.log(btcPrice);

        setBtcPrice(btcPrice.price * brlPrice);
        setbrlPrice(brlPrice);
        setPrices(prices);
        setLoadingPrice(false);
      }
    } catch (error) {
      // Handle errors appropriately
      console.error('Error fetching prices:', error);
    }
  };

  useEffect(() => {
    // Run getPrices initially
    getPrices();

    // Set up interval to run getPrices every 5 seconds
    const intervalId = setInterval(getPrices, 5000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);


  return(
    <div className="widget-div">
      {!loadingPrice && (
        <>
          {prices.map ((p) => {
            return (
              <div className="widget-currency" key={p.name} id={(p.symbol.toLocaleLowerCase() === 'btc')?  'first-bitcoin' : ''}>
                { p.symbol.toLocaleLowerCase() === 'usd'
                  ? <img
                    className="widget-currency-img"
                    src={`/icons/usdt.svg`}
                    alt={`tether-icon`}
                  />
                  : <img
                    className="widget-currency-img"
                    src={`/icons/${p.symbol.toLocaleLowerCase()}.svg`}
                    alt={`${p.symbol}-icon`}
                  />
                }
                <div className="widget-name-div" style={{color: '#4f4f4f'}}>
                  <span className="widget-name-currency">{p.name}</span>
                  { p.symbol === 'USD'
                    ? <span className="widget-name-symbol">   (USDT)</span>
                    : <span className="widget-name-symbol">   ({p.symbol})</span>
                  }
                </div>
                <div
                  className="widget-price-div"
                  style={ p.variation > 0 ? { color:'#11581f' } : { color:'#c60000' }}
                >
                  <div className="widget-price">
                    <span className="widget-brl-price">
                      { p.base === "USD"
                        ? `R$  ${formatMoney(p.price * brlPrice)}`
                        : `R$  ${formatMoney(p.price)}` }
                    </span>
                  </div>
                  <div>
                    <span className="widget-price-variation">
                      ({ p.variation.toFixed(2) } %)
                    </span>
                  </div>
                  {/* icon up or down */}
                  <div className="widget-icon">
                    { p.variation > 0 ? 
                      <i className="fa fa-halfs fa-chevron-up"></i>
                      : <i className="fa fa-halfs fa-chevron-down"></i>
                    }
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )}
      {!loadingStocks && (
        <>
          {stocks.map ((s) => {
            return (
              <div className="widget-currency" key={s.name}>
                <img
                    className="widget-currency-img"
                    src={`/icons/nasdaq.svg`}
                    alt={`nasdaq-icon`}
                  />
                <div className="widget-name-div" style={{color: '#4f4f4f'}}>
                  {s.name === 'NASDAQ Composite' 
                    ?  <span className="widget-name-currency">NASDAQ</span>
                    : <span className="widget-name-currency">S&P500</span>
                  }
                  <span className="widget-name-symbol">   ({s.symbol})</span>
                </div>
                <div
                  className="widget-price-div"
                  style={ s.changesPercentage > 0 ? { color:'#11581f' } : { color:'#c60000' }}
                >
                  <div className="widget-price">
                    <span className="widget-brl-price">
                      { s.base === "USD"
                        ? `R$  ${formatMoney(s.price * brlPrice)}`
                        : `R$  ${formatMoney(s.price)}` }
                    </span>
                  </div>
                  <div>
                    <span className="widget-price-variation">
                      ({ s.changesPercentage.toFixed(2) } %)
                    </span>
                  </div>
                  {/* icon up or down */}
                  <div className="widget-icon">
                    { s.changesPercentage > 0 ? 
                      <i className="fa fa-halfs fa-chevron-up"></i>
                      : <i className="fa fa-halfs fa-chevron-down"></i>
                    }
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )}

{!loadingPrice && (
        <>
          {prices.map ((p) => {
            return (
              <div className="widget-currency" key={p.name}>
                { p.symbol.toLocaleLowerCase() === 'usd'
                  ? <img
                    className="widget-currency-img"
                    src={`/icons/usdt.svg`}
                    alt={`tether-icon`}
                  />
                  : <img
                    className="widget-currency-img"
                    src={`/icons/${p.symbol.toLocaleLowerCase()}.svg`}
                    alt={`${p.symbol}-icon`}
                  />
                }
                <div className="widget-name-div" style={{color: '#4f4f4f'}}>
                  <span className="widget-name-currency">{p.name}</span>
                  { p.symbol === 'USD'
                    ? <span className="widget-name-symbol">   (USDT)</span>
                    : <span className="widget-name-symbol">   ({p.symbol})</span>
                  }
                </div>
                <div
                  className="widget-price-div"
                  style={ p.variation > 0 ? { color:'#11581f' } : { color:'#c60000' }}
                >
                  <div className="widget-price">
                    <span className="widget-brl-price">
                      { p.base === "USD"
                        ? `R$  ${formatMoney(p.price * brlPrice)}`
                        : `R$  ${formatMoney(p.price)}` }
                    </span>
                  </div>
                  <div>
                    <span className="widget-price-variation">
                      ({ p.variation.toFixed(2) } %)
                    </span>
                  </div>
                  {/* icon up or down */}
                  <div className="widget-icon">
                    { p.variation > 0 ? 
                      <i className="fa fa-halfs fa-chevron-up"></i>
                      : <i className="fa fa-halfs fa-chevron-down"></i>
                    }
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )}
      {!loadingStocks && (
        <>
          {stocks.map ((s) => {
            return (
              <div className="widget-currency" key={s.name}>
                <img
                    className="widget-currency-img"
                    src={`/icons/nasdaq.svg`}
                    alt={`nasdaq-icon`}
                  />
                <div className="widget-name-div" style={{color: '#4f4f4f'}}>
                  {s.name === 'NASDAQ Composite' 
                    ?  <span className="widget-name-currency">NASDAQ</span>
                    : <span className="widget-name-currency">S&P500</span>
                  }
                  <span className="widget-name-symbol">   ({s.symbol})</span>
                </div>
                <div
                  className="widget-price-div"
                  style={ s.changesPercentage > 0 ? { color:'#11581f' } : { color:'#c60000' }}
                >
                  <div className="widget-price">
                    <span className="widget-brl-price">
                      { s.base === "USD"
                        ? `R$  ${formatMoney(s.price * brlPrice)}`
                        : `R$  ${formatMoney(s.price)}` }
                    </span>
                  </div>
                  <div>
                    <span className="widget-price-variation">
                      ({ s.changesPercentage.toFixed(2) } %)
                    </span>
                  </div>
                  {/* icon up or down */}
                  <div className="widget-icon">
                    { s.changesPercentage > 0 ? 
                      <i className="fa fa-halfs fa-chevron-up"></i>
                      : <i className="fa fa-halfs fa-chevron-down"></i>
                    }
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
};

export default CurrencyWidget;