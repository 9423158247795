import React from "react"
import { Container, Button } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
// import Particles from 'react-particles-js';
import HeaderBar from '../components/HeaderBar';
// import BottomBar from '../components/BottomBar';
import CurrenciesBar from '../components/CurrenciesBar';
import BottomBarHome from "../components/BottomBarHome";

const params = {
    fps_limit: 28,
    particles: {
        color: 'rgba(0, 0, 0, 1)',
        links: {
            color: 'rgba(0, 0, 0, .7)',
        },
        move: {
            speed: 0.5,
        },
        number: {
            value: 50
        },
        size: {
            value: 3
        }
    },
};
const opacityParticles = 0.5;

export default class Home extends React.Component {

    render() {
        return (<Container className="p-0 d-table" fluid>
            <div className="d-none d-md-flex" style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: '#FFF',
                top:0,
                left: 0,
                padding: 0,
                margin: 0,
                zIndex: -1,
            }}>
                <img
                    src='/images/bg.png'
                    width="100%"
                    height="auto"
                    className="d-inline-block align-top m-auto"
                    alt="BC Coins Logo"
                />
            </div>
            {/* <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                padding: 0,
                margin: 0,
                zIndex: -1,
                opacity: opacityParticles,
            }}>
                <Particles
                    style={{
                        height: '100%'
                    }}
                    width="100%"
                    height="100%"
                    params={params} />
            </div> */}
            <HeaderBar />
            <Container className="text-center pb-5 pt-3 d-table-row">
                <CurrenciesBar />
                <img
                    style={{
                        minWidth: 300,
                        maxWidth: 350,
                    }}
                    src="./images/logo.png"
                    width="30%"
                    className="d-inline-block align-top mt-5"
                    alt="BC Coins Logo"
                />
                <h1 className="bold mt-2">Compra e venda de moedas digitais</h1>
                <h2 className="font-weight-light mb-3">BCX um modo seguro de negociar seus ativos</h2>
                <LinkContainer className="d-md-none mb-5" to="/panel/signup">
                    <Button className="btn-primary">Registrar</Button>
                </LinkContainer>
                <div className="mb-5"></div>
            </Container>
            <BottomBarHome />
        </Container>)
    }
}
