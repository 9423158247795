import dayjs from "dayjs";
import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Spinner, Table, Modal, Dropdown, DropdownButton } from "react-bootstrap";
import Pagination from "../components/Pagination";
import api from "../utils/api";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import Panel from "../components/Panel";
import { formatBTCString, formatEVMString } from "../utils/Helpers/btcFormat";
import { useTranslation, Trans } from 'react-i18next';
import { numberFormatBRL } from "../components/InvestmentId";

function TxHistory() {
  const { pagination, setPagination, loggedInUser } = useContext(AppContext);
  const [txHistory, setTxHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const navigate = useNavigate();
 
  const { t } = useTranslation();

  async function loadTxHistory() {
    setLoading(true);
    const count = await api.get(`orders/count?[where][usersId]=${loggedInUser.id}`);
  //  console.log('count >>>>', count);

    const pagesNumber = await Math.ceil(count.data.count / 10);
    console.log(pagesNumber);

    setPagination((pagination) => {
      return {
        ...pagination,
        lastPage: pagesNumber
      };
    });

    try {
      const url = `orders/${loggedInUser.id}?limit=${pagination.limit}&offset=${pagination.offset}`;
      const response = await api.get(url);
      const data = response.data;
      console.log(data);
      setTxHistory(data);
      setLoading(false);
    } catch (e) {
      setTxHistory([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadTxHistory();
    setPagination(() => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });
  }, []);

  useEffect(() => {
    loadTxHistory();
  }, [pagination.offset]);

  const itHasNFSe = (row) => (row.nfeid ? true : false);

  const rowStatus = {
    done: t("statusdone"), // "Confirmado",
    canceled: t("statuscanceled"), // "Cancelado",
    open: t("statusopen"), // "Aberto",
    failed: t("statusfailed"), //  "Falha",
  };


  const handleOpenNFeModal = (orderId) => {
    setSelectedOrderId(orderId);
  };

  const handleCloseNFeModal = () => {
    setSelectedOrderId(null);
  };

  const handleSelectNFe = (nfeId) => {

      // Construct the URL for downloading the PDF
      const pdfUrl = `${api.getURL()}notas/${nfeId}/download`;

      // Open the PDF in a new tab
      window.open(pdfUrl, "_blank");
    

    // Close the modal
    handleCloseNFeModal();
  };

  return (
    <Panel page="tx_history" title={t("txhistorytitle")}> {/* "Histórico de transações" */}
      {!loading ? (
        <div className="row">
          <Col>
            <div className="div-export-csv" >
              <Button className="export-csv">
              {t("exportcsv")}
              </Button>
              <div
                onClick={(event) => {
                  loadTxHistory()
                }
                }
                className="btn-menu d-flex ml-3 text-mid bold"
                style={{ alignItems: "center", justifyContent: "center", height: "33px", width: "33px", borderRadius: "4px", backgroundColor: "#ceba85" }}
                name="balances"
              >
                <img
                  className="mr-1"
                  alt="bitcoin icon"
                  src="/images/refresh-button.svg"
                  style={{ width: "16px", padding: "3px" }}
                  name="balances"
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                backgroundColor: "#50504E",
              }}
            >
              <span className="bold text1 ml-3 text-mid2">
              {t("txhistorytabletitle")} {/* Histórico de transações (compra e venda) */} 
              </span>
            </div>
            <Table className="hist-table m-0 p-0" responsive="md" striped hover>
              <thead>
                <tr>
                  <th>{t("date")}</th>
                  <th>{t("type")}</th>
                  <th>{t("coin")}</th>
                  <th>{t("qty")}</th>
                  <th>{t("price")}</th>
                  <th>Status</th>
                  <th>NF-e</th>
                </tr>
              </thead>
              <tbody>
                {txHistory.map((row, i) => {
                  return (
                    <tr key={row.id}>
                      <td>
                        {dayjs(row.created_at).format("DD/MM/YYYY HH:mm:ss")}
                      </td>
                      <td>
                        {row.type === "ask" ? t("selltype") : t("buytype")}  {/* "Venda" : "Compra" */}
                      </td>
                      <td>
                        {String(row.pair).toUpperCase()}
                      </td>
                      <td>
                        {row.pair === "btc" ? formatBTCString((row.amount)) : formatEVMString((row.amount),3,row.pair.toUpperCase())}
                      </td>
                      <td>
                        {numberFormatBRL(row.price)}
                      </td>
                      <td>
                        {rowStatus[row.status]}
                      </td>
                      <td>
                        {itHasNFSe(row) ? (
                          <Button onClick={() => handleOpenNFeModal(row.id)}>
                            {t("see")} NFes
                          </Button>
                        ) : (
                          t("notsended")
                        )}
                      </td>
                      {/*
                    <td>
                      itHasNFSe(row) ? (
                        <Button
                          onClick={(e) => {
                            let ur = `${ap.geURL()}notas/${rw.nfeid}/download`;
                            windo.opn(url, "_blank");
                          }}
                        >
                          Ver
                        </Button>
                      ) : (
                        "Não enviado"
                      )}
                    </td>
                      */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {txHistory.length > 0 && (
              <Pagination pagination={pagination} setPagination={setPagination} />
            )}
          </Col>
        </div>
      ) : (
        <div className="text-center mt-3 mb-3">
          <Spinner animation="border" variant="secondary" />
        </div>
      )}

      {/* Modal to display NFes */}
      <Modal show={selectedOrderId !== null} onHide={handleCloseNFeModal}>
        <Modal.Header closeButton>
          <Modal.Title>NFes {t("fororder")} {/* para a Ordem */} #{selectedOrderId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrderId !== null && (
            <ul>
              {txHistory
                .filter((order) => order.id === selectedOrderId)
                .map((order) => (
                  order.nfes.map((nfe) => (
                    <li key={nfe.nfeid}>
                      <Button
                        onClick={() => handleSelectNFe(nfe.nfeid)}
                        variant="link"
                      >
                        {t("see")} NFe #{nfe.nfeid}
                      </Button>
                    </li>
                  ))
                ))}
            </ul>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNFeModal}>
          {t("close")} {/* Fechar */}
          </Button>
        </Modal.Footer>
      </Modal>

    </Panel>
  );
}

export default TxHistory;
