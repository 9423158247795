import React from 'react';
import QRCode from "react-qr-code";
import { Button, Form, Image, Modal } from "react-bootstrap";
import Panel from '../components/Panel';
import api from "../utils/api";
import { withTranslation } from 'react-i18next';

class KYC extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            secret: '',
            load: true,
            firstTrySubmit: false,
            errors: {
                code: '',
            },
            form: {
                code: '',
            },
            validations: {
                code: this.validateCode
            },
            showModal: false,
            modalMessage: '',
        }
    }

    componentDidMount = () => {
        this.getSecret().then(secret => this.setState({ load: false, secret }));
    }

    getSecret = async () => {
        let req = await api.get('me');
        if (!req.error) {
            let user = req.data;
            req = await api.get('twofactor/secret');
            if (!req.error) {
                if (req.data && req.data.token) {
                    return `otpauth://totp/${encodeURIComponent('BC X')}:${encodeURIComponent(user.email)}?secret=${req.data.token}&issuer=${encodeURIComponent('BC X')}`;
                } else {
                    return '';
                }
            }
        }
    }

    validateCode = (value) => {
        if (/^[0-9]{6}$/.test(value) || value === '') {
            return '';
        } else {
            return this.props.t('invalid2fa') // '2FA inválido'
        }
    }

    handleChange = (event) => {
        let form = this.state.form
        let errors = this.state.errors
        let validations = this.state.validations
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        form[event.target.name] = value
        if (this.state.firstTrySubmit) {
            errors[event.target.name] = validations[event.target.name](value)
        }
        this.setState({ form, errors })
    }

    onSubmit = async (event) => {
        event.preventDefault();

        let form = this.state.form
        let errors = this.state.errors
        let validations = this.state.validations
        let formParams = Object.keys(form);
        let foundError = false;
        formParams.forEach(key => {
            let error = validations[key](form[key]);
            errors[key] = error;
            if (error !== '') {
                foundError = true;
            }
        })
        if (!foundError) {
            let req = await api.post(this.state.secret ? 'twofactor/active' : 'twofactor/deactivate', {
                token: form.code
            }, false);
            if (!req.error) {
                errors.code = '';
                form.code = '';
                let secret = '';
                if (!this.state.secret) {
                    secret = await this.getSecret();
                }
                await this.setState({
                    secret,
                    firstTrySubmit: false,
                    form,
                    errors,
                    showModal: true,
                });
            } else {
                errors.code = validations.code('xxx');
                this.setState({ errors, firstTrySubmit: true });
            }
        } else {
            this.setState({ errors, firstTrySubmit: true });
        }
    }

    handleClose = () => {
        this.setState({ showModal: false });
    }

    render() {
        return (<Panel load={this.state.load} history={this.props.history} page="twofa" title={this.props.t('twofatitle')} > {/* Ativação 2FA"*/}
            {(!this.state.secret) && <>
                <div className="kyc-icon-ok" style={{width: 100}}><i className="fas fa-check-circle"></i>{this.props.t('activated')} {/* Ativado"*/}</div>
                <h1 className="text-mid mb-0 mt-4">{this.props.t('for')} {/* Para"*/} <span className="bold">{this.props.t('deactivate')} {/* desativar"*/}</span> {this.props.t('authentication2fa')} {/* a autenticação em dois fatores"*/} </h1>
                <h1 className="text-mid mb-0">{this.props.t('inserttoken')} {/* Insira o token de 6 digitos que foi gerado e clique em desativar"*/} </h1>
            </>}
            {(this.state.secret) && <>
                <p className="text-mid bold mb-0">{this.props.t('step12fa')} {/* 1 - Instalar o Aplicativo"*/} </p>
                <p className="text-small1">{this.props.t('step12fatext')} {/* Baixe o aplicativo na Apple Store ou na Google Play"*/}</p>
                <Image style={{
                    width: 100,
                    marginBottom: 38,
                    marginTop: 20,
                }} src="/images/authy-ar21.svg" />
                <p className="text-mid bold mb-0">{this.props.t('step22fa')} {/* 2 - Ler o QR Code"*/}</p>
                <p className="text-small1">{this.props.t('step22fatext')} {/* Abra o aplicativo e escaneie o QR Code a baixo"*/} </p>
                <QRCode value={this.state.secret} size={120} />
                <p className="text-mid bold mb-0 mt-4">{this.props.t('step32fa')} {/*   3 - Informar o token"*/}</p>
                <p className="text-small1">{this.props.t('step32fatext')} {/* Insira o token de 6 digitos que foi gerado e clique em ativar"*/}</p>
            </>}
            <Form className="m-0 mb-5 row" onSubmit={this.onSubmit}>
                <Form.Group style={{ width: 100, marginRight: 10 }}>
                    <Form.Label className="text-mid mb-0">Token</Form.Label>
                    <Form.Control
                        className="small"
                        name="code"
                        type="number"
                        value={this.state.form.code}
                        onChange={this.handleChange} required
                        isInvalid={this.state.errors.code}
                    />
                    <Form.Control.Feedback type="invalid">{this.state.errors.code}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Button type="submit" style={{ marginTop: 24, width: 100 }}>{this.state.secret ? this.props.t('activate') : this.props.t('deactivateaction')}</Button> {/* Ativar : Desativar */}
                </Form.Group>
            </Form>
            <Modal show={this.state.showModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 ml-3 text-center">{this.props.t('twofaauth')} {/* Autenticação 2FA */}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">{this.props.t('twofaauth')} {/* Autenticação 2FA */} {this.props.t('has')} {/* foi */} {this.state.secret ? this.props.t('deactivatemsg') : this.props.t('activatemsg')} {this.props.t('withsuccess')} {/* com sucesso! */}</Modal.Body> {/* desativada : ativada */}
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose}>OK</Button>
                </Modal.Footer>
            </Modal>
        </Panel >)
    }
}

export default withTranslation()(KYC);