import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import api, { microservice } from '../utils/api';
import { AppContext } from "../context/AppContext";
import { toast } from "react-toastify";

export default class ConfirmModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            title: '',
            body: <div></div>,
            action: () => console.log('ok button'),
        }
    }

    show = async (title, body, action) => {
        this.setState({
            showModal: true,
            title,
            body,
            action,
        });
    }

    handleClose = async () => {
        await this.setState({ showModal: false });
        if(this.props.handleClose) {
            this.props.handleClose();
        }
    }

    submit = async (event) => {
        event.preventDefault();
        await this.handleClose();
        await this.state.action();
        if(this.props.handleClose) {
            this.props.handleClose();
        }
    }

    static contextType = AppContext;


    handleOkClick = async () => {
        const res = await api.put(
            `investments/withdraws`, {
            "investmentId": this.props.withdraw.investmentId,
            "withdrawId" : this.props.withdraw.withdraws.id,
            "requestedTo": this.props.withdraw.withdraws.requestedTo,
            }
        );
        if (res) {
            toast.success('A rejeição foi feita com sucesso!');
        } else {
            toast.error('Não foi possível rejeitar o saque');
        }
        this.context.setReloadWithdraws(true);
        await this.handleClose();
        await this.state.action();
        if(this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render() {
        return (<Modal show={this.state.showModal} onHide={this.handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title className="w-100 ml-3 text-center">{this.state.title}</Modal.Title>
            </Modal.Header>
            <Form className="w-100" onSubmit={this.submit}>
                <Modal.Body className="w-100">
                    {this.state.body}
                </Modal.Body>
                <Modal.Footer>
                    { this.props.withdraw ?
                    <Button variant="primary" onClick={ this.handleOkClick }>OK</Button>
                    :
                    <Button variant="primary" type="submit">OK</Button>
                    }
                    <Button variant="primary" onClick={this.handleClose}>Cancel</Button>
                </Modal.Footer>
            </Form>
        </Modal>)
    }
}
