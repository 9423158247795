import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Panel from "../components/Panel";
import { AppContext } from "../context/AppContext";
import { useQuery } from "../hooks/useQuery";
import api from "../utils/api";
import { useTranslation, Trans } from 'react-i18next';

const Confirmations = ({ history }) => {
  const navigate = useNavigate();
  const query = useQuery();
  const id = query.get("id");
  const { getBalances, userObj } = useContext(AppContext);
  
  const { t } = useTranslation();
 
  const [translatedStatus, setTranslatedStatus] = useState(
    t("tproccesing") // "Transação em processamento"
  );


  useEffect(() => {
    getBalances(userObj.id);
  }, []);

  useEffect(() => {
    const timer = window.setInterval(() => {
      if (!id) {
        return () => {};
      }
      const confirmations = updateStatus(id);
      if (confirmations >= 3) {
        window.clearInterval(timer);
      }
    }, 5000);
    return () => {
      // Return callback to run on unmount.
      window.clearInterval(timer);
    };
  }, []); // Pass in empty array to run useEffect only on mount.

  async function updateStatus(bcxId) {
    try {
      const res = await api.get("node/transactions/" + bcxId);
      const nodeData = res.data;

      if (nodeData == null) {
        navigate("/panel/trade");
        return;
      }

      var bar = new window.ldBar(".ldBar", {
        stroke: "#CEBA85",
        "stroke-width": 10,
        preset: "fan",
        value: 0,
      });

      function getPercentage(confirmations) {
        /**
         *      0 - 25
         *      1 - 50
         *      2 - 75
         *      3+ - 100
         */
        switch (confirmations) {
          case 0:
            return 25;
          case 1:
            return 50;
          case 2:
            return 75;
          case 3:
            return 100;
          default:
            return 100;
        }
      }

      function getTranslatedStatus(confirmations) {
        switch (confirmations) {
          case 0:
            return t("waitconf");  // "Aguardando confirmação";
          case 1:
            return t("tproccesing"); //"Transação em processamento";
          case 2:
            return t("tproccesing"); // "Transação em processamento";
          case 3:
            return t("tconfirmed"); // "Transação concluída";
          default:
            return t("tconfirmed"); // "Transação concluída";
        }
      }

      bar.set(
        getPercentage(nodeData.confirmations) /* target value. */,
        true /* enable animation. default is true */
      );

      setTranslatedStatus(getTranslatedStatus(nodeData.confirmations));

      return nodeData.confirmations;
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (typeof window == undefined) {
      return () => {};
    }

    if (!id) {
      return () => {};
    }

    updateStatus(id);
  }, [id]);

  return (
    <Panel history={history} page="terms" title={t("tstatus")}>
      <Button
        style={{ marginTop: "1rem" }}
        size="lg"
        onClick={() => navigate("/panel/send")}
      >
       {t("back")}
      </Button>
      <div className="ldBar label-center mx-auto w-50 h-50"></div>
      <h2 className="text-center">{translatedStatus}</h2>
    </Panel>
  );
};

export default Confirmations;
