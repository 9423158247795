/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Panel from "../../components/Panel";
import AdminUserInfo from "../../components/Admin/AdminUserInfo";
import AdminUserInvestments from "../../components/Admin/AdminUserInvestments";
import AdminChangelevelPermission from "../../components/Admin/AdminChangelevelPermission";
import AdminChangePassword from "../../components/Admin/AdminChangePassword";
import AdminUserInvestmentPermission from "../../components/Admin/AdminUserInvestmentPermission";
import AdminUserReferalPermission from "../../components/Admin/AdminUserReferalPermission copy";
import AdminUserTransactions from "../../components/Admin/AdminUserTransactions";
import AdminUserBalance from "../../components/Admin/AdminUserBalance";
import AdminUserPpe from "../../components/Admin/AdminUserPpe";

class AdminUser extends React.Component {
  render() {
    return (
      <Panel
        load={false}
        navigate={this.props.navigate}
        page="admin/user"
        title= "Usuário"
        admin={true}
      >
        <AdminUserInfo id={this.props.params.id} />
        {/* <AdminUserBalances
          id={this.props.params.id}
          navigate={this.props.navigate}
        /> */}

        <AdminUserInvestmentPermission id={this.props.params.id}/>

        <AdminUserReferalPermission id={this.props.params.id}/>

        <AdminChangePassword id={this.props.params.id} />
        
        <AdminChangelevelPermission id={this.props.params.id} />


        <AdminUserInvestments
          id={this.props.params.id}
          navigate={this.props.navigate}
        />

        <AdminUserBalance id={this.props.params.id} />
        <AdminUserTransactions id={this.props.params.id} />
        {/* <AdminUserKYC id={this.props.params.id} /> */}
      </Panel>
    );
  }
}

export default (props) => (
  <AdminUser {...props} navigate={useNavigate()} params={useParams()} />
);
