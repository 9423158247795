import React from "react";
import Panel from "../../components/Panel";
import { Table, Button, Modal, Form, Spinner } from "react-bootstrap";
import {
  DEPOSITS_STATUS_TYPES,
  formatStatus,
  currencyConfig,
} from "../../utils/types";
import api from "../../utils/api";
import IntlCurrencyInput from "react-intl-currency-input";
import { useNavigate } from "react-router-dom";
import { disableAction } from "../../utils/helper";
import { AppContext } from "../../context/AppContext";
import Pagination from "../../components/Pagination";
import { numberFormatBRL } from "../../components/InvestmentId";

class AdminDeposits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      loadingBtn: false,
      deposits: [],
      showModal: false,
      usersId: null,
      depositsId: null,
      offset: 0,
      form: {
        amount: 0,
      },
    };
  }

  static contextType = AppContext;

  componentDidMount = async () => {
    this.reloadTable();

    this.context.setPagination((pagination) => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.context.pagination.offset !== prevState.offset) {
      this.reloadTable();
    }
  }

  reloadTable = async () => {
    this.setState({
      offset: this.context.pagination.offset,
      load: true,
    });

    let req = await api.get("users");
    if (req.error) return;
    let users = req.data.data;

    req = await api.get(
      `deposits?filter[order]=created%20DESC&filter[skip]=${this.context.pagination.offset}&filter[limit]=${this.context.pagination.limit}`
    );

    let deposits = req.data;

    this.context.setPagination((pagination) => {
      return {
        ...pagination,
        limit: deposits.limit,
        lastPage: Math.ceil(deposits.total / deposits.limit),
      };
    });

    if (req.error) return;
    let data = deposits.data.map((deposit) => {
      users.forEach((user) => {
        if (user.id === deposit.usersId) {
          deposit.user = user;
        }
      });
      return deposit;
    });

    this.setState({
      load: false,
      deposits: data,
    });
  };

  goTo = (row) => {
    this.props.navigate("/panel/admin/user/" + row.user.id);
  };

  cancel = async (row) => {
    let req = await api.patch("deposits/" + row.id, {
      status: "canceled",
    });
    if (req.error) return;
    this.reloadTable();
    this.getCountPendingStatus();
  };

  btnConfirm = async (row) => {
    this.setState({
      showModal: true,
      usersId: row.usersId,
      depositsId: row.id,
      form: {
        amount: row.amount,
      },
    });
  };

  confirm = async (row) => {
    this.setState({ showModal: false, loadingBtn: true });
    let req = await api.patch("deposits/" + this.state.depositsId, {
      status: "confirmed",
      amount: this.state.form.amount,
    });
    if (req.error) return;
    req = await api.post("balances/add", {
      usersId: this.state.usersId,
      currency: "BRL",
      currentAmount: this.state.form.amount,
    });
    if (req.error) return;
    this.setState({
      showModal: false,
    });
    this.reloadTable();
    this.getCountPendingStatus();
    this.setState({ loadingBtn: false });
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleChangeCurrency = (event, value, maskedValue) => {
    let form = this.state.form;
    form[event.target.name] = value;
    this.setState({ form });
  };

  getCountPendingStatus = async () => {
    await api.get("stats").then((req) => {
      if (!req.error) {
        this.context.setCountPendingStatus(req.data.data);
      }
    });
  };

  render() {
    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="deposits"
        title="Aprovação de Depósito"
        admin
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#50504E",
          }}
        >
          <span className="bold text1 ml-3 text-mid2">Depósitos</span>
        </div>
        <Table
          className="hist-table m-0 p-0 text-mid"
          responsive="md"
          striped
          hover
        >
          <thead>
            <tr>
              <th>Data</th>
              <th>Cliente</th>
              <th>Comprovante de depósito</th>
              <th>Nota fiscal</th>
              <th>Valor</th>
              <th>Status</th>
              <th>Ver cliente</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.deposits.map((row) => (
              <tr key={row.id}>
                <td>{new Date(row.created).toLocaleString()}</td>
                <td>{row.user ? row.user.name : ""}</td>
                <td>
                  {row.filesId === "" ? (
                    "Não enviado"
                  ) : (
                    <a
                      className="link-file"
                      href={`${api.getURL()}files/${row.filesId}/download`}
                    >
                      download
                    </a>
                  )}
                </td>
                <td>
                  {row.nfseId === "" || row.nfseId === null ? (
                    "Não enviado"
                  ) : (
                    <a
                      className="link-file"
                      target={"_blank"}
                      href={`${api.getURL()}notas/${row.nfseId}/download`}
                    >
                      download
                    </a>
                  )}
                </td>
                <td>{numberFormatBRL(row.amount.toFixed(2))}</td>
                <td>{formatStatus(DEPOSITS_STATUS_TYPES, row.status)}</td>

                <td>
                  <Button onClick={() => this.goTo(row)}>Ver</Button>
                </td>
                <td>
                  { !this.state.loadingBtn && (
                  <Button
                    className={
                      row.status !== "waiting" && row.status !== "sent"
                        ? "d-none"
                        : ""
                    }
                    variant="success"
                    onClick={() => this.btnConfirm(row)}
                    disabled={disableAction(
                      this.context.loggedInUser?.permission?.level,
                      false,
                      true,
                      false
                    )}
                  >
                    Confirmar
                  </Button>
                  )}
                  {this.state.loadingBtn && row.status==="waiting" && (
                    <Spinner animation="border" variant="primary" />
                  )}
                </td>
                <td>
                  {!this.state.loadingBtn &&  (
                  <Button
                    className={
                      row.status !== "waiting" && row.status !== "sent"
                        ? "d-none"
                        : ""
                    }
                    variant="danger"
                    onClick={() => this.cancel(row)}
                    disabled={disableAction(
                      this.context.loggedInUser?.permission?.level,
                      false,
                      true,
                      false
                    )}
                  >
                    Cancelar
                  </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {this.state.deposits.length > 0 && (
          <Pagination
            pagination={this.context.pagination}
            setPagination={this.context.setPagination}
          />
        )}

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-center">
             Confirmação de Depósito
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <h5>Você confirma o depósito no valor de:</h5>
            <h3>{numberFormatBRL(this.state.form.amount)}</h3>
            <br></br>
            <h6 id="exp-timer-txt">
              ATENÇÃO: Verifique o comprovante!
            </h6>
            <p id="warning-black-regular">
              Não aceite depósitos com valor maior ou menor do que foi informado pelo cliente. Depósitos com valores diferentes do informado deverão ser rejeitados.
            </p>
          </Modal.Body>
          {/* <Modal.Header closeButton>
            <Modal.Title className="w-100 ml-3 text-center">
              Confirme o valor
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <Form className="m-0 mb-5" onSubmit={this.onSubmit}>
              <Form.Group>
                <Form.Control
                  as={IntlCurrencyInput}
                  currency="BRL"
                  config={currencyConfig}
                  className="input"
                  name="amount"
                  value={this.state.form.amount}
                  onChange={this.handleChangeCurrency}
                  required
                  disabled
                />
              </Form.Group>
            </Form>
          </Modal.Body> */}
          <Modal.Footer>
            <Button variant="primary" onClick={this.confirm}>
              OK
            </Button>
            <Button variant="primary" onClick={this.handleClose}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
      </Panel>
    );
  }
}

export default (props) => <AdminDeposits {...props} navigate={useNavigate()} />;
