import React, { useRef, useEffect, useState, useMemo } from "react";
import { Button, Col, Row, Form, Spinner, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import api from "../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { formatMoney } from "../utils/helper";
import { numberFormatBRL } from "./InvestmentId";
import { formatBTCString, formatEVMString } from "../utils/Helpers/btcFormat";
import { useTranslation, Trans } from 'react-i18next';
import CurrencyInput from './CurrencyInput'
import Swal from 'sweetalert2';

export const setAmount = (refParam, amount, coin) => {
  var value = amount.includes(",") ? amount.replace(",", ".") : amount;
  if (!value) {
    value = "";
  }
  if (!refParam?.current) {
    return amount;
  }
  var smallestUnit = "0.000001";

  if (value.includes(".") && coin === "BTC") {
    var decimal = value.split(".")[1];
    value = value;
    if (decimal.length <= 6) {
      value =
        value.split(".")[0].slice(0, 4) + "." + value.split(".")[1].slice(0, 6);
      refParam.current.value = value;
      if (value.split(".")[1] === "000000") {
        value = value.split(".")[0];
      }
      return value;
    } else if (value.includes(".") && coin === "BRL") {
      var decimal = value.split(".")[1];
      value = value;
      if (decimal.length <= 10) {
        value =
          value.split(".")[0].slice(0, 8) +
          "." +
          value.split(".")[1].slice(0, 10);
        refParam.current.value = value;
        if (value.split(".")[1] === "000000") {
          value = value.split(".")[0];
        }
        return value;
      }
    } else {
      value =
        value.split(".")[0].slice(0, 4) + "." + value.split(".")[1].slice(0, 6);
      if (value === "0.000000") {
        value = smallestUnit;
      }
      if (value.split(".")[1] === "000000") {
        value = value.split(".")[0];
      }
      refParam.current.value = value;
      return value;
    }
  }

  if (value.length > 1) {
    if (value === 0 || value === "0") {
      refParam.current.value = smallestUnit;
      return smallestUnit;
    }
  }

  if (value.length >= 5 && coin === "BTC") {
    value = value.slice(0, 4);
    refParam.current.value = value;
    return value;
  }

  if (value.length >= 10 && coin === "BRL") {
    value = value.slice(0, 9);
    refParam.current.value = value;
    return value;
  }

  if (refParam.current) {
    refParam.current.value = value;
  }

  return value;
};

function CreateBuyOrder(props) {
  const { selectedCoin } = props;
  const navigate = useNavigate();
  const amountInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { btcPrice, usdtPrice, fees } = useContext(AppContext);
  const [market, setMarket] = useState(true);
 // const [selectedCoin, setSelectedCoin] = useState("BTC");
  const [inputValueBRL, setInputValueBRL] = useState("0");
  const [inputValueBRLDisplay, setInputValueBRLDisplay] = useState("0");
  const [inputValueBRLLimit, setInputValueBRLLimit] = useState("20");
  const [inputValueBRLLimitDisplay, setInputValueBRLLimitDisplay] =
    useState("20");
  const [inputValueCoin, setInputValueCoin] = useState("0");
  const [inputCoinDisplay, setInputCoinDisplay] = useState("0");
  const [inputValueUSDT, setInputValueUSDT] = useState("0");
  const [inputUSDTDisplay, setInputUSDTDisplay] = useState("0");
  const slippageValue = Number(fees[7]?.value);
  const messageRequired = t("msgrequired");

  const [showModal, setShowModal] = useState(false);
  const [showModalMKT, setShowModalMKT] = useState(false);
  const [confirmedOrder, setConfirmOrder] = useState(false);
  
  const sendCryptoSchema = yup.object().shape({
    amount: yup.string().required(messageRequired),
    //   .test(
    //     "minimum-amount",
    //     "O valor mínimo é de 0.000001 (100 satoshi)",
    //     (val) => val != 0
    //   )
    //   .test(
    //     "maxLength",
    //     "O valor máximo é de 13 caracteres.",
    //     (val) => val.length <= 13
    //   )
    //   .test(
    //     "minLength",
    //     "O valor mínimo é de 1 caractere.",
    //     (val) => val.length >= 1
    //   ),
    // // btcPrice: yup.string(),
    // marketType: yup.string().default("market"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sendCryptoSchema),
  });

  const createOrder = async (validData) => {
    setLoading(true);

    let data = new Date();
    let formatedDate = `${data.getFullYear()}-${data.getMonth() + 1 > 10
      ? data.getMonth() + 1
      : "0" + (data.getMonth() + 1)
      }-${data.getDate()}T${data.getHours() > 10 ? data.getHours() : "0" + data.getHours()
      }:${data.getMinutes() > 10 ? data.getMinutes() : "0" + data.getMinutes()
      }:06.727Z`;

    let brlBtcPtice = 0.0;

    if (market) {
      brlBtcPtice = selectedCoin === "BTC" ? btcPrice : usdtPrice;
      toast.warn(t("marketSlippage"), {
        position: "top-center",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: "priceDifferenceToast",
        onClose: (reason) => {
          if (reason === "click") {
            // Handle the user action if needed
          }
        },
      });
    } else {
      brlBtcPtice = inputValueBRLLimitDisplay;
    }

    // Calculate the difference percentage
    let priceDifferencePercentage = selectedCoin === "BTC" ? ((brlBtcPtice - btcPrice) / btcPrice) * 100 : ((brlBtcPtice - usdtPrice) / usdtPrice) * 100;
    console.log("difference % >> ", priceDifferencePercentage);

    // Check if the difference exceeds 50%
    if (Math.abs(priceDifferencePercentage) > 25) {
      const result = await Swal.fire({
        title: t("termswarningtitle"),
        text: t("differencePrice"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'green',
        cancelButtonColor: '#d33',
        confirmButtonText: t("confirm"),
        cancelButtonText: t("cancel")
      });
  
      if (result.isConfirmed) {
       
      } else {
        // User clicked cancel
        setLoading(false);
        return; // Exit the function
      }
    }

    if (Math.abs(priceDifferencePercentage) > -25 && Math.abs(priceDifferencePercentage) < 0 ) {
      console.log("caiu auqi");
      const result = await Swal.fire({
        title: t("termswarningtitle"),
        text: t("differencePrice"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'green',
        cancelButtonColor: '#d33',
        confirmButtonText: t("confirm"),
        cancelButtonText: t("cancel")
      });
  
      if (result.isConfirmed) {
       
      } else {
        // User clicked cancel
        setLoading(false);
        return; // Exit the function
      }
    }

    if (selectedCoin === "BRL" && Number(validData.amount) >= 50) {

      try {
        let res = await api.post("orders", {
          amount: parseFloat(validData.amount / brlBtcPtice),
          currentAmount: 0,
          price: String(validData.brlBtcPtice),
          quantity: String(validData.amount),
          pair: selectedCoin === "BTC" ? "btc" : selectedCoin.toLocaleLowerCase(),
          type: "bid",
          operation: market ? "market" : "limit",
          status: "open",
          createdAt: formatedDate,
          updatedAt: formatedDate,
        });

        if (!res.error) {
          setLoading(false);
          setInputValueBRL("0");
          setInputValueBRLLimit(selectedCoin === "BTC" ? btcPrice : usdtPrice);
          setInputValueBRLLimitDisplay(selectedCoin === "BTC" ? btcPrice : usdtPrice);
          setInputValueCoin("0");

          setTimeout(function () {
           // window.location.reload();
          }, 4000);

          if (res.data.filledOrders && res.data.filledOrders.length > 0) {

            for (const filledOrder of res.data.filledOrders){
              const brlPrice = numberFormatBRL(filledOrder.price);
             toast.success(t("filledOrder",{type: "BUY | COMPRA", price: brlPrice , quantity: filledOrder.quantity}), {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
             });
            }
           } else{
             toast.success(t("ordercreatedsuccess"), {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
             });
          }
          return;
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 500) { // Check for HTTP 500 error
          const errorMessage = error.response.data.message || "Internal Server Error"; // Extract error message from error.response.data
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } else if (Number(inputValueCoin) * brlBtcPtice >= 50) {

      try {
        let res = await api.post("orders", {
          amount: Number(inputValueCoin),
          currentAmount: Number(0),
          price: String(brlBtcPtice),
          quantity: String(inputValueCoin * brlBtcPtice),
          pair: selectedCoin === "BTC" ? "btc" : selectedCoin.toLocaleLowerCase(),
          type: "bid",
          operation: market ? "market" : "limit",
          status: "open",
          // "createdAt": formatedDate,
          // "updatedAt": formatedDate
        });

        if (!res.error) {
          setLoading(false);
          setInputValueBRL("0");
          setInputValueBRLLimit(selectedCoin === "BTC" ? btcPrice : usdtPrice);
          setInputValueBRLLimitDisplay(selectedCoin === "BTC" ? btcPrice : usdtPrice);
          setInputValueCoin("0");
          setTimeout(function () {
           // window.location.reload();
          }, 4000);

          if (res.data.filledOrders && res.data.filledOrders.length > 0) {
            for (const filledOrder of res.data.filledOrders){
              const brlPrice = numberFormatBRL(filledOrder.price);
             toast.success(t("filledOrder",{type: "BUY | COMPRA", price: brlPrice , quantity: filledOrder.quantity}), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
             }
           } else{
             toast.success(t("ordercreatedsuccess"), {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
             });
          }
          return;
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 500) { // Check for HTTP 500 error
          const errorMessage = error.response.data.message || "Internal Server Error"; // Extract error message from error.response.data
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } else {
      toast.error(t("error.ordercreated"), {  // `Não foi possível criar a sua ordem. Verifique se o valor da ordem é igual ou superior a R$50,00`
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };


  const { ref: amountRHFRef, ...theArgs } = register("amount");

  useEffect(() => {
    setValue("amount", "0");
    setValue("btcPrice", btcPrice);
    setValue("usdtPrice", usdtPrice);
    setInputValueBRLLimit("20");
    setInputValueBRLLimitDisplay("20");
  }, []);

  const amount = watch("amount");
  const btcPriceLocal = watch("btcPrice");

  const btcMarketPrice = useMemo(() => {
    // console.log(btcPriceLocal, amount, 'btcMarketPrice')
    return btcPriceLocal * amount;
  }, [btcPriceLocal, amount]);

  useEffect(() => {
    // remove o R$ e troca a virgula por ponto
    setValue("btcPrice", String(btcPrice).replace("R$", "").replace(",", "."));
    setValue("usdtPrice", String(usdtPrice).replace("R$", "").replace(",", "."));
  }, [market]);

  const handleBTCChange = (event) => {
    let { value } = event.target;

    value = value.replace(/[^0-9.,]/g, "");

    value = value.replace(/,/g, ".");

    const dotIndex = value.indexOf(".");
    if (dotIndex !== -1) {
      const beforeDot = value.slice(0, dotIndex);
      const afterDot = value.slice(dotIndex + 1).replace(/\./g, "");
      value = `${beforeDot}.${afterDot}`;
    }

    setInputValueCoin(value);
    setInputCoinDisplay(value);
    if (market) {
      let convertedValue = selectedCoin === "BTC" ?  value * btcPrice : value * usdtPrice;
      convertedValue = convertedValue.toString();
      // Remove any characters that are not numbers, commas, or dots
      convertedValue = convertedValue.replace(/[^0-9.,]/g, "");
      convertedValue = convertedValue.replace(/\./g, ",");

      setInputValueBRLDisplay(convertedValue);
      convertedValue = convertedValue.replace(/,/g, ".");
      setInputValueBRL(convertedValue);

    } else {
      let convertedValue = value * inputValueBRLLimitDisplay;
      convertedValue = convertedValue.toString();
      // Remove any characters that are not numbers, commas, or dots
      convertedValue = convertedValue.replace(/[^0-9.,]/g, "");
      convertedValue = convertedValue.replace(/\./g, ",");

      setInputValueBRLDisplay(convertedValue);
      convertedValue = convertedValue.replace(/,/g, ".");
      setInputValueBRL(convertedValue);
    }

    //   const convertedValue = value * btcPrice;
    //  setInputValueBRL(Number(convertedValue));

  };

  const handleUSDTChange = (event) => {
    let { value } = event.target;

    value = value.replace(/[^0-9.,]/g, "");

    value = value.replace(/,/g, ".");

    const dotIndex = value.indexOf(".");
    if (dotIndex !== -1) {
      const beforeDot = value.slice(0, dotIndex);
      const afterDot = value.slice(dotIndex + 1).replace(/\./g, "");
      value = `${beforeDot}.${afterDot}`;
    }

    setInputValueCoin(value);
    setInputCoinDisplay(value);
    if (market) {
      let convertedValue = value * usdtPrice;
      convertedValue = convertedValue.toString();
      // Remove any characters that are not numbers, commas, or dots
      convertedValue = convertedValue.replace(/[^0-9.,]/g, "");
      convertedValue = convertedValue.replace(/\./g, ",");

      setInputValueBRLDisplay(convertedValue);
      convertedValue = convertedValue.replace(/,/g, ".");
      setInputValueBRL(convertedValue);

    } else {
      let convertedValue = value * inputValueBRLLimitDisplay;
      convertedValue = convertedValue.toString();
      // Remove any characters that are not numbers, commas, or dots
      convertedValue = convertedValue.replace(/[^0-9.,]/g, "");
      convertedValue = convertedValue.replace(/\./g, ",");

      setInputValueBRLDisplay(convertedValue);
      convertedValue = convertedValue.replace(/,/g, ".");
      setInputValueBRL(convertedValue);
    }

    //   const convertedValue = value * btcPrice;
    //  setInputValueBRL(Number(convertedValue));

  };

  const handleBRLChange = (event) => {
    let { value } = event.target;

    // Remove any characters that are not numbers, commas, or dots
    value = value.replace(/[^0-9.,]/g, "");

    // Replace dots with an empty string to join numbers
    value = value.replace(/\./g, "");


    // Update the input value with the transformed value
    setInputValueBRLDisplay(value);
    value = value.replace(/,/g, ".");
    setInputValueBRL(value);

    if (market) {
      value = value.replace(/,/g, ".");
      const convertedValue = selectedCoin === "BTC" ? value / btcPrice : value / usdtPrice;
      setInputValueCoin(Number(convertedValue));
      setInputCoinDisplay(Number(convertedValue).toFixed(selectedCoin === "BTC" ? 8 : 3));
    } else {
      value = value.replace(/,/g, ".");
      const convertedValue = value / inputValueBRLLimitDisplay;
      setInputValueCoin(Number(convertedValue));
      setInputCoinDisplay(Number(convertedValue).toFixed(selectedCoin === "BTC" ? 8 : 3));
    }
  };


  const handleBRLChangeLimit = (event) => {
    let { value } = event.target;

    if (Number(value) == 0) {
      value = "1";
    }
    // Remove any characters that are not numbers, commas, or dots
    value = value.replace(/[^0-9.,]/g, "");


    // Replace dots with an empty string to join numbers
    value = value.replace(/\./g, "");

    //     // Replace the comma with a dot to make it the decimal separator
    // value = value.replace(/,/g, ".");

    //   console.log(value);
    // Update the input value with the transformed value
    setInputValueBRLLimit(value);
    value = value.replace(/,/g, ".");
    setInputValueBRLLimitDisplay(value);

    if (market) {

      const convertedValue = selectedCoin === "BTC" ? value / btcPrice : value / usdtPrice;
      setInputValueCoin(Number(convertedValue));
      setInputCoinDisplay(Number(convertedValue).toFixed(selectedCoin === "BTC" ? 8 : 2));
    } else {

      const convertedValue = inputValueBRL / value;
      setInputValueCoin(Number(convertedValue));
      setInputCoinDisplay(Number(convertedValue).toFixed(selectedCoin === "BTC" ? 8 : 2));
    }
  };


  const calculateMinusFee = (amount) => {
    const amountNumber = Number(amount);

    switch (fees[5]?.currency) {
      case 'BRL': {
        const value = amountNumber - ((Number(fees[5]?.value) / Number(btcPrice)))
        if (value < 0) {
          return formatBTCString(0)
        } return formatBTCString(value)
      }
      case 'BTC': {
        const value = amountNumber - ((Number(fees[5]?.value)))
        if (value < 0) {
          return formatBTCString(0)
        } return formatBTCString(value)
      }
      default: {
        const value = amountNumber - (amountNumber * (fees[5]?.value / 100))
        if (value < 0) {
          return formatBTCString(0)
        } return selectedCoin === "BTC" ? formatBTCString(value) : formatEVMString(value,6,selectedCoin.toLocaleUpperCase());
      }
    }
  }

  return (
    <Col
      style={{
        minWidth: 300,
        maxWidth: 500,
      }}
      md
    >
      <Form onSubmit={handleSubmit(createOrder)} className="card p-3">
        <Row className="text-center mb-2">
          <Col className="title-buyOrSell">
            <h5 className="buy-active">{t("buy")}</h5>
          </Col>
        </Row>

        <Form.Group className="text-center mt-2 mb-4 bold-title">
          {/* 
          <Form.Label>{t("selectcoin")} </Form.Label>
          <select
            value={selectedCoin}
            onChange={(e) => setSelectedCoin(e.target.value)}
            className="form-control input"
            style={{ width: "100%" }}
          // {...register("crypto", { required: true })}
          >
            <option value="BTC">Bitcoin</option>
            <option value="USDT">USDT</option>
          </select>
          {errors.crypto && (
            <span className="invalid-feedback show">
              {errors.crypto.message}
            </span>
          )} */}

          <Form.Label className="mt-4">{t("operationtype")} {/* Tipo de operação */}</Form.Label>
          <div
            className="d-flex mb-2 mt-2"
            style={{ justifyContent: "space-evenly" }}
          >
            <Form.Check
              inline
              label={t("market")}
              name="market-value"
              type="radio"
              id="radio-market-value"
              className="not-bold-input"
              checked={market}
              onChange={() => setMarket(true)}
            />
            <Form.Check
              inline
              label={t("limit")}
              name="limit-value"
              type="radio"
              id="radio-limit-value"
              className="not-bold-input"
              checked={!market}
              onChange={() => setMarket(false)}
            />
          </div>

          {!market && (
            <>
              <Form.Label className="mt-4">{t("executionprice")} {/* Preço de execução */}</Form.Label>

              <div className="input-group">
                <CurrencyInput
                  className="input append form-control"
                  name="amount"
                  type="text"
                  min={1}
                  value={inputValueBRLLimit}
                  onChange={handleBRLChangeLimit}
                  // You can pass additional props if necessary
                  maskOptions={{
                    prefix: '',
                    allowNegative: false,
                    decimalLimit: 2,
                    integerLimit: 10,
                  }}
                />
                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                    LIM
                  </span>
                </div>
              </div>
            </>
          )}

          {/* {{amount}} */}
          <Form.Label className="mt-4 bold-title">{t("qty")}</Form.Label>
          {selectedCoin === "BRL" ? (
            <>
              <div className="input-group">
                {/* <input
              className="input append form-control"
              name="amount"
              type="text"
              minLength={1}
              maxLength={1000000}
              max={100000}
              min={50}
              {...register("amount", {
                setValueAs: (value) => setAmount(amountInput, value, selectedCoin),
              })}
              ref={(e) => {
                amountRHFRef(e);
                amountInput.current = e;
              }}
            />
            <div className="input-group-append append-from-input">
              <span className="input-group-text input append-from-input">
                BRL
              </span>
            </div> */}
              </div>
              {/* <div style={{ fontWeight :"400", fontStyle: "italic", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
            <span>Referência (BTC): </span>
            <span>{amount > 0 ? Number(amount / btcPrice).toFixed(8) : `0.00000000 BTC`}</span>
          </div> */ }
            </>
          ) : (
            <>
              <div className="input-group">
                <CurrencyInput
                  className="input append form-control"
                  name="amount"
                  type="text"
                  value={inputValueBRLDisplay}
                  onChange={handleBRLChange}
                  // You can pass additional props if necessary
                  maskOptions={{
                    prefix: '',
                    allowNegative: false,
                    decimalLimit: 2,
                    integerLimit: 10,
                  }}
                />
                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                    R$
                  </span>
                </div>
              </div>
              <div className="input-group" style={{ marginTop: 10 }}>
                <input
                  className="input append form-control"
                  name="amount"
                  type="text"
                  minLength={1}
                  maxLength={13}
                  disabled={!market}
                  // {...register("amount", {
                  //   setValueAs: (value) => setAmount(amountInput, value,  selectedCoin),
                  // })}
                  // ref={(e) => {
                  //   amountRHFRef(e);
                  //   amountInput.current = e;
                  // }}
                  value={inputCoinDisplay}
                  onChange={handleBTCChange}
                />
                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                    {selectedCoin === "BTC" ? "BTC" : selectedCoin.toLocaleUpperCase()}
                  </span>
                </div>
              </div>
            </>
          )}
          {errors.amount && (
            <span className="invalid-feedback show">
              {errors.amount.message}
            </span>
          )}

          <div style={{ fontWeight: "400", fontStyle: "italic", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
            <span>{t("valuetoreceive")}: </span>
            <span>{inputValueCoin > 0 ? (calculateMinusFee(inputValueCoin)) : selectedCoin === "BTC" ? `0.00000000` : `0.000` } </span>
          </div>
          <br></br>

          <div style={{ display: "grid", flexDirection: "row" }}>
            <span>{t("refferenceprice")} {/* Preço de Referência */}:</span>
            {market ? (
              <>
                <span>{selectedCoin === "BTC" ? numberFormatBRL(btcPrice) : numberFormatBRL(usdtPrice)}</span>
                <div style={{ fontWeight: "400", fontStyle: "italic", fontSize: 13, color: "red", justifyContent: "center", marginTop: "10px" }}>
                  <span>({t("slippage", { slippageValue })})</span>
                </div>
              </>
            ) : (
              <>
                <span>{numberFormatBRL(inputValueBRLLimitDisplay)}</span>
              </>
            )}
          </div>
          {/* <Form.Label className="mt-4 bold-title">Total</Form.Label>
          <div className="input-group">
            { selectedCoin === 'BRL' ? (
              <input
              className="input form-control"
              name="btcPrice-market"
              type="text"
              value={`${numberFormatBRL(amount)}`}
              disabled
            />
            ) :  (
              <input
              className="input form-control"
              name="btcPrice-market"
              type="text"
              value={`${numberFormatBRL(btcPrice * amount)}`}
              disabled
            />
            )}
          </div> */}
        </Form.Group>

        {/* <div className="orderbook-fees order-container">
            <table>
              <thead>
                <tr>
                  <th colSpan="3" className="orderbook-title">Tarifa de Compra da Corretora</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tarifa:</td>
                  <td>{fees?.buyFee?.value} %</td>
                </tr>
                <tr>
                  <td>Valor da Tarifa(BTC):</td>
                  <td>BTC { ((amount) * (Number(fees?.buyFee?.value) / 100)).toFixed(6) }</td>
                </tr>
                <tr>
                  <td>Referência (R$):</td>
                  <td>R$ { formatMoney((btcPrice * amount) * (Number(fees?.buyFee?.value) / 100)) }</td>
                </tr>
              </tbody>
            </table>
          </div> */}

        {!loading ? (
          <div className="text-center mb-3">
            <Button type="submit">{t("confirm")}</Button>
          </div>
        ) : (
          <div className="text-center mt-3 mb-3">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
      </Form>
      {/*
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Aviso | Warning</h2>
            <p>{t("differencePrice")}</p>
            <button onClick={confirmOrder}>{t("confirm")}</button>
            <button onClick={cancelOrder("limit")}>{t("cancel")}</button>
          </div>
        </div>
      )}

      {showModalMKT && (
        <div className="modal">
          <div className="modal-content">
            <h2>Aviso | Warning</h2>
            <p>{t("marketSlippage")}</p>
            <button onClick={confirmOrder}>{t("confirm")}</button>
            <button onClick={cancelOrder("market")}>{t("cancel")}</button>
          </div>
        </div>
      )}*/}

    </Col>

  );
}

export default CreateBuyOrder;
