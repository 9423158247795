import React, { forwardRef, useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import api from "../utils/api";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { formatDate, formatDateDay } from "../utils/helper";
import { isDateInRange } from "../utils/dateComparation"
import { numberFormatBRL, prepareValue, removeSymbol } from "./InvestmentList";
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)

const TradeMasterReportPDF = forwardRef((props, ref) => {

  const {
    trade,
    investment,
    initialDate,
    finalDate,
    prices
  } = useContext(AppContext);

  const [user, setUser] = useState({});

  useEffect(() => {

    if (trade) {
      getUser();
    }

  }, [trade, investment]);

  async function getUser() {
    const response = await api.get("users/" + trade?.usersId);
    setUser(response.data);

  }
  
  const data = investment?.data;
  
  const statements = investment?.statements.map(statement => ({ ...statement, initial: false }));

  if (statements) {
    statements.unshift({ initial: true, initialAmount: data.initialAmount, baseDay: dayjs(data.createdAt).format("YYYY-MM-DD"), period: data.period, end_date: dayjs(data.end_date).format("YYYY-MM-DD"), profitAmount: data.profitAmount, currentAmount: data.currentAmount, contract: data.contract })
  }

  const WithdrawsLiquid = (el, index) => {
    var currentValue = el.currentAmount;

    if (el.withdraws.length > 0) {

      currentValue = statements[index + 1]?.currentAmount

      currentValue = currentValue - el.withdraws.reduce
        ((acc, w) => acc + w.finalAmount, 0);
    }

    return currentValue;
  }

  const WithdrawsProfitAmount = (el, index, justProfitability = false) => {

    var currentValue = WithdrawsLiquid(el, index);

    var rate = statements[index]?.profitability?.rate / 100;

    if (justProfitability) {
      return currentValue * rate;
    }

    currentValue = currentValue * (1 + rate);

    return currentValue;
  }

  const returnValue = (index) => {
    switch (index) {
      case 0:
        return statements[index].initialAmount;
      case 1:
        return statements[index - 1].initialAmount;
      default:
        return statements[index - 1].currentAmount;
        break;
    }
  }

  const DepositsPlusCurrentAmount = (el, index) => {
    var currentValue = WithdrawsProfitAmount(el, index);
    if (el.deposits.length > 0) {
      currentValue = currentValue + el.deposits.reduce((acc, d) => acc + d.newAmount, 0);
    }
    return currentValue;
  }
  if (!investment || !trade) {
    return null;
  }

  const returnPropsDays = (el) => {
    let baseDay = new Date(el.baseDay);
    let newMonth = baseDay.getMonth() + 1;
    let deposits = el.deposits;
    for(let i in deposits){
      const d = new Date(deposits[i].createdAt);
      let month = d.getMonth() + 1;
      if(month == newMonth){
        return baseDay.getDate();
      }
    }
  }

  const filterWithdraws = (s, range, i) => {
    const filteredDeposits = s.withdraws?.filter((w) => {
      const isInBetween = isDateInRange(w.createdAt, range);
      if (isInBetween) {
        return w;
      } return null
    });
    return filteredDeposits;
  }

  const filterDeposits = (s, range) => {
    const filteredDeposits = s.deposits?.filter((d) => {
      const isInBetween = isDateInRange(d.createdAt, range);
      if (isInBetween) {
        return d;
      } return null
    });
    return filteredDeposits;
  }

  const filterStatements = (range, i) => {
    statements.map((s) => {
      const deposits = filterDeposits(s, range);
      if (deposits && deposits.length > 0) {
        range.deposits = deposits;
      }
      const withdraws = filterWithdraws(s, range, i);
      if (withdraws && withdraws.length > 0) {
        range.withdraws = withdraws;
      }
    })
  }

  const newRanges = props.ranges.filter((range) => {
    const startDate = dayjs(range.start);
    const endDate = dayjs(range.end);

    const [day, month, year] = initialDate.split("/");
    const formattedInitial = `${year}-${month}-${day}`;

    const [dayf, monthf, yearf] = finalDate.split("/");
    const formattedFinal = `${yearf}-${monthf}-${dayf}`;

    const initial = dayjs(formattedInitial);
    const final = dayjs(formattedFinal);

    if (startDate.isSameOrAfter(`${initial}`, 'month') && endDate.isSameOrBefore(final, 'month')) {
      return range
    };
  });

  const rangesDayDiff = (range) => {
    const difference = dayjs(range.end).diff(dayjs(range.start), "day");
    return difference;
  }


  return (
    <div id="pdf" ref={ref} style={{ display: "none" }}>
      {/* begin header */}
      {/* <div className="reports-trade-master">
        <header>
          <img src="/images/logo.png" alt="bcx logo" />
          <div className="customer">
            <h2>{user.name}</h2>
            <h2>Extrato</h2>
            <p>Total (BTC) {prepareValue(Number(data?.currentAmount / prices.btc).toFixed(6))}</p>
          </div>
          <div className="address">
            <p>Av. Osvaldo Reis</p>
            <p>3385 | Sala 1407</p>
            <p>Praia Brava</p>
            <p>Itajaí | SC</p>
            <p>CEP 88306-773</p>
            <p>+55 47 99128-0000</p>
            <p>www.bcxcorretora.com.br</p>
          </div>
        </header>
      </div> */}
      {/* end header */}
      <div className="reports-trade-master pdf-component">
        <main className="main-investmentList">
        {statements?.map((el, index) => {
            if (el.initial) {
              return (
                <div className="balances pdf-top" key={index}>
                    <h2 className="margins">
                    Informações Gerais
                    </h2>
                  <div className="balance">
                    <div className="balance-data" style={{ display: 'flex', justifyContent: 'space-between'}}>
                      <span> {dayjs(data.createdAt, 'YYYY-MM-DD').format("DD/MM/YYYY")} - Aporte inicial (BTC)</span>
                      <span>BTC {prepareValue(Number(Number(el.initialAmount) / prices.btc).toFixed(8))}</span>
                    </div>
                    <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between'}}>
                      <span className="refferences" style={{ color: "#50504E", fontWeight: 400 }}>Referência (R$):</span>
                      <span className="refferences" style={{ color: "#50504E", fontWeight: 400 }}>R$ {removeSymbol(numberFormatBRL(el.initialAmount))}</span>
                    </div>
                    <div className="balance-data">
                      <span>Data de Criação</span>
                      <span>
                        {dayjs(data.createdAt, 'YYYY-MM-DD').format("DD/MM/YYYY")}
                      </span>
                    </div>
                    <div className="balance-data">
                      <span>Período</span>
                      <span>
                        {investment.data.period} meses
                      </span>
                    </div>
                    <div className="balance-data">
                      <span>Aniversário do fundo:</span>
                      <span>
                        dia {formatDateDay(investment.data.createdAt)}
                      </span>
                    </div>
                  </div>
                  <br></br>
                  <div className="balance">
                    <h2 className="margins">
                      Cotação do Bitcoin
                    </h2>
                    <div className="balance-data">
                        <span> {dayjs().format("DD/MM/YYYY")} - </span>
                        <span>BTC {prepareValue(Number(Number(1)).toFixed(8))}</span>
                    </div>
                    <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <span className="refferences" style={{ color: "#50504E", fontWeight: 400 }}>Referência (R$):</span>
                        <span className="refferences" style={{ color: "#50504E", fontWeight: 400 }}>R$ {removeSymbol(numberFormatBRL(prices.btc))}</span>
                    </div>
                  </div>
                </div>
              )
            }
          })}

          {newRanges.map((r, i) => {
                return (
                  <>
                    <div className="balances">
                      {statements[i] && <div>
                        <div className="monthly-balance">
                          {(i === 0) ?
                            <h2 className="margins" key={i}>
                              Mês {i + 1} - ( {rangesDayDiff(newRanges[i])} Dias )
                            </h2>
                          : <h2 className="margins" key={i}>
                              Mês {i + 1}
                            </h2>
                          }
                        </div>
                        <div className="refferences" key={'a' + i}>
                          <span className="refference" >Data de Início do Mês:
                          </span>
                          <span className="refference">{formatDate(r.start)}</span>
                        </div>
                        <div className="refferences" key={'b' + i}>
                          <span className="refference">Data de Fim do Mês:
                          </span>
                          <span className="refference">{formatDate(r.end)}</span>
                        </div>
                      </div>}

                      <br></br>
                      <div>
                        {(statements[i])
                          && <div>
                            {
                              <>
                                <div className="dashboard-bold" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                  <p className="info">
                                    {formatDate(r.start)} - Valor inicial (BTC)
                                  </p>
                                  <p className="info">
                                    BTC {prepareValue(Number(Number(returnValue(i)) / prices.btc).toFixed(8))}
                                  </p>
                                </div>
                                <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                  <span className="refferences">
                                    Referência (R$)
                                  </span>
                                  <span className="refferences">
                                    R$ {removeSymbol(numberFormatBRL(returnValue(i)))}
                                  </span>

                                </div>
                                <br></br>

                                {statements[i]?.profitability?.rate && (
                                  <>
                                    <div className="dashboard-bold" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                      <p className="info">
                                        {dayjs(statements[i].baseDay).format("DD/MM/YYYY")} - Rentabilidade
                                      </p>
                                      <p className="info">
                                        + {statements[i].profitability.rate}%
                                      </p>
                                    </div>
                                    <div className="dashboard-bold" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                      <p className="info">
                                        {dayjs(statements[i].baseDay).format("DD/MM/YYYY")} - Referência (BTC)
                                      </p>
                                      <p className="info">
                                        + BTC {prepareValue((Number(statements[i].profitAmountLessDeposit) / Number(prices.btc)).toFixed(8))}
                                      </p>
                                    </div>
                                    <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                      <span className="refferences">
                                        Referência (R$)
                                      </span>
                                      {statements[i]?.profitability?.rate &&
                                        <span className="refferences" >
                                          + R$ {removeSymbol(numberFormatBRL(statements[i].profitAmountLessDeposit))}
                                        </span>
                                      }
                                    </div>
                                    {statements[i]?.deposits.length !== 0 &&
                                      <>
                                        {statements[i].deposits.map((d) =>
                                          <div>
                                            <div className="dashboard-bold" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                              <p className="info">{dayjs(statements[i].baseDay).format("DD/MM/YYYY")} ({returnPropsDays(statements[i])} Dias) - Rentabilidade Proporcional do Aporte (BTC)</p>
                                              <p className="info">+ BTC {prepareValue(Number(d.profitabilityAmount / prices.btc).toFixed(6))}</p>
                                            </div>
                                            <div className="dashboard-bold" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                              <p className="refferences">Referência (R$)</p>
                                              <span>+ R$ {removeSymbol(numberFormatBRL(d.profitabilityAmount))}</span>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    }
                                    <br></br>
                                  </>)}
                              </>
                            }
                          </div>
                        }

                      </div>
                      {statements && <div key={'c' + i}>
                        {filterStatements(r, i)}
                      </div>}

                      {r.deposits && <div key={'d' + i}>
                        {r.deposits.map((d, i) =>
                          <div>
                            <div className="dashboard-bold" style={{ display: 'flex', justifyContent: 'space-between'}}>
                              <p className="info">{dayjs(d.createdAt).format("DD/MM/YYYY")} - Aporte (BTC)</p>
                              <p className="info">+ BTC {prepareValue(Number(d.amount / prices.btc).toFixed(6))}</p>
                            </div>
                            <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between'}}>
                              <p className="refferences">Referência (R$)</p>
                              <span>+ R$ {removeSymbol(numberFormatBRL(d.amount))}</span>
                            </div>
                          </div>
                        )}
                      </div>}

                      {r.withdraws && <div key={'e' + i}>
                      {r.withdraws.map((w, i) =>
                          <>
                            <div className="dashboard-bold" style={{ display: 'flex', justifyContent: 'space-between'}}>
                              <p className="info">{dayjs(w.createdAt).format("DD/MM/YYYY")} - Saque (BTC)</p>
                              <p className="info">- BTC {prepareValue(Number(w.requestedAmount / prices.btc ?? 0).toFixed(6))}</p>
                            </div>
                            <div className="dashboard-bold" style={{ display: 'flex', justifyContent: 'space-between'}}>
                              <span className="info">
                                Tipo de Saque:
                              </span>
                              <span className="info">
                                {w.type === "withdraw" ? "Resgate antecipado" : "Saque de rentabilidade"}
                              </span>
                            </div>
                            <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between'}}>
                              <p className="refferences">Referência (R$)</p>
                              <p className="refferences">- R$ {removeSymbol(numberFormatBRL(w.requestedAmount))}</p>
                            </div>
                            {w.type === 'withdraw-profit' &&
                              <>
                                <div className="dashboard-bold">
                                  <p className="info">Taxa operacional (BTC)</p>
                                  <p className="info">- BTC {prepareValue(Number(w.operatingFee / prices.btc ?? 0).toFixed(6))}</p>
                                  {/* {w.operatingFee} */}
                                </div>
                                <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                  <p className="refferences">Referência (R$)</p>
                                  <p className="refference">- R$ {removeSymbol(numberFormatBRL(w.operatingFee))}</p>
                                </div>
                              </>
                            }
                            {w.type === "withdraw" && (
                              <>
                                {Number(w.proRata) > 0 && (<>
                                  <div className="dashboard-bold"style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <p className="info">Pró rata (BTC)</p>
                                    <p className="info">- BTC {prepareValue(Number(w.proRata / prices.btc ?? 0).toFixed(6))}</p>
                                    {/* {w.proRata} */}
                                  </div>
                                  <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <p className="refferences">Referência (R$)</p>
                                    <p className="refferences">- R$ {removeSymbol(numberFormatBRL(w.proRata))}</p>
                                  </div>
                                </>)}
                                {Number(w.penalty) > 0 && (<>
                                  <div className="dashboard-bold" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <p className="info">Multa (BTC)</p>
                                    <p className="info">- BTC {prepareValue(Number(w.penalty / prices.btc ?? 0).toFixed(6))}</p>
                                  </div>
                                  <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <p className="refferences">Referência (R$)</p>
                                    <p className="refferences">- R$ {removeSymbol(numberFormatBRL(w.penalty))}</p>
                                  </div>
                                </>)}
                              </>)}
                          </>
                        )}
                      </div>}

                      <>
                        {statements.map((d, index) =>
                          <div>
                            {(i == index && i > 0) && <div className="dashboard-bold" style={{ display: 'flex', justifyContent: 'space-between'}}>
                              <p className="info">
                                Saldo Final (BTC)
                              </p>
                              <p className="info">
                                BTC {i == index ? prepareValue(Number(Number(d.currentAmount) / prices.btc).toFixed(8)) : ''}
                              </p>
                            </div>}

                            {(i == index && i > 0) && <div className="refferences" style={{ display: 'flex', justifyContent: 'space-between'}}>
                              <span className="refferences">
                                Referência (R$)
                              </span>
                              <span className="refferences">
                                R$ {i == index ? removeSymbol(numberFormatBRL(d.currentAmount)) : ''}
                              </span>
                            </div>}
                          </div>
                        )}
                      </>

                    </div>
                  </>
                )
              })
              }
        </main>

        <footer className='pdf-footer'>
          <div className="title">
            <span>Fundo iniciado em {dayjs(trade.createdAt).format("DD/MM/YYYY")}</span>
            <span>Próxima renovação em {dayjs(trade.createdAt).add(trade.period + 1, 'month').format("DD/MM/YYYY")}</span>
          </div>
          <p>
            Atenção: Valores de referência em Reais, na data/horário de
            geração do relatório. Não devem ser considerados como valores
            líquidos para saque.
          </p>
          <p>
            Referência: Coingecko <a>https://www.coingecko.com</a>
          </p>
        </footer>
      </div >
    </div >
  );
});

export default TradeMasterReportPDF;
