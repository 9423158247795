import React from "react";
import Panel from "../../components/Panel";
import { Table, Button } from "react-bootstrap";
import api from "../../utils/api";

export default class AdminEmployees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      rows: [],
      filteredRows: [],
      page: 0,
      pages: 0,
    };
  }

  componentDidMount = () => {
    this.reloadTable();
  };

  reloadTable = () => {
    api.get("users").then((req) => {
      if (!req.error) {
        let rows = req.data;
        rows = rows.filter((row) => row.roles.name !== "user");
        this.setState({
          load: false,
          rows: rows,
          filteredRows: rows.slice(0, 20),
          page: 0,
          pages: Math.floor(rows.length / 20),
        });
      }
    });
  };

  setPage = (page) => {
    if (page < 0) {
      page = 0;
    } else if (page > this.state.pages) {
      page = this.state.pages;
    } else {
      this.setState({
        page: page,
        filteredRows: this.state.rows.slice(page * 20, page * 20 + 20),
      });
    }
  };

  formatStatusKYC = (kycStatus) => {
    if (kycStatus === "accepted") {
      return "Sim";
    } else if (kycStatus === "rejected") {
      return "Rejeitado";
    } else if (kycStatus === "waiting") {
      return "Aguardando Aprovação";
    } else {
      return "Não";
    }
  };

  render() {
    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="cash-book"
        title="Livro Caixa"
        admin={true}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#50504E",
          }}
        >
          <span className="bold text1 ml-3 text-mid2">Livro Caixa</span>
        </div>
        <Table
          className="hist-table m-0 p-0 text-mid"
          responsive="md"
          striped
          hover
        >
          <thead>
            <tr>
              <th>ID Cliente</th>
              <th>Data</th>
              <th>Operação</th>
              <th>Taxa %</th>
              <th>Tipo</th>
              <th>Moeda</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>12345678</td>
              <td>30/09/2020</td>
              <td>OTC</td>
              <td>0,125</td>
              <td>Saída</td>
              <td>Bitcoin (BTC)</td>
              <td>1,023445</td>
            </tr>
          </tbody>
        </Table>
        <div className="text-center text-large mt-3 mb-5">
          <span
            onClick={() => this.setPage(this.state.page - 1)}
            className="pr-2 btn"
          >
            <i className="fas fa-caret-left"></i>
          </span>
          <span>
            Página {this.state.page + 1} de {this.state.pages + 1}
          </span>
          <span
            onClick={() => this.setPage(this.state.page + 1)}
            className="pl-2 btn"
          >
            <i className="fas fa-caret-right"></i>
          </span>
        </div>
      </Panel>
    );
  }
}
