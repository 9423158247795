export const INVESTMENT_STATUS_TYPES = [
    // { name: 'Pendente', status: 'pending' },
    { name: 'Ativo', status: 'active' },
    { name: 'Encerrado', status: 'inactive' },
    // { name: 'Pausado', status: 'paused' },
]

export const INVESTMENT_STATUS_TYPES_EN = [
    // { name: 'Pendente', status: 'pending' },
    { name: 'Active', status: 'active' },
    { name: 'Finished', status: 'inactive' },
    // { name: 'Pausado', status: 'paused' },
]

export const INVESTMENTS_TYPES = [
    { name: 'Trade', status: 'trade' },
    { name: 'Standard', status: 'standard' },
    { name: 'Moderado', status: 'moderado' },
    { name: 'Alto Valor', status: 'alto valor' },
    { name: 'Alta Performance', status: 'high performance' },
]

export const INVESTMENTS_TYPES_EN = [
    { name: 'Trade', status: 'trade' },
    { name: 'Standard', status: 'standard' },
    { name: 'Moderate', status: 'moderado' },
    { name: 'High Value', status: 'alto valor' },
    { name: 'High Performance', status: 'high performance' },
]

export const SUPPORT_THEME_TYPES = [
    { name: 'Trader', status: 'Trader' },
    { name: 'Autenticação', status: 'Autenticação' },
    { name: 'Depósitos/Saques', status: 'Depósitos/Saques' },
    { name: 'Outros', status: 'Outros' },
]

export const SUPPORT_THEME_TYPES_EN = [
    { name: 'Trader', status: 'Trader' },
    { name: 'Authentication', status: 'Autenticação' },
    { name: 'Deposits/Withdraws', status: 'Depósitos/Saques' },
    { name: 'Others', status: 'Outros' },
]

export const SUPPORT_SUBJECT_TYPES = [
    { name: 'Outros', status: 'Outros' },
]

export const SUPPORT_SUBJECT_TYPES_EN = [
    { name: 'Others', status: 'Outros' },
]

export const DEPOSITS_DESTINY_TYPES = [
    { name: 'Fundo', status: 'fundo' },
]

export const DEPOSITS_TYPE_TYPES = [
    { name: 'Transferência', status: 'transferencia' },
]

export const DEPOSITS_STATUS_TYPES = [
    { name: 'Rejeitado', status: 'withdraw' },
    { name: 'Cancelado', status: 'canceled' },
    { name: 'Aguardando', status: 'waiting' },
    { name: 'Aguardando', status: 'sent' },
    { name: 'Confirmado', status: 'confirmed' },
]

export const DEPOSITS_STATUS_TYPES_EN = [
    { name: 'Denied', status: 'withdraw' },
    { name: 'Canceled', status: 'canceled' },
    { name: 'Waiting', status: 'waiting' },
    { name: 'Waiting', status: 'sent' },
    { name: 'Confirmed', status: 'confirmed' },
]

export const DEPOSITS_CURRENCIES_TYPES = [
    { name: 'BRL', status: 'BRL' },
    { name: 'USD', status: 'USD' },
    { name: 'BTC', status: 'BTC' },
]

export const WITHDRAW_TYPES = [
    { name: 'Saque de Lucro', status: 'withdraw-profit' },
    { name: 'Saque Antecipado', status: 'withdraw' },
]

export const WITHDRAW_STATUS = [
    { name: 'Aprovado', status: 'approved' },
    { name: 'Pendente', status: 'pending' },
    { name: 'Rejeitado', status: 'rejected' },
]

export const formatStatus = (types, type) => {
    for (let i = 0; i < types.length; i++) {
        if (types[i].status === type) {
            return types[i].name;
        }
    }
    return '';
}

export const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            USD: {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            BTC: {
                style: "currency",
                currency: "BTC",
                minimumFractionDigits: 8,
                maximumFractionDigits: 8,
            },
            DECIMAL: {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};