import React, { useEffect, useState, useContext } from "react";
import { Form, Collapse, Col, Table } from "react-bootstrap";
import api from "../../utils/api";
import { numberFormatBRL } from "../InvestmentWithdrawModal";
import { formatBTCString, formatEVMString } from "../../utils/Helpers/btcFormat";
import { AppContext } from "../../context/AppContext";

const AdminUserBalance = ({ id }) => {
  const [show, setShow] = useState(true);
  const [userBalances, setUserBalances] = useState([]);

  const { btcPrice, usdtPrice } = useContext(AppContext);

  const toggleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    getUserBalances(id);
  }, [id]);

  const getUserBalances = (userId) => {
    api.get(`balances?filter[where][usersid]=${userId}`).then((req) => {
      if (!req.error) {
        setUserBalances(req.data);
      }
    });
  };

  return (
    <Form className="w-100 card p-3 mb-5">
      <div
        className="text-large bold mb-2 w-100 d-flex btn"
        onClick={toggleShow}
      >
        <span className="">Saldos do Usuário</span>
        <i className="fas fa-caret-down text-large mr-3 ml-auto"></i>
      </div>
      <div
        style={{ width: "100%", height: 1, backgroundColor: "#50504E" }}
      ></div>
      <Collapse in={show}>
        <>
        <br></br>
        { userBalances && (userBalances?.length > 0) && userBalances?.map((balance) => (
          <>
          <Col lg>
          <div
            style={{
              width: "100%",
              backgroundColor: "#50504E",
            }}
          >
            {balance.currency === "BTC" ? (
              <span className="bold text1 ml-3 text-mid2">
                Carteira de Bitcoin (BTC)
              </span>
            ) : balance.currency === "USDT" ? (
              <span className="bold text1 ml-3 text-mid2">
                Carteira de USDT 
              </span>
            ) : (
              <span className="bold text1 ml-3 text-mid2">
                Saldo (R$)
              </span>
            )}
          </div>
          <Table
            className="hist-table m-0 p-0 text-mid"
            responsive="md"
            striped
            hover
          >
            <thead>
              <tr>
                <th>Saldo</th>
                { balance.currency !== "BRL" && (
                  <th>Referência BRL</th>
                )}

              </tr>
            </thead>
            <tbody>
              <tr>
              { balance.currency === "BTC" ? (
                <>
                  <td>
                    { formatBTCString(balance.currentAmount) }
                  </td>
                  <td>
                    { numberFormatBRL(Number(balance.currentAmount * btcPrice)) }
                  </td>
                </>
              ) : balance.currency === "USDT" ? (
                <>
                  <td>
                    { formatEVMString(balance.currentAmount,3,"USDT") }
                  </td>
                  <td>
                    { numberFormatBRL(Number(balance.currentAmount * usdtPrice)) }
                  </td>
                </>
              ) : (
                <td>
                  { numberFormatBRL(balance.currentAmount) }
                </td>
              ) }
              </tr>
            </tbody>
          </Table>
        </Col>
        <br></br>
        </>
        ))}
        </>
      </Collapse>
    </Form>
  );
};

export default AdminUserBalance;
