import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../context/AppContext";
import api from "../utils/api";
import { set } from "react-hook-form";

export const TermsTrade = ({ showModal }) => {
  const {
    getBalances,
    setShowModalTermsTrade,
    loggedInUser,
    investmentAmount,
    investmentPeriod,
    setCompleteUser,
    setShowInvestmentModal,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheck = () => {
    setIsChecked(!isChecked);
  };

  const saveInvestmentTerms = async () => {
    setLoading(true);
    let req = await api.patch(`users/${loggedInUser.id}`, {
      investmentTerms: isChecked
    });
    if (!req.error) {
      toast.success(`Termos de investimento aceitos!`);
      api.get(`users/${loggedInUser.id}`).then((req) => {
        if (!req.error) {
            setCompleteUser(req.data)
          }
      });
      setLoading(false);
      setShowModalTermsTrade(false);
    } else {
      toast.error(`Ocorreu um erro ao aceitar os termos de investimento! Entre em contato com a nossa equipe.`);
      setLoading(false);
      setShowModalTermsTrade(false);
    } 
  }



  const createInvestment = async () => {
    setLoading(true);

    var now = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const periodFormat = parseInt(investmentPeriod);
    const periodEndFormat = dayjs()
      .add(investmentPeriod, "month")
      .format("YYYY-MM-DD HH:mm:ss");

    let investment = {
      createdAt: now,
      end: periodEndFormat,
      usersId: loggedInUser.id,
      type: "trade",
      status: "pending",
      initialAmount: investmentAmount,
      period: periodFormat,
      currency: "BTC",
      currentAmount: 0,
      acceptTerms: true,
    };

    const res = await api.post("investments", investment);

    if (!res.error) {
      toast.success(`Aporte enviado`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getBalances(loggedInUser.id);
    }

    setShowInvestmentModal(false);
    setShowModalTermsTrade(false);
    setLoading(false);
  };

  const handleClose = () => {
    setShowModalTermsTrade(false);
    navigate("/panel/bem-vindo")
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <h5 className="w-100 ml-3 text-center">
          Você precisa aceitar os termos de investimento para prosseguir.
        </h5>
      </Modal.Header>
      <Modal.Body className="w-100 text2">
        <div
          className="ml-5 mr-1 pr-5"
          style={{
            maxHeight: 400,
            overflowY: "scroll",
          }}
        >
          <div className="legal-div">
            <h4 className="legal-title">
              TERMOS E CONDIÇÕES EXCLUSIVOS DO SERVIÇO DE “GERENCIAMENTO DE
              ATIVOS
            </h4>
            <br></br>
            <p className="legal-text">
              Este serviço está disponível para contratação apenas para usuários
              selecionados e que já sejam clientes da BCX CORRETORA
            </p>
            <p className="legal-text">
              AO ADERIR AO SERVIÇO, ENTENDE-SE QUE O USUÁRIO ESTÁ DE ACORDO COM
              OS TERMOS E CONDIÇÕES DETERMINADOS NESTE DOCUMENTO. SENDO ASSIM,
              FAVOR LER COM ATENÇÃO TODAS AS DISPOSIÇÕES DESTES TERMOS ANTES DE
              ACEITÁ-LOS.
            </p>
            <p className="legal-text">
              (i) A BCX, já qualificada nos seus Termos de Uso da Exchange,
              oferece exclusivamente aos seus USUÁRIOS cadastrados na
              Plataforma, acessível mediante login e senha, a possibilidade de
              adesão ao Serviço de Gerenciamento de Ativos (“Serviço”).
            </p>
            <p className="legal-text">
              (ii) AO CLICAR NA CAIXA “EU LI, CONCORDO E ACEITO EXPRESSAMENTE
              ESTES TERMOS E CONDIÇÕES, VOCÊ, USUÁRIO, DECLARA QUE CONCORDA COM
              AS CONDIÇÕES AQUI DISPOSTAS.
            </p>
            <p className="legal-text">
              (iii) CASO NÃO CONCORDE COM TODOS OS TERMOS E CONDIÇÕES A SEGUIR,
              VOCÊ NÃO DEVERÁ ACEITÁ-LOS OU ADERIR AO SERVIÇO.
            </p>
            <p className="legal-text">
              (iv) A BCX PODERÁ, A SEU CRITÉRIO E A QUALQUER MOMENTO, ALTERAR
              ESTES TERMOS E CONDIÇÕES, MEDIANTE PUBLICAÇÃO DA VERSÃO ATUALIZADA
              NA PLATAFORMA. As atualizações destes Termos e Condições entrarão
              em vigor na data de sua publicação, a não ser que seja informado
              em sentido diverso. Os USUÁRIOS serão informados previamente, por
              meio de e- mail OU aviso na Plataforma, sendo requisitado novo
              aceite a estes Termos.
            </p>
            <p className="legal-text">
              (v) Aproveitam-se para esses Termos e Condiçoes, no que forem
              complementares, as mesmas disposições dos Termos de Uso da
              Exchange.
            </p>
            <br></br>
            <div>
              <h5 className="legal-subtitle">
                1. O QUE É O SERVIÇO DE GERENCIAMENTO DE ATIVOS
              </h5>
              <p className="legal-text">
                1.1 Por meio do Serviço de Gerenciamento de Ativos, os USUÁRIOS
                têm a possibilidde de transferir parte dos seus criptoativos
                para serem gerenciados pela BCX por um período pré-determinado
                (“ciclo”). A BCX realizará operações estratégicas com o objetivo
                de aumentar a quantidade original de criptoativos transferida
                pelo USUÁRIO.
              </p>
              <p className="legal-text">
                1.2 A BCX oferece três modalidades do serviço, para atender os
                diferentes perfis de USUÁRIOS, de acordo com o nível de risco,
                as estratégias são desenvolvidas por uma equipe analistas
                especialistas no mercado de criptoativos e que indicam sempre o
                melhor momento para as operações, conforme caracteristicas
                abaixo:
              </p>
              <table>
                <thead>
                  <tr>
                    <th>Modalidade</th>
                    <th>Ativo</th>
                    <th>Stop Loss</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Standard ou Clássico</td>
                    <td>Bitcoin</td>
                    <td>1%</td>
                  </tr>
                  <tr>
                    <td>Moderado, Security ou Alto Valor</td>
                    <td>Bitcoin</td>
                    <td>0,5%</td>
                  </tr>
                  <tr>
                    <td>High Performance ou Arrojado</td>
                    <td>Bitcoin</td>
                    <td>1,5%</td>
                  </tr>
                </tbody>
              </table>
              <p className="legal-text">
                1.3 O USUÁRIO deve ler atentamente as características de cada
                modalidade e considerar o seu nível de risco e a volatilidade
                que envolve as operações com criptoativos. A BCX irá envidar
                todos os seus enforços para obter os melhores resultados com as
                estratégias desenvolvidas, no entanto, a BCX não garante o
                efetivo aumento da quantidade de criptoativos do USUÁRIO e, ao
                aceitar estes Termos e Condições, este está ciente e isenta a
                BCX de QUALQUER responsalibidade em caso de perdas, não cabendo,
                nesta hipótese, qualquer tipo de indenização.
              </p>
              <p className="legal-text">
                1.3.1 A BCX adota medidas de segurança cibernética para a
                garantir a estabilidade da PLATAFORMA, no entanto, o USUÁRIO tem
                ciência de que ambientes virtuais estão, ainda que minimamente,
                expostos a riscos, instalibilidade, invasões (hackeamento) que
                poderão acarretar na perda/roubo parcial ou total dos seus
                recursos, isentando, de igual modo, a BCX da responsabilidade
                por esses eventos quando esta demonstrar que adotou todas as
                medidas esperadas de uma empresa de seu porte e atividade para
                evitar esse tipo de ação.
              </p>

              <h5 className="legal-subtitle">2. COMO ADERIR AO SERVIÇO: </h5>
              <p className="legal-text">
                2.1 O Serviço é disponibilizado para aquisição, exclusiva e
                privativa, pelos USUÁRIOS cadastrados na Exchange da BCX,
                portanto, para aderir, o USUÁRIO deverá ter uma conta aberta e
                ativa, KYC aprovado e saldo disponível em criptoativos.
              </p>
              <p className="legal-text">
                2.2 Para aderir ao Serviço o USUÁRIO deverá aceitar estes Termos
                e Condições Específicos, após o aceite, deverá criar uma ordem
                de gerenciamento, informando à BCX, a quantidade de criptoativos
                da sua wallet que está disponível para o gerenciamento. A
                transferência dos criptoativos para gerenciamento será realizada
                de forma automática.
              </p>

              <h5 className="legal-subtitle">
                3. DOS CICLOS DE PRESTAÇÃO DOS SERVIÇOS
              </h5>
              <p className="legal-text">
                3.1 No momento em que os criptoativos forem transferidos para a
                BCX, o USUÁRIO deverá selecionar o período do ciclo de
                gerenciamento.
              </p>
              <p className="legal-text">
                3.1.1 Os Ciclos de Prestação de Serviços serão considerados os
                períodos em que os criptoativos deverão permanecer sob a gestão
                da BCX, conforme modalidade escolhida pelo USUÁRIO.
              </p>
              <p className="legal-text">
                3.2 Mensalmente, será gerado e encaminhado ao USUÁRIO um
                relatório de transações e operações realizadas pela BCX.
              </p>

              <h5 className="legal-subtitle">4. DAS TAXAS E TARIFAS </h5>
              <p className="legal-text">
                4.1 Será devida à BCX o pagamento da Taxa de Infraestrutura
                calculada sobre o valor bruto da rentabilidade e a Tarifa
                Operacional calculada sobre a rentabilidade líquida obtida pela
                BCX.
              </p>
              <p className="legal-text">
                4.2 Caso haja renovação automática do ciclo, a Tarifa
                Operacional poderá ser alteradas a cada novo ciclo, acesse as
                taxas e tarifas atualizadas clicando aqui.
              </p>

              <h5 className="legal-subtitle">5. DA RENTABILIDADE</h5>
              <p className="legal-text">
                5.1 Toda a rentabilidade será paga em Bitcoin, ao final do ciclo
                do gerenciamento,mediante a transferência pela BCX para a wallet
                de destino indicada pelo USUÁRIO.
              </p>
              <p className="legal-text">
                5.2 A quantidade original de criptoativos do USUÁRIO será
                transferida para sua wallet de destino. Caso o USUÁRIO opte por
                vender seus criptoativos para a BCX, deverá indicar conta
                corrente de sua própria titularidade para o recebimento em moeda
                fiduciária.
              </p>

              <h5 className="legal-subtitle">6. DA RESCISÃO DESTES TERMOS </h5>
              <p className="legal-text">
                6.1 O USUÁRIO poderá solicitar a rescisão destes Termos e
                Condições Específicos, conforme disposto abaixo:
              </p>
              <p className="legal-text">
                <ul>
                  <li>
                    a) com aviso prévio de 30 (trinta) dias, sendo devido à BCX
                    a TARIFA OPERACIONAL de 20% (vinte por cento) da
                    rentabilidade do período, bem como multa de 15% (quinze por
                    cento) sobre a rentabilidade do período, tendo a BCX o prazo
                    de 7 (sete) dias úteis para pagamento.
                  </li>
                  <li>
                    b) sem aviso prévio de 30 (trinta) dias, será devida à BCX a
                    Tarifa Operacional de 20% (vinte por cento) da
                    rentabilidade, bem como uma multa de 40% (quarenta por
                    cento) sobre a rentabilidade obtida até a data da
                    solicitação, tendo a BCX um prazo de 7 (sete) dias úteis
                    para pagamento.
                  </li>
                </ul>
              </p>
              <p className="legal-text">
                6.2 Da pró-rata e carência em caso de rescisão antes do termino
                do ciclo:
              </p>
              <p className="legal-text">
                6.2.1. Em ambos os casos, se a solicitação de rescisão destes
                Termos ocorrer antes do término do ciclo escolhido, o USUÁRIO
                receberá como rentabilidade o valor correspondente a média dos
                meses anteriores, respeitado o mínimo de 5% (cinco por cento)
                sobre os meses restantes para o término do contrato, aplicada a
                Tarifa Operacional sobre o valor final da rentabilidade
                estimada, como se o ciclo tivesse sido cumprido até o final.
              </p>
              <p className="legal-text">
                6.2.3 Se a solicitação de rescisão for anterior ao término do
                ciclo, o USUÁRIO somente poderá aderir novamente ao serviço após
                06 (seis) meses da rescisão, salvo disposição em contrário
                determinada a critério da BCX.
              </p>
              <p className="legal-text">
                6.3 O USUÁRIO poderá optar por retirar ao final de cada ciclo de
                30 dias, apenas a rentabilidade obtida no período, mantendo os
                criptoativos originalmente aportados dentro do ciclo de
                gerenciamento contratado.
              </p>

              <h5 className="legal-subtitle">
                7. DA POSSIBILIDADE DE EXTINÇÃO DO SERVIÇO PELA BCX
              </h5>
              <p className="legal-text">
                7.1 A BCX reserva para si o direito de extinguir a prestação do
                Serviço, a qualquer momento, mediante comunicação previa aos
                USUÁRIOS, salvo caso fortuito ou força maior, e devolução dos
                criptoativos colocados sob gerenciamento.
              </p>

              <h5 className="legal-subtitle">
                8. DISPOSIÇÕES IMPORTANTES AOS USUÁRIOS DO SERVIÇO
              </h5>
              <p className="legal-text">8.1 Canais de atendimento</p>
              <p className="legal-text">
                8.1.1 Para atender o USUÁRIO em demandas relacionadas ao Serviço
                ou as estes Termos e Condições, a BCX disponibilizará um canal
                de atendimento para solução de problemas e/ou esclarecimentos de
                dúvidas, podendo ser realizado contato via e-mail
                suporte@bcxcorretora.com.br
              </p>
              <p className="legal-text">8.2 Política de Privacidade</p>
              <p className="legal-text">
                8.2.1 Aplicam-se aos dados pessoais tratados em razão destes
                Termos, as disposições da POLÍTICA DE PRIVACIDADE.
              </p>
              <p className="legal-text">8.3 Responsabilidades e Garantias:</p>
              <p className="legal-text">
                8.3.1 A BCX envidará todos os esforços na prestação do serviço,
                no entanto, não poderá ser responsabilizada por (i) qualquer
                problema decorrente de culpa exclusiva do USUÁRIO; (ii) eventos
                definidos na legislação civil como caso fortuito ou força maior.
              </p>
              <p className="legal-text">
                8.3.2 A BCX declara que possui um Fundo de Reserva, administrado
                com objetivo de minimizar impactos negativos causados por
                situações adversas e emergenciais, a seu critério.
              </p>
              <p className="legal-text">
                8.3.3 O USUÁRIO GARANTE E DECLARA QUE OS ATIVOS TRANSFERIDO À
                BCX PARA GESTÃO POSSUEM ORIGEM LÍCITA E COMPROVADA PERANTE A
                RECEITA FEDERAL DO BRASIL, NÃO SENDO OBJETO DE CRIMES OU
                ATIVIDADES SUSPEITAS DE LAVAGEM DE DINHEIRO E FINANCIAMENTO AO
                TERRORISMO.
              </p>
              <p className="legal-text">
                8.3.4 A BCX informa que em caso de suspeita de fraude ou
                qualquer outra atividade ilícita, poderá, além de recorrer às
                medidas legais cabíveis, no momento efetivo da rescisão, reter
                recursos do USUÁRIO até a conclusão de eventuais investigaçõess.
              </p>
              <p className="legal-text">
                8.3.5 A BCX reserva para si o direito de negar a solicitação de
                prestação do Serviço, bem como proceder a devolução de todo e
                qualquer valor transferido pelo USUÁRIO em caso de indícios de,
                mas sem se limitar a:
              </p>
              <p className="legal-text">
                a) não atendimento às legislações nacionais sobre lavagem de
                dinheiro e anticorrupção; b) fornecimento de informações falsas,
                imprecisas, incompletas ou enganosas; c) não fornecimento de
                informações e/ou documentos adicionais, após 3 dias da
                solicitação, sem resposta do USUÁRIOS; d) má-fé ou dolo; e)
                violação destes Termos e Condições Específicos, dos Termos e
                Condições da Exchance ou de quaisquer outras políticas da BCX;
                f) uso dos serviços prestados pela BCX em desacordo com a
                legislação; g) envolvimento em condutas fraudulentas ou ilegais;
              </p>
              <p className="legal-text">8.4 Obrigações Fiscais e Legais</p>
              <p className="legal-text">
                8.4.1 Cada uma das Partes é responsável por todas as obrigações
                fiscais, tributárias e legais decorrentes da execução destes
                Termos, aplicadas em seu país de origem. Em nenhuma hipótese a
                BCX poderá ser responsabilizada por quaisquer tributos ou
                obrigações de responsabilidade do USUÁRIO.
              </p>
              <p className="legal-text">8.5 Propriedade Intelectual:</p>
              <p className="legal-text">
                8.5.1. Todos os direitos de propriedade intelectual referente ao
                serviço de gerenciamento de ativos pertencem à BCX. A aceitação
                a estes termos e condições de uso não dá ao USUÁRIO nenhum
                direito de propriedade sobre os serviços e conteúdos da BCX.
              </p>
              <p className="legal-text">8.6 Da Oferta Privada</p>
              <p className="legal-text">
                8.6.1 A oferta do Serviço é feita em caráter EXCLUSIVO E PRIVADO
                aos USUÁRIOS que já são clientes da BCX, portanto, é
                EXPRESSAMENTE PROIBIDO que estes façam ou que permitam que
                terceiros façam o download de qualquer conteúdo com o intuito de
                armazená-lo em banco de dados e/ou para oferecer para terceiros
                ou para divulgá-los em quaisquer canais, redes sociais, ou
                qualquer meio de acesso público.
              </p>
              <p className="legal-text">
                8.6.2 Caso o USUÁRIO tenha ciência de infrações ao diposto no
                item acima, deverá encaminhar à BCX por e-mail.
              </p>

              <h5 className="legal-subtitle">9. SEGURANÇA DO USUÁRIO</h5>
              <p className="legal-text">
                9.1 A BCX NÃO SOLICITA OU EXIGE QUE SEUS USUÁRIOS DIVULGUEM SUAS
                SENHAS, DADOS DE CARTÃO DE CRÉDITO OU OUTROS DADOS BANCÁRIOS POR
                E-MAIL, TELEFONE OU QUALQUER OUTRO CANAL DE ATENDIMENTO
                PERSONALIZADO. Caso você receba qualquer comunicação com esse
                tipo de abordagem e conteúdo, não responda, desconsidere-o e, se
                possível, encaminhe o seu relato para o e-mail da BCX.
              </p>

              <h5 className="legal-subtitle">10. DISPOSIÇÕES FINAIS</h5>
              <p className="legal-text">
                10.1 Estes Termos e Condições são regidos pelas leis da
                República Federativa do Brasil. Quaisquer dúvidas e situações
                não previstas nestes Termos e Condições serão primeiramente
                resolvidas entre o USUÁRIO e a BCX e, caso persistam, deverão
                ser solucionadas pelo Foro da Comarca de Itajaí, estado de Santa
                Catarina, com exclusão de qualquer outro, por mais privilegiado
                que seja ou venha a ser.
              </p>

              <p className="legal-subtitle">
                DATA DA ÚLTIMA ATUALIZAÇÃO: 16/06/2023
              </p>
              <div className="input-legal-div">
                  <input
                    type="checkbox"
                    className="input-legal"
                    onChange={handleCheck}
                    checked={isChecked}
                  ></input>
                  <span className="legal-text">
                    Ao selecionar ao lado e clicar em "ACEITAR" o USUÁRIO
                    concorda com o previsto nesses TERMOS, aceitando e
                    vinculando-se a eles.
                  </span>
                </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <div className="text-center">
            <Spinner
              variant="secondary"
              animation="border"
              role="status"
            ></Spinner>
          </div>
        ) : (
          <Button variant="primary" onClick={saveInvestmentTerms} disabled={!isChecked}>
            Aceitar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
