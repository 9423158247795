import React from "react";
// import InvestmentList from "../components/InvestmentList";
import Panel from "../components/Panel";
import { AppContext } from "../context/AppContext";
import InvestmentsSumary from "../components/InvestmentsSummary";
import { withTranslation } from 'react-i18next';

class Trade extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      disable: false,
      rows: [],
      hasInvestmentActive: false,
    };
  }

  static contextType = AppContext;

  componentDidMount = async () => {
    this.setState({
      load: false,
      disable: this.context.allInvestments?.length === 0,
    });
  };

  render() {
    return (
      <Panel
        history={this.props.history}
        page="trade"
        title={
          this.context.allInvestments
            ? this.props.t("investments")
            : this.props.t("initialdep") /* "Aporte Inicial"*/ + " - " + this.props.t("investments")
        }
      > 
      <InvestmentsSumary />
        {/* <InvestmentList
          title="Contrato"
        /> */}
      </Panel>
    );
  }
}

export default withTranslation()(Trade);