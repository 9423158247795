import React, { useContext, useState, useEffect } from "react";
import { Form, Col, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

import FormPerfilKYC from "../../Admin/FormPerfilKYC";
import { disableAction } from "../../../utils/helper";
import api from "../../../utils/api";

import { AppContext } from "../../../context/AppContext";

function getFileContentType(url) {
  return fetch(url, { method: "GET" }).then((response) =>
    response.headers.get("Content-type")
  );
}

const DisplayFile = ({ activeFileId }) => {
  const [contentType, setContentType] = useState(null);
  const url = `${process.env.REACT_APP_API_URL}/api/files/${activeFileId}/view`;
  useEffect(() => {
    getFileContentType(url).then((type) => {
      setContentType(type);
    });
  }, []);

  return contentType === "application/pdf" ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <object data={url} type="application/pdf" width={500} height={678}>
        <iframe src={url} width={500} height={678}>
          This browser does not support PDF!
        </iframe>
      </object>
      <a href={url} target="_blank" className="btn btn-primary mt-4">
        Abrir
      </a>
    </div>
  ) : (
    <a href={url} target="_blank">
      <img
        style={{
          height: "100%",
          objectFit: "contain",
          maxHeight: "400px",
        }}
        className="img-fluid"
        src={url}
      ></img>
    </a>
  );
};

export default function ModalKYC({
  // modalKYC,
  // setModalKYC,
  selectedUser,
  setSelectedUser,
  activeFileId,
  getUsersKyc,
  setData,
  data,
}) {
  const { loggedInUser, modalKYC, setModalKYC } = useContext(AppContext);

  const [reason, setReason] = useState("");
  const [showTextReason, setShowTextReason] = useState(false);
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const [reasonBlank, setReasonBlank] = useState(false);
  const [currentFile, setCurrentFile] = useState({});

  useEffect(() => {
    setCurrentFile(
      data
        .filter((el) => el.id == selectedUser.id)[0]
        ?.kycFiles?.filter((el) => el.id == activeFileId)[0]
    );
    // kyc document visualized
    async function doVisualize() {
      await api.post(`event-logs/kycdocs`, {
        fileId: activeFileId,
        userId: selectedUser.id,
      });
    }

    if (activeFileId && selectedUser) {
      doVisualize();
    }
  }, [data, activeFileId]);

 
  const changeStatusKYC = async (userId, approval, reason) => {
    setLoadingChangeStatus(true);
    await api
      .put(`users-kyc/files`, {
        userId,
        fileId: activeFileId,
        approval,
        reason,
      })
      .then(() =>
        getUsersKyc().then((req) => {
          let data = req.data.data.filter((item) => item?.kycFiles?.length > 0);

          const res = data.filter((el) => el.id == selectedUser.id)[0];

          setSelectedUser(res);
          setData(data);
          setLoadingChangeStatus(false);
        })
      )
      .catch((e) => {
        console.log(e);
        alert("erro");
      });
  };

  if (!currentFile) return "";

  return (
    <Modal
      show={modalKYC}
      onHide={() => {
        setModalKYC(!modalKYC);
        setShowTextReason(false);
        setReasonBlank(false);
        setReason("");
      }}
      size="lg"
      centered
      key={selectedUser?.id}
    >
      <Modal.Header closeButton>
        <Modal.Title className="w-100 ml-3 text-center">
          Conferência de documentos
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="w-100 d-flex justify-content-center flex-column">
        <div
          className="mb-2"
          style={{
            height: "100%",
            maxHeight: "400px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DisplayFile activeFileId={activeFileId} />
        </div>
        <FormPerfilKYC id={selectedUser?.id} />
      </Modal.Body>

      <Modal.Footer
        style={{ flexDirection: "column", width: "360px", margin: "auto" }}
      >
        <div className="table_kyc_action mb-2 flex-column align-items-center">
          <div>
            {!currentFile?.approval ? (
              <Button
                disabled={disableAction(
                  loggedInUser?.permission?.level,
                  true,
                  false,
                  false
                )}
                onClick={async () => {
                  await changeStatusKYC(selectedUser?.id, true, null);

                  toast.success(`Documento aprovado`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setModalKYC(!modalKYC)
                }}
                style={{ marginRight: "5px" }}
              >
                Aprovar
              </Button>
            ) : (
              <span style={{ marginRight: "10px" }}>
                Documento está aprovado!
              </span>
            )}

 
            {!currentFile?.rejectreason ? (
              <Button
                disabled={disableAction(
                  loggedInUser?.permission?.level,
                  true,
                  false,
                  false
                )}
                onClick={() => setShowTextReason(true)}
              >
                Rejeitar
              </Button>
            ) : (
              <span style={{ marginLeft: "10px" }}>
                Documento está Rejeitado!
              </span>
            )}
          </div>

          {loadingChangeStatus && (
            <Spinner
              className="mt-3"
              variant="secondary"
              animation="border"
              role="status"
            ></Spinner>
          )}
        </div>

        {showTextReason && (
          <Form.Group
            as={Col}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Control
              placeholder="Motivo da rejeição"
              as="textarea"
              className="input mb-2"
              name="feedback"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              style={{ height: "70px" }}
              required
              isInvalid={reasonBlank}
            />

            <Button
              onClick={async () => {
                if (reason.trim() === "") {
                  return setReasonBlank(true);
                }

                await changeStatusKYC(selectedUser?.id, false, reason);

                setShowTextReason(false);
                setReason("");

                toast.info(`Documento rejeitado`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });

                setModalKYC(!modalKYC)
              }}
            >
              Enviar feedback
            </Button>
          </Form.Group>
        )}
      </Modal.Footer>
    </Modal>
  );
}
