import React, { useState, useContext } from "react";
import { Button, Col, Row, Modal, Image } from "react-bootstrap";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { AppContext } from "../context/AppContext";
import _isEqual from "lodash/isEqual";
import _debounce from "lodash/debounce";
import { toast } from "react-toastify";
import { getProfilePhoto } from "../utils/helper";
import api from "../utils/api";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useTranslation, Trans } from 'react-i18next';
import '../i18n'

export default function ChangePhoto() {
    const { photoId, setPhotoId } =
        useContext(AppContext);
    const [showModalCrop, setShowModalCrop] = useState(false);
    const [src, setSrc] = useState(null);

    const { t , i18n } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage.toString();

    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [crop, setCrop] = useState({
        unit: "%",
        width: 30,
        aspect: 1 / 1,
    });
    const imageProfileInputRef = React.createRef();
    const imageProfileCropRef = React.createRef();



    const handleCloseCrop = () => {
        setShowModalCrop(false);
    };

    const onSelectFile = (e) => {
        setSrc(null);
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setSrc(reader.result);
                setShowModalCrop(true);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };


    const onCropComplete = (doCrop) => {
        // console.log(crop, doCrop)
        // getCroppedImg(imageProfileCropRef.current, doCrop, "newFile.jpeg").then(res => {
        //     setCroppedImageUrl(res);
        // }).catch(e=>{
        //     console.log(e);
        // });
    };

    const onCropChange = (crop, percentCrop) => setCrop(percentCrop)

    async function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

        const crop = centerCrop(
            makeAspectCrop(
                {
                    // You don't need to pass a complete crop into
                    // makeAspectCrop or centerCrop.
                    unit: '%',
                    width: 90,
                },
                16 / 9,
                width,
                height
            ),
            width,
            height
        )

        setCrop(crop)
    }

    const uploadPhoto = async (image) => {
        setShowModalCrop(false);
        let req = await api.post("profile/photo", {
            photo: image,
        });
        if (!req.error) {
            let photoId = req.data.photoId;
            setPhotoId(photoId);
            toast(t("photouploadsuccess"))
            // reloadPage();
        } else {
            toast(t("photouploadfailed"))
        }
    };

    
    const [image, setImage] = useState(getProfilePhoto(photoId));
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            setShowModalCrop(true);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            const imageBase64Url = cropper.getCroppedCanvas({
                width: 120,
                height: 120,
            }).toDataURL();
            setCropData(imageBase64Url);
            uploadPhoto(imageBase64Url);
        }
    };

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <div onClick={() => { console.log('ha???'); imageProfileInputRef.current.value = ""; imageProfileInputRef.current.click() }}>
                        <Image
                            style={{
                                margin: "auto",
                                width: 120,
                                height: 120,
                                cursor: "pointer",
                            }}
                            src={getProfilePhoto(photoId)}
                            roundedCircle
                        />
                        <Image
                            style={{
                                marginTop: 80,
                                marginLeft: -30,
                                width: 40,
                                height: 40,
                                cursor: "pointer",
                            }}
                            src="/images/photo.png"
                        />
                    </div>
                </Col>
                <Col>
                    <input
                        ref={imageProfileInputRef}
                        className="d-none"
                        type="file"
                        accept="image/*"
                        onChange={onChange}
                    />
                </Col>
            </Row>
            <Modal show={showModalCrop} onHide={handleCloseCrop}>
                <Modal.Header closeButton>
                    <Modal.Title className="w-100 ml-3 text-center">
                    {t("selectphoto")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Cropper
                        style={{ height: 400, width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={120}
                        minCropBoxWidth={120}
                        background={false}
                        responsive={true}
                        cropBoxMovable={true}
                        cropBoxResizable={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseCrop}>
                        {t("cancel")}
                    </Button>
                    <Button variant="primary" onClick={getCropData}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
