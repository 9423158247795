import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

export default class DialogModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            title: '',
            body: <div></div>,
        }
    }

    show = async (title, body) => {
        this.setState({
            showModal: true,
            title,
            body,
        });
    }

    handleClose = async () => {
        await this.setState({ showModal: false });
        if(this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render() {
        return (<Modal show={this.state.showModal} onHide={this.handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title className="w-100 ml-3 text-center">{this.state.title}</Modal.Title>
            </Modal.Header>
                <Modal.Body className="w-100">
                    {this.state.body}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose}>OK</Button>
                </Modal.Footer>
        </Modal>)
    }
}
