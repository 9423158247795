import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import LPHeader from "../components/LandingPage/LPHeader";
import LPFooter from "../components/LandingPage/LPFooter";
import Timeline from "../components/LandingPage/Timeline"
import bitcoinGuy from "../assets/images/bitcoin-guy.png";
import eng from "../assets/images/eng.png";
import pt from "../assets/images/pt.png";
import FAQ from "../components/LandingPage/FAQ";
import CurrencyWidget from "../components/CurrenciesWidget";
import { useTranslation, Trans } from 'react-i18next';
import '../i18n'
import { useLocation } from "react-router-dom";

const lngs = {
    en: { nativeName: 'English' },
    pt: { nativeName: 'Português' }
};

const LandingPage = () => { 
    
    const { t , i18n } = useTranslation();

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const referralCode = params.get('ref');
        if (referralCode !== null && referralCode !== '') {
            localStorage.setItem('referralCode', referralCode);
        }
    }, [location.search]);

    return (
        <div className="landing-page">
            <header className="lp-header">
                <CurrencyWidget />
                <LPHeader />
            </header>
            <Container className="lp-start">
                <div className="lp-start-divs start-left-div">
                    <div>
                        {Object.keys(lngs).map((lng) => (
                            <Button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal', margin: '10px' }} type="submit" onClick={() => i18n.changeLanguage(lng).then(window.location.reload())}>
                                <img
                                    src={lngs[lng].nativeName == 'English' ? eng : pt}
                                    alt="lang icon"
                                    width={'30px'}
                                /> {lngs[lng].nativeName}
                            </Button>
                        ))}
                    </div>
                    <h1 className="title">{t('title')}</h1>
                    <h2 className="subtitle">{t('subtitle1')}</h2>
                    <h2 className="subtitle">{t('subtitle2')}</h2>
                    <div className="login-btn-div">
                        <LinkContainer to="/panel/signin">
                            <button id="bnt-start">
                            {t('enter')}
                            </button>
                        </LinkContainer>
                        <LinkContainer to="/panel/signup">
                            <Button className="btn-register">
                            {t('register')}
                            </Button>
                        </LinkContainer>
                    </div>
                </div>
                <div className="lp-start-divs bull-head">
                    <img className="logo-svg"
                        src={'./images/bull-head.svg'}
                        alt="BC Coins Logo"
                    />
                </div>
            </Container>
            <div className="section-2">
                <div className="section-2-divs">
                    <h1 className="s2-title">
                        {t("trajectory")}
                    </h1>
                    <Timeline />
                </div>
            </div>
            <div className="section-3">
                <div className="section-3-div">
                    <div>
                        <h2 className="s3-title">
                           {t("slogan")}
                        </h2>
                    </div>
                    <div className="s3-btn-div">
                        <LinkContainer to="/panel/signup">
                            <Button className="btn-register">
                                {t("register")}
                            </Button>
                        </LinkContainer>
                    </div>
                </div>
            </div>
            <div className="section-4">
                <div className="section-4-div">
                    <h2 className="s4-title">
                       {t("whatwedo")}
                    </h2>
                    <div className="div-itens">
                        <h2>{t("buysell")}</h2>
                        <p className="s4-subtitle">{t("s4-subtitle")}</p>
                        <br></br>
                        <br></br>
                        <h2 className="s4-title">
                        {t("s4-title")} 
                        </h2>
                        <div className="item">
                            <div className="icon">
                                <i className="fab fa-halfx fa-bitcoin"></i>
                            </div>
                            <p className="item-title">Bitcoin</p>
                            <p className="item-text">{t("item1-text")}</p>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <i className="fa fa-halfx fa-piggy-bank"></i>
                            </div>
                            <p className="item-title">{t("item2-title")}</p>
                            <p className="item-text">{t("item2-text")}</p>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <i className="fa fa-halfx fa-cubes"></i>
                            </div>
                            <p className="item-title">Blockchain</p>
                            <p className="item-text">{t("item3-text")}</p>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <i className="fa fa-halfx fa-coins"></i>
                            </div>
                            <p className="item-title">Wallet</p>
                            <p className="item-text">{t("item4-text")}</p>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <i className="fa fa-halfx fa-exchange-alt"></i>
                            </div>
                            <p className="item-title">{t("item5-title")}</p>
                            <p className="item-text">{t("item5-text")}</p>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <i className="fa fa-halfx fa-money-bill"></i>
                            </div>
                            <p className="item-title">{t("item6-title")}</p>
                            <p className="item-text">{t("item6-text")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-5-divider"></div>
            <div className="section-5">
                <div className="s5-div">
                    <div className="s5-left-div">
                        <h2 className="s5-title">{t("s5-title")}</h2>
                        <p className="s5-text">{t("s5-text1")}</p>
                        <p className="s5-text">{t("s5-text2")}</p>
                    </div>
                    <div className="s5-right-div-parent">
                        <div className="s5-right-div">
                            <div className="white-frame">
                                <img
                                    src={bitcoinGuy}
                                    alt="guy holding bitcoin"
                                    className="s5-image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-5b">
                <div className="s5b-div">
                    <div className="s5b-item">
                        <b className="s5b-title">{t("s5b-title")}</b>
                        <p className="s5b-text">{t("s5b-text")}</p>
                    </div>
                    <div className="s5b-item">
                        <b className="s5b-title">{t("s5b-title2")}</b>
                        <p className="s5b-text">{t("s5b-text2")}</p>
                    </div>
                    <div className="s5b-item">
                        <b className="s5b-title">{t("s5b-title3")}</b>
                        <p className="s5b-text">{t("s5b-text3")}</p>
                    </div>
                </div>
            </div>
            {/*
             <div className="section-6">
                <div className="s6-div">
                    <h2 className="s6-title">Escute de Nossos Clientes</h2>
                    <div className="s6-testimonials-div">
                        <div className="testimonial" id="testimonial1">
                            <div className="quote" id="q1">
                                <i className="fa fa-quote-left"></i>
                            </div>
                            <p className="s6-text">A BCX oferece opções para cada tipo de perfil, empresa sólida e comprometida com o cliente, sempre disposta a ajudar e negociar da melhor maneira, seguro, transparente e de alto desempenho. </p>
                            {/*
                            <img
                                src={ testimonial1 }
                                alt="testimonial1"
                                className="picture"
                            />
                          
                            <p className="s6-name">Joe Doe</p>
                        </div>
                        
                        <div className="testimonial" id="testimonial2">
                            <div className="quote" id="q2">
                                <i className="fa fa-quote-left"></i>
                            </div>
                            <p className="s6-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <img
                                src={ testimonial1 }
                                alt="testimonial1"
                                className="picture"
                            />
                            <p className="s6-name">Rodrigo</p>
                        </div>
                        <div className="testimonial" id="testimonial3">
                            <div className="quote" id="q3">
                                <i className="fa fa-quote-left"></i>
                            </div>
                            <p className="s6-text">...Durante 05 anos de investimentos com a BCX, todos os planejamentos pessoais foram concretizados após a análise de melhores perfis de investimentos ao cliente,
                            com os melhores cenários de mercados e as estratégias, além de todas as liquidações da BCX feito em dia..</p>
                            <img
                                src={ testimonial1 }
                                alt="testimonial1"
                                className="picture"
                            />                       
                            <p className="s6-name">Renato Eskibel de Werk</p>
                        </div>
                    </div>
                </div>
             </div>
             */}
            <div className="section-7">
                <div className="s7-big-div">
                    <h2 className="s7-title">{t('s7-title')}</h2>
                    <FAQ />
                </div>
            </div>
            <div className="section-8">
                <div className="s8-div">
                    <h2 className="s7-title">{t('s8-title')}</h2>
                    <p className="s8-text">{t('s8-text')} <a>comercial@bcxcorretora.com.br</a></p>
                    <a target="_blank" rel="noreferrer" href="https://suporte.bcxcorretora.com.br/">
                        <button id="support-bt">
                        {t('support')}
                        </button>
                    </a>
                </div>
            </div>
            <LPFooter />
        </div>)
}

export default LandingPage;
