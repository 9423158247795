import React from "react";
import Panel from "../../components/Panel";
import {
  Table,
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Spinner,
} from "react-bootstrap";
import api, { microservice } from "../../utils/api";
import { INVESTMENT_STATUS_TYPES, formatStatus } from "../../utils/types";
import { disableAction, formatStatusHelper } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Pagination from "../../components/Pagination";
import dayjs from "dayjs";
import ConfirmRenew from "../../components/Admin/Investment/ConfirmRenew";
import { numberFormatBRL } from "../../utils/Helpers/btcFormat";

class AdminInvestments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      investments: [],
      usernamesById: {},
      users: {},
      offset: 0,
      form: {
        initDate: "",
        endDate: "",
        status: "",
      },
      investmentsCount: 0,
      selectRenew: null,
      btcPrice: 0
    };
  }

  static contextType = AppContext;

  handleChange = (event) => {
    let form = this.state.form;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    form[event.target.name] = value;
    this.setState({ form });
  };

  setType = (name, type) => {
    let form = this.state.form;
    form[name] = type.status;
    this.setState({ form });
  };

  setSelectRenew = (renewData) => {
    // Update the state with the renew data
    this.setState({ selectRenew: renewData });
  };

  componentDidMount = () => {
    // this.getInvestmentsCount();
    this.getAllInvestments();
    // this.context.setPagination(() => {
    //   return {
    //     currentPage: 1,
    //     lastPage: 1,
    //     offset: 0,
    //     limit: 10,
    //   };
    // });

    api.get("prices").then((req) => {

      if (!req.error) {
        let prices = req.data;

        let brlPrice;
        prices.forEach((price) => {
          if (price.symbol === "USD" && price.base === "BRL") {
            brlPrice = price.price;
          }
        });

        const btcPrice = req.data.filter((coin) => {
          return coin.symbol === "BTC";
        });
        this.setState({ btcPrice: btcPrice[0].price * brlPrice });
        // console.log("BTC PRICE >> ", this.state.btcPrice);
      }
    });

  };

  getAllInvestments = async () => {
    await microservice.get("investments").then((req) => {

      const promises = req.map((row) => {
        const username = this.getUserById(row.userId);
        return username;
      });

      this.setState({
        investments: req,
      });

      Promise.all(promises)
        .then((results) => {
          const users = results.map((res) => res);
          this.setState({
            load: false,
          });
          this.setState({ users });
        })
        .catch((error) => {
          console.error(error);
        });

      // this.fetchUsernames();

      console.log('req >>>', req);
      //   console.log( "Users > ", this.state.users);
    });
   await this.fetchUsernames();
  };

  fetchUsernames = async () => {
    const { investments } = this.state;
    const usernamesById = {};

    // Fetch usernames for each investment's userId
    await Promise.all(investments.map(async (investment) => {
      const username = await this.getUserById(investment.userId);
      if (username !== null) {
        usernamesById[investment.userId] = username;
      }
    }));

    this.setState({ usernamesById: usernamesById, load: false });
  };

  getUserById = async (id) => {
    try {
      const response = await api.get(`users/${id}`);
      if (response.data) {
        return response.data.name;
      }
    } catch (error) {
      console.error(`Error fetching user with ID ${id}:`, error);
    }
    return null;
  };

  // getInvestmentsCount = async () => {
  //   let req = await api.get("investments/count");
  //   if (req.error) {
  //     return;
  //   }
  //   this.context.setPagination((pagination) => {
  //     return {
  //       ...pagination,
  //       lastPage: Math.ceil(req.data.count / pagination.limit),
  //     };
  //   });

  //   this.setState({
  //     investmentsCount: req.data.count,
  //   });
  // };

  // getInvestments = () => {
  //   this.setState({
  //     offset: this.context.pagination.offset,
  //     load: true,
  //   });

  // api.get("users").then((req) => {
  //   if (!req.error) {
  //     let users = req.data.data;
  //     api
  //       .get(
  //         `investments?filter[order]=createdAt%20DESC&filter[skip]=${this.context.pagination.offset}&filter[limit]=${this.context.pagination.limit}`
  //       )
  //       .then((req) => {
  //         if (!req.error) {
  //           let investments = req.data;

  //           investments.data.forEach((row) => {
  //             users.forEach((user) => {
  //               if (user.id === row.usersId) {
  //                 row.nameUser = user.name;
  //               }
  //             });
  //           });

  //           this.context.setPagination((pagination) => {
  //             return {
  //               ...pagination,
  //               limit: investments.limit,
  //               lastPage: Math.ceil(investments.total / investments.limit),
  //             };
  //           });

  //           this.setState({
  //             load: false,
  //             investments: investments.data,
  //           });
  //         }
  //       });
  //   }
  // });
  // };

  // filter = async (event) => {
  //   event.preventDefault();
  //   let req = await api.get("investments");
  //   if (req.error) {
  //     return;
  //   }
  //   let form = this.state.form;
  //   let rows = req.data.data;
  //   rows = rows.sort(
  //     (a, b) =>
  //       new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  //   );
  //   if (form.status) {
  //     rows = rows.filter((row) => row.status === form.status);
  //   }
  //   if (form.initDate) {
  //     let time = new Date(form.initDate).getTime();
  //     rows = rows.filter((row) => new Date(row.createdAt).getTime() > time);
  //   }
  //   if (form.endDate) {
  //     let time = new Date(form.endDate).getTime();
  //     rows = rows.filter((row) => new Date(row.createdAt).getTime() < time);
  //   }
  //   this.setState({
  //     investments: rows,
  //   });
  // };

  goTo = (row, userName) => {
    this.props.navigate("/panel/admin/investment/" + row._id);
    if (userName) {
      row.userName = userName;
    }
    this.context.setTrade(row);
  };

  disableTrade = (row) => { };

  render() {
    const { investments, usernamesById, load } = this.state;
    const btcPrice = this.context.btcPrice;

    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="admin/investments"
        title="Investimentos"
        admin
      >
        {/* <Form onSubmit={this.filter}>
          <Row className="mb-1">
            <Form.Group as={Col}>
              <Form.Label>Início</Form.Label>
              <Form.Control
                className="input"
                name="initDate"
                type="date"
                value={this.state.form.initDate}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Fim</Form.Label>
              <Form.Control
                className="input"
                name="endDate"
                type="date"
                value={this.state.form.endDate}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Status</Form.Label>
              <DropdownButton
                className="form-dropdown"
                title={` ${formatStatus(
                  INVESTMENT_STATUS_TYPES,
                  this.state.form.status
                )}`}
              >
                {INVESTMENT_STATUS_TYPES.map((type) => (
                  <Dropdown.Item
                    onClick={() => this.setType("status", type)}
                    key={`status-${type.name}`}
                  >
                    {type.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Form.Group>
            <Button type="submit" style={{ margin: "2rem 1rem 2rem 0" }}>
              Buscar
            </Button>
          </Row>
        </Form> */}

        <div
          style={{
            width: "100%",
            backgroundColor: "#50504E",
          }}
        >
          <span className="bold text1 ml-3 text-mid2">Investimentos de Clientes</span>
        </div>
        <Table
          className="hist-table m-0 p-0 text-mid"
          responsive="md"
          striped
          hover
        >
          <thead>
            <tr>
              <th>Cliente</th>
              <th>Tipo</th>
              <th>Aporte Inicial</th>
              <th>Criação</th>
              <th>Período</th>
              <th>Status</th>
              <th>Opçoes</th>
            </tr>
          </thead>
          <tbody>
            {this.state.users?.length > 0 && this.state.investments?.length > 0 && this.state.investments
              .sort((a, b) => {
                if (a.status === "renewing" && b.status !== "renewing") {
                  return -1; // "renewing" comes before other statuses
                } else if (a.status !== "renewing" && b.status === "renewing") {
                  return 1; // "renewing" comes after other statuses
                } else {
                  return a.status.localeCompare(b.status); // Sort other statuses alphabetically
                }
              })
              .map((row, i) => (
                <tr key={row._id} className={row.status === "renewing" ? "renewing-row" : ""}>
                  <td>{this.state.usernamesById[row.userId] || "Loading..."}</td>
                  <td>{row.type} {row.modality}</td>
                  <td>{numberFormatBRL(row.initialAmount)}</td>
                  <td>{dayjs(row.createdAt).format("DD/MM/YYYY")}</td>
                  <td>{row.period} meses</td>
                  <td>{row.status}</td>
                  <td>
                    <Button onClick={() => this.goTo(row, this.state.usernamesById[row.userId])}>Ver</Button>
                    {row.status === "renewing" && (
                      <Button
                        onClick={() => {
                          this.setSelectRenew(row);
                        }}
                        variant="success"
                        style={{ fontSize: "12px", margin: "2px", marginBottom: "5px", height: "50px", width: "100px" }}
                      >
                        Confirmar Renovação
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        {this.state.investments.length > 0 && (
          <Pagination
            pagination={this.context.pagination}
            setPagination={this.context.setPagination}
          />
        )}

        {this.state.selectRenew && (
          <ConfirmRenew
            renew={this.state.selectRenew}
            setRenew={this.setSelectRenew}
            exchangeRate={this.state.btcPrice}
            from={"renew"}
          />
        )}

      </Panel>
    );
  }
}

export default (props) => (
  <AdminInvestments {...props} navigate={useNavigate()} />
);
