import React from "react";
import { Navbar, Nav, Container, Card, Row, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "../context/AppContext";
import api from "../utils/api";
import { formatMoney } from "../utils/helper";
// import CurrenciesBar from "./../components/CurrenciesBar";
import CurrencyWidget from "./../components/CurrenciesWidget";

const CurrencyCard = (props) => (
  <Card
    className={
      props.hide
        ? "currency-card-small text-left ml-3 d-none d-lg-flex"
        : "currency-card-small text-left ml-3"
    }
  >
    <img
      src={`/icons/${props.price.symbol.toLocaleLowerCase()}.svg`}
      width="15px"
      height="15px"
      alt="crypto icon"
    />
    <span className="currency-text-name-small">{props.price.name}</span>
    <span className="mb-1 currency-text-symbol-small">
      {props.price.symbol}
    </span>
    <span className="currency-text-info-small">
      {props.price.variation} nas últimas 24h
    </span>
    <span className="currency-text-info-small">
      Cotação{" "}
      <span className="bold">
        {props.price.base === "USD"
          ? `R$  ${formatMoney(props.price.price * props.brlPrice)}`
          : `R$  ${formatMoney(props.price.price)}`}
      </span>
    </span>
  </Card>
);

const Price = (props) => (
  <div className="price-line">
    <span className="price-number">{props.number}</span>
    <span className="price-text">{props.symbol}</span>
    <span className="price-text">${props.value.toFixed(2)}</span>
  </div>
);

export default class HeaderBarIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      brlPrice: 0,
      prices: [
        {
          symbol: "BTC",
          base: "USD",
          name: "Bitcoin",
          price: 0,
          variation: 0,
        },
        {
          symbol: "ETH",
          base: "USD",
          name: "Ethereum",
          price: 0,
          variation: 0,
        },
        {
          symbol: "USD",
          base: "BRL",
          name: "Dolar",
          price: 0,
          variation: 0,
        },
        {
          symbol: "USDT",
          base: "BRL",
          name: "Tether",
          price: 0,
          variation: 0,
        },
      ],
    };
  }

  static contextType = AppContext;

  componentDidMount = () => {
    api.get("prices").then((req) => {
      if (!req.error) {
        let prices = req.data;
        let brlPrice = 0;
        prices.forEach((price) => {
          if (price.symbol === "USD" && price.base === "BRL") {
            brlPrice = price.price;
          }
        });

        this.setState({
          load: false,
          brlPrice,
          prices,
        });
      }
    });
  };

  render() {
    return (
      <>
      <CurrencyWidget />
      <Container className="header-bg p-0 pt-1" fluid>
        <Container>
          <Navbar
            className="p-0"
            collapseOnSelect
            expand="lg"
            bg="transparent"
            variant="dark"
          >
            <Navbar.Brand className="d-flex p-0">
              {this.props.admin ? (<LinkContainer to="/panel/admin">
              {/* <LinkContainer to="/panel/admin/employees"> */}
                <img
                  style={{ marginRight: 35 }}
                  src="/images/bcx-logo-gold.svg"
                  width="130"
                  height="130"
                  className="d-inline-block align-top"
                  alt="BC Coins Logo"
                />
              </LinkContainer>) : (<LinkContainer to="/panel/bem-vindo">
                <img
                  style={{ marginRight: 35 }}
                  src="/images/bcx-logo-gold.svg"
                  width="130"
                  height="130"
                  className="d-inline-block align-top"
                  alt="BC Coins Logo"
                />
              </LinkContainer>)}
              
            </Navbar.Brand>
            {/* <CurrenciesBar style={{ marginRight: 100 }} /> */}
          </Navbar>
        </Container>
        <div
          style={{
            width: "100%",
            height: "3px",
            backgroundColor: "#CEBA85",
            marginTop: 5,
          }}
        ></div>
        <div style={{ width: "100%", height: "5px" }}></div>
      </Container>
      </>
    );
  }
}
