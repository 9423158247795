import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import api, { microservice } from "../../../../utils/api";
import dayjs from "dayjs";
import { disableAction } from "../../../../utils/helper";
import { AppContext } from "../../../../context/AppContext";
import ConfirmModal from "../../../ConfirmModal";
import { toast } from "react-toastify";
import { numberFormatBRL } from "../../../../utils/Helpers/btcFormat";

export default function Profitabilities({profitabilities}) {
  const confirm = useRef(null);

  const {
    loggedInUser,
    setShowModalEditProfitabily,
    showModalCreateProfitabily,
    setShowModalCreateProfitabily,
    setInvestment,
  } = useContext(AppContext);

  const deleteProfitability = async (id) => {
    confirm.current.show(
        "Atenção",
        <>
            <h3 className="text-center mb-2">Deseja excluir a rentabilidade?</h3>
            <h6 className="text-center text-danger">
                Esta ação não pode ser desfeita
            </h6>
        </>,
        async () => {
            const res = await api.del(`investments-profitabilities/${id}`)
            if(!res.error){
              toast.success("Rentabilidade excluída com sucesso")
            //  getProfitabilities()
            }
        }
    );
};

  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "#50504E",
          marginTop: 30,
        }}
      >
        <span className="bold text1 ml-3 text-mid2">
          HISTÓRICO DE RENTABILIDADES
        </span>
      </div>
      <Table className="hist-table m-0 p-0 text-mid" responsive striped hover>
        <thead>
          <tr>
            <th>Data</th>
            <th>Percentual</th>
            <th>Rentabilidade</th>
            {/* <th>Editar</th>
            <th>Excluir</th> */}
          </tr>
        </thead>
        <tbody>
          {profitabilities.map((row, i) => (
            <tr key={i}>
              <td>{dayjs(row.date).add(1, 'day').format("DD/MM/YYYY")}</td>
              <td>{((row.profitPercentage) * 100).toFixed(2)}%</td>
              <td>{numberFormatBRL(row.dailyProfit)}</td>
              {/* <td>
                <Button
                  disabled={disableAction(
                    loggedInUser?.permission?.level,
                    false,
                    false,
                    false
                  )}
                  onClick={() => {
                    setShowModalEditProfitabily((prevState) => ({
                      id: row.id,
                      show: !prevState.show,
                    }));
                  }}
                >
                  Editar
                </Button>
              </td>
              <td>
                <Button
                  disabled={disableAction(
                    loggedInUser?.permission?.level,
                    false,
                    false,
                    false
                  )}
                  onClick={()=>deleteProfitability(row.id)}
                >
                  Excluir
                </Button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-center d-flex text-large mt-3 mb-5">
        <Button
          disabled={disableAction(
            loggedInUser?.permission?.level,
            false,
            false,
            false
          )}
          className="ml-3 mr-auto"
          onClick={() =>
            setShowModalCreateProfitabily(!showModalCreateProfitabily)
          }
        >
          Criar nova rentabilidade para este Investimento
        </Button>
      </div>
      <ConfirmModal ref={confirm} />
    </>
  );
}
