import React from 'react';
import Panel from '../components/Panel';

export default class OTC extends React.Component {

    render() {
        return (<Panel history={this.props.history} page="otc" title="Plataforma OTC">
            <h1 className="text2 text-center mt-5">Em breve</h1>
        </Panel >)
    }
}
