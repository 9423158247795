import React from "react"
import { Container, Col, Row, Image } from "react-bootstrap"
import PrivacyB from "./PrivacyB"
import PrivacyBEN from "./PrivacyBEN"
import TermsB from "./TermsB"
import TermsBEN from "./TermsBEN"
import { AppContext } from "../context/AppContext";
import { withTranslation } from 'react-i18next';

class BottomBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          load: true,
        };
      }

    static contextType = AppContext;

    render() {
        const { t, i18n } = this.props;
        const currentLanguage = i18n.resolvedLanguage;
        const TermsComponent = currentLanguage === 'en' ? TermsBEN : TermsB;
        const PrivacyComponent = currentLanguage === 'en' ? PrivacyBEN : PrivacyB;
        return (
            <div>
                <Image style={{
                    width: "97%",
                    marginBottom: 5
                }} src="/images/linha_touro.svg" />
                <Container className="footer-bg pl-0 pr-0 pt-0 pb-2 text-mid_footer" fluid>
                    <div style={{ width: '100%', height: '5px' }}></div>
                    <div style={{ width: '100%', height: '3px', backgroundColor: '#CEBA85' }}></div>
                    <Row className="justify-content-md-center m-0 pt-2 pb-4">
                        <Col className="align-items-center row flex-column" md="2">
                            <a><img
                                src="/logo-w.svg"
                                width="60"
                                height="60"
                                className="d-inline-block align-top"
                                alt="BC Coins Logo"
                            /></a>
                            <span className="text1">{this.props.t('copyright')}</span>
                        </Col>
                        <Col md="2">
                            <div className="mt-3 row flex-column ml-3">
                                <a href="/panel/about">{this.props.t('about')}</a>
                                {/* <a href="/panel/info">{this.props.t('info')}</a> */}
                                {this.context.loggedInUser ? (<a href="/panel/tarifas">{this.props.t('taxes')}</a>) : (<a href="/tarifas">{this.props.t('taxes')}</a>)}
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="mt-3 row flex-column ml-3">
                                <span className="clickable" onClick={() => this.privacy.show()}>{this.props.t('ppolicy')}</span>
                                <span className="clickable" onClick={() => this.terms.show()}>{this.props.t('terms')}</span>
                                <a href="/panel/open_support">{this.props.t('support')}</a>
                               {/* <a href="/panel/faq">FAQ</a>*/}
                            </div>
                        </Col>
                        <Col md="2">
                            <div className="mt-3 row flex-column ml-3">
                                <span className="text1 pb-2">{this.props.t('followus')}</span>
                                <div>
                                    <a href="https://twitter.com/bcxcorretora" className="pr-2"><i className="fab fa-twitter"></i></a>
                                    {/* <a href="/panel/" className="p-2"><i className="fab fa-facebook-f"></i></a> */}
                                    <a href="https://www.instagram.com/bcxcorretora" className="p-2"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <TermsComponent ref={ref => this.terms = ref} />
                    <PrivacyComponent ref={ref => this.privacy = ref} />
                </Container>
            </div>
        )
    }
}

const TranslatedBottom = withTranslation()(BottomBar);

export default TranslatedBottom;