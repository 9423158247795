import React, { useContext, useEffect, useState, useRef } from "react";
import Panel from "../components/Panel";
import dayjs from "dayjs";
import { Col, Table, Button } from "react-bootstrap";
import api from "../utils/api";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import ConfirmModal from "../components/ConfirmModal";
import { useTranslation, Trans } from 'react-i18next';
import he from 'he';

export default function Notifications() {
  const {
    setNotifications,
    setCountNotifications,
    loggedInUser,
    pagination,
    setPagination,
  } = useContext(AppContext);

  const { t } = useTranslation();

  const confirm = useRef(null);

  const navigate = useNavigate();

  const [load, setLoad] = useState(true);
  const [notificationsArr, setNotificationArr] = useState([]);

  useEffect(() => {
    setPagination(() => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });
  }, []);

  useEffect(() => {
    getNotifications();
  }, [pagination.offset]);

  async function getNotifications() {
    setLoad(true);

    const req = await api.get(
      `users-notifications?filter[order]=createdAt%20DESC&filter[skip]=${pagination.offset}&filter[limit]=${pagination.limit}`
    );
    const data = req.data;

    setNotifications(data.data);
    setNotificationArr(data.data);

    setPagination((pagination) => {
      return {
        ...pagination,
        limit: data.limit,
        lastPage: Math.ceil(data.total / data.limit),
      };
    });

    setLoad(false);
  }

  async function getCountNotifications() {
    const req = await api.get(
      `users-notifications/count?where[read]=false&usersId=${loggedInUser?.id}`
    );
    setCountNotifications(req.data.count);
    setLoad(false);
  }

  const deleteNotification = async (id) => {
    setLoad(true);

    await api.del(`/users-notifications/${id}`);

    await getNotifications();
    await getCountNotifications();
  };

  const readNotification = async (id) => {
    setLoad(true);

    await api.post(`/users-notifications/${id}/read`, {
      read: true,
    });

    await getNotifications();
    await getCountNotifications();
  };

  const readAllNotifications = async () => {
    setLoad(true);

    await api.post(`users-notifications/read-all`);

    await getNotifications();
    await getCountNotifications();
  };

  const redirectLink = (type) => {
    if (type == "kyc") {
      navigate("/panel/kyc");
    }

    if (type == "deposits") {
      navigate("/panel/deposits");
    }

    if (type == "withdraws") {
      navigate("/panel/withdraw");
    }

    if (type == "trade") {
      navigate("/panel/trade");
    }

    if (type == "trade-canceled") {
      navigate("/panel/trade_hist");
    }
  };

  return (
    <Panel page="notifications" title={t("notifications")} load={load}>
      <div className="row">
        <Col>
          <div
            style={{
              width: "100%",
              backgroundColor: "#50504E",
              display: "flex",
              justifyContent: "space-between",
              alignItems: 'center'
            }}
          >
            <span className="bold text1 ml-3 text-mid2">{t("notifications")}</span>
            <div class="btn-menu text-mid"><span class="text0 mr-1" onClick={() => {
                if (!confirm.current) return;
                confirm.current.show(
                  t("attention"),
                  <h3 className="text-center">{t("qmarkasread")}</h3>,
                  () => {
                    readAllNotifications()
                  }
                );

              }}>{t("markallasread")}</span></div>
          </div>

          <Table className="hist-table m-0 p-0" responsive="md" striped hover>
            <thead>
              <tr>
                <th>{t("date")}</th>
                <th>{t("description")}</th>
                <th>{t("markasread")}</th>
                <th>{t("see")}</th>
                <th>{t("exclude")}</th>
              </tr>
            </thead>
            <tbody>
              {notificationsArr.length > 0 ? (
                notificationsArr.map((notification) => {
                  return (
                    <tr key={notification.id}>
                      <td>
                        {dayjs(notification.createdAt).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </td>
                      <td>{he.decode(notification.event)}</td>

                      {notification.read ? (
                        <td>{t("readed")}</td>
                      ) : (
                        <td>
                          <Button
                            onClick={() => readNotification(notification.id)}
                          >
                            {t("read")}
                          </Button>
                        </td>
                      )}

                      <td>
                        <Button onClick={() => redirectLink(notification.type)}>
                        {t("see")}
                        </Button>
                      </td>

                      <td>
                        <Button
                          variant="danger"
                          onClick={() => deleteNotification(notification.id)}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="text-center" colSpan={6}>
                  {t("nonotifications")}.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {notificationsArr.length > 0 && (
            <Pagination pagination={pagination} setPagination={setPagination} />
          )}

        </Col>
        <ConfirmModal ref={confirm} />
      </div>
    </Panel>
  );
}
