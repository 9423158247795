import React from "react";
import { useTranslation } from 'react-i18next';

function MaintenancePage() {
  const { t } = useTranslation();

  const maintenancePageStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', 
    padding: '20px',
    fontFamily: 'Arial, sans-serif', 
    backgroundColor: '#f7f7f7',
  };

  const titleStyle = {
    fontSize: '2rem',
    marginBottom: '20px',
    color: '#333', 
  };

  const messageStyle = {
    fontSize: '1rem',
    marginBottom: '10px',
    textAlign: 'center',
    color: '#666', 
  };

  const checkBackStyle = {
    fontSize: '0.9rem',
    color: '#888', 
  };

  return (
    <div className="maintenance-page" style={maintenancePageStyle}>
      <h1 style={titleStyle}>{t('maintenance.title')}</h1>
      <p style={messageStyle}>{t('maintenance.message')}</p>
      <p style={checkBackStyle}>{t('maintenance.checkBack')}</p>
    </div>
  );
}

export default MaintenancePage;
