import React, { useState } from "react";
import TimelineListItem from "./TimelineListItem";
import { useTranslation, Trans } from 'react-i18next';

const LPHeader = () => {
  const { t } = useTranslation();

  const [items, setItems] = useState([
    {
      id: 1,
      direction: 'r',
      year: '2012',
      text: `${t('timeline1')}`,
      active: true
    },
    {
      id: 2,
      direction: 'l',
      year: '2015',
      text: `${t('timeline2')}`,
      active: false
    },
    {
      id: 3,
      direction: 'r',
      year: '2016',
      text: `${t('timeline3')}`,
      active: false
    },
    {
      id: 4,
      direction: 'l',
      year: '2017',
      text: `${t('timeline4')}`,
      active: false
    },
    {
      id: 5,
      direction: 'r',
      year: '2018',
      text: `${t('timeline5')}`,
      active: false
    },
    {
      id: 6,
      direction: 'l',
      year: '2019',
      text: `${t('timeline6')}`,
      active: false
    },
    {
      id: 7,
      direction: 'r',
      year: '2020',
      text: `${t('timeline7')}`,
      active: false
    },
    {
      id: 8,
      direction: 'l',
      year: '2021',
      text: `${t('timeline8')}`,
      active: false
    },
    {
      id: 9,
      direction: 'r',
      year: '2022',
      text: `${t('timeline9')}`,
      active: false
    },
    {
      id: 10,
      direction: 'l',
      year: '2023',
      text: `${t('timeline10')}`,
      active: false
    },
  ]);

  const toggleActive = itemId => {
    const removeClicked = items.map(item => {
      if (item.active === true) {
        return { ...item, active: !item.active };
      }
      return item;
    })
    const newItens = removeClicked.map(item => {
      if (item.id === itemId) {
        return { ...item, active: !item.active };
      }
      return item;
    })
    setItems(newItens);
  };

  return (
    <ul className="timeline">
      {items.map((item) =>
        <TimelineListItem key={item.id} item={item} toggleActive={toggleActive} />
      )}
    </ul>
  );
}

export default LPHeader;
