import React from "react";
import { Col, Row } from "react-bootstrap";
import Panel from "../components/Panel";
import { withTranslation } from 'react-i18next';

// git push update

class About extends React.Component {
  render() {
    return (
      <Panel history={this.props.history} page="about" title={this.props.t('abouttitle')} hide>
        <Row>
          <Col className="text2 mt-3 mb-5">
            <p className="bold">BCX Criptoativos | {this.props.t('cronology')}</p>
            <p>
            {this.props.t('text.textp1')}
            </p>
            <p>
            {this.props.t('text.textp2')}
            </p>
            <p>
            {this.props.t('text.textp3')}
            </p>
            <p>
            {this.props.t('text.textp4')}
            </p>
            <p>
            {this.props.t('text.textp5')}
            </p>
            <p>
            {this.props.t('text.textp6')}
            </p>
            <p>
            {this.props.t('text.textp7')}
            </p>
            <p>
            {this.props.t('text.textp8')}
            </p>
          </Col>
        </Row>
      </Panel>
    );
  }
}

const TranslatedAbout = withTranslation()(About);

export default TranslatedAbout;