import React, { useEffect, useState, useRef, useContext } from "react";
import { Button,  Form, Modal, Row, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AppContext } from "../context/AppContext";
import api, { microservice } from "../utils/api";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import TimerExpire from "./TimerExpire";
import { formatBTCString } from "../utils/Helpers/btcFormat";
import { useTranslation, Trans } from 'react-i18next';
import '../i18n'

export const removeSymbol = (value) => {
  return value.replace("R$", "").trim();
}

export const numberFormatBRL = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}

function InvestmentWithdrawProfitModal({ investment, showModal }) {
  const profitAmountInput = useRef(0);
  const requestedAmountInput = useRef(0);
  
  const { t , i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage.toString();

  const [inputValue, setInputValue] = useState(0);
  const [withdrawDate, setWithdrawDate] = useState(dayjs().add(5, 'day').format('YYYY-MM-DD'));
  const [validDate, setValidDate] = useState(true);
  const [lockedBtcPrice, setLockedBtcPrice] = useState(null);
  const [advanceNotice, setAdvanceNotice] = useState(true);
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const [alreadyHasPendingWithDraw, setAlreadyHasPendingWithDraw] =
    useState(null);
  const [alreadyHasApprovedWithDraw, setAlreadyHasApprovedWithDraw] =
    useState(null);

  const {
    timerFinished,
    setShowInvestmentWithdrawProfitModal,
    btcPrice,
  } = useContext(AppContext);

  const checkPendingWithdraws = (investment) => {
    const pendingWithdraw = investment?.dailyReports?.some((dr, i) => {
      const pending = dr.withdraws.some((w) => w.status === "pending");
      if (pending) {
        return true;
      } else {
        return false;
      }
    })
    if (pendingWithdraw) {
      setAlreadyHasPendingWithDraw(true);
      return true;
    } else {
      return false;
    }
  };

  const checkApprovedWithdraws = (investment) => {
    const today = dayjs().format('YYYY-MM-DD'); // Get today's date in YYYY-MM-DD format
    
    const approvedWithdraw = investment?.dailyReports?.some((dr, i) => {
      const approved = dr.withdraws.some((w) => {
        return w.status === "approved" && 
               w.type === "rentability" && 
               dayjs(w.updatedAt).format('YYYY-MM-DD') === today;
      });
  
      if (approved) {
        return true;
      } else {
        return false;
      }
    });
  
    console.log("approved withdraw?", approvedWithdraw);
    
    if (approvedWithdraw) {
      setAlreadyHasApprovedWithDraw(true);
      return true;
    } else {
    
      return false;
    }
  };

  useEffect(() => {
    setValue("investmentId", "0");
    setValue("requestedTo",   dayjs().add(5, 'day').format("DD/MM/YYYY"));
    setValue("requestedAmount", "0");
    setValue("exchangeRate", "0");
    checkPendingWithdraws(investment);
    checkApprovedWithdraws(investment);
    setLockedBtcPrice(btcPrice);
  }, [lockedBtcPrice]);

  const messageRequired = t("msgrequired"); // Por favor, preencha este campo.";

  const investmentWithdrawSchema = yup.object().shape({
    requestedTo: yup.string().required(messageRequired),
    requestedAmount: yup.number().required(messageRequired),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(investmentWithdrawSchema),
  });

  const { ref: profitAmountRHFRef } = register("profitAmount");
  const { ref: requestedAmountRHFRef } = register("requestedAmount");

  const handleCloseModal = () => {
    setValue("profitAmount", "0");
    setValue("requestedAmount", "0");
    setShowInvestmentWithdrawProfitModal(false);
    setLockedBtcPrice(btcPrice);
  };

  const setRequestedAmount = (event) => {
    setInputValue(event.target.value);
  }

  const onWithdrawDateChange = (e) => {
    const value = e.target.value;
    setWithdrawDate(dayjs(value).format('YYYY-MM-DD'));
    const todayPlus5Days = dayjs().add(5, "day")
    const todayPlus30DaysDateObj = dayjs().add(30, "day")
    const withdrawDateObj = dayjs(value);
    setAdvanceNotice(withdrawDateObj.isAfter(todayPlus30DaysDateObj, 'day'));
    if((withdrawDateObj.day() === 0 || withdrawDateObj.day() === 6) || withdrawDateObj.isBefore(todayPlus5Days, 'day')){
      setValidDate(false);
    } else {
      setValidDate(true);
    }
  };

  const findLastAppliedProfit = () => {
    for (let i = investment.dailyReports.length - 1; i >= 0; i--) {
      const report = investment.dailyReports[i];
      if (report.appliedProfit && report.dailyProfit > 0) {
        return report.dailyProfit.toFixed(2);
      }
    }
    return 0;
  };

  const submit = async (data) => {
    setLoadingChangeStatus(true);

    const lastProfitAmount = findLastAppliedProfit();

    let requestedAmount = inputValue * lockedBtcPrice;

    if ((investment.dailyReports.length > 0) && (requestedAmount > lastProfitAmount)) {
      toast.error(t("error.withdrawoverbalance") , { // "O valor solicitado é maior do que o disponível."
        position: "top-right",
        theme: "colored",
      });
      setLoadingChangeStatus(false);
      return;
    };
    if ((investment.dailyReports.length === 0) && (requestedAmount > investment.initialAmount )) {
      toast.error(t("error.withdrawoverbalance"), {
        position: "top-right",
        theme: "colored",
      });
      setLoadingChangeStatus(false);
      return;
    };

    const todayPlusFive = dayjs().add(5, 'day')

    const withdrawDateObj = dayjs(withdrawDate, 'YYYY-MM-DD');
    if (withdrawDateObj.isBefore(todayPlusFive, 'day')) {
      toast.error(t("error.withdrawminimumdate") , { // "Data de retirada deve ser ao menos 5 dias maior a data atual."
        position: "top-right",
        theme: "colored",
      }, handleCloseModal(), setLoadingChangeStatus(false));
      return
    }

    const res = await api.post(`withdraws`, {
      investmentId: investment._id,
      requestedTo: dayjs(withdrawDate),
      requestedAmount: requestedAmount,
      exchangeRate: lockedBtcPrice,
      type: "rentability",
      userId: investment.userId,
      locale: currentLanguage
    });

    setLoadingChangeStatus(false);

    if (!res.data.error) {
      toast.success(t("withdrawsuccess"), {   /// "Solicitação de saque realizada com sucesso."
        position: "top-right",
        theme: "colored",
      });
    } else {
      toast.error(t("error.withdrawdenied") , {    /// "Solicitação de saque negada."
        position: "top-right",
        theme: "colored",
      });
    }

    handleCloseModal();
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };


  if (alreadyHasPendingWithDraw) {
    return (
      <Modal show={  showModal } onHide={ handleCloseModal } size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title
            className="w-100 ml-3 text-center"
            style={{
              textDecoration: "underline",
              textTransform: "uppercase",
              color: "#ceba85",
            }}
          >
           {t("withdrawpending")} {/* Solicitação de Saque Pendente */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="w-100 pl-5 pr-5 pb-5 pt-0 ">
          <Row
            className="mb-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p className="bold">
            {t("waitwithdrawpending")} {/* Você já possui uma solicitação de saque pendente. Espere pela
              aprovação! */} 
            </p>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  if (alreadyHasApprovedWithDraw) {
    return (
      <Modal show={  showModal } onHide={ handleCloseModal } size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title
            className="w-100 ml-3 text-center"
            style={{
              textDecoration: "underline",
              textTransform: "uppercase",
              color: "#ceba85",
            }}
          >
           {t("withdrawpending")} {/* Solicitação de Saque Pendente */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="w-100 pl-5 pr-5 pb-5 pt-0 ">
          <Row
            className="mb-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p className="bold">
            {t("waitwithdrawapproved")} {/* Você já realizou um saque de rentabilidade neste periodo
               */} 
            </p>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={ showModal } onHide={ handleCloseModal } size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title
          className="w-100 ml-3 text-center"
          style={{
            textDecoration: "underline",
            textTransform: "uppercase",
            color: "#ceba85",
          }}
        >
          {t("requestwithdraw")} {t("profit")} {/* Solicitar saque */}  
        </Modal.Title>
      </Modal.Header>
      <TimerExpire />
      <Form className="w-100" onSubmit={ handleSubmit(submit) }>
        <Modal.Body className="w-100 pb-0 pt-0">
          <Row
            className="mb-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >

            <div className="form-group col">
              <div className="mt-3 mb-2">
                  <Form.Group className="mt-4 mb-2">
                    <Form.Label style={{ fontWeight : "bold", color: "black" }}> {t("withdrawvalue")} {/* Valor do Saque: */}</Form.Label>
                    <div className="input-group">
                      <input
                        className="input append form-control"
                        name="inputValue"
                        type="number"
                        value={ inputValue }
                        minLength={ 0.0000001 }
                        maxLength={ 13 }
                        onChange={ setRequestedAmount }
                      />
                      <div className="input-group-append append-from-input">
                        <span className="input-group-text input append-from-input">
                          BTC
                        </span>
                      </div>
                    </div>
                    <div style={{ fontWeight :"400", fontStyle: "italic", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                      <span>{t("refference")} (R$): </span>
                      <span>R$ {inputValue > 0 ? removeSymbol(numberFormatBRL(inputValue * lockedBtcPrice)) : 0}</span>
                    </div>
                    {errors.requestedAmount && (
                      <span className="invalid-feedback show">
                        { errors.requestedAmount.message }
                      </span>
                    )}
                  </Form.Group>
                  <>
                    <br></br>
                    <label style={{ fontWeight : "bold", color: "black" }}>{t("withdrawdate")} {/* Data para saque: */}</label>
                    <input
                        className="input form-control"
                        type="date" 
                        name="withdrawDate"
                        onChange={ onWithdrawDateChange }
                        value={ withdrawDate }
                        min={dayjs().add(5, 'day').format('YYYY-MM-DD')}
                        style={{ width :"467px", height:  "38px",  borderColor:  "#B4A680 !important"}}
                      />
                    {/* {!advanceNotice && (
                      <p id="exp-timer-txt" style={{ fontWeight :"400", textAlign: "justify", lineHeight: "17px", fontStyle: "italic" }}>Aviso: Saques antecipados solicitados com menos de 30 dias da data de hoje serão considerados saques sem aviso prévio, sofrendo incidência de multa de 40% da rentabilidade obtida, nos termos do contrato.</p>
                    )} */}
                    
                    { !validDate && (
                      <p id="exp-timer-txt">{t("invalidwithdrawdate")} {/* Não é possível solicitar saques para datas anteriores a 5 dias da data de hoje e/ou Finais de semana */}</p>
                    ) }
                    
                    <br></br>
                    <div style={{fontWeight: "600", display: "flex", justifyContent: "space-between", flexDirection: "row !important !important"}}>
                      <span>
                      {t("currentrentabilityvalue")} {/* Valor atual de Rentabilidade */}
                      </span>
                      {
                      investment.dailyReports?.length > 0 ? (
                        <span>
                         {"R$"} {findLastAppliedProfit()}  {/* (investment.dailyReports[investment.dailyReports?.length - 1]?.dailyProfit) */}
                        </span>
                      ) : (
                        <span>
                          R$ 0,00
                        </span>
                      )}
                    </div>
                    <div style={{fontWeight: "400", display: "flex", justifyContent: "space-between", flexDirection: "row !important !important", fontStyle: "italic"}}>
                      <span style={{}}>
                      {t("refference")} (BTC):
                      </span>
                      { investment.dailyReports?.length > 0 ? (
                        <span>
                          { formatBTCString(findLastAppliedProfit() / lockedBtcPrice) }
                        </span>
                      ) : (
                        <span>
                          { formatBTCString(0) }
                        </span>
                      )}
                    </div>
                  </>
              </div>
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          {loadingChangeStatus ? (
            <div className="text-center">
              <Spinner
                variant="secondary"
                animation="border"
                role="status"
              ></Spinner>
            </div>
          ) : (
            <>
              <Button variant="primary" type="submit" disabled={timerFinished || !validDate}>
              {t("confirm")}
              </Button>
              <Button onClick={ handleCloseModal } variant="primary" disabled={ timerFinished }>
              {t("cancel")}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default InvestmentWithdrawProfitModal;
