import React, { useEffect, useContext, useState, useRef, useMemo } from "react";
// import React, { useEffect, useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
import api from "../utils/api";
import { setAmount } from "../pages/SendCrypto";
// import BitcoinToBrl from "../utils/bitcoinToBrl";
import { numberFormatBRL } from "./InvestmentWithdrawModal";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatMoney } from "../utils/helper";
import { formatBTCString } from "../utils/Helpers/btcFormat";
import { useTranslation, Trans } from 'react-i18next';
import CurrencyInput from './CurrencyInput'

export default function BuyBTC() {
  const { modalBuyBTC, setModalBuyBTC, getBalances, userObj, btcPrice, fees } =
    useContext(AppContext);

  // const amountInput = useRef(null);

  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("0");
  const [inputBRL, setInputBRL] = useState("0");
  const { t } = useTranslation();
  const amountInput = useRef(null);
  const [market, setMarket] = useState(true);
  const [selectedCoin, setSelectedCoin] = useState("");
  const [inputValueBRL, setInputValueBRL] = useState("0");
  const [inputValueBRLDisplay, setInputValueBRLDisplay] = useState("0");
  const [inputValueBTC, setInputValueBTC] = useState("0");
  const [inputBTCDisplay, setInputBTCDisplay] = useState("0");
  const messageRequired = "Por favor, preencha este campo.";
  const slippageValue = Number(fees[7]?.value);
  const sendCryptoSchema = yup.object().shape({
    amount: yup.string().required(messageRequired),
    //   .test(
    //     "minimum-amount",
    //     "O valor mínimo é de 0.000001 (100 satoshi)",
    //     (val) => val != 0
    //   )
    //   .test(
    //     "maxLength",
    //     "O valor máximo é de 13 caracteres.",
    //     (val) => val.length <= 13
    //   )
    //   .test(
    //     "minLength",
    //     "O valor mínimo é de 1 caractere.",
    //     (val) => val.length >= 1
    //   ),
    // // btcPrice: yup.string(),
    // marketType: yup.string().default("market"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sendCryptoSchema),
  });

  const createOrder = async (validData) => {
    setLoading(true);

    const amount = Number(inputValueBTC);


    if (amount < 0.0003) {
      toast.error(t("valuebelowbtc"), { // `O valor deve ser maior que 0.0001`
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        // pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      return;
    }

    let data = new Date();
    let formatedDate = `${data.getFullYear()}-${data.getMonth() + 1 > 10
      ? data.getMonth() + 1
      : "0" + (data.getMonth() + 1)
      }-${data.getDate()}T${data.getHours() > 10 ? data.getHours() : "0" + data.getHours()
      }:${data.getMinutes() > 10 ? data.getMinutes() : "0" + data.getMinutes()
      }:06.727Z`;

    toast.warn(t("marketSlippage"), {
      position: "top-center",
      autoClose: true,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: "priceDifferenceToast",
      onClose: (reason) => {
        if (reason === "click") {
          // Handle the user action if needed
        }
      },
    });

    if (selectedCoin === "BRL") {
      try {
        let res = await api.post("orders", {
          amount: parseFloat(validData.amount / btcPrice),
          currentAmount: 0,
          price: String(validData.btcPrice),
          quantity: String(validData.amount),
          pair: "btc",
          type: "bid",
          operation: "market",
          status: "open",
          createdAt: formatedDate,
          updatedAt: formatedDate,
        });

        if (!res.error) {
          setLoading(false);
          if (res.data.filledOrders && res.data.filledOrders.length > 0) {

            for (const filledOrder of res.data.filledOrders) {
              const brlPrice = numberFormatBRL(filledOrder.price);
              toast.success(t("filledOrder", { type: "BUY | COMPRA", price: brlPrice, quantity: filledOrder.quantity }), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          } else {
            toast.success(t("ordercreatedsuccess"), {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          return;
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 500) { // Check for HTTP 500 error
          const errorMessage = error.response.data.message || "Internal Server Error"; // Extract error message from error.response.data
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } else {
      try {
        let res = await api.post("orders", {
          amount: Number(inputValueBTC),
          currentAmount: Number(0),
          price: String(btcPrice),
          quantity: String(inputValueBTC * btcPrice),
          pair: "btc",
          type: "bid",
          operation: "market",
          status: "open",
          // "createdAt": formatedDate,
          // "updatedAt": formatedDate
        });

        if (!res.error) {
          setLoading(false);
          if (res.data.filledOrders && res.data.filledOrders.length > 0) {

            for (const filledOrder of res.data.filledOrders) {
              const brlPrice = numberFormatBRL(filledOrder.price);
              toast.success(t("filledOrder", { type: "BUY | COMPRA", price: brlPrice, quantity: filledOrder.quantity }), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          } else {
            toast.success(t("ordercreatedsuccess"), {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          return;
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 500) { // Check for HTTP 500 error
          const errorMessage = error.response.data.message || "Internal Server Error"; // Extract error message from error.response.data
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };

  const { ref: amountRHFRef, ...theArgs } = register("amount");

  useEffect(() => {
    setValue("amount", "0");
    setValue("btcPrice", btcPrice);
  }, []);

  const amount = watch("amount");
  const btcPriceLocal = watch("btcPrice");

  const btcMarketPrice = useMemo(() => {
    // console.log(btcPriceLocal, amount, 'btcMarketPrice')
    return btcPriceLocal * amount;
  }, [btcPriceLocal, amount]);

  useEffect(() => {
    // remove o R$ e troca a virgula por ponto

    setValue("btcPrice", String(btcPrice).replace("R$", "").replace(",", "."));
  }, [market]);

  const handleBTCChange = (event) => {
    let { value } = event.target;

    value = value.replace(/[^0-9.,]/g, "");
    value = value.replace(/,/g, ".");

    const dotIndex = value.indexOf(".");
    if (dotIndex !== -1) {
      const beforeDot = value.slice(0, dotIndex);
      const afterDot = value.slice(dotIndex + 1).replace(/\./g, "");
      value = `${beforeDot}.${afterDot}`;
    }

    setInputValueBTC(value);
    setInputBTCDisplay(value);
    let convertedValue = value * btcPrice;
    convertedValue = convertedValue.toString();
    // Remove any characters that are not numbers, commas, or dots
    convertedValue = convertedValue.replace(/[^0-9.,]/g, "");
    convertedValue = convertedValue.replace(/\./g, ",");

    setInputValueBRLDisplay(convertedValue);
    convertedValue = convertedValue.replace(/,/g, ".");
    setInputValueBRL(convertedValue);
  };

  const handleBRLChange = (event) => {
    let { value } = event.target;

    // Remove any characters that are not numbers, commas, or dots
    value = value.replace(/[^0-9.,]/g, "");

    // Replace dots with an empty string to join numbers
    value = value.replace(/\./g, "");


    // Update the input value with the transformed value
    setInputValueBRLDisplay(value);
    value = value.replace(/,/g, ".");
    setInputValueBRL(value);

    value = value.replace(/,/g, ".");
    const convertedValue = value / btcPrice;
    setInputValueBTC(Number(convertedValue));
    setInputBTCDisplay(Number(convertedValue).toFixed(8));

  };


  // const messageRequired = "Por favor, preencha este campo.";

  // const buySchema = yup.object().shape({
  //   // amount: yup
  //     // .string()
  //     // .required(messageRequired)
  // });

  // const {
  //   register,
  //   handleSubmit,
  //   setValue,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(buySchema),
  // });

  // const { ref: amountRHFRef, ...theArgs } = register("amount");

  const navigate = useNavigate();

  const close = () => {
    setModalBuyBTC(false);
  };

  // const buy = async (data) => {
  const handleBuy = async (e) => {
    e.preventDefault();

    const amount = Number(inputValue);

    if (amount < 0.0001) {
      toast.error(t("valuebelowbtc"), { // `O valor deve ser maior que 0.0001`
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        // pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setModalBuyBTC(false);
      // try {
      // setLoading(true);
      const res = await api.post("deposit-btc", {
        amount: Number(inputValue),
        destiny: "bitcoin",
        currency: "BTC",
        type: "bitcoin",
        exchangeRate: btcPrice,
        // twoFactorAuth: data.twoFactorAuth,
      });

      console.log({
        amount: Number(inputValue),
        destiny: "bitcoin",
        currency: "BTC",
        type: "bitcoin",
        exchangeRate: btcPrice,
        // twoFactorAuth: data.twoFactorAuth,
      });

      const resData = res.data;

      setLoading(true);

      if (!res.data.error) {
        setLoading(false);
        if (res.data.filledOrders && res.data.filledOrders.length > 0) {

          for (const filledOrder of res.data.filledOrders) {
            const brlPrice = numberFormatBRL(filledOrder.price);
            toast.success(t("filledOrder", { type: "BUY | COMPRA", price: brlPrice, quantity: filledOrder.quantity }), {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          toast.success(t("buybtcqty", { inputValue }), {  // `Compra de ${inputValue} btc realizada com sucesso!`
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // navigate("/panel/receive");
        getBalances(userObj.id);
      }

      if (res.error) {
        setLoading(false);
        toast.error(t("error.buybtcqty"), { // `Erro ao realizar a compra.`
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setLoading(false);
      setModalBuyBTC(false);
    }
  };

  const handleChange = (event) => {
    let { value } = event.target;

    value = value.replace(/[^0-9.,]/g, "");

    value = value.replace(/,/g, ".");

    const dotIndex = value.indexOf(".");
    if (dotIndex !== -1) {
      const beforeDot = value.slice(0, dotIndex);
      const afterDot = value.slice(dotIndex + 1).replace(/\./g, "");
      value = `${beforeDot}.${afterDot}`;
    }

    setInputValue(value);
    const convertedValue = value * btcPrice;
    setInputBRL(Number(convertedValue).toFixed(2));
  };

  const calculateMinusFee = (amount) => {
    const amountNumber = Number(amount);
    switch (fees[2]?.currency) {
      case 'BRL': {
        const value = amountNumber - ((Number(fees[2]?.value) / Number(btcPrice)))
        if (value < 0) {
          return formatBTCString(0)
        } return formatBTCString(value)
      }
      case 'BTC': {
        const value = amountNumber - ((Number(fees[2]?.value)))
        if (value < 0) {
          return formatBTCString(0)
        } return formatBTCString(value)
      }
      default: {

        const value = amountNumber - (amountNumber * (fees[2]?.value / 100))
        if (value < 0) {
          return formatBTCString(0)
        } return formatBTCString(value)
      }
    }
  }

  return (
    <Modal show={modalBuyBTC} onHide={close} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 ml-3 text-center">
          {t("buybtc")}
        </Modal.Title>
      </Modal.Header>
      {/* <Form className="w-100" onSubmit={handleSubmit(buy)}> */}

      <Form onSubmit={handleSubmit(createOrder)} className="card p-3">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h5 className="buy-active" style={{ alignItems: "center" }}>
            {t("buymarket")} {/* Comprar a mercado. */}
          </h5>
        </div>

        <Form.Group className="text-center mt-1 mb-4 bold-title">
          {errors.crypto && (
            <span className="invalid-feedback show">
              {errors.crypto.message}
            </span>
          )}

          {/* {{amount}} */}
          <Form.Label className="mt-4 bold-title">{t("qty")}</Form.Label>
          {selectedCoin === "BRL" ? (
            <>
              <div className="input-group">
                {/* <input
              className="input append form-control"
              name="amount"
              type="text"
              minLength={1}
              maxLength={1000000}
              max={100000}
              min={50}
              {...register("amount", {
                setValueAs: (value) => setAmount(amountInput, value, selectedCoin),
              })}
              ref={(e) => {
                amountRHFRef(e);
                amountInput.current = e;
              }}
            />
            <div className="input-group-append append-from-input">
              <span className="input-group-text input append-from-input">
                BRL
              </span>
            </div> */}
              </div>
              {/* <div style={{ fontWeight :"400", fontStyle: "italic", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
            <span>Referência (BTC): </span>
            <span>{amount > 0 ? Number(amount / btcPrice).toFixed(8) : `0.00000000 BTC`}</span>
          </div> */}
            </>
          ) : (
            <>
              <div className="input-group">
                <CurrencyInput
                  className="input append form-control"
                  name="amount"
                  type="text"
                  value={inputValueBRLDisplay}
                  onChange={handleBRLChange}
                  // You can pass additional props if necessary
                  maskOptions={{
                    prefix: '',
                    allowNegative: false,
                    decimalLimit: 2,
                    integerLimit: 10,
                  }}
                />
                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                    BRL
                  </span>
                </div>
              </div>
              <div className="input-group">
                <input
                  className="input append form-control"
                  name="amount"
                  type="text"
                  minLength={1}
                  maxLength={20}
                  // {...register("amount", {
                  //   setValueAs: (value) => setAmount(amountInput, value,  selectedCoin),
                  // })}
                  // ref={(e) => {
                  //   amountRHFRef(e);
                  //   amountInput.current = e;
                  // }}
                  value={inputBTCDisplay}
                  onChange={handleBTCChange}
                />
                <div className="input-group-append append-from-input">
                  <span className="input-group-text input append-from-input">
                    BTC
                  </span>
                </div>
              </div>
            </>
          )}
          {errors.amount && (
            <span className="invalid-feedback show">
              {errors.amount.message}
            </span>
          )}

          <div style={{ fontWeight: "400", fontStyle: "italic", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
            <span>{t("valuetoreceive")}: </span>
            <span>{inputValueBTC > 0 ? (calculateMinusFee(inputValueBTC)) : `0.00000000 BTC`}</span>
          </div>


          <Form.Label className="mt-4 bold-title">{t("buyvalue")} {/* Valor de Compra */}</Form.Label>
          <div
            className="d-flex mb-2 mt-2"
            style={{ justifyContent: "space-evenly" }}
          ></div>

          {market ? (
            <div className="input-group">
              <input
                className="input form-control"
                name="btcPrice-market"
                type="text"
                value={`R$ ${formatMoney(btcPrice)}`}
                disabled
              />
            </div>
          ) : (
            <div className="input-group">
              <input
                className="input form-control"
                name="btcPrice"
                {...register("btcPrice")}
                type="text"
                required
              />
            </div>
          )}
          <div style={{ fontWeight: "400", fontStyle: "italic", fontSize: 13, color: "red", justifyContent: "center", marginTop: "10px" }}>
            <span>({t("slippage", { slippageValue })})</span>
          </div>
          {/* <Form.Label className="mt-4 bold-title">Total</Form.Label>
          <div className="input-group">
            { selectedCoin === 'BRL' ? (
              <input
              className="input form-control"
              name="btcPrice-market"
              type="text"
              value={`${numberFormatBRL(amount)}`}
              disabled
            />
            ) :  (
              <input
              className="input form-control"
              name="btcPrice-market"
              type="text"
              value={`${numberFormatBRL(btcPrice * amount)}`}
              disabled
            />
            )}
          </div> */}
        </Form.Group>

        {/* <div className="orderbook-fees order-container">
            <table>
              <thead>
                <tr>
                  <th colSpan="3" className="orderbook-title">Tarifa de Compra da Corretora</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tarifa:</td>
                  <td>{fees?.buyFee?.value} %</td>
                </tr>
                <tr>
                  <td>Valor da Tarifa(BTC):</td>
                  <td>BTC { ((amount) * (Number(fees?.buyFee?.value) / 100)).toFixed(6) }</td>
                </tr>
                <tr>
                  <td>Referência (R$):</td>
                  <td>R$ { formatMoney((btcPrice * amount) * (Number(fees?.buyFee?.value) / 100)) }</td>
                </tr>
              </tbody>
            </table>
          </div> */}

        {!loading ? (
          <div className="text-center mb-3">
            <Button type="submit">{t("confirm")}</Button>
          </div>
        ) : (
          <div className="text-center mt-3 mb-3">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
      </Form>
    </Modal>
  );
}
