import React from 'react';
import { Card, Button, Row, Image } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import Panel from '../components/Panel';

class Dashboard extends React.Component {

    goTo = (path) => {
        this.props.navigate(path);
    }

    render() {
        return (<Panel history={this.props.history} page="dashboard" title="Meus Investimentos">
            <Row>
                <Card className="dashboard-card">
                    <span className="text-large2 bold text3">Bot</span>
                    <Image style={{
                        width: 100,
                        marginTop: 'auto',
                        cursor: 'pointer',
                    }} src="/images/bot.png" />
                    <Button style={{
                        width: 80,
                        marginTop: 15,
                    }} onClick={() => this.goTo('/panel/bot')}>Aporte</Button>
                </Card>
                <Card className="dashboard-card">
                    <span className="text-large2 bold text3">Investimentos</span>
                    <Image style={{
                        width: 100,
                        marginTop: 'auto',
                        cursor: 'pointer',
                    }} src="/images/trade.png" />
                    <Button style={{
                        width: 80,
                        marginTop: 15,
                    }} onClick={() => this.goTo('/panel/trade')}>Aporte</Button>
                </Card>
                <Card className="dashboard-card">
                    <span className="text-large2 bold text3">Mineração</span>
                    <Image style={{
                        width: 100,
                        marginBottom: 38,
                        marginTop: 'auto',
                        cursor: 'pointer',
                    }} src="/images/mining.png" />
                </Card>
            </Row >
        </Panel >)
    }
}


export default (props) => (<Dashboard {...props} navigate={useNavigate()}/>);