function TimelineListItem({ item, toggleActive }) {
    const handleClick = () => {
      toggleActive(item.id);
    };
  
    return (
      <li classname="timeline-li" onClick={ handleClick }>
        <div className={`direction-${item.direction}`}>
        <div className={`ball-${item.active}`}></div>
        <div className={`box-${item.direction}`}>
            <span className={`years-${item.active}`}>{item.year}</span>
            <div className={`text-${item.active}`}>
                {item.text}
            </div>
        </div>
        </div>
      </li>
    );
  }

export default TimelineListItem;