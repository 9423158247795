import React, { useState } from "react";
import { NavDropdown, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation, Trans } from 'react-i18next';

const LPHeader = () => {

  const { t } = useTranslation();
  const [myScroll, setMyScroll] = useState(false);

  window.onscroll = function (event) {
      var scroll = window.pageYOffset;
      if (scroll < 500) {
        setMyScroll(false)
        // console.log(myScroll)
      }
      if (scroll > 500) {
        setMyScroll(true)
        // console.log(myScroll)
    }
  };

    return (
      <div className={ `lp-header-${myScroll}`} fluid>
          <div>
            <a href="/">
              <img className={ `lp-header-logo-${myScroll}`}
                src="/images/bcx-logo-blue-gold.svg"
                alt="BC Coins Logo"
              />
            </a>
          </div >
          {myScroll && <div className="lp-header-buttons">
                <a href="/panel/signin">
                  <button id="login-bnt" >{t('enter')}</button>
                </a>
                  <LinkContainer
                    className="btn-primary mr-3 mb-1"
                    to="/panel/signup"
                  >
                    <Nav.Link>{t('register')}</Nav.Link>
                  </LinkContainer>
                  {/* <NavDropdown className="btn-primary mr-3 mb-1" title="PT">
                    <NavDropdown.Item>Português - PT</NavDropdown.Item>
                    <NavDropdown.Item>English - EN</NavDropdown.Item>
                  </NavDropdown> */}
          </div >}
      </div>
    );
  }

export default LPHeader;
