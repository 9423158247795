import React from "react";
import Panel from "../../components/Panel";
import { Table, Button } from "react-bootstrap";
import api from "../../utils/api";
import { disableAction } from "../../utils/helper";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";

class AdminEmployees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      usersList: [],
      offset: 0,
    };
  }

  static contextType = AppContext;

  componentDidMount = () => {
    this.reloadTable();

    this.context.setPagination(() => {
      return {
        currentPage: 1,
        lastPage: 1,
        offset: 0,
        limit: 10,
      };
    });
  };

  componentDidUpdate(_, prevState) {
    if (this.context.pagination.offset !== prevState.offset) {
      this.reloadTable();
    }
  }

  reloadTable = () => {
    this.setState({
      offset: this.context.pagination.offset,
      load: true,
    });

    api
      .get(
        `users?filter[order]=createdAt%20DESC&filter[skip]=${this.context.pagination.offset}&filter[limit]=${this.context.pagination.limit}&filter[where][rolesId]=f1915543-5015-45c6-959c-e5d9417d2051`
      )
      .then((req) => {
        if (!req.error) {
          let rows = req.data;

          this.context.setPagination((pagination) => {
            return {
              ...pagination,
              limit: rows.limit,
              lastPage: Math.ceil(rows.total / rows.limit),
            };
          });

          this.setState({
            load: false,
            usersList: rows.data,
          });
        }
      });
  };

  setPage = (page) => {
    if (page < 0) {
      page = 0;
    } else if (page > this.state.pages) {
      page = this.state.pages;
    } else {
      this.setState({
        page: page,
        filteredRows: this.state.rows.slice(page * 20, page * 20 + 20),
      });
    }
  };

  formatStatusKYC = (kycStatus) => {
    if (kycStatus === "accepted") {
      return "Sim";
    } else if (kycStatus === "rejected") {
      return "Rejeitado";
    } else if (kycStatus === "waiting") {
      return "Aguardando Aprovação";
    } else {
      return "Não";
    }
  };

  deleteUser = async (user) => {
    let response = window.confirm(
      "ATENÇÂO!!! Esta ação não pode ser desfeita.\nDeletar usuário: " +
        user.name
    );
    if (response) {
      let req = await api.del(`users/${user.id}`);
      if (!req.error) {
        this.reloadTable();
      }
    }
  };

  goTo = (row) => {
    this.context.setSelectedUser(row);
    this.props.navigate("/panel/admin/user/" + row.id);
  };

  changeStatus = (id, status) => {
    api
      .patch("users/activate", {
        id,
        active: status ? true : false,
      })
      .then((res) => {
        this.reloadTable();
      });
  };

  render() {
    return (
      <Panel
        load={this.state.load}
        history={this.props.history}
        page="employees"
        title="Usuários cadastrados"
        admin={true}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#50504E",
          }}
        >
          <span className="bold text1 ml-3 text-mid2">Lista de Usuários</span>
        </div>

        <Table
          className="hist-table m-0 p-0 text-mid"
          responsive="md"
          striped
          hover
        >
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              {/* <th>Email Confirmado</th> */}
              {/* <th>Telefone Confirmado</th> */}
              <th>Nível</th>
              <th>Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.usersList.length > 0 && this.state.usersList.map((row) => (
              <tr key={row.id}>
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.phone}</td>
                {/* <td>{row.emailConfirm ? "Sim" : "Não"}</td> */}
                {/* <td>{row.phoneConfirm ? "Sim" : "Não"}</td> */}
                <td>{row.usersPermissions?.length > 0 ? row.usersPermissions[0].level : 1}</td>
                <td>Ativo</td>
                <td>
                  <Button
                    disabled={disableAction(
                      this.context.loggedInUser?.permission?.level,
                      false,
                      false,
                      false
                    )}
                    onClick={() => this.goTo(row)}
                  >
                    Editar
                  </Button>
                </td>
                <td>
                  <Button
                    disabled={disableAction(
                      this.context.loggedInUser?.permission?.level,
                      false,
                      false,
                      false
                    )}
                    onClick={() => this.changeStatus(row.id, row.deletedAt)}
                  >
                    {row.deletedAt ? "Ativar" : "Inativar"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="d-flex justify-content-between align-items-center mt-3 mb-5 pr-5">
          {this.state.usersList.length > 0 && (
            <Pagination
              pagination={this.context.pagination}
              setPagination={this.context.setPagination}
            />
          )}

          <div className="text-center text-large">
            <Button
              disabled={disableAction(
                this.context.loggedInUser?.permission?.level,
                false,
                false,
                false
              )}
              onClick={() => {
                this.props.navigate("/panel/admin/employees/create");
              }}
            >
              Cadastrar
            </Button>
          </div>
        </div>
      </Panel>
    );
  }
}

export default (props) => (
  <AdminEmployees {...props} navigate={useNavigate()} />
);
