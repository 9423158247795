import React from "react";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Row,
  Form,
  Collapse,
  Image,
} from "react-bootstrap";
import api from "../../utils/api";
import { getProfilePhoto } from "../../utils/helper";
import { disableAction } from "../../utils/helper";
import ConfirmModal from "../ConfirmModal";
import { AppContext } from "../../context/AppContext";
import _debounce from "lodash/debounce";

export default class AdminUserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      roles: [],
      role: { id: "", name: "" },
      photoId: "",
      form: {
        name: "",
        tradingName: "",
        motherName: "",
        email: "",
        cpf: "",
        cnpj: "",
        corporateName: "",
        rg: "",
        birthDate: "",
        phone: "",
        postalCode: "",
        streetAddress: "",
        streetAddress2: "",
        streetAddressNumber: "",
        district: "",
        city: "",
        stateOrProvince: "",
        country: "",
        kycStatus: "",
        ppe: true,
      },
      legalPerson: false,
      levelPermission: "",
    };
  }

  componentDidMount = () => {
    this.toggleShow();
  };

  static contextType = AppContext;

  toggleShow = () => {
    if (this.state.show) {
      this.setState({ show: false });
    } else {
      let id = this.props.id;
      api.get(`users/${id}`).then((req) => {
        if (!req.error) {
          
          this.setState({
            show: true,
            role: req.data.roles,
            photoId: req.data.photoId,
            form: {
              name: req.data.name,
              tradingName: req.data.tradingName,
              motherName: req.data.motherName,
              email: req.data.email,
              cpf: req.data.cpf,
              rg: req.data.rg,
              cnpj: req.data.cnpj,
              corporateName: req.data.corporateName,
              birthDate: req.data.birthDate,
              phone: req.data.phone,
              postalCode: req.data.postalCode,
              streetAddress: req.data.streetAddress,
              streetAddress2: req.data.streetAddress2,
              streetAddressNumber: req.data.streetAddressNumber,
              district: req.data.district,
              city: req.data.city,
              stateOrProvince: req.data.stateOrProvince,
              country: req.data.country,
              rolesId: req.data.rolesId,
              kycStatus: req.data.kycStatus,
              ppe: req.data.ppe,
            },
            legalPerson: req.data.legalPerson,
          });

          if(req.data.usersPermissions) {
            let condition = req.data.usersPermissions.length > 0
                ? req.data.usersPermissions[0].level
                : 1;
  
            this.context.setLevelPermissionSelected(condition);
          }

          this.context.setUserSelected(req.data);
        }
      });
      api.get(`roles`).then((req) => {
        if (!req.error) {
          this.setState({
            roles: req.data,
          });
        }
      });
    }
  };

  handleChange = (event) => {
    let form = this.state.form;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    form[event.target.name] = value;
    this.setState({ form });
  };

  handlePpeChange = (event) => {
    let value = event.target.value;
    let booleanValue = value === "true";
    this.setState({form: {...this.state.form, ppe: booleanValue}});
  };

  setRole = (role) => {
    let form = this.state.form;
    form.rolesId = role.id;
    this.setState({ form, role });
  };

  submit = () => {
    this.confirm.show(
      "Atenção",
      <h3 className="text-center">Deseja salvar as modificações?</h3>,
      () => {
        this.saveProfile();
      }
    );
  };

  saveProfile = async () => {
    let id = this.props.id;
    let req = await api.patch(`users/${id}`, this.state.form);
    if (!req.error) {
      toast.success(`Usuário editado`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(`Erro ao editar usuário: ${req.message}`, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    }
  };

  
  handleDebounceFn = (postalCode) => {
    if (postalCode !== "_____-___" && postalCode !== "") {
      fetch(`https://viacep.com.br/ws/${postalCode}/json/`)
        .then((response) => response.json())
        .then((data) => {
          this.setState(prevState => ({
            ...prevState,
            form: {
              ...prevState.form,
              postalCode,
              streetAddress: data.logradouro,
              district: data.bairro,
              city: data.localidade,
              stateOrProvince: data.uf,
              country: "Brasil",
            },
          }));
        });
    }
  }
  debounceFn = _debounce(this.handleDebounceFn, 1500);

  handleChangeCEP = (event) => {
    event.persist();
    let value = event.target.value;

    // console.log(event.target, 'aqui')
    this.setState(prevState => {
      return {
      ...prevState,
      form: {
        ...prevState.form,
        [event.target.name]: value
      }
    }
    })

    this.debounceFn(value);
  };

  render() {
    return (
      <>
        <Form className="w-100 card p-3 mb-5">
          <div
            className="text-large bold mb-2 w-100 d-flex btn"
            onClick={this.toggleShow}
          >
            <span className="">Informações do perfil</span>
            <i className="fas fa-caret-down text-large mr-3 ml-auto"></i>
          </div>
          <div
            style={{ width: "100%", height: 1, backgroundColor: "#50504E" }}
          ></div>
          <Collapse in={this.state.show}>
            <div>
              <Row className="mb-1 mt-3">
                <Col>
                  <Image
                    style={{
                      margin: "auto",
                      width: 120,
                      height: 120,
                    }}
                    src={getProfilePhoto(this.state.photoId)}
                  />
                </Col>
              </Row>
              <Row className="mb-1 mt-3">
                <Form.Group as={Col}>
                  <Form.Label>Nome Completo</Form.Label>
                  <Form.Control
                    className="input"
                    name="name"
                    type="text"
                    value={this.state.form.name}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Nome da Mãe</Form.Label>
                  <Form.Control
                    className="input"
                    name="motherName"
                    type="text"
                    value={this.state.form.motherName}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    className="input"
                    name="email"
                    type="email"
                    value={this.state.form.email}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-1">
                <Form.Group as={Col}>
                  <Form.Label>CPF</Form.Label>
                  <Form.Control
                    as={InputMask}
                    mask="999.999.999-99"
                    className="input"
                    name="cpf"
                    type="text"
                    value={this.state.form.cpf}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>RG</Form.Label>
                  <Form.Control
                    className="input"
                    name="rg"
                    type="number"
                    value={this.state.form.rg}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Row>
              {this.state.legalPerson && (
                <>
                  <Row className="mb-1">
                    <Form.Group as={Col}>
                      <Form.Label>CNPJ</Form.Label>
                      <Form.Control
                        as={InputMask}
                        className="input"
                        name="cnpj"
                        mask="99.999.999/9999-99"
                        type="text"
                        value={this.state.form.cnpj}
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Nome Fantasia</Form.Label>
                      <Form.Control
                        className="input"
                        name="tradingName"
                        type="text"
                        value={this.state.form.tradingName}
                        onChange={this.handleChange}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Razão Social</Form.Label>
                      <Form.Control
                        className="input"
                        name="corporateName"
                        type="text"
                        value={this.state.form.corporateName}
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </Row>
                </>
              )}
              <Row className="mb-1">
                <Form.Group as={Col}>
                  <Form.Label>Data de Nasc.</Form.Label>
                  <Form.Control
                    className="input"
                    name="birthDate"
                    type="date"
                    value={this.state.form.birthDate}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    as={InputMask}
                    mask="(99) 99999-9999"
                    className="input"
                    name="phone"
                    type="text"
                    value={this.state.form.phone}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-1">
                <Form.Group as={Col}>
                  <Form.Label>CEP</Form.Label>
                  <Form.Control
                    as={InputMask}
                    mask="99999-999"
                    className="input"
                    name="postalCode"
                    type="text"
                    value={this.state.form.postalCode}
                    onChange={this.handleChangeCEP}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Endereço</Form.Label>
                  <Form.Control
                    className="input"
                    name="streetAddress"
                    type="text"
                    value={this.state.form.streetAddress}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-1">
                <Form.Group as={Col}>
                  <Form.Label>n°</Form.Label>
                  <Form.Control
                    className="input"
                    name="streetAddressNumber"
                    type="number"
                    value={this.state.form.streetAddressNumber}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Endereço</Form.Label>
                  <Form.Control
                    className="input"
                    name="streetAddress2"
                    type="text"
                    value={this.state.form.streetAddress2}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-1">
                <Form.Group as={Col}>
                  <Form.Label>Bairro</Form.Label>
                  <Form.Control
                    className="input"
                    type="text"
                    name="district"
                    value={this.state.form.district}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control
                    className="input"
                    name="city"
                    type="text"
                    value={this.state.form.city}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-1">
                <Form.Group as={Col}>
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    as={InputMask}
                    mask="aa"
                    className="input"
                    type="text"
                    name="stateOrProvince"
                    value={this.state.form.stateOrProvince}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>País</Form.Label>
                  <Form.Control
                    className="input"
                    name="country"
                    type="text"
                    value={this.state.form.country}
                    onChange={this.handleChange}
                  />
                </Form.Group>

                {this.context.userSelected?.role !== "admin" && (
                  <>
                  <Form.Group as={Col}>
                    <Form.Label>Nível de KYC</Form.Label>
                    <Form.Control
                      className="input"
                      as="select"
                      name="kycStatus"
                      onChange={this.handleChange}
                      value={this.state.form.kycStatus}
                    >
                      <option value="">Escolha um nível</option>
                      <option value="none">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </Form.Control>
                  </Form.Group>
                  </>
                )}
              </Row>
              <Row>
                <Form.Group as={Col}>
                <Form.Label>É uma Pessoa Politicamente Exposta (P.P.E)?</Form.Label>
                <Form.Control
                  className="input"
                  as="select"
                  name="ppe"
                  onChange={this.handlePpeChange}
                  value={this.state.form.ppe}
                >
                  <option value={ true }>Não. Não é uma Pessoa Politicamente Exposta P.P.E.</option>
                  <option value={ false }>Sim. É uma Pessoa Politicamente Exposta P.P.E.</option>
                </Form.Control>
              </Form.Group>
              </Row>
              {/* <Row className="mb-1">
                        <Form.Group as={Col}>
                            <Form.Label>Tipo de perfil</Form.Label>
                            <DropdownButton className="form-dropdown" title={` ${this.state.role.name.toUpperCase()}`}>
                                {this.state.roles.map(role =>
                                    <Dropdown.Item onClick={() => this.setRole(role)} key={role.id}>
                                        {role.name.toUpperCase()}
                                    </Dropdown.Item>)}
                            </DropdownButton>
                        </Form.Group>
                    </Row> */}
              <Row>
                <Button
                  disabled={disableAction(
                    this.context.loggedInUser?.permission?.level,
                    false,
                    false,
                    false
                  )}
                  className="ml-auto mr-auto"
                  onClick={this.submit}
                >
                  Salvar
                </Button>
              </Row>
            </div>
          </Collapse>
        </Form>
        <ConfirmModal ref={(ref) => (this.confirm = ref)} />
      </>
    );
  }
}
