import React, { useContext, useEffect } from "react";
import { useCountdown } from '../hooks/useCountdown';
import { AppContext } from "../context/AppContext";
import { useTranslation, Trans } from 'react-i18next';
import '../i18n'

function TimerExpire() {

  const twoMinutes = 2 * 60;
  
  const { t , i18n } = useTranslation();

  const { minutes, seconds } = useCountdown(twoMinutes);
  
  const { timerFinished, setTimerFinished } = useContext(AppContext);
  
  useEffect(() => {
    setTimerFinished(false);
  }, []);

  if ((minutes === 0) && (seconds === 0)) {
    setTimerFinished(true);
  };
  
  return (
      <div className='timer-div-parent'>
      {timerFinished ?
      <div className='timer-expired-div'>
        <div id='exp-timer-txt'>
           {t("timeexpire")} { /* Seu tempo expirou, tente novamente. */}
        </div>
      </div>
      : 
          <div className='timer-div'>
            <div className='timer-opt'>
              <div className='timer-num'>{ minutes }</div>
              <p className='timer-words'>{t("minutes")}</p>
            </div>
            <div className='timer-opt'>
              <div className='timer-num'>{ seconds }</div>
              <p className='timer-words'>{t("seconds")}</p>
            </div>
          </div>
      }
    </div>
  );
}

export default TimerExpire;
